import React, {useEffect, useReducer, useRef, useState} from "react";
import Styles from "./StreamChat.module.scss";
import {BsChatDotsFill} from "react-icons/bs";
import Classnames from "classnames";
import {BiSolidSend} from "react-icons/bi";
import {VscClose} from "react-icons/vsc";
import meta11ShortLogo from "../../assets/images/meta11ShortLogo.webp";
import * as PubNubService from "../../services/PubNubService";
import PubNubLiveChatHandler from "../LiveChat/PubNubLiveChatHandler";
import {connect} from "react-redux";
import * as helpers from "../../helpers/LeaderBoardHelpers"
import {format, isSameDay, isValid} from 'date-fns';
import {MdVerifiedUser} from "react-icons/md";
import {FaAnglesDown, FaAnglesUp} from "react-icons/fa6";
import {Mention, MentionsInput} from "react-mentions";
import {getConf} from "../../services/ConfigService";
import {STREAM_CHAT} from "../../utilities/ConfigConstants";
import {BsFillHandThumbsUpFill} from "react-icons/bs";
import {BsHandThumbsUp} from "react-icons/bs";
import {GoHeart, GoHeartFill} from "react-icons/go";
import {BiCool} from "react-icons/bi";
import Picker from "@emoji-mart/react";
import {useTranslation} from "react-i18next";


const StreamChat = (props) => {
   let verifiedUsersList = getConf(STREAM_CHAT.VERIFIED_USERS);
   let chatWithAiEnable = getConf(STREAM_CHAT.ENABLE_CHAT_WITH_AI);

   const {t} = useTranslation();
   const [send, setSend] = useState("");
   const msgListRef = useRef();
   const [connectPubnubLive, setConnectPubnubLive] = useState(false);
   const [liveToken, setLiveToken] = useState(null);
   const [message, setMessage] = useState(null);
   const [subscribeChannel, setSubscribeChannel] = useState("LiveChatReceive");
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);
   const [fetchHistory, setFetchHistory] = useState(false);
   const [fetchReactions, setFetchReaction] = useState(false);
   const [scrolledToBottom, setScrolledToBottom] = useState(false);
   const [showChat, setShowChat] = useState(false);
   const [lastScrollHeight, setLastScrollHeight] = useState(0);

   const [playerNames, setPlayerNames] = useState(['usman', 'takunda', 'vishal', 'mason', 'virath']);
   const [displayNames, setDisplayNames] = useState([{id: 'usman', display: '@usman'}, {id: 'vishal', display: '@vishal'}, {id: 'takunda', display: '@takunda'}, {id: 'virath', display: '@virath'}, {id: 'mason', display: '@mason'}]);
   const [isFirstRender, setIsFirstRender] = React.useState(true);
   const [messageAction, setMessageAction] = useState({});
   const [removeMessageAction, setRemoveMessageAction] = useState({});
   const [selectedEmojis, setSelectedEmojis] = useState([]);
   const mentionsInputRef = React.createRef();
   const [cursorPosition, setCursorPosition] = useState(0)
   const [showPicker, setShowPicker] = useState(false);
   const [historyFetch, setHistoryFetch] = useState(false);
   const [normalFetch, setNormalFetch] = useState(false);
   const observer = useRef();
   // const [reactions,setReactions] = useState([]);
   const REACTIONS = {
      SMILEY_FACE: "smiley_face",
      THUMBS_UP: "thumbs_up",
      HEART: "heart",
      READ: "read"
   }

   // useEffect with an empty dependency array runs only once (on mount)
   useEffect(() => {
      // Logic to run on the first render
      console.log('Component has been mounted for the first time');

      // Update the state to indicate that it's no longer the first render
      setIsFirstRender(false);

      // If you have cleanup logic, you can return a function to perform it
      return () => {
         console.log('Component will unmount');
         // Cleanup logic goes here
      };
   }, []);
   let MessageObject = {
      "userName": props.userName,
      "jwtToken": props.Token,
      "messageBodyType": "LIVE",
      "message": null,
      "adaptiveCard": null,
      "authenticated": null,
      "timestamp": null,
      "name": props.name,
      "profilePicUrl": props.profileImage,
      "isSeen": null
   }
   const initialState = {
      socketMessageState: [],
      reactions: [],
      unreadCount: 0,
      isRead: false
   }

   const botReducer = (state, action) => {
      switch (action.type) {
         case 'ADD_MESSAGES':
            const updatedMessages = [...state.socketMessageState, action.payload];
            return {
               ...state,
               socketMessageState: updatedMessages
            };
         case 'ADD_HISTORY_MESSAGES':
            const updatedHistoryMessages = [action.payload, ...state.socketMessageState];
            return {
               ...state,
               socketMessageState: updatedHistoryMessages
            };
         case 'UPDATE_MESSAGES':
            const updatedReactionMessages = state.socketMessageState.map((message) => {
                  // console.log("update messages 1",message.timetoken);
                  // console.log("update messages 2",action.payload.messageTimetoken);
                  if (message.timetoken == action.payload.messageTimetoken && message[action.payload.value] != action.payload.actionTimetoken) {
                     console.log("update messages")
                     return {
                        ...message,
                        [action.payload.value]: action.payload.actionTimetoken
                     }
                  }
                  return message
               }
            )
            return {
               ...state,
               socketMessageState: updatedReactionMessages
            }
         case 'GET_UNREAD_STATUS':
            const unreadStatus = state?.socketMessageState?.some(message => message?.messageTimetoken == action.payload.messageTimetoken && message.userName == props.userName && message.read != null)
            return {
               ...state,
               isRead: unreadStatus
            }
         case 'GET_UNREAD_COUNT':
            const lastReadTimestamp = localStorage.getItem(props.userName) || 0;
            const unreadCountArray = state?.socketMessageState?.filter(item => item.timetoken > lastReadTimestamp && item.userName != props.userName)
            return {
               ...state,
               unreadCount: unreadCountArray?.length
            }
         case 'UPDATE_MESSAGES_VISIBILITY':
            const updatedVisibleMessages = state.socketMessageState.map((message) => {
                  console.log("update messages 1", message.timetoken);
                  console.log("update messages 2", action.payload.messageTimetoken);
                  if (message.timetoken == action.payload.messageTimetoken) {
                     console.log("update messages")
                     return {
                        ...message,
                        "isSeen": true
                     }
                  }
                  return message
               }
            )
            return {
               ...state,
               socketMessageState: updatedVisibleMessages
            }
         case 'REMOVE_ACTION_MESSAGES':

            const updateRemovedReactionState = state?.reactions?.filter(item => item.actionTimetoken != action.payload.actionTimetoken)
            return {
               ...state,
               reactions: updateRemovedReactionState
            }
         case 'ADD_ACTION_MESSAGES':
            if (!state?.reactions?.some(reaction => reaction.uuid == action.payload.uuid && reaction.messageTimetoken == action.payload.messageTimetoken && reaction.value == action.payload.value)) {
               const updateAddedReactionState = [...state?.reactions, action.payload]
               return {
                  ...state,
                  reactions: updateAddedReactionState
               }
            } else {
               return state
            }

         case 'CLEAN_MAIN_STATE':
            const updatedMessageState = state?.socketMessageState?.map(item => {
               console.log("update messages 1", item.timetoken);
               console.log("update messages 2", action.payload.value);
               if (item[action.payload.value] == action.payload.value && item.messageTimetoken == action.payload.messageTimetoken) {
                  console.log("update messages")
                  return {
                     ...item,
                     [action.payload.value]: null
                  }
               }
               return item
            })
            return {
               ...state,
               socketMessageState: updatedMessageState
            }
         case 'ADD_REACTIONS':
            const uniqueReactions = action.payload.filter(item => (
               !state.reactions.some(existingItem => existingItem.actionTimetoken == item.actionTimetoken)
            ))
            const updatedReactions = [...state.reactions, ...uniqueReactions];
            return {
               ...state,
               reactions: updatedReactions
            };
         default:
            return state;
      }
   };

   const [state, dispatch] = useReducer(botReducer, initialState);

   useEffect(() => {

      const fetchData = async () => {

         console.log(props.Token)
         console.log(props.email)
         if (localStorage.getItem("pubnubLiveToken") == null) {
            await PubNubService.requestAccessTokenLive(props.userName, props.Token).then(res => {
               // console.log(res)
               console.log(res);
               setLiveToken(res);
               setConnectPubnubLive(true);
               localStorage.setItem("pubnubLiveToken", res);
               setSubscribeChannel("LiveChat-" + props.userName)
            }).catch(e => {

               console.log(e)
            })
         } else {
            setLiveToken(localStorage.getItem("pubnubLiveToken"));
            setConnectPubnubLive(true);
            setSubscribeChannel("LiveChat-" + props.userName)
         }
      }
      fetchData();

   }, [props.userName]);

   useEffect(() => {
      if (state?.socketMessageState?.length > 0 && scrolledToBottom == false) {
         scrollToBottom();
         setScrolledToBottom(true)
      }
   }, [state?.socketMessageState])
   const messageFromCallback = async (message) => {
      console.log("from callback quizbot", message)
      let data = {
         ...message,
         "direction": message.userName == props.userName ? "outgoing" : "incoming"
      }
      await dispatch({
         type: "ADD_MESSAGES",
         payload: data
      })
      if (message != null) {
         setHistoryFetch(false);
         setNormalFetch(true);
      }
      if (message.userName == props.userName) {
         console.log("equal usernames")
         scrollToBottom();
      }
   }
   useEffect(() => {
      console.log("last", state?.socketMessageState[state?.socketMessageState.length - 1])
      dispatch({
         type: "GET_UNREAD_COUNT"
      })
      if (state?.socketMessageState[state?.socketMessageState.length - 1]?.userName == props.userName) {
         console.log("equal usernames in use Effect")
         scrollToBottom();
      } else {
         console.log("no scroll")
      }
   }, [state?.socketMessageState])
   const messageFromHistoryCallback = (message) => {
      console.log("from callback live", message)

      let data = {
         ...message,
         "direction": message.userName == props.userName ? "outgoing" : "incoming"
      }
      dispatch({
         type: "ADD_HISTORY_MESSAGES",
         payload: data
      })
      if (message != null) {
         setHistoryFetch(true);
         setNormalFetch(false);
      }
      updateMessagesWithReactions();
   }

   const handlePlayerChat = (data) => {

      let final = {}
      const atIndices = [];
      const words = data.message.split(/\s+/);
      words.forEach((word, index) => {
         if (word.startsWith('@')) {
            const startIndex = data.message.indexOf(word);
            atIndices.push(startIndex);
         }
      });

      const mentionedPlayers = atIndices.map(index => {
         const playerNameStartIndex = index + 1;
         const nextSpaceIndex = data.message.indexOf(' ', playerNameStartIndex);
         const playerNameEndIndex = nextSpaceIndex !== -1 ? nextSpaceIndex : undefined;
         return data.message.slice(playerNameStartIndex, playerNameEndIndex);
      });

      mentionedPlayers.forEach(playerName => {
         if (playerNames.includes(playerName) && chatWithAiEnable) {
            let playerMessage = {
               ...data,
               "messageBodyType": "AI"
            }
            final = {
               "message": playerMessage,
               "channel": playerName.toUpperCase() + "-AI"
            }
            console.log("send Message: ", final)
            setMessage(final);
            setSend("");
            console.log("good to go")

         } else {
            final = {
               "message": data,
               "channel": "LiveChat"
            }
            console.log("send Message: ", final)
            setMessage(final);
            setSend("");
            console.log("not go")
         }
      });
   }
   const sendMessage = (message) => {
      if (message != "" && message?.trim() !== '') {

         const currentDate = new Date();

         const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false};
         const dateTimeString = currentDate.toLocaleString(undefined, options);

         console.log(dateTimeString);
         let final = {};
         let data = {
            ...MessageObject,
            "timestamp": null,
            "message": message
         }
         if (message.includes('@')) {
            handlePlayerChat(data)
         } else {
            final = {
               "message": data,
               "channel": "LiveChat"
            }
            console.log("send Message: ", final)
            setMessage(final);
            setSend("");
         }
         setShowPicker(false);
      }
   }
   const handleScrollEvent = (event) => {
      console.log('Scroll event properties:', {
         scrollTop: event.target.scrollTop,
         scrollHeight: event.target.scrollHeight,
         clientHeight: event.target.clientHeight,
      });
      dispatch({
         type: "GET_UNREAD_COUNT"
      })
      const {scrollTop, scrollHeight, clientHeight} = msgListRef.current;
      console.log("scrolling")

      setLastScrollHeight(msgListRef?.current?.scrollHeight);
      if (scrollTop === 0) {
         console.log("top")
         setFetchHistory(true);
      } else {
         setFetchHistory(false);
      }
      // if (scrollTop + clientHeight == scrollHeight){
      //     setUnreadCount(1);
      // }

   };
   const updateMessagesWithReactions = () => {
      console.log("reaction for each", state?.reactions)
      state?.reactions?.forEach(item => {
         console.log("reaction for each", item)
         console.log(item.messageTimetoken)
         console.log(item.actionTimetoken)
         if (item.uuid == props.userName) {
            dispatch({
               type: 'UPDATE_MESSAGES',
               payload: {
                  "messageTimetoken": item?.messageTimetoken,
                  "actionTimetoken": item?.actionTimetoken,
                  value: item.value
               }
            })
         }
      })
   }
   const updateMessageStateWithReactions = (messageTimetoken) => {
      if (!state?.socketMessageState?.some(message => message?.messageTimetoken == messageTimetoken && message.userName == props.userName && message.read != null)) {
         dispatch({
            type: 'UPDATE_MESSAGES',
            payload: {
               "messageTimetoken": messageTimetoken,
               "actionTimetoken": true,
               value: REACTIONS.READ
            }
         })

      }

   }
   useEffect(() => {
      const processEntries = async (entries) => {
         for (const entry of entries) {
            if (entry.isIntersecting) {
               dispatch({
                  type: "GET_UNREAD_STATUS",
                  payload: {
                     "messageTimeToken": entry.target.id
                  }
               })
               console.log("ID -=========", entry.target.id)
               console.log('State Reactions:', state?.reactions);
               console.log('Props UserName:', props.userName);
               console.log('Entry Target ID:', entry.target.id);
               console.log('REACTIONS.READ:', REACTIONS.READ);
               console.log("GET REACTION :", state?.reactions?.some(reaction => reaction.uuid == props.userName && reaction.messageTimetoken == entry.target.id && reaction.value == REACTIONS.READ));
               // if (!state?.isRead && !(JSON.stringify(messageAction) == JSON.stringify(
               //    {
               //       "channel": "LiveChatReceive",
               //       "messageTimetoken": entry.target.id,
               //       "type": "reaction",
               //       "value": REACTIONS.READ
               //    }))) {
               //    updateMessageStateWithReactions(entry.target.id);
               //
               //    setMessageAction({
               //       "channel": "LiveChatReceive",
               //       "messageTimetoken": entry.target.id,
               //       "type": "reaction",
               //       "value": REACTIONS.READ
               //    })
               //
               //    // setFetchReaction(true);
               // } else {
               //    // setFetchReaction(false);
               //    setMessageAction(null)
               // }
               const lastReadTimestamp = localStorage.getItem(props.userName) || 0;
               if (lastReadTimestamp <= entry.target.id) {
                  localStorage.setItem(props.userName, entry.target.id);
               } else {
                  console.log("Previously read message!!!!!!");
               }
            }
         }
      }
      const observerFunction = (entries) => {
         processEntries(entries);
      };
      observer.current = new IntersectionObserver(
         observerFunction,
         {threshold: 1} // Adjust this value to suit your needs
      );

      return () => observer.current.disconnect();
   }, []);
   // useEffect(() => {
   //
   //    const processEntries = async (entries) => {
   //       for (const entry of entries) {
   //          if (entry.isIntersecting) {
   //             console.log("ID -=========", entry.target.id)
   //             console.log('State Reactions:', state?.reactions);
   //             console.log('Props UserName:', props.userName);
   //             console.log('Entry Target ID:', entry.target.id);
   //             console.log('REACTIONS.READ:', REACTIONS.READ);
   //             console.log("GET REACTION :",state?.reactions?.some(reaction => reaction.uuid == props.userName && reaction.messageTimetoken == entry.target.id && reaction.value == REACTIONS.READ));
   //             if (!state?.socketMessageState?.some(message => message?.messageTimetoken == entry.target.id && message.userName == props.userName && message.read != null) && !(JSON.stringify(messageAction) == JSON.stringify(
   //                {
   //                   "channel": "LiveChatReceive",
   //                   "messageTimetoken": entry.target.id,
   //                   "type": "reaction",
   //                   "value": REACTIONS.READ
   //                }))) {
   //                updateMessageStateWithReactions(entry.target.id);
   //
   //                setMessageAction({
   //                   "channel": "LiveChatReceive",
   //                   "messageTimetoken": entry.target.id,
   //                   "type": "reaction",
   //                   "value": REACTIONS.READ
   //                })
   //
   //                // setFetchReaction(true);
   //             } else {
   //                // setFetchReaction(false);
   //                setMessageAction(null)
   //             }
   //          }
   //       }
   //    }
   //    const observerFunction = (entries) => {
   //       processEntries(entries);
   //    };
   //    observer.current = new IntersectionObserver(
   //       observerFunction,
   //       { threshold: 1 } // Adjust this value to suit your needs
   //    );
   //
   //    return () => observer.current.disconnect();
   // }, [state?.reactions]);
   useEffect(() => {
      updateMessagesWithReactions();
      dispatch({
         type: "GET_UNREAD_COUNT"
      })

   }, [showChat]);
   useEffect(() => {
      // updateMessagesWithReactions();
      dispatch({
         type: "GET_UNREAD_COUNT"
      })

   }, [state?.socketMessageState]);
   // useEffect(()=> {
   //    updateMessagesWithReactions();
   //    dispatch({
   //       type:"GET_UNREAD_COUNT"
   //    })
   // },[])
   useEffect(() => {
      updateMessagesWithReactions();
      dispatch({
         type: "GET_UNREAD_COUNT"
      })
   }, [state?.reactions])

   useEffect(() => {
      const scrollH = msgListRef?.current?.scrollHeight;
      if (scrollH > lastScrollHeight && fetchHistory) {
         msgListRef.current.scrollTop = scrollH - lastScrollHeight
      }

   }, [msgListRef?.current?.scrollHeight, lastScrollHeight, fetchHistory]);

   const scrollToBottom = () => {
      console.log("scroll bottom")
      dispatch({
         type: "GET_UNREAD_COUNT"
      })
      if (msgListRef.current) {
         msgListRef.current.scrollTop = msgListRef.current.scrollHeight;
         // setUnreadCount(0);

         setFetchReaction(false);
      }
   };
   const scrollToTop = () => {
      console.log("scroll top")
      if (msgListRef.current) {
         msgListRef.current.scrollTo({top: 0, behavior: 'smooth'});
         dispatch({
            type: "GET_UNREAD_COUNT"
         })
         // setUnreadCount(0);
         // setFetchReaction(false);
      }
   };

   function processName(inputString) {
      if (!inputString) {
         return {
            firstName: '',
            lastName: '',
         };
      }
      const trimmedString = inputString.replace(/\s+/g, ' ').trim();
      const [firstName, lastName] = trimmedString.split(' ', 2);
      return {
         firstName: firstName || '',
         lastName: lastName ?? '',
      };
   }

   function verifiedUser(username) {
      if (verifiedUsersList.includes(username)) {
         return <MdVerifiedUser/>
      } else {
         return null;
      }
   }

   const handleKeyPress = (event) => {
      if (event.key === 'Enter' && send?.length <= 250 && send?.trim() !== '' && !(window.matchMedia && window.matchMedia("(max-width:480px)").matches)) {
         event.preventDefault();
         sendMessage(send);
      }
   };

   const handleInputChange = (e, newValue, newPlainTextValue, mentions) => {
      console.log(e.target.selectionStart)
      if (newPlainTextValue.length <= 250) {
         setSend(newPlainTextValue);
      }
   };

   const handleSelect = (e) => {
      console.log(e.target.selectionStart, " end ", e.target.selectionEnd)
      setCursorPosition(e.target.selectionStart)
   }

   function removeAfterAtSign(inputString) {
      const atIndex = inputString.indexOf('@');
      if (atIndex !== -1) {
         return inputString.substring(0, atIndex);
      }
      return inputString;
   }

   function removeUndefinedParts(inputString) {
      // Split the string into parts
      const parts = inputString?.split(' ');

      // Filter out undefined parts
      const filteredParts = parts?.filter(part => part !== 'undefined');

      // Join the remaining parts back into a string
      return filteredParts?.join(' ');
   }

   const handleReactions = (reactions) => {
      // const isDuplicate = reactions.some((item) => item.actionTimetoken === reaction.actionTimetoken);
      //
      // if (!isDuplicate) {
      //     // Add the new item to the array
      //     setReactions([...reactions, reaction]);
      // }else{
      //     console.log("duplicate reaction entry")
      // }
      console.log(reactions)
      // setReactions(reactions)
      dispatch({
         type: 'ADD_REACTIONS',
         payload: reactions
      })
   }

   const getReaction = (username, timetoken, value) => {
      // console.log("reactions",username+timetoken+value)
      // console.log("get reaction fill",state?.reactions?.some(reaction => reaction.uuid == username && reaction.messageTimetoken == timetoken && reaction.value == value))
      return state?.reactions?.some(reaction => reaction.uuid == username && reaction.messageTimetoken == timetoken && reaction.value == value)
   }

   function getReactionCount(timetoken, value) {
      const filtered = state?.reactions?.filter((item) => item.messageTimetoken == timetoken && item.value == value);
      return filtered?.length
   }

   function getReactionData(userName, timetoken, value) {
      // console.log("reactions rm",userName+timetoken+value)
      // console.log("re data",state?.reactions.find((item)=> item.uuid == userName && item.messageTimetoken == timetoken && item.value == value))
      return state?.reactions.find((item) => item.uuid == userName && item.messageTimetoken == timetoken && item.value == value)
   }

   const handleMessageRemove = async (timetoken, value, actionTimetoken) => {
      console.log(timetoken + " " + value + " " + actionTimetoken)
      if (timetoken && value && actionTimetoken) {
         dispatch({
            type: 'CLEAN_MAIN_STATE',
            payload: {
               value: value,
               "messageTimetoken": timetoken
            }
         })
         setRemoveMessageAction({
            "channel": "LiveChatReceive",
            "messageTimetoken": timetoken,
            "actionTimetoken": actionTimetoken
         })
         dispatch({
            type: 'REMOVE_ACTION_MESSAGES',
            payload: {
               value: value,
               actionTimetoken: actionTimetoken
            }
         })

      } else {
         console.log("Action not available to remove");
      }

   }
   const handleMessageAdd = async (timetoken, value, actionTimetoken) => {
      console.log(timetoken + " " + value + " " + actionTimetoken)
      if (timetoken && value && actionTimetoken && !getReaction(props.userName, timetoken, value == REACTIONS?.HEART ? REACTIONS.THUMBS_UP : REACTIONS.HEART)) {

         dispatch({
            type: 'UPDATE_MESSAGES',
            payload: {
               "messageTimetoken": timetoken,
               "actionTimetoken": true,
               value: value
            }
         })
         setMessageAction({
            "channel": "LiveChatReceive",
            "messageTimetoken": timetoken,
            "type": "reaction",
            "value": value
         })
         // dispatch({
         //    type:'REMOVE_ACTION_MESSAGES',
         //    payload: {
         //       value:value,
         //       actionTimetoken:actionTimetoken
         //    }
         // })

      } else {
         console.log("Action not found");
      }

   }

   const handleEmojiSelect = (emoji) => {
      console.log(emoji)
      setSend((prevState) => {
         return prevState.slice(0, cursorPosition) + emoji.native + prevState.slice(cursorPosition);
      })
      setCursorPosition((prevState => {
         return prevState + 2
      }))
      setSelectedEmojis([...selectedEmojis, emoji]);
   };


   return (
      <>
         {(connectPubnubLive && (liveToken != null) && props.isAuthenticated && props.Token != undefined && props.Token != null) ?
            <PubNubLiveChatHandler fetchHistory={fetchHistory} onDataHistory={messageFromHistoryCallback} onDataReceivedQuizbot={messageFromCallback} sendMessage={message}
                                   subscribe={subscribeChannel} unSubscribe={unSubscribeChannel} token={liveToken} msgAction={messageAction} removeMsgAction={removeMessageAction} fetchReactions={fetchReactions} onReactionFetch={handleReactions}/> : ""}

         <div className={Classnames(Styles.ChatWrappper)}>
            {showChat ? <div className={Classnames(Styles.chatInnerWrapper, "OverviewBox")}>
               <div className={Classnames(Styles.chatHeader, "heading4 bold color-black")}>
                  <img src={meta11ShortLogo}/>
                  {t("STREAM_CHAT.META_11_OFFICIAL")}
                  <a onClick={() => setShowChat(false)} className={Classnames(Styles.closeBtn)} style={{cursor: 'pointer'}}>
                     <VscClose/>
                  </a>
               </div>
               <div className={Classnames(Styles.chatContent)} onScroll={handleScrollEvent} ref={msgListRef}>


                  <ul className={Classnames(Styles.chatList)}>
                     {state?.unreadCount > 0 && !historyFetch && normalFetch ? <a onClick={() => scrollToBottom()} className={Styles.downArrow}><FaAnglesDown/></a> : ""}
                     {state?.unreadCount > 0 && historyFetch && !normalFetch ? <a onClick={() => scrollToTop()} className={Styles.downArrow}><FaAnglesUp/></a> : ""}
                     {state?.socketMessageState?.sort((a, b) => a.timetoken - b.timetoken).map((message, i) => {

                        return (
                           <li key={i} id={message?.timetoken} ref={el => el && observer.current.observe(el)} className={Classnames("chat-message unread")}>
                              {isValid(message?.timestamp) && i > 0 && !isSameDay(state?.socketMessageState[i - 1]?.timestamp, message?.timestamp) && (
                                 <label className={Classnames(Styles.msgDate, "body-text6 color-gray2")}>
                                    <span>{isValid(message?.timestamp) ? format(message?.timestamp, 'MMMM d, yyyy') : ""}</span>
                                 </label>
                              )}
                              <div className={Classnames(Styles.wrapper, message.direction == "incoming" ? "incoming" : "outgoing")}>
                                 <div className={Classnames(Styles.avatarImgWrapper)} style={{marginLeft: message.direction == "outgoing" ? "10px" : ""}}>
                                    {helpers.getProfileImage(message?.profilePicUrl, processName(message?.name).firstName, processName(message?.name).lastName, null, message?.userName)}
                                    {/*<img src={message.profilePicUrl} alt={"Profile Image"}/>*/}
                                 </div>
                                 <div className={Classnames(Styles.avatarNameWrapper)}>
                                    {message.direction == "incoming" ?
                                       <div className={Classnames(Styles.avatarName)}>
                                          <a style={{textDecoration: "none", color: "#FFFFFF"}} href={`/user/${btoa(message.userName)}`}>
                                       <span className="body-text5-b-italic" style={{marginRight: 15}}>
                                      {(!message?.name || message?.name == " ") ? removeAfterAtSign(message.userName) : removeUndefinedParts(message?.name)}
                                          <span>{verifiedUser(message?.userName)}</span>
                                    </span>
                                          </a>
                                          <span
                                             className={Classnames(Styles.chattime, "body-text6 color-gray2")}>{isValid(message?.timestamp) ? format(message?.timestamp, 'h:mm a') : ""}</span>
                                       </div> :
                                       <div className={Classnames(Styles.avatarName, "myChat")} style={{textAlign: "right"}}>
                                     <span className="body-text5-b-italic" style={{marginRight: 15}}>
                                        {(!message?.name || message?.name == " ") ? removeAfterAtSign(message.userName) : removeUndefinedParts(message?.name)}
                                        <span>{verifiedUser(message?.userName)}</span>
                                     </span>
                                          <span className="body-text6 color-gray2" style={{textAlign: "left"}}>{isValid(message?.timestamp) ? format(message?.timestamp, 'h:mm a') : ""}</span>
                                       </div>
                                    }
                                    <div className={Classnames(Styles.avatarMessage, "body-text5 color-gray1")} style={{textAlign: 'left', wordWrap: "break-word", wordBreak: "break-word", overflowWrap: "break-word"}}>
                                       {message?.message}
                                    </div>
                                    <div className={Classnames(Styles.msgIconWrapper)}>
                                       {console.log(getReactionData(props.userName, message.timetoken, REACTIONS.HEART)?.actionTimetoken)}

                                       {getReaction(props.userName, message?.timetoken, REACTIONS.HEART) ?
                                          <span className={Classnames(Styles.heartIconBtn)}>
                                        <a onClick={async () => {
                                           await handleMessageRemove(message?.timetoken, REACTIONS.HEART, message[REACTIONS.HEART])
                                        }}><GoHeartFill/></a>
                                    <span className={Classnames(Styles.ccc)}>
                                        {getReactionCount(message.timetoken, REACTIONS.HEART) ? getReactionCount(message.timetoken, REACTIONS.HEART) : 0}</span>

                                    </span> : <span><a onClick={async () => {
                                             await handleMessageRemove(message?.timetoken, REACTIONS.THUMBS_UP, message[REACTIONS.THUMBS_UP])
                                             await handleMessageAdd(message?.timetoken, REACTIONS.HEART, true)
                                          }}><GoHeart/></a>
                               <span>{getReactionCount(message.timetoken, REACTIONS.HEART) ? getReactionCount(message.timetoken, REACTIONS.HEART) : 0}</span>
                                    </span>}
                                       {getReaction(props.userName, message?.timetoken, REACTIONS.THUMBS_UP) ?
                                          <span><a onClick={async () => {
                                             await handleMessageRemove(message?.timetoken, REACTIONS.THUMBS_UP, message[REACTIONS.THUMBS_UP])
                                          }}><BsFillHandThumbsUpFill/></a>
                                                                        <span>{getReactionCount(message.timetoken, REACTIONS.THUMBS_UP) ? getReactionCount(message.timetoken, REACTIONS.THUMBS_UP) : 0}</span>

                                    </span> : <span><a onClick={async () => {
                                             await handleMessageRemove(message?.timetoken, REACTIONS.HEART, message[REACTIONS.HEART])
                                             await handleMessageAdd(message?.timetoken, REACTIONS.THUMBS_UP, true)

                                          }}><BsHandThumbsUp/></a>
                               <span>{getReactionCount(message.timetoken, REACTIONS.THUMBS_UP) ? getReactionCount(message.timetoken, REACTIONS.THUMBS_UP) : 0}</span>

                                    </span>}
                                    </div>
                                 </div>

                              </div>

                           </li>
                        )
                     })}


                  </ul>
               </div>
               <div className={Classnames(Styles.chatFooter)}>
                  <div className={Classnames(Styles.group)}>
                     {showPicker ? <Picker onEmojiSelect={(e) => handleEmojiSelect(e)} style={{
                        width: '30vh',
                        right: '35vh',
                        position: 'absolute',
                        zIndex: 1500,
                        bottom: '8vh'
                     }}/> : ""}

                     <a onClick={() => setShowPicker(!showPicker)} className={Classnames(Styles.pickerBtn)}><BiCool/></a>
                     <MentionsInput value={send} onChange={handleInputChange} maxLength={250} onSelect={handleSelect} onKeyPress={handleKeyPress} placeholder={t("STREAM_CHAT.TYPE_YOUR_MESSAGE")} ref={mentionsInputRef}>

                        <Mention
                           trigger={chatWithAiEnable ? "@" : "null"}
                           data={displayNames}
                           renderSuggestion={(suggestion, search, highlightedDisplay) => (
                              <div className="mention">{highlightedDisplay}</div>
                           )}

                        />

                     </MentionsInput>
                     {/*<input  type="text" name="name" maxLength={250} onKeyPress={handleKeyPress} required value={send} onChange={e => setSend(e.target.value)} style={{maxWidth:"none", width:"100%"}} placeholder="Text your message"/>*/}
                     <a onClick={() => sendMessage(send)} className={Classnames(Styles.sendBtn)}><BiSolidSend/></a>

                  </div>

               </div>
            </div> : ""}
            <button className={Classnames(Styles.floatingButton, 'show')}
                    style={{display: 'block'}} onClick={async () => {
               await setShowChat(!showChat)
               await setFetchReaction(true);
               scrollToBottom();
            }}>
               {state?.unreadCount > 0 ? <div className={Styles.unreadNotify}>{state?.unreadCount > 10 ? '9+' : state?.unreadCount}</div> : ""}
               <BsChatDotsFill/> <span className={Styles.floatingButtonText}>{t("STREAM_CHAT.STREAM_CHAT")}</span>
            </button>
         </div>
      </>
   );
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      name: state.auth.name
   };
}

export default connect(mapStateToProps)(StreamChat);
