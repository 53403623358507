import AxiosInstance from "../helpers/AxiosInstance";
import {ApiEndpoints} from "./ApiConstants";

export const deleteAsset = (fileName, assetContainer, authToken, email, userName) => {
   const url = `${ApiEndpoints.DELETE_DESIGN_BLOB}`
   let data = {
      "container": assetContainer,
      "fileName": fileName,
      "email": email,
      "username": userName,
   }
   const config = {
      headers: {
         "AuthToken": authToken
      },
      data: data,
   };

   return AxiosInstance().delete(url, config)
      .then((response) => {
         return response;
      })
      .catch((error) => {
         return error.response;
      });
}
export const deleteAssetWithoutToken = (fileName, assetContainer, email, userId) => {
   const url = `${ApiEndpoints.DELETE_FILE_WITHOUT_TOKEN}`
   let data = {
      "container": assetContainer,
      "fileName": fileName,
      "email": email,
      "username": email
   }
   const config = {
      data: data,
   };

   return AxiosInstance().delete(url, config)
      .then((response) => {
         console.log('delete complete:', response.data);
         return response.data;
      })
      .catch((error) => {
         console.error('delete failed:', error);
         return error.response;
      });
}
export const assetUpload = (file, type, token) => {

   const url = `${ApiEndpoints.UPLOAD_DESIGN_BLOB}/${type}`
   const formData = new FormData();
   formData.append('file', file);
   return AxiosInstance()
      .post(url, formData, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         console.log('Upload complete:', response.data);
         return response.data;
      })
      .catch((error) => {
         console.error('Upload failed:', error);
         return error.response;
      });
};
