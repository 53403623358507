import classNames from "classnames";
import Classnames from "classnames";
import classnames from "classnames";
import React, {useEffect, useState} from "react";
import styles from "./Transactions.module.scss";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar"
import Footer from "../../components/Footer/Footer";
import {connect} from "react-redux";
import {getUserTransactions} from "../../services/WalletService";
import Paginator from "../../components/Paginator/Paginator";
import {TRANSACTION_TYPES} from "../../utilities/Constants";
import {formatDate} from "../../helpers/CommonFunctions";
import CreditIcon from "../../assets/images/icons/24_sprts_token.webp";
import OMNICreditsBuyWithdrawalModal from "../../components/Wallet/OMNICreditsBuyWithdrawalModal/OMNICreditsBuyWithdrawalModal";
import {useNavigate} from "react-router-dom";

const Transactions = ({token, userName}) => {
   const [isLoading, setIsLoading] = useState(true);
   const [isHistoryEmpty, setIsHistoryEmpty] = useState(false);
   const [transactions, setTransactions] = useState([]);
   const [currentPage, setCurrentPage] = useState(0);
   const [postsPerPage, setPostsPerPage] = useState(10);
   const [totalElements, setTotalElements] = useState(0);
   const [isBuyWithdrawalModalShow, setIsBuyWithdrawalModalShow] = useState(false);
   let navigate = useNavigate();

   const paginate = (pageNumber, postsPerPage) => {
      pageNumber !== currentPage && setIsLoading(true);
      setPostsPerPage(Number(postsPerPage));
      setCurrentPage(pageNumber);
   };

   const getOperationStyles = (operation) => {
      if (operation === "CREDIT") {
         return styles.Credit;
      } else if (operation === "DEBIT") {
         return styles.Debit;
      }
   };

   useEffect(() => {
      const fetchTransactionHistory = async () => {
         getUserTransactions(token, true, true, currentPage, postsPerPage, userName)
            .then((response) => {
               if (response?.apiStatus === true && response?.transactionDTOS?.length > 0) {
                  setTransactions(response?.transactionDTOS);
                  setIsHistoryEmpty(false);
                  setTotalElements(response?.totalElements);
               } else {
                  setIsHistoryEmpty(true);
               }
               setIsLoading(false);
            })
            .catch((error) => {
               console.log(error);
               setIsLoading(false);
            });
      }

      fetchTransactionHistory();
   }, [currentPage, postsPerPage, token, userName]);

   useEffect(() => {
      if (!localStorage.getItem('accessToken')) {
         navigate("/login");
      }
   }, [userName, token]);

   const handleBuyWithdrawalModalOpen = () => {
      setIsBuyWithdrawalModalShow(true)
   };

   const handleBuyWithdrawalModalClose = () => {
      setIsBuyWithdrawalModalShow(false)
   }

   const loadingIndicator = (<div className="text-center">
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   const debitIcon = (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ff0000"
           stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-arrow-up-left">
         <path d="M7 17V7h10"/>
         <path d="M17 17 7 7"/>
      </svg>);

   const creditIcon = (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#17EA85"
           stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-arrow-down-right">
         <path d="m7 7 10 10"/>
         <path d="M17 7v10H7"/>
      </svg>);

   return (<div className={classNames(styles.bodyLeaderBoardOld, "ps-2 pe-2 ps-xxl-0 pe-xxl-0")}>
      <div className={classNames(styles.pageWrapper, styles.TransactionsPage)}>
         <div
            className={classNames(styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
            <div className="col-12 p-0 row">
               <NavbarSecondary page="events"/>
            </div>

            <div className="container p-0">
               <div className="row gx-0">
                  <div className="col-md-12 col-xl-12">
                     <div className="row gx-0 mt-1">
                        <div className="col-12 ">
                           <h2 className={classNames(styles.sectionTitle, "mt-4 mb-4 w-100 ccccc")}>
                              Transaction History
                              <button className={classnames(styles.BuyButton)} onClick={handleBuyWithdrawalModalOpen}>
                                 <span>Buy / Withdraw</span>
                                 <img src={CreditIcon} alt={""}/>
                              </button>
                           </h2>

                           <div className="col-12 p-0 mt-3">
                              {isLoading ? loadingIndicator : <>
                                 <div className="row p-0">
                                    <div
                                       className={Classnames(styles.FantasyHistoryTable, styles.transactionsTable, 'z-1')}>
                                       {isHistoryEmpty ?
                                          <div className={Classnames(styles.NoFantasyHistoryData)}>No transactions
                                             available.</div> : <>
                                             <div className={Classnames(styles.TableWrapper)}>
                                                <table className={'position-relative'}>
                                                   <thead>
                                                   <tr>
                                                      <th>Type</th>
                                                      <th>Description</th>
                                                      <th>Date</th>
                                                      <th>Operation</th>
                                                      <th>&nbsp;&nbsp;Amount</th>
                                                      <th>Running Balance</th>
                                                   </tr>
                                                   </thead>
                                                   <tbody>
                                                   {transactions?.map((transaction, index) => (
                                                      <tr key={index} style={{height: "30px"}}>
                                                         <td>{TRANSACTION_TYPES[transaction?.transactionType]}</td>
                                                         <td>{transaction?.description}</td>
                                                         <td>{formatDate(transaction?.transactionDate)}</td>
                                                         <td className="text-center">
                                                            {transaction?.operation === "CREDIT" ? creditIcon : debitIcon}&nbsp;
                                                            {transaction?.operation === "CREDIT" ? "CR" : "DR"}
                                                         </td>
                                                         <td className="text-end">
                                                            <img src={CreditIcon} alt={""}/>&nbsp;&nbsp;
                                                            {transaction?.omniCredits}

                                                            {/*{transaction?.transactionType === TRANSACTION_TYPES.FANTASY.toUpperCase() &&*/}
                                                            {/*   <button*/}
                                                            {/*      className={classNames(styles.WithdrawBtn)}>Withdraw</button>}*/}
                                                         </td>
                                                         <td><img src={CreditIcon} alt={""}/>&nbsp;&nbsp;{transaction?.runningBalance}
                                                         </td>
                                                      </tr>))}

                                                   </tbody>
                                                </table>
                                             </div>
                                             {totalElements > 0 &&
                                                <div className={classnames(styles.PaginatorRow, "row mt-4")}>
                                                   <Paginator postsPerPage={postsPerPage} totalElements={totalElements}
                                                              currentPage={currentPage}
                                                              paginate={paginate}/>
                                                </div>}
                                          </>}
                                    </div>
                                 </div>
                              </>}
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </div>
         {isBuyWithdrawalModalShow ? <OMNICreditsBuyWithdrawalModal handleClose={handleBuyWithdrawalModalClose}/> : <></>}

         <Footer/>
      </div>
   </div>);
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null && state.auth.accessToken !== undefined,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      token: state.auth.accessToken,
   };
};

export default connect(mapStateToProps)(Transactions);
