import {connect} from "react-redux";
import classNames from "classnames";
import Styles from "./TournamentFantasySection.module.scss";
import React, {useEffect, useState} from "react";
import MoreInfoIcon from "../../../assets/images/icons/more_info_icon.svg";
import SprtsToken from "../../../assets/images/icons/24_sprts_token.webp";
import OmniPoints from "../../../assets/images/icons/24_xp.webp";
import {checkUserFantasySubscription, getFantasyTeamByUsernameAndTournamentId} from "../../../services/FantasyService";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getConf} from "../../../services/ConfigService";
import {WALLET} from "../../../utilities/ConfigConstants";
import {toast} from "react-toastify";
import HowFantasyWorksModal from "../../Modals/HowFantasyWorks/HowFantasyWorks";
import {t} from "i18next";
import {
   getFantasyPointsStatusClass, formatNumberToKWithoutRounding,
   getShowingFantasyPoints,
   getSprtsValueFromPoints, toolTipProvider, getBoosterRemainingTime, getBoosterRemainingCircle
} from "../../../helpers/CommonFunctions";
import FantasyPointCalculation from "../../Modals/HowFantasyWorks/FantasyPointCalculation";
import PowerBoosterPurchaseModal from "../../Modals/PowerBoosterPurchase/PowerBoosterPurchase";
import {ReactComponent as FlashIcon} from "../../../assets/images/icons/flash.svg";
import PlayerInfoHistoryModal from "../../Modals/PlayerInfoHistory/PlayerInfoHistory";
import * as StatisticsService from "../../../services/StatisticsService";
import {BOOSTER_EXTRA_TIME} from "../../../utilities/Constants";
import UserDataChannelHandler from "../../../services/PubNubHandlers/UserDataChannelHandler";
import * as PubNubService from "../../../services/PubNubService";

const TournamentFantasySection = (props) => {
   const navigate = useNavigate();
   let {tournamentId} = useParams();
   tournamentId = tournamentId || props?.tournamentDetails?.id;
   const XP_TO_SPRTS_RATIO = getConf(WALLET.XP_TO_SPRTS_RATIO)
   const FANTASY_STATUS_TOAST_ID = "FANTASY_STATUS_TOAST_ID";
   const FANTASY_WARNING_TOAST_ID = "FANTASY_WARNING_TOAST_ID";

   const [checkingSubscription, setCheckingSubscription] = useState(true);
   const [isFetchingFantasyTeam, setIsFetchingFantasyTeam] = useState(true);
   const [isSubscribed, setIsSubscribed] = useState(false);
   const [isTeamEditable, setIsTeamEditable] = useState(false);
   const [lang] = useState(localStorage.getItem("language"));
   const [fantasyTeamName, setFantasyTeamName] = useState('');
   const [fantasyTeam, setFantasyTeam] = useState([]);
   const [teamCaptain, setTeamCaptain] = useState(null);
   const [teamViceCaptain, setTeamViceCaptain] = useState(null);
   const [totalPoints, setTotalPoints] = useState(0);
   const [activeStatus, setActiveStatus] = useState(false);
   const [isBoosterPurchaseAvailable, setIsBoosterPurchaseAvailable] = useState(false);

   const [showHowFantasyWorksModal, setShowHowFantasyWorksModal] = useState(false);
   const [showFantasyPointCalculationModal, setShowFantasyPointCalculationModal] = useState(false);
   const [showPlayerInfoHistoryModal, setShowPlayerInfoHistoryModal] = useState(false);
   const [selectedPlayer, setSelectedPlayer] = useState(null);
   const [refreshFantasy, setRefreshFantasy] = useState(true);

   const [showBoosterPurchaseModal, setShowBoosterPurchaseModal] = useState(false);
   const [isLiveAndUpcomingAvailable, setIsLiveAndUpcomingAvailable] = useState(false)

   const uuid = localStorage.getItem("uuid");
   const [userConnectPubnub, setUserConnectPubnub] = useState(false);
   const [pubNubToken, setPubNubToken] = useState(null);

   const handleUpdateTeam = (updatedTeamData) => {
      let isCaptainAvailable = false;
      let isViceCaptainAvailable = false;
      updatedTeamData?.team.forEach(player => {
         // set captain and vice captain
         if (player.isCaptain) {
            isCaptainAvailable = true;
            setTeamCaptain(player);
         } else if (player.isVcCaptain) {
            isViceCaptainAvailable = true;
            setTeamViceCaptain(player);
         }

         if (!isCaptainAvailable) {
            setTeamCaptain(null);
         }
         if (!isViceCaptainAvailable) {
            setTeamViceCaptain(null);
         }
      });

      setTotalPoints(updatedTeamData?.totalPoints || 0)
      setFantasyTeam(updatedTeamData?.team);
   }

   const handleFantasyUserPointsTotal = (message) => {
      if (message?.tournamentId === tournamentId) {
         handleUpdateTeam(message);
      }
   }

   const navigateToFantasyTeam = (isEdit = false) => {
      navigate(`/fantasy-team/${tournamentId}`, {state: {tournamentDetails: props.tournamentDetails, isEdit: isEdit}})
   }

   // navigate to create fantasy team page
   const handleCreateFantasyTeam = () => {
      // check if the tournament is completed
      if (props.tournamentDetails?.active === false && props.tournamentDetails?.toStart === false) {
         toast.warning(t("FANTASY.YOU_CANT_CREATE_FANTASY_TEAM_FOR_A_COMPLETED_TOURNAMENT"), {toastId: FANTASY_WARNING_TOAST_ID});
      } else if (!isLiveAndUpcomingAvailable) {
         toast.warning(t("FANTASY.YOU_CANT_CREATE_A_FANTASY_TEAM_WHEN_THE_LAST_MATCH_IS_COMPLETED"));
      } else {
         navigateToFantasyTeam(false);
      }
   }

   const handleEditTeam = () => {
      // toast.info(t("FANTASY.THIS_OPTION_IS_SOON_TO_BE_DEPLOYED"))
      if (isTeamEditable) {
         navigateToFantasyTeam(true);
      } else {
         if (props.tournamentDetails?.active === true) {
            toast.warning(t("FANTASY.YOU_CANT_EDIT_YOUR_FANTASY_TEAM_ONCE_THE_TOURNAMENT_HAS_STARTED"), {toastId: FANTASY_WARNING_TOAST_ID})
         } else {
            toast.warning(t("FANTASY.YOU_CANT_EDIT_YOUR_FANTASY_TEAM_ONCE_THE_TOURNAMENT_HAS_COMPLETED"), {toastId: FANTASY_WARNING_TOAST_ID})
         }
      }
   }

   const handleShowHowFantasyWorks = () => {
      setShowHowFantasyWorksModal(true);
   }

   const handleShowPlayerInfoHistory = (e, player) => {
      setSelectedPlayer(player)
      setShowPlayerInfoHistoryModal(true);
   }

   const handleShowBoosterPurchase = (e, player) => {
      const currentTime = new Date();

      if (isBoosterPurchaseAvailable && activeStatus) {
         setSelectedPlayer(player)
         setShowBoosterPurchaseModal(true);
      } else if (!isBoosterPurchaseAvailable) {
         toast.warning(t("FANTASY.YOU_CANT_PURCHASE_A_BOOSTER_FOR_A_COMPLETED_TOURNAMENT"));
      } else if (new Date(props?.tournamentDetails?.startTime) > currentTime) {
         toast.info("Team is still inactive. You will be able to boost your team once the tournament starts.", {toastId: FANTASY_STATUS_TOAST_ID})
      } else if (new Date(props?.tournamentDetails?.endTime) > currentTime) {
         toast.info("Team is still inactive. You will be able to boost your team from next match onwards.", {toastId: FANTASY_STATUS_TOAST_ID})
      } else {
         toast.warning(t("FANTASY.YOU_CANT_PURCHASE_A_BOOSTER_FOR_A_COMPLETED_TOURNAMENT"), {toastId: FANTASY_WARNING_TOAST_ID})
      }
   }

   useEffect(() => {
      const fetchData = async () => {
         try {
            await PubNubService.requestAccessTokenQuiz(props.userName, 1234, 1234, props.Token).then(response => {
               if (response != null) {
                  setPubNubToken(response);
                  setUserConnectPubnub(true);
               }
            });

         } catch (error) {
            console.error("Error:", error);
         }

      };
      fetchData();
   }, [props.Token, props.userName, uuid]);

   useEffect(() => {
      if (props.tournamentDetails?.active === false && props.tournamentDetails?.toStart === true) {
         setIsTeamEditable(true);
      } else {
         setIsTeamEditable(false);
      }
   }, [props.tournamentDetails]);


   useEffect(() => {
      const fetchData = async () => {
         await StatisticsService.getAllMatchCardsByTournamentId("", 0, 1, "isLive,isScheduled,matchId,desc", "all", tournamentId, lang, props.Token)
            .then((response) => {

               if (response.apiStatus && response.totalElements !== 0) {
                  setActiveStatus(true);
                  setIsBoosterPurchaseAvailable(true);
                  setIsLiveAndUpcomingAvailable(true)
               } else {
                  setActiveStatus(false);
                  setIsBoosterPurchaseAvailable(false);
                  setIsLiveAndUpcomingAvailable(false)
               }
            });
      };
      fetchData();
   }, [props.Token, props.tournamentDetails, tournamentId, lang]);

   // check if user is subscribed to fantasy team
   useEffect(() => {
      const checkSubscription = async () => {
         setCheckingSubscription(true);
         checkUserFantasySubscription(tournamentId, props.userName, props.Token)
            .then((response) => {
               if (response.apiStatus && response.isSubscribed === true) {
                  setIsSubscribed(true);
                  setCheckingSubscription(false);
               } else if (response.apiStatus) {
                  setIsSubscribed(false);
                  setCheckingSubscription(false);
               }
            })
      }

      checkSubscription();
   }, [props.Token, props.userName, tournamentId]);

   // get fantasy team of the user
   useEffect(() => {
      const fetchFantasyTeam = async () => {
         getFantasyTeamByUsernameAndTournamentId(props.userName, tournamentId, props.Token)
            .then((response) => {
               if (response.apiStatus && response.team) {
                  handleUpdateTeam(response);

                  setFantasyTeamName(response?.teamName || '')

                  const currentTime = new Date();
                  if (new Date(props?.tournamentDetails?.startTime) < currentTime && new Date(props?.tournamentDetails?.endTime) > currentTime) {
                     setActiveStatus(response?.activeStatus);
                  } else {
                     setActiveStatus(false);
                  }
               }
            })
            .finally(() => {
               setIsFetchingFantasyTeam(false);
            });
      }

      if (isSubscribed) {
         // fetch fantasy team immediately
         setIsFetchingFantasyTeam(true);
         fetchFantasyTeam();
      }
   }, [isSubscribed, props.Token, props?.tournamentDetails?.endTime, props?.tournamentDetails?.startTime, props.userName, tournamentId, refreshFantasy]);

   const loadingIndicator = (<div style={{textAlign: "center"}}>
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   const playerCard = (player) => {
      const playerPoints = getShowingFantasyPoints(player?.points);

      const activeBooster = player?.boosterRulePointsDTOS?.find(booster => {
         const endTime = new Date(booster?.endTime) - BOOSTER_EXTRA_TIME;
         if (booster?.active === true && new Date(endTime) > new Date()) {
            return booster
         }
      });

      const boosterTooltip = (element) => toolTipProvider(getBoosterRemainingTime(activeBooster), "top", element, true)

      return (<div className={classNames(Styles.Card)}>
         <div className={classNames(Styles.CardBackground)}>
            <div className="row">

               {boosterTooltip(<div className={classNames(Styles.BoostRemainingTime, "BoostRemainingTime")}>
                  <svg viewBox="0 0 180 180" width="180" height="180" xmlns="http://www.w3.org/2000/svg">
                     <path className="TimePath1" d="m 90,4 a 86,86 0 0,1 0,172 a 86,86 0 0,1 0,-172" fill="none"
                           stroke="#d9d9d9" stroke-width="8"></path>
                     <path className="TimePath2" style={{
                        stroke: activeBooster ? activeBooster?.colourCode : "transparent",
                        strokeDasharray: 540,
                        strokeDashoffset: getBoosterRemainingCircle(activeBooster),
                     }} d="m 90,4 a 86,86 0 0,1 0,172 a 86,86 0 0,1 0,-172" fill="none" stroke="rgb(0,255,194)"
                           stroke-linecap="round" stroke-width="8"
                     ></path>
                  </svg>
               </div>)}

               <a className={classNames(Styles.MoreInfoIconBtn)}
                  style={{cursor: 'pointer'}}
                  onClick={(e) => {
                     handleShowPlayerInfoHistory(e, player)
                  }}>
                  <img src={MoreInfoIcon}/></a>
               <div className={classNames(Styles.ProfilePic)}
                    style={{backgroundImage: `url(${player?.playerPictureURL})`}}></div>
               {!player?.isCaptain && !player?.isVcCaptain &&
                  <div className={classNames(Styles.BoostIconWrapper, "BoostIconWrapper")}
                       style={{
                          cursor: isBoosterPurchaseAvailable ? 'pointer' : 'not-allowed'
                       }}
                       onClick={(e) => handleShowBoosterPurchase(e, player)}>
                     <div className={classNames(Styles.Circle, "Circle", !activeStatus ? "IconDisable" : "")}>
                        <div className={classNames(Styles.InnerCircle, "InnerCircle")}></div>
                        <FlashIcon/>
                     </div>
                  </div>
               }
            </div>

            <div className={classNames(Styles.TitleRow, "row")}>
               <h4>{player?.playerName}</h4>
               {player?.isCaptain ? <span>{"(C)"}</span> : player?.isVcCaptain &&
                  <span>{"(VC)"}</span>}
            </div>
            {/* <div className={classNames(Styles.BtnRow, "row")}></div> */}
            <div className={classNames(Styles.PlayerPointsRow, "PlayerPointsRow row")}>
               <div className={classNames(Styles.PlayerPoints, "PlayerPoints", `${getFantasyPointsStatusClass(playerPoints?.sign)}`)} role={'button'} style={{cursor: "default"}}>
                  <span className={classNames(Styles.PointSplus, "PointSplus")}>{playerPoints.sign}</span>
                  <div
                     className={classNames(Styles.PointsWrapper, "PointsWrapper", playerPoints.intPart === 0 ? 'noPoints' : '')}>
                     {/*<span className={classNames(Styles.Points, "Points")}>{playerPoints.intPart}</span>*/}
                     <span className={classNames(Styles.DecimalPoints, "DecimalPoints")}>{playerPoints.intPart}{playerPoints.decPart}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>)
   }

   const fantasyTeamStatusToast = (isActive) => {
      const currentTime = new Date();

      if (isActive) {
         toast.success("Your fantasy team is Active", {toastId: FANTASY_STATUS_TOAST_ID})
      } else if (!isBoosterPurchaseAvailable) {
         toast.info("Team is inactive and the tournament is completed.", {toastId: FANTASY_STATUS_TOAST_ID})
      } else if (new Date(props?.tournamentDetails?.startTime) > currentTime) {
         toast.info("Team is still inactive, will get active once the tournament starts.", {toastId: FANTASY_STATUS_TOAST_ID})
      } else if (new Date(props?.tournamentDetails?.endTime) > currentTime) {
         toast.info("Team is still inactive, will get active from next match onwards.", {toastId: FANTASY_STATUS_TOAST_ID})
      } else {
         toast.info("Team is inactive and the tournament is completed.", {toastId: FANTASY_STATUS_TOAST_ID})
      }
   }

   return (<>
      {(userConnectPubnub && pubNubToken != null && props.isAuthenticated) ?
         <UserDataChannelHandler
            handleFantasyUserPointsTotal={handleFantasyUserPointsTotal}
            pubNubToken={pubNubToken}/> : <></>}
      <div className={classNames(Styles.TournamentFantasySection)}>
         <div className={classNames(Styles.TournamentFantasySectionRow1, "row")}>
            <div className="col">
               <h2>
                  <span>{t("FANTASY.FANTASY_TEAM")}&nbsp;</span>
                  <img onClick={handleShowHowFantasyWorks} src={MoreInfoIcon} style={{cursor: 'pointer'}}/>
               </h2>
            </div>
            {props?.userName &&
               <div className={classNames(Styles.PreviousFantasyTeamsCol, "col")}>
                  <Link to={`/user/${btoa(props.userName)}/#fantasyHistoryTitleRef`} className={classNames(Styles.PreviousFantasyTeams, "PreviousFantasyTeams")}>
                     <button>
                        {t("FANTASY.PREVIOUS_FANTASY_TEAMS")}
                     </button>
                  </Link>
               </div>}
         </div>

         {!props?.userName ?
            <div className="row p-0">
               <div className={classNames(Styles.MatchDetails, "col p-0 m-0")}>
                  <div className={classNames(Styles.WinPrizes)}>
                     <div className={classNames(Styles.Conner1)}></div>
                     <div className={classNames(Styles.Conner2)}></div>
                     <div className={classNames(Styles.Conner3)}></div>
                     <div className={classNames(Styles.Conner4)}></div>

                     <div className={classNames(Styles.Shade1)}></div>
                     <div className={classNames(Styles.Shade2)}></div>
                     <div className={classNames(Styles.Shade3)}></div>
                     <div className={classNames(Styles.Shade4)}></div>

                     <div className={classNames(Styles.WinPrizesWrapper)}>
                        <div className={classNames(Styles.Tournament)}>
                           <div className={classNames(Styles.NoDataWrapper, "NoDataWrapperInFantasyTounament")}>
                              <button className="lightGreenButton" onClick={() => navigate("/login")}>
                                 {t("LOGIN_PAGE.SIGN_IN")}
                              </button>
                              <button className="lightGreenButton" onClick={() => navigate("/register")}>
                                 {t("LOGIN_PAGE.CLICK_TO_SIGN_UP")}
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            : checkingSubscription || (isSubscribed && isFetchingFantasyTeam) || props?.parentLoading ? loadingIndicator : <>
               {!isSubscribed ?
                  <div className="row p-0">
                     <div className={classNames(Styles.MatchDetails, "col p-0 m-0")}>
                        <div className={classNames(Styles.WinPrizes)}>
                           <div className={classNames(Styles.Conner1)}></div>
                           <div className={classNames(Styles.Conner2)}></div>
                           <div className={classNames(Styles.Conner3)}></div>
                           <div className={classNames(Styles.Conner4)}></div>

                           <div className={classNames(Styles.Shade1)}></div>
                           <div className={classNames(Styles.Shade2)}></div>
                           <div className={classNames(Styles.Shade3)}></div>
                           <div className={classNames(Styles.Shade4)}></div>

                           <div className={classNames(Styles.WinPrizesWrapper)}>
                              <div className={classNames(Styles.Tournament)}>
                                 <div className={classNames(Styles.NoDataWrapper, Styles.NoDataWrapperInFantasySection)}>
                                    {/*{props?.tournamentDetails?.tournamentTicketOmniCredits > 0 ? <>*/}
                                    {/*   <h3>{t("FANTASY.THIS_IS_A_PAID_TOURNAMENT_PLEASE_VISIT_THROUGH_TOURNAMENTS_PAGE")}</h3>*/}
                                    {/*   <button className="lightGreenButton" onClick={navigateToTournaments}>*/}
                                    {/*      {t("FANTASY.GO_TO_TOURNAMENTS")}*/}
                                    {/*   </button>*/}
                                    {/*</> : <>*/}
                                    {/*   <h3>{t("FANTASY.YOU_DONT_HAVE_A_FANTASY_TEAM_YET")}</h3>*/}
                                    {/*   <button className="lightGreenButton" onClick={handleCreateFantasyTeam}>*/}
                                    {/*      {t("FANTASY.CREATE_FANTASY_TEAM")}*/}
                                    {/*   </button>*/}
                                    {/*</>}*/}
                                    {(props.tournamentDetails?.active === false && props.tournamentDetails?.toStart === false)
                                       ? <h3>{t("FANTASY.YOU_CANT_CREATE_FANTASY_TEAM_FOR_A_COMPLETED_TOURNAMENT")}</h3>
                                       : <>
                                          <h3>{t("FANTASY.YOU_DONT_HAVE_A_FANTASY_TEAM_YET")}</h3>
                                          <button className="lightGreenButton" onClick={handleCreateFantasyTeam}>
                                             {t("FANTASY.CREATE_FANTASY_TEAM")}
                                          </button>
                                       </>
                                    }
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div> : <div className="row p-0">
                     <div className={classNames(Styles.MatchDetails, "col p-0 m-0")}>
                        <div className={classNames(Styles.WinPrizes)}>
                           <div className={classNames(Styles.Conner1)}></div>
                           <div className={classNames(Styles.Conner2)}></div>
                           <div className={classNames(Styles.Conner3)}></div>
                           <div className={classNames(Styles.Conner4)}></div>

                           <div className={classNames(Styles.Shade1)}></div>
                           <div className={classNames(Styles.Shade2)}></div>
                           <div className={classNames(Styles.Shade3)}></div>
                           <div className={classNames(Styles.Shade4)}></div>

                           <div className={classNames(Styles.WinPrizesWrapper)}>
                              <div className={classNames(Styles.FantacyTeamContainer, "row")}>
                                 <div className="col-md-12">

                                    <div id="SelectedPlayersCol" className={classNames(Styles.SelectedPlayersCol)}>
                                       <div className={classNames(Styles.ActiveStatusTagRow, "row")}>
                                          <div className="col">
                                             <div className={classNames(Styles.ActiveStatusTagWrapper, "ActiveStatusTagWrapper")}>
                                                {activeStatus && isBoosterPurchaseAvailable ?
                                                   <div className={classNames(Styles.TournamentStatus, Styles.ActiveStatus)} onClick={() => fantasyTeamStatusToast(true)}><span></span>{t("FANTASY.ACTIVE")}</div> :
                                                   <div className={classNames(Styles.TournamentStatus, Styles.InactiveStatus)} onClick={() => fantasyTeamStatusToast(false)}><span></span>{t("FANTASY.INACTIVE")}</div>}
                                             </div>
                                          </div>
                                          {props.tournamentDetails?.active === false && props.tournamentDetails?.toStart === false ?
                                             <></> :
                                             <div className="col">
                                                <button className={classNames(Styles.EditTeamBtn)} onClick={handleEditTeam}
                                                        style={{cursor: isTeamEditable && isLiveAndUpcomingAvailable ? 'pointer' : 'not-allowed'}}>
                                                   <div>{t("FANTASY.EDIT_TEAM")}</div>
                                                </button>
                                             </div>
                                          }
                                       </div>

                                       <div className="row">
                                          <div className="col-md-12">
                                             <h4>{fantasyTeamName}</h4>
                                          </div>
                                       </div>

                                       {(teamCaptain || teamViceCaptain) &&
                                          <div className={classNames(Styles.TeamCaptains, "row")}>
                                             <div className="col-md-12">
                                                <div className={classNames(Styles.CaptainRow)}>
                                                   {/* Team Captain */}
                                                   {teamCaptain && playerCard(teamCaptain)}
                                                   {teamViceCaptain && playerCard(teamViceCaptain)}
                                                </div>
                                             </div>
                                          </div>}

                                       <div className={classNames(Styles.OtherPlayers, "row")}>
                                          <div className="col-md-12">
                                             <div className={classNames(Styles.Wrapper)}>

                                                {fantasyTeam.map(player => {
                                                   if (!player.isCaptain && !player.isVcCaptain) {
                                                      return playerCard(player);
                                                   } else {
                                                      return null;
                                                   }
                                                })}

                                             </div>
                                          </div>
                                       </div>

                                       <div className={classNames(Styles.TotalEarningRow, "row")}>
                                          <div className="col-md-12">
                                             <div className={classNames(Styles.TotalEarningWrapper)}>
                                                <div className={classNames(Styles.TotalEarning)}>
                                                   <div className={classNames(Styles.Title)}>
                                                      <span> {t("FANTASY.TOTAL_EARNING")}</span><span>Total Pts </span> :
                                                   </div>
                                                   <div className={classNames(Styles.OmniPoints)}>
                                                      <img src={OmniPoints}/>{formatNumberToKWithoutRounding(totalPoints)}&nbsp;
                                                   </div>
                                                   <div className={classNames(Styles.SprtsToken)}>
                                                      <img
                                                         src={SprtsToken}/>{totalPoints ? getSprtsValueFromPoints(totalPoints, XP_TO_SPRTS_RATIO) : 0}
                                                   </div>
                                                </div>

                                             </div>
                                          </div>
                                       </div>

                                    </div>

                                 </div>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>}
            </>}
      </div>
      {showHowFantasyWorksModal && <HowFantasyWorksModal
         handleModalClose={() => {
            setShowHowFantasyWorksModal(false);
         }}
         handleShowHowFantasyPoints={() => {
            setShowFantasyPointCalculationModal(true);
         }}/>}
      {showFantasyPointCalculationModal && <FantasyPointCalculation handleModalClose={() => {
         setShowFantasyPointCalculationModal(false);
      }}/>}
      {showPlayerInfoHistoryModal && <PlayerInfoHistoryModal
         data={{
            playerId: selectedPlayer?.playerId,
            playerName: selectedPlayer?.playerName,
            playerImage: selectedPlayer?.playerPictureURL,
            tournamentId: tournamentId
         }}
         handleModalClose={() => {
            setShowPlayerInfoHistoryModal(false);
         }}/>}
      {showBoosterPurchaseModal && <PowerBoosterPurchaseModal
         player={selectedPlayer}
         tournamentId={tournamentId}
         refreshFantasy={() => {
            setRefreshFantasy(prev => !prev)
         }}
         handleModalClose={() => {
            setShowBoosterPurchaseModal(false)
         }}
      />}
   </>)

}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};

export default connect(mapStateToProps)(TournamentFantasySection);
