import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export const createNewCart = (data, token) => {
   const url = `${ApiEndpoints.CREATE_NEW_CART}`
   return AxiosInstance()
      .post(url, data, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return response;
      })
      .catch((error) => {
         return error.response;
      });
};

export const checkoutCart = (cartId, token) => {
   const url = `${ApiEndpoints.CHECKOUT_CART}/${cartId}/checkout`
   return AxiosInstance()
      .post(url, "", {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return response;
      })
      .catch((error) => {
         return error.response;
      });
};
