import AxiosInstance from "../helpers/AxiosInstance";
import {ApiEndpoints} from "./ApiConstants";

export const getLeaderBoardHistoryByMatchIdAndUserId = async (userId, matchId) => {
   const url = `${ApiEndpoints.GET_LEADER_BOARD_HISTORY_BY_MATCH_ID_AND_USER_ID}/${userId}`

   const params = {
      params: {
         matchId
      }
   }

   try {
      const response = await AxiosInstance().get(url, params);
      return response.data;
   } catch (error) {
      return error.response;
   }
};

export const getLeaderBoardHistory = async (matchId, page, size) => {
   const url = `${ApiEndpoints.GET_LEADER_BOARD_HISTORY}`

   const params = {
      params: {
         matchId,
         page,
         size
      }
   }
   try {
      const response = await AxiosInstance().get(url, params);
      return response.data;
   } catch (error) {
      return error.response;
   }
}

export const getLeaderBoardHistoryByUserIdAndMatchId = async (userId, matchId) => {
   const url = `${ApiEndpoints.GET_LEADER_BOARD_HISTORY_BY_USER_ID}/${userId}`;
   const params = {
      params: {
         matchId,
      }
   }

   try {
      const response = await AxiosInstance().get(url, params);
      return response.data;
   } catch (error) {
      return error.response;
   }
}


export const getPredictionHistory = async (userName, matchId) => {
   const url = `${ApiEndpoints.GET_PREDICTION_HISTORY}/${userName}/${matchId}`;

   try {
      const response = await AxiosInstance().get(url);
      return response.data;
   } catch (error) {
      return error.response;
   }
}
export const getPredictionResultHistory = async (userName, matchId) => {
   const url = `${ApiEndpoints.GET_PREDICTION_RESULT}/${userName}/${matchId}`;

   try {
      const response = await AxiosInstance().get(url);
      return response.data;
   } catch (error) {
      return error.response;
   }
}
