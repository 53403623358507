import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import GameVector from "../../assets/images/GameVector.svg";
import youVector from "../../assets/images/youVector.svg";
import twitterVector from "../../assets/images/twitterVector.svg";
import fbVector from "../../assets/images/fbVector.svg";
import classNames from "classnames";
import styles from "./MobileFooter.module.scss";
import {useTranslation} from "react-i18next";
import Dropdown from 'react-bootstrap/Dropdown';
import globe from "../../assets/images/globe-03.webp";

const MobileFooter = () => {
   const {t} = useTranslation();
   const {i18n} = useTranslation();

   const handleLanguageChange = (selectedLanguage) => {
      localStorage.setItem('language', selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
      window.location.reload();
   };

   useEffect(() => {
      localStorage.setItem('language', i18n.language === 'hi' ? 'hi' : 'en');
   }, []);

   return (
      <div className={classNames(styles.MobileMenuFooter, "row gx-0")}>
         <div className="col-12">
            <div className={classNames(styles.mobileMenuFooterContainer, "row gx-0container mt-5 mt-sm-0")}>
               <div className={classNames(styles.uppeFooter, "mt129")}>
                  <div className="row gx-0">
                     <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                        <div className={classNames(styles.metalogoWrapper)}>
                           <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
                              <img src={"https://storage.googleapis.com/meta11/serviceplatform/META11betaLogo.png"}/>
                           </Link>
                        </div>
                     </div>

                     {/* <div className="mobile-footer-links">
                                <a href="/terms-and-conditions" className={classNames(styles.footerLinks)}>
                                    TERMS AND CONDITIONS
                                </a>

                                <a href="/privacy-policy" className={classNames(styles.footerLinks)}>
                                    PRIVACY POLICY
                                </a>
                                <a href="mailto:support@behaviol.com" className={classNames(styles.footerLinks)}>
                                    support@behaviol.com
                                </a>
                            </div> */}

                     <div className="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8 ">
                        <div className={classNames(styles.mobileLang, "d-flex mobile-lang mobileLang")}>
                           <Dropdown onSelect={handleLanguageChange}>
                              <Dropdown.Toggle variant="success" id="dropdown-basic" className="langDrop">
                                 <img src={globe}/> {i18n.language === 'hi' ? 'हिंदी' : 'English'}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                 <Dropdown.Item eventKey="en">English</Dropdown.Item>
                                 <Dropdown.Item eventKey="hi">हिंदी</Dropdown.Item>
                              </Dropdown.Menu>
                           </Dropdown>
                        </div>

                        <ul className={classNames(styles.socialUi)}>
                           <li>
                              <div className={classNames(styles.social)}>
                                 <a href="https://discord.gg/EMFxXvsU37" target="_blank">
                                    <img src={GameVector}/>
                                 </a>
                              </div>
                           </li>
                           <li>
                              <div className={classNames(styles.social)}>
                                 <a href="https://www.youtube.com/@meta11official" target="_blank">
                                    <img src={youVector}/>
                                 </a>
                              </div>
                           </li>
                           <li>
                              <div className={classNames(styles.social)}>
                                 <a href="https://www.instagram.com/meta11official/" target="_blank">
                                    <img src="https://storage.googleapis.com/meta11/serviceplatform/Insta-Icon.png"
                                    />
                                 </a>
                              </div>
                           </li>
                           <li>
                              <div className={classNames(styles.social)}>
                                 <a href="https://twitter.com/Meta11official" target="_blank">
                                    <img src={twitterVector}/>
                                 </a>
                              </div>
                           </li>
                           <li>
                              <div className={classNames(styles.social)}>
                                 <a href="https://www.facebook.com/meta11official/" target="_blank">
                                    <img src={fbVector}/>
                                 </a>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>

               <div className={classNames(styles.siteFooter, "site-footer")}>
                  <div className={classNames(styles.copyRightMsg, "copy-right-msg")}>
                     © {t("FOOTER.COPYRIGHT_2023_BY")}
                     <a href={"https://www.behaviol.com/"}>
                        {t("FOOTER.BEHAVIOL")}
                     </a>
                  </div>
                  <div className={classNames(styles.footerLinks)}>
                     <a href="/terms-and-conditions" style={{textDecoration: "none"}} className={classNames(styles.footerLinks)}>
                        {t("FOOTER.TERMS_AND_CONDITIONS")}
                     </a>

                     <a href="/privacy-policy" style={{textDecoration: "none"}} className={classNames(styles.footerLinks)}>
                        {t("FOOTER.PRIVACY_POLICY")}
                     </a>
                     <a href="mailto:support@behaviol.com" style={{textDecoration: "none"}} className={classNames(styles.footerLinks)}>
                        support@behaviol.com
                     </a>
                  </div>
               </div>


            </div>
         </div>
         {/* <div className="bottomGradient"></div> */}
      </div>
   );
};

export default MobileFooter;
