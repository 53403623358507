import purpleCap from "../assets/images/purpleCap.webp";
import React from "react";
import classNames from "classnames";
import styles from "../containers/Leaderboard/LeaderBoard.module.scss";
import LbUser from "../assets/images/LbUser.svg";
import LbUserWithoutBorder from "../assets/images/LbUserWithoutBorder.svg";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import StarIcon from "../assets/images/icons/star_icon.svg";
import SprtIcon from "../assets/images/icons/24_sprts_token.webp";

export const getGreenOrPurpleCap = (user, maxAvgRightAnsUser, firstThreeLBUsers) => {
   if (!user || !maxAvgRightAnsUser || !firstThreeLBUsers) {
      return <></>;
   }
   const isMaxAvgRightAnsweredUser = maxAvgRightAnsUser?.userName?.toLowerCase() === user?.userName?.toLowerCase();
   const isMaxPointOccupiedUser = firstThreeLBUsers?.[0]?.userName?.toLowerCase() === user?.userName?.toLowerCase();

   if (isMaxPointOccupiedUser && isMaxAvgRightAnsweredUser) {
      return <>
         <div><img src={purpleCap} alt='Purple Cap Icon'/></div>
      </>
   } else if (isMaxAvgRightAnsweredUser) {
   } else if (isMaxPointOccupiedUser) {
      return <img src={purpleCap} alt='Purple Cap Icon'/>;
   } else {
      return <></>;
   }
}

export const getCorrectWrongIconByHistoryStatus = (historyStatus, i) => {
   if (historyStatus === "CORRECT") {
      return <span className="me-1" key={i}><img alt='Correct Icon'
                                                 src="https://storage.googleapis.com/meta11/serviceplatform/correctIco.webp"></img></span>
   }
   return <span className="me-1" key={i}><img alt='Wrong Icon'
                                              src="https://storage.googleapis.com/meta11/serviceplatform/wrongIco.webp"></img></span>
}

export const getDisNameForFirst3Users = (fname, lname, userName = undefined) => {
   if (fname && lname) {
      const fullName = `${fname.replace(/\s+/g, " ").trim()} ${lname.replace(/\s+/g, " ").trim()}`
      return [fullName.split(' ')[0], fullName.split(' ')[1]]
   } else if (fname) {
      const nameArr = fname.replace(/\s+/g, " ").trim().split(' ');
      if (nameArr.length >= 2) {
         return [nameArr[0], nameArr[1]]
      }
      return [nameArr[0], ''];
   } else if (lname) {
      const nameArr = lname.replace(/\s+/g, " ").trim().split(' ');
      if (nameArr.length >= 2) {
         return [nameArr[0], nameArr[1]]
      }
      return [nameArr[0], ''];
   }

   if (userName) {
      return [userName?.split('@')[0], '']
   }

   return ['--', '--']
}

export function getDisplayName(fName, lName, userName, charLength) {
   const fNameArr = fName ? fName?.trim().split(' ') : '';
   const lNameArr = lName ? lName?.trim().split(' ') : '';

   if (!fName && !lName) {

      if (userName?.length > charLength) {
         return `${userName?.slice(0, charLength)}...`;
      }
      return userName;
   }

   const resultName = [...fNameArr, ...lNameArr];
   const filteredNameArr = resultName?.filter(item => item !== '')

   let resultNameArr;
   if (filteredNameArr.length > 1) {
      resultNameArr = `${filteredNameArr[0]} ${filteredNameArr[1]}`.split('');
   } else {
      resultNameArr = `${filteredNameArr[0]}`.split('');
   }
   return resultNameArr?.length > charLength ? `${resultNameArr?.slice(0, charLength).join('')}...` : resultNameArr.join('');
}

export function getUpDownSameIconByDirection(position) {
   switch (position) {
      case "UP":
         return <img src="https://storage.googleapis.com/meta11/serviceplatform/up-icon.png" alt='Up Icon'></img>
      case "DOWN":
         return <img src="https://storage.googleapis.com/meta11/serviceplatform/down-icon.png" alt='Down Icon'></img>
      default:
         return <img style={{width: "16px", height: "16px", paddingLeft: "2px"}} src="https://storage.googleapis.com/meta11/serviceplatform/same-icon.png" alt='Same Icon'></img>
   }
}

export function handleProfileImageError(fName, lName) {
   const fNameChar = fName?.charAt(0);
   const lNameChar = lName?.charAt(0);

   if (fNameChar && lNameChar) {
      return `${fNameChar.toUpperCase()}${lNameChar.toUpperCase()}`;
   } else if (!fNameChar && !lNameChar) {
      return '';
   } else {
      return fNameChar.toUpperCase();
   }
}

export function getProfileImage(imgUrl, fName, lName, isFirst3User, userName = undefined) {
   const proImgStyle = isFirst3User ? classNames(styles.proTopUserImg) : classNames(styles.proImg);
   const proTextImgStyle = isFirst3User ? classNames(styles.proTopUserTextImg, "text-center") : classNames(styles.proTextImg, "text-center");
   const proTextImgLetterStyle = isFirst3User ? classNames(styles.proTopUserTextImgLetters) : classNames(styles.proTextImgLetters);

   if (imgUrl) {
      return <img src={imgUrl} className={proImgStyle} onError={(event) => {
         event.currentTarget.src = isFirst3User ? LbUserWithoutBorder : LbUser;
         event.currentTarget.className = proImgStyle;
      }} alt="User Image"/>
   }
   if (!fName && !lName) {
      if (userName) {
         return <div className={proTextImgStyle}>
            <section className={proTextImgLetterStyle}>
               {handleProfileImageError(userName, "")}
            </section>
         </div>
      }
      return <img src={isFirst3User ? LbUserWithoutBorder : LbUser} className={proImgStyle} alt="User Icon"/>
   }
   return <div className={proTextImgStyle}>
      <section className={proTextImgLetterStyle}>
         {handleProfileImageError(fName, lName)}</section>
   </div>
}

export function getPosition(position, tipId) {
   if (!position) {
      return '--';
   }
   if (position < 9000) {
      return position;
   }
   return (<OverlayTrigger placement="top" overlay={<Tooltip id={tipId}>{position}</Tooltip>}>
      <span>{"9K+"}</span>
   </OverlayTrigger>);
}

export function getBonusPoint(position, points, rewardConfig) {
   if (points > 0) {
      let bonusPoint = 0;
      if (rewardConfig?.[position]) {
         bonusPoint = rewardConfig[position]?.token;
      } else {
         return;
      }

      return <div
         className={classNames(styles.BonusPointWrapper, position <= 3 ? styles.Top3Bonus : styles.Top10Bonus)}>
         <div><span><img src={StarIcon}/> Bonus</span></div>
         <div>+ {bonusPoint}<img className={classNames(styles.SpartIcon)} src={SprtIcon}/></div>
      </div>;
   } else {

   }
}

export function getBonusPointsForWinPrizesSection(position, points) {
   if (points > 0) {
      let bonusPoint = 0;
      if (position === 1) {
         bonusPoint = 75;
      } else if (position === 2) {
         bonusPoint = 50;
      } else if (position === 3) {
         bonusPoint = 25;
      } else if (position === 4) {
         bonusPoint = 20;
      } else if (position === 5) {
         bonusPoint = 15;
      } else if (position === 6) {
         bonusPoint = 10;
      } else if (position === 7 || position === 8 || position === 9 || position === 10) {
         bonusPoint = 5;
      } else {
         return;
      }
      return <div className={classNames(styles.BonusPointWrapperWinPrizes)}>
         <div><span><img src={StarIcon}/> Bonus</span></div>
         <div>+ {bonusPoint}<img className={classNames(styles.SpartIcon)} src={SprtIcon}/></div>
      </div>;
   } else {

   }
}

export function pointsPerGame(matches, points) {
   if (matches && points) {
      if (matches > 0 && points > 0) {
         // return (points / matches).toFixed(2);
         const pointsPerGame = points / matches;
         let [intPart, decPart] = pointsPerGame.toString().split('.');

         return `${intPart}.${decPart ? decPart.substring(0, 2) : '00'}`;
      } else {
         return 0;
      }
   } else {
      return 0;
   }

}
