import classNames from "classnames";
import classnames from "classnames";
import Styles from "./TournamentLeaderBoard.module.scss"
import GoldRank from "../../../assets/images/icons/GoldRank.webp";
import BronzeRank from "../../../assets/images/icons/BronzeRank.webp";
import SilverRank from "../../../assets/images/icons/SilverRank.webp";
import Credit from "../../../assets/images/icons/24_sprts_token.webp";
import OmniPoints from "../../../assets/images/icons/24_xp.webp";
import React, {useEffect, useState} from "react";
import * as LeaderBoardService from "../../../services/LeaderBoardService";
import styles from "../../../containers/UserProfile/userProfile.module.scss";
import Paginator from "../../Paginator/Paginator";
import {connect} from "react-redux";
import * as TournamentService from "../../../services/TournamentService";
import {getBonusPoint, getProfileImage} from "../../../helpers/LeaderBoardHelpers";
import {useTranslation} from "react-i18next";
import {formatNumberToKWithoutRounding, getPlayerShowingName, getUsdValueFromPoints, toolTipProvider} from "../../../helpers/CommonFunctions";
import {useLocation, useNavigate} from "react-router-dom";
import {getConf} from "../../../services/ConfigService";
import {WALLET} from "../../../utilities/ConfigConstants";
import * as BirdeyeService from "../../../services/BirdeyeService";
import {ReactComponent as FlashIcon} from "../../../assets/images/icons/flash.svg";
import useRewardConfig from "../../../hooks/useRewardConfig";

const TournamentLeaderBoard = (props) => {
   let navigate = useNavigate();
   const location = useLocation();
   const [xpToSprtsRatio, setXpToSprtsRatio] = useState(getConf(WALLET.XP_TO_SPRTS_RATIO));
   const [usdValue, setUsdValue] = useState(localStorage.getItem("usdValue") || 0);

   const [lbUsers, setLbUsers] = useState();
   const [currentUser, setCurrentUser] = useState(null);
   const [currentPage, setCurrentPage] = useState(0);
   const [postsPerPage, setPostsPerPage] = useState(5);
   const [totalElements, setTotalElements] = useState(0);
   const [loading, setLoading] = useState(true);
   const [currentUserPageNumber, setcurrentUserPageNumber] = useState(null);
   const [rewardsCategory, setRewardsCategory] = useState();
   const {t} = useTranslation();
   const {rewardConfig} = useRewardConfig(props.tournamentDetails?.id);

   const navigateToUserProfile = (user) => {
      if (user?.isPrivate) {
         return;
      }
      navigate(`/user/${btoa(user.userName)}?returnUrl=${encodeURIComponent(location.pathname)}`);
   };

   const fetchTournamentData = async () => {
      try {
         const leaderBoardResponse = await LeaderBoardService.getTournamentLeaderBoard(currentPage, postsPerPage, props.tournamentDetails?.id, 'rank', "asc", props.Token);
         if (leaderBoardResponse.apiStatus) {
            if (leaderBoardResponse?.results) {
               setLbUsers(leaderBoardResponse?.results);
               setTotalElements(leaderBoardResponse?.totalElements);
               setLoading(false);
               setXpToSprtsRatio(leaderBoardResponse?.sprtsRates?.xpToSprtsRatio);
            }
         }

         LeaderBoardService.getLBUserByUserNameAndTournamentId(props.userName, props.tournamentDetails?.id, props.Token).then((res) => {
            if (res?.apiStatus) {
               setCurrentUser(res?.position);
               setXpToSprtsRatio(res?.sprtsRates?.xpToSprtsRatio);
               if ((Math.ceil(res?.position.position / postsPerPage) - 1) > 0) {
                  setcurrentUserPageNumber(Math.ceil(res?.position.position / postsPerPage) - 1);
               } else {
                  setcurrentUserPageNumber(0);
               }
            }
         })
      } catch (error) {
         console.error("An error occurred:", error);
      }
   };

   useEffect(() => {
      const fetchData = async () => {
         await TournamentService.getUserRewardCategory(props.tournamentDetails?.id)
            .then((response) => {
               if (response.apiStatus && response.rewardCategoryList) {
                  setRewardsCategory(response.rewardCategoryList);
               }
            })
      };
      fetchData();
   }, [props.tournamentDetails?.id]);

   useEffect(() => {
      if (props.leaderboardData != null || undefined) {
         fetchTournamentData();
      }
   }, [props.leaderboardData]);

   useEffect(() => {
      if (props.userLeaderBoardData != null || undefined) {
         setCurrentUser(props.userLeaderBoardData?.position);
         if ((Math.ceil(props.userLeaderBoardData?.position.position / postsPerPage) - 1) > 0) {
            setcurrentUserPageNumber(Math.ceil(props.userLeaderBoardData?.position.position / postsPerPage) - 1);
         } else {
            setcurrentUserPageNumber(0);
         }
      }
   }, [props.userLeaderBoardData]);

   function getRewardForPosition(position, rewardCategoryList, user) {
      const points = user?.points < 0 ? 0 : user?.points;

      if (!props.tournamentDetails.tournamentTicketOmniCredits) {
         return getTruncatedValue(points, xpToSprtsRatio);

      }

      if (!Array.isArray(rewardCategoryList)) {
         return 0;
      }
      for (const category of rewardCategoryList) {
         const {minRank, maxRank, reward} = category.userRanking;
         if (position >= minRank && position <= maxRank) {
            if (props.tournamentDetails?.tournamentTicketOmniCredits) {
               return getTruncatedValue(reward, 1);
            } else {
               return getTruncatedValue(points, 1000);
            }
         }
      }
      return 0;
   }

   const paginate = (pageNumber, postsPerPage) => {
      setPostsPerPage(Number(postsPerPage));
      setCurrentPage(pageNumber);
      setLoading(true);
   };

   const getTruncatedValue = (points, xpToSprtsRatio) => {
      if (!points || !xpToSprtsRatio) return '0.00';
      const sprtsValue = points / xpToSprtsRatio;

      // Truncate to two decimal places without rounding
      const truncatedValue = Math.floor(sprtsValue * 100) / 100;

      // Convert to string and ensure two decimal places
      return (truncatedValue >= 0 ? truncatedValue : -truncatedValue).toString().padEnd(4, '0').replace('.', '.');
   };

   useEffect(() => {
      fetchTournamentData();
   }, [currentPage, props.tournamentDetails?.id]);

   useEffect(() => {
      props.onPageAndSize({page: currentPage, size: postsPerPage})
   }, [currentPage, postsPerPage, props.leaderboardData]);

   useEffect(() => {
      const fetchUSDValue = async () => {
         try {
            const response = await BirdeyeService.getUSDValue();
            if (response?.success) {
               setUsdValue(response?.data?.value);
               localStorage.setItem('usdValue', response?.data?.value);
            }
         } catch (error) {
            console.error("Error:", error);
         }
      };

      fetchUSDValue();
   }, []);


   return (<>
         <div className="row p-0">
            <div className={classNames(Styles.TournamentSubText, "mt-4 mb-2")}>Fantasy Leaderboard</div>
         </div>
         <div className={classNames(Styles.LeaderBoard, "row p-0")}>
            <div className={classNames(Styles.MatchDetails, "col p-0 m-0")}>
               <div className={classNames(Styles.WinPrizes)}>
                  <div className={classNames(Styles.Conner1)}></div>
                  <div className={classNames(Styles.Conner2)}></div>
                  <div className={classNames(Styles.Conner3)}></div>
                  <div className={classNames(Styles.Conner4)}></div>

                  <div className={classNames(Styles.Shade1)}></div>
                  <div className={classNames(Styles.Shade2)}></div>
                  <div className={classNames(Styles.Shade3)}></div>
                  <div className={classNames(Styles.Shade4)}></div>

                  <div className={classNames(Styles.WinPrizesWrapper)}>
                     <div className={classNames(Styles.Tournament)}>
                        <div className={classNames(Styles.LeaderboardView)}>
                           <div className="row mt-4">
                              <div className="col-md-12">
                                 <div className={classNames(Styles.FlexDataTableContainer)}>
                                    <div className={classNames(Styles.FlexDataTable)}>
                                       {/* Header */}
                                       <div className={classNames(Styles.FlexTableHeader)}>
                                          <div className={classNames(Styles.FlexTableTh)}>{t("MODAL.PLAYERS")}</div>
                                          <div
                                             className={classNames(Styles.FlexTableTh, "text-center")}>{t("MODAL.RANK")}</div>
                                          <div
                                             className={classNames(Styles.FlexTableTh, "d-sm-block text-center")}>{t("MODAL.POINTS")}</div>
                                          <div
                                             className={classNames(Styles.FlexTableTh, "d-sm-block text-center")}>{t("MODAL.REWARDS")}</div>
                                          <div
                                             className={classNames(Styles.FlexTableTh, "d-sm-block text-center")}>{t("MODAL.USD_VALUE")}</div>
                                          <div
                                             className={classNames(Styles.FlexTableTh, "d-sm-block text-center")}>{t("LEADER_BOARD.BOOSTS_APPLIED")}</div>
                                          {/*<div*/}
                                          {/*   className={classNames(Styles.FlexTableTh, " d-none d-sm-block text-center")}>{t("MODAL.AVERAGE_PER_MATCH")}</div>*/}
                                       </div>
                                       {/* body */}

                                       {loading ? <div className={classNames(Styles.FlexTableBody)}>
                                          <div className={classNames(Styles.FlexTableBodyRow, Styles.LoaderRow)}>
                                             <div className={classNames(Styles.FlexTableTh)} colSpan="5">
                                                <div style={{textAlign: "center"}}>
                                                   <div className="lds-facebook">
                                                      <div></div>
                                                      <div></div>
                                                      <div></div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div> : <div className={classNames(Styles.FlexTableBody)}>
                                          {lbUsers?.map(lbUser => {
                                             const playerName = getPlayerShowingName(lbUser);
                                             return (<>
                                                {lbUser?.userName === currentUser?.userName && currentUser.firstName ? <div
                                                   className={classNames(Styles.FlexTableBodyRow, Styles.ActiveRow, "ActiveRow")}>
                                                   <div className={classNames(Styles.FlexTableTD)}
                                                        role={'button'} onClick={() => {
                                                      navigateToUserProfile(lbUser);
                                                   }}>
                                                      {/*<div className={classNames(Styles.Number,"float-left")}>{lbUser?.position}</div>*/}
                                                      <div className={classNames(Styles.Thumb, "float-left")}>
                                                         {getProfileImage(lbUser?.profilePicUrl, playerName.split(' ')[0], playerName.split(' ')[1])}
                                                      </div>
                                                      <div
                                                         className={classNames(Styles.Name)}>{playerName}
                                                         <div
                                                            className={classNames(Styles.BonusPointCell, "BonusPointCell")}>
                                                            {getBonusPoint(lbUser?.position, lbUser?.points, rewardConfig)}
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div
                                                      className={classNames(Styles.FlexTableTD, "d-flex m-0 p-0")}>
                                                      <label className="body-text4 color-gray2 d-none"></label>
                                                      <div className="col-6- p-0 text-right ">
                                                         {lbUser?.position == 1 ? <img className="float-right me-1"
                                                                                       src={GoldRank}/> : lbUser?.position == 2 ?
                                                            <img className="float-right me-1"
                                                                 src={SilverRank}/> : lbUser?.position == 3 ?
                                                               <img className="float-right me-1"
                                                                    src={BronzeRank}/> : <div className={classNames(Styles.NoPosition)}></div>}
                                                      </div>
                                                      <div
                                                         className="col-6- p-0 text-left ">{lbUser?.position}</div>
                                                   </div>
                                                   <div className={classNames(Styles.FlexTableTD)}>
                                                      <label
                                                         className="body-text4 color-gray2 text-center">{t("MODAL.POINTS")}</label>
                                                      <div className="d-flex m-0 p-0">
                                                         <div className="col-6- p-0 text-right"><img
                                                            className="float-right me-1" src={OmniPoints}/></div>
                                                         <div
                                                            className="col-6- p-0 text-left ">{formatNumberToKWithoutRounding(lbUser?.points)}</div>
                                                      </div>
                                                   </div>
                                                   <div className={classNames(Styles.FlexTableTD)}>
                                                      <label
                                                         className="body-text4 color-gray2  text-center">{t("MODAL.REWARDS")}</label>
                                                      <div className="d-flex m-0 p-0">
                                                         <div className="col-6- p-0 text-right"><img
                                                            className="float-right me-1" src={Credit}/></div>
                                                         <div className="col-6- p-0 text-left ">
                                                            {lbUser?.points == 0 ? '0' : getRewardForPosition(lbUser?.position, rewardsCategory, lbUser)}
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div className={classNames(Styles.FlexTableTD, "text-center")}>
                                                      <label
                                                         className="body-text4 color-gray2  text-center">{t("MODAL.USD_VALUE")}</label>
                                                      <div
                                                         className="p-0"><b>US$ </b>{getUsdValueFromPoints(lbUser?.points, xpToSprtsRatio, usdValue)}
                                                      </div>
                                                   </div>
                                                   <div className={classNames(Styles.FlexTableTD, "text-center")}>
                                                      <div className="Boostount">
                                                         <div className="BoostIndicatorIcon">
                                                            <div className="LeaderBoardBoostIconWrapper">
                                                               <div className={classNames(styles.Circle, "Circle")}>
                                                                  <div className={classNames(styles.InnerCircle, "InnerCircle")}></div>
                                                                  <FlashIcon/>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="BoostIndicatorIcon" style={{color: "#000"}}>
                                                            X <span style={{color: "#000"}}> {lbUser?.boostCount ? lbUser?.boostCount : 0}</span>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   {/*<div className={classNames(Styles.FlexTableTD, "text-center")}>*/}
                                                   {/*   <label*/}
                                                   {/*      className="body-text4 color-gray2  text-center">{t("MODAL.AVERAGE_PER_MATCH")}</label>*/}
                                                   {/*   <div className="p-0">{(lbUser?.average).toFixed(1)}</div>*/}
                                                   {/*</div>*/}
                                                </div> : <div className={classNames(Styles.FlexTableBodyRow)}>
                                                   {toolTipProvider(t("LEADER_BOARD_ROW.YOU_CANN'T_VISIT_A_PRIVATE_USER'S_PROFILE"), "top",
                                                      <div
                                                         className={classNames(Styles.FlexTableTD, " w-md-50")}
                                                         role={'button'} onClick={() => {
                                                         navigateToUserProfile(lbUser);
                                                      }}>
                                                         <div
                                                            className={classNames(Styles.Thumb, "float-left")}>
                                                            {getProfileImage(lbUser?.profilePicUrl, playerName.split(' ')[0], playerName.split(' ')[1])}
                                                         </div>
                                                         <div
                                                            className={classNames(Styles.Name)}>{playerName}
                                                            <div
                                                               className={classNames(Styles.BonusPointCell, "BonusPointCell")}>
                                                               {getBonusPoint(lbUser?.position, lbUser?.points, rewardConfig)}
                                                            </div>
                                                         </div>
                                                      </div>, lbUser?.isPrivate)}
                                                   <div
                                                      className={classNames(Styles.FlexTableTD, "d-flex m-0 p-0")}>
                                                      <label className="body-text4 color-gray2 d-none">
                                                      </label>
                                                      <div className="col-6- p-0 text-right ">
                                                         {lbUser?.position == 1 ? <img className="float-right me-1"
                                                                                       src={GoldRank}/> : lbUser?.position == 2 ?
                                                            <img className="float-right me-1"
                                                                 src={SilverRank}/> : lbUser?.position == 3 ?
                                                               <img className="float-right me-1"
                                                                    src={BronzeRank}/> : <div className={classNames(Styles.NoPosition)}></div>}</div>
                                                      <div
                                                         className="col-6- p-0 text-left">{lbUser?.position}</div>

                                                   </div>
                                                   <div className={classNames(Styles.FlexTableTD)}>
                                                      <label
                                                         className="body-text4 color-gray2 text-center">{t("MODAL.POINTS")}</label>
                                                      <div className="d-flex m-0 p-0">
                                                         <div className="col-6- p-0 text-right"><img
                                                            className="float-right me-1" src={OmniPoints}/></div>
                                                         <div
                                                            className="col-6- p-0 text-left ">{formatNumberToKWithoutRounding(lbUser?.points)}</div>
                                                      </div>
                                                   </div>
                                                   <div className={classNames(Styles.FlexTableTD)}>
                                                      <label
                                                         className="body-text4 color-gray2 text-center">{t("MODAL.REWARDS")}</label>
                                                      <div className="d-flex m-0 p-0">
                                                         <div className="col-6- p-0 text-right"><img
                                                            className="float-right me-1" src={Credit}/></div>

                                                         <div
                                                            className="col-6- p-0 text-left ">{lbUser?.points == 0 ? '0' : getRewardForPosition(lbUser?.position, rewardsCategory, lbUser)}</div>
                                                      </div>
                                                   </div>
                                                   <div
                                                      className={classNames(Styles.FlexTableTD, "text-center")}>
                                                      <label
                                                         className="body-text4 color-gray2 text-center">{t("MODAL.USD_VALUE")}</label>
                                                      <div
                                                         className="p-0"><b>US$ </b>{getUsdValueFromPoints(lbUser?.points, xpToSprtsRatio, usdValue)}</div>
                                                   </div>
                                                   <div
                                                      className={classNames(Styles.FlexTableTD, "text-center")}>
                                                      <div className="Boostount">
                                                         <div className="BoostIndicatorIcon">
                                                            <div className="LeaderBoardBoostIconWrapper">
                                                               <div className={classNames(styles.Circle, "Circle")}>
                                                                  <div className={classNames(styles.InnerCircle, "InnerCircle")}></div>
                                                                  {/* <img src={FlashIcon}/> */}
                                                                  <FlashIcon/>
                                                               </div>

                                                            </div>
                                                         </div>
                                                         <div className="BoostIndicatorIcon">
                                                            X <span> {lbUser?.boostCount ? lbUser?.boostCount : 0}</span>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   {/*<div*/}
                                                   {/*   className={classNames(Styles.FlexTableTD, "text-center")}>*/}
                                                   {/*   <label*/}
                                                   {/*      className="body-text4 color-gray2 text-center">{t("MODAL.AVERAGE_PER_MATCH")}</label>*/}
                                                   {/*   <div className="p-0">{(lbUser?.average).toFixed(1)}</div>*/}
                                                   {/*</div>*/}
                                                </div>}
                                             </>)
                                          })}

                                          {currentUser && currentUser.firstName && currentUserPageNumber !== currentPage &&
                                             <div className={classNames(Styles.FlexTableBodyRow, Styles.ActiveRow)}>
                                                {(() => {
                                                   const currPlayerName = getPlayerShowingName(currentUser);
                                                   return (
                                                      <div className={classNames(Styles.FlexTableTD)} role={'button'}
                                                           onClick={() => {
                                                              navigateToUserProfile(currentUser);
                                                           }}>
                                                         {/*<div className={classNames(Styles.Number,"float-left")}>{currentUser?.position}</div>*/}
                                                         <div className={classNames(Styles.Thumb, "float-left")}>
                                                            {getProfileImage(currentUser?.profilePicUrl, currPlayerName.split(' ')[0], currPlayerName.split(' ')[1])}
                                                         </div>
                                                         <div
                                                            className={classNames(Styles.Name)}>{currPlayerName}
                                                            <div
                                                               className={classNames(Styles.BonusPointCell2, "BonusPointCell2")}>
                                                               {getBonusPoint(currentUser?.position, currentUser?.points, rewardConfig)}
                                                            </div>
                                                         </div>
                                                      </div>)
                                                })()}
                                                <div className={classNames(Styles.FlexTableTD, "d-flex m-0 p-0")}>
                                                   <label className="body-text4 color-gray2 d-none"></label>
                                                   <div className="col-6 p-0 text-right ">
                                                      {currentUser?.position == 1 ?
                                                         <img src={GoldRank}/> : currentUser?.position == 2 ?
                                                            <img src={SilverRank}/> : currentUser?.position == 3 ?
                                                               <img src={BronzeRank}/> : <></>}
                                                   </div>
                                                   <div className={classNames(Styles.NoPositionBug, "col-6 p-0 text-left ")}>{currentUser?.position}</div>
                                                </div>
                                                <div className={classNames(Styles.FlexTableTD)}>
                                                   <label className="body-text4 color-gray2">{t("MODAL.POINTS")}</label>
                                                   <img src={OmniPoints}/>
                                                   <span>{formatNumberToKWithoutRounding(currentUser?.points)}</span>
                                                </div>
                                                <div className={classNames(Styles.FlexTableTD)}>
                                                   <label className="body-text4 color-gray2">
                                                      {t("MODAL.REWARDS")}
                                                   </label>
                                                   <img src={Credit}/>
                                                   <span>
                                                      {currentUser?.points == 0 ? '0' : getRewardForPosition(currentUser?.position, rewardsCategory, currentUser)}
                                                   </span>
                                                </div>
                                                <div className={classNames(Styles.FlexTableTD, "text-center")}>
                                                   <label
                                                      className="body-text4 color-gray2 text-center">{t("MODAL.USD_VALUE")}</label>
                                                   <div className="p-0">
                                                      <b>US$ </b>{getUsdValueFromPoints(currentUser?.points, xpToSprtsRatio, usdValue)}
                                                   </div>
                                                </div>
                                                <div className={classNames(Styles.FlexTableTD, "text-center")}>
                                                   <div className="Boostount">
                                                      <div className="BoostIndicatorIcon">
                                                         <div className="LeaderBoardBoostIconWrapper">
                                                            <div className={classNames(styles.Circle, "Circle")}>
                                                               <div className={classNames(styles.InnerCircle, "InnerCircle")}></div>
                                                               <FlashIcon/>
                                                            </div>

                                                         </div>
                                                      </div>
                                                      <div className="BoostIndicatorIcon" style={{color: "#000"}}>
                                                         X
                                                         <span style={{color: "#000"}}>
                                                            {currentUser?.boostCount ? currentUser?.boostCount : 0}
                                                         </span>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>}
                                       </div>}

                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className={classnames(styles.userProfilePaginationWrapper)}>
                        <div className={classnames("paginationWrapper col-12 p-1 pt-3")} style={{textAlign: "center"}}>
                           <Paginator postsPerPage={postsPerPage} totalElements={totalElements}
                                      currentPage={currentPage} paginate={paginate} style/>
                        </div>
                     </div>
                  </div>

               </div>
            </div>

         </div>
      </>

   )

}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};

export default connect(mapStateToProps)(TournamentLeaderBoard);
