import {Card} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import styles from "./BallByBallCard.module.scss";
import classNames from "classnames";
import ProgressBar from "./ProgressBar";

const BallByBallCard = ({cardData, lang, onAnswerSelected, disableCallback, predictionResult, overSummary, summaryMessages}) => {

   console.log(predictionResult);
   console.log(overSummary);

   const [selectedAnswer, setSelectedAnswer] = useState(null);
   const [sendAnswer, setSendAnswer] = useState(null);
   const [disable, setDisable] = useState(false);
   const [selectedBall, setSelectedBall] = useState({
      "ballId": 1
   });
   const [predictions, setPredictions] = useState([]);
   const [predictionData, setPredictionData] = useState([]);

   const ballArrangement = [
      {"ballId": 1}, {"ballId": 2}, {"ballId": 3}, {"ballId": 4}, {"ballId": 5}, {"ballId": 6}
   ]

   const handleBallSelection = (ballData) => {
      console.log('handleBallSelection')
      if (!disable) {
         setSelectedBall(ballData)
      }
      setSelectedAnswer(null)
   }

   // value returning  from the progressbar when its completed
   const onDisable = (disable) => {
      setDisable(disable)
   }

   const handleOnInput = (answer) => {
      console.log('handleOnInput')
      if (!disable) {
         setSelectedAnswer(answer);

         const ballPrediction = {
            "answer": answer.answer,
            "ballId": selectedBall.ballId,
            "inning": cardData?.inning,
            "languageCode": lang,
            "overId": cardData?.overNo,
            "points": cardData?.points,
            "questionRun": 0,
            "questionText": cardData?.questionList?.find(question => question.languageCode == lang).questionText
         }

         if (predictions?.some(prediction => prediction?.ballId == ballPrediction.ballId) && ballPrediction?.ballId <= 6 && ballPrediction?.ballId != 0) {

            let amenedList = predictions?.map(prediction => prediction.ballId == ballPrediction.ballId ? ballPrediction : prediction)
            setPredictions(amenedList);

         } else if (ballPrediction?.ballId <= 6 && ballPrediction?.ballId != 0) {
            setPredictions(prevState => [...prevState, ballPrediction])
         }
         if (selectedBall.ballId < 6) {
            setSelectedBall(prevState => ({"ballId": prevState.ballId + 1}))
         } else if (selectedBall.ballId == 6) {
            setSelectedBall({"ballId": 1})
         }

         const answerData = {
            "questionId": cardData.questionId,
            "answerId": answer.answerId,
            "answer": answer.answer,
            "time": 0,
            "questionText": cardData?.questionText,
            "messageType": cardData?.messageBodyType,
            "maxMillsEnc": cardData?.maxMillsEnc,
            "sendAnswer": answer,
            "questionRun": cardData?.questionRun
         }
         setSendAnswer(answerData);

      } else {
         const nullAnswerData = {
            "questionId": cardData.questionId,
            "answerId": null,
            "answer": null,
            "time": 0,
            "questionText": cardData?.questionText,
            "messageType": cardData?.messageBodyType,
            "maxMillsEnc": cardData?.maxMillsEnc,
            "sendAnswer": null,
            "questionRun": cardData?.questionRun
         }
         setSendAnswer(nullAnswerData);
      }
   }

   useEffect(() => {
      const submit = async () => {

         if (sendAnswer == null) {
            onAnswerSelected(cardData.questionId, null, null, 0, cardData?.questionList?.find(question => question.languageCode == lang).questionText, "BALL_PREDICTION", cardData?.maxMillsEnc, null, 0);
         } else {
            onAnswerSelected(cardData.questionId, null, null, 0, cardData?.questionList?.find(question => question.languageCode == lang).questionText, "BALL_PREDICTION", cardData?.maxMillsEnc, predictions, 0);
         }

      }
      if (disable) {

         submit();
         console.log("disabled")
         if (cardData?.questionId != null) {
            disableCallback(true)
         }
         setSelectedBall({ballId: 0})
         setSelectedAnswer(null)
      }

   }, [disable])

   const getResult = (ballId) => {

      //TODO runs everytime with other questions too ?

      let summary = overSummary?.find(item => item?.inning == cardData?.inning && item?.overId == cardData?.overNo);
      let predication = predictionResult?.find(result => result?.inning == cardData?.inning && result?.overId == cardData?.overNo);

      if (summary?.inning == predication?.inning && summary?.overId == predication?.overId) {

         let ballResult = predictions.find(prediction => prediction.ballId == ballId);

         if (ballResult != undefined && predictionResult?.some(result => result?.inning == cardData?.inning && result?.overId == cardData?.overNo)) {

            if (predictionResult?.find(result => result?.ballId == ballId && result?.overId == cardData?.overNo && result?.inning == cardData?.inning)?.actual == undefined) {
               return null;
            } else {
               return predictionResult?.find(result => result?.ballId == ballId && result?.overId == cardData?.overNo && result?.inning == cardData?.inning)?.actual == ballResult?.answer;
            }
         } else {
            return null;
         }
      }
      return null;
   }

   const getSummaryData = (inning, overId) => {
      let summary = overSummary?.find(item => item?.inning == inning && item.overId == overId)

      return {
         "consecutive3Points": summary?.overSummary?.consecutive3Points != null ? summary?.overSummary?.consecutive3Points : summary?.overSummary?.consecutiveBonusPoints,
         "predictionPoints": summary?.overSummary?.predictionPoints,
         "streakBonusPoints": summary?.overSummary?.streakBonusPoints,
         "sixPredictionBonus": summary?.overSummary?.sixPredictionBonusPoints,
         "totalPoints": summary?.overSummary?.totalPoints
      };
   }

   const mapData = (feedbacks) => {

      const mappedObject = {};

      feedbacks.forEach((item) => {
         const {fId, language_code, feedback_txt} = item;

         // Define key names based on fId
         let keyName = '';

         switch (fId) {
            case 10:
               keyName = 'OverSummary';
               break;
            case 11:
               keyName = 'CorrectAnswers';
               break;
            case 12:
               keyName = 'StreakBonus';
               break;
            case 13:
               keyName = 'RowBonus';
               break;
            case 14:
               keyName = 'TPoints';
               break;
            case 15:
               keyName = 'CheckPoints';
               break;
            case 16:
               keyName = 'AllCorrectBonus';
               break;
            case 17:
               keyName = 'MatchSummary';
               break;
            default:
               keyName = `DefaultKey_${fId}`;
         }

         // Use keyName as the key
         if (!mappedObject[keyName]) {
            mappedObject[keyName] = {en: [], ta: [], hi: []};
         }

         mappedObject[keyName][language_code].push({language_code, feedback_txt});
      });

      return (mappedObject);
   };

   useEffect(() => {
      if (predictionResult?.some(result => result?.overId == cardData?.overNo && result?.inning == cardData?.inning)) {
         onDisable(true);
      }
   }, [predictionResult])

   // setting up 6ball prediction ball results
   // useEffect(() => {
   //    setPredictionData(predictionResult?.filter(data => data?.actual != "N/P" && data?.inning == cardData?.inning && data?.overId == cardData?.overNo))
   // }, [predictionResult])

   return (
      <div>
         <Card className={classNames(styles.botCardWrapper)}>
            {/*<Card.Subtitle>Inning : {cardData?.inning} Over : {cardData?.overNo}</Card.Subtitle>*/}
            <Card.Body className={classNames(styles.botCardBody)}>
               {overSummary?.length > 0 && overSummary?.some(summary => summary?.inning == cardData?.inning && summary?.overId == cardData?.overNo) || disable ?
                  <h6 className="body-text4 color-white d-flex"> {mapData(summaryMessages)?.CheckPoints[lang][0]?.feedback_txt}</h6> :
                  <h6 className="body-text4 color-white d-flex"> {cardData?.questionList?.find(question => question.languageCode == lang).questionText}</h6>
               }

               <div className={classNames(styles.ballBody, styles.ballArrangementWrapper)}>
                  {ballArrangement?.map(ball => (
                     <div className={classNames(getResult(ball?.ballId) == null ? styles.ballWrapper : styles.BallWrapper40, (getResult(ball?.ballId) != null && getResult(ball?.ballId) == true) ? styles.bigBallGreen : styles.bigBallRed)}>

                      <span className={classNames(selectedBall?.ballId == ball?.ballId ? styles.active : "", "heading4 color-black")}
                            onClick={() => handleBallSelection({"ballId": ball?.ballId})}>
                         {predictions?.some(prediction => prediction?.ballId == ball?.ballId) ? predictions.find(prediction => prediction?.ballId == ball?.ballId).answer : ""}
                      </span>

                        {/*renders the small correct incorrect value text under the ball*/}
                        {(getResult(ball?.ballId) != null && getResult(ball?.ballId) == true) ?
                           <span className={classNames(styles.muted, "bold italic")}>+{cardData?.points}</span> : <>{getResult(ball?.ballId) == null ? "" :
                              <span className={classNames(styles.muted, "bold italic")}>0</span>} </>

                        }
                     </div>))
                  }
               </div>

               {/*animation for the result of the 6 ball prediction */}
               {(predictionData[predictionData?.length - 1] && predictions?.length > 0 && !overSummary?.some(summary => summary?.inning == cardData?.inning && summary?.overId == cardData?.overNo) && predictionResult?.length > 0 && predictionResult?.some(result => result?.overId == cardData?.overNo && result?.inning == cardData?.inning)) ?
                  <div className={classNames(styles.glowBack)}>
                     <div className={classNames(styles.ballSecondBody, "d-flex")} style={{justifyContent: "center"}}>
                        <div className={classNames(styles.bigBallWrapper, (getResult(predictionData[predictionData?.length - 1]?.ballId) != null && getResult(predictionData[predictionData?.length - 1]?.ballId) == true) ? styles.bigBallGreen : styles.bigBallRed)}>
                           <span className="heading3">{predictionData[predictionData?.length - 1]?.actual}</span>
                        </div>
                        <label className={classNames((getResult(predictionData[predictionData?.length - 1]?.ballId) != null && getResult(predictionData[predictionData?.length - 1]?.ballId) == true) ? styles.ptstxtlabel : styles.mnstxtlabel, "heading3 color-white bold italic d-flex lower-case")}
                               style={{alignItems: "center"}}>
                           <div>
                              <span>+ {getResult(predictionData[predictionData?.length - 1]?.ballId) == true ? cardData?.points : 0} </span><span
                              className={classNames(styles.pts)}>pts</span></div>
                        </label>
                     </div>
                  </div> : ""
               }

               {!disable ?
                  <div className={classNames(styles.ballSecondBody, styles.predictionWrapper)}>
                     {cardData?.answers?.filter(answer => answer.languageCode == lang).map(choice => (
                        <div className={classNames(styles.scoreWrapper)}>
                           <input disabled={disable} type={"radio"} id={choice?.answerId}
                                  checked={selectedAnswer?.answerId === choice?.answerId} value={choice?.answer}
                                  name={"answer"}/>
                           <label htmlFor={choice?.answerId}
                                  className={classNames(selectedAnswer?.answerId == choice?.answerId ? "ball-selected-input " : "ball-deselected-input ", choice?.answer == "W" ? "wicketBall" : "", choice?.answer == "WD" ? "wideBall" : "")}
                                  onClick={disable ? null : () => handleOnInput(choice)}>{choice?.answer}</label>
                        </div>))
                     }
                  </div> : <></>
               }

               {!disable && (cardData?.ttl != 0 || cardData?.ttl !== null) ?
                  <ProgressBar interval={cardData?.ttl} disable={onDisable}/> : <></>
               }

               {/*over summery comes here*/}
               {overSummary?.length > 0 && overSummary?.some(summary => summary?.inning == cardData?.inning && summary?.overId == cardData?.overNo) ?
                  <div className={classNames(styles.ballSecondBody, "d-flex mt-4")}
                       style={{justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                     <h7 className={classNames(styles.summarytxtlabel, "heading4 color-white bold d-flex capitalize green-glow")}
                         style={{alignItems: "center"}}>{mapData(summaryMessages)?.OverSummary[lang][0]?.feedback_txt}</h7>
                     <h7 className="color-textturquiose heading5 bold green-glow">{getSummaryData(cardData?.inning, cardData?.overNo)?.totalPoints} pts</h7>
                     <div className={classNames(styles.borderBottomNion, "d-flex mb-2")}></div>
                     <div className={classNames(styles.boardWrapper)}>
                      <span className="d-flex mt-1 heading6 semi-bold"><label
                         className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.CorrectAnswers[lang][0]?.feedback_txt}:</label><label
                         className="body-text4 color-textturquiose">{getSummaryData(cardData?.inning, cardData?.overNo)?.predictionPoints} pts</label></span>
                        <span className="d-flex mt-1 heading6 semi-bold"><label
                           className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.StreakBonus[lang][0]?.feedback_txt}</label><label
                           className="body-text4 color-textturquiose">{getSummaryData(cardData?.inning, cardData?.overNo)?.streakBonusPoints} pts</label></span>
                        <span className="d-flex mt-1 heading6 semi-bold"><label
                           className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.RowBonus[lang][0]?.feedback_txt}</label><label
                           className="body-text4 color-textturquiose">{getSummaryData(cardData?.inning, cardData?.overNo)?.consecutive3Points} pts</label></span>
                        <span className="d-flex mt-1 heading6 semi-bold"><label
                           className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.AllCorrectBonus[lang][0]?.feedback_txt}:</label><label
                           className="body-text4 color-textturquiose">{getSummaryData(cardData?.inning, cardData?.overNo)?.sixPredictionBonus} pts</label></span>
                        <div className={classNames(styles.borderBottomNion, "d-flex mb-2 mt-2")}></div>
                        <span className="d-flex mt-1 heading6 semi-bold"><label
                           className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.TPoints[lang][0]?.feedback_txt}</label><label
                           className="body-text4 color-textturquiose">{getSummaryData(cardData?.inning, cardData?.overNo)?.totalPoints} pts</label></span>
                     </div>
                  </div> : ""}
            </Card.Body>
         </Card>
      </div>
   )
}

export default BallByBallCard;
