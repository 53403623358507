import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export const updateFavoritePlayer = async (token, data) => {
   const url = `${ApiEndpoints.SET_FAVOURITE_PLAYER}`
   return AxiosInstance()
      .put(url, data, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
};

export const updateFavoriteTeam = async (token, data) => {
   const url = `${ApiEndpoints.SET_FAVOURITE_TEAM}`
   return AxiosInstance()
      .put(url, data, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
};

// TODO check user profile change
export const getFavourites = async (token, type, userName, page, size, sort) => {
   //http://localhost:8080/api/user/1/artwork/TEST?page=0&size=10
   const url = `${ApiEndpoints.GET_FAVOURITES}/${type}/${userName}?page=${page}&size=${size}`
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return {
            status: response.status,
            data: response.data,
         };
      })
      .catch((error) => {
         return error.response;
      });
};
