import React from "react";
import styles from "./Paginator.module.scss";
import classnames from "classnames";
import {BsArrowLeft, BsArrowRight} from "react-icons/bs";

const Paginator = ({postsPerPage, totalElements, paginate, currentPage}) => {

   if (!currentPage) {
      currentPage = 0;
   }

   const pageNumbers = [];
   const numVisiblePages = 5;

   for (let i = 0; i < Math.ceil(totalElements / postsPerPage); i++) {
      pageNumbers.push(i);
   }

   const handleVisiblePages = () => {

      const halfVisiblePages = Math.floor(numVisiblePages / 2);

      let startPage = currentPage - halfVisiblePages;
      let endPage = currentPage + halfVisiblePages;

      if (startPage < 0) {
         startPage = 0;
         endPage = Math.min(numVisiblePages - 1, pageNumbers.length);
      } else if (endPage >= pageNumbers.length) {
         endPage = pageNumbers.length - 1;
         startPage = Math.max(endPage - numVisiblePages + 1, 0);
      }
      return pageNumbers.slice(startPage, endPage + 1);
   };

   let visiblePages = handleVisiblePages()

   pageNumbers.pop();
   let nextPage = currentPage + 1;
   let prevPage = currentPage - 1;

   return (
      <React.Fragment>
         <nav aria-label={classnames("Page navigation")}>
            <ul className={classnames("pagination", "justify-content-left", "flex-wrap", styles.pagination)}>
               {/*{currentPage > 2 ?*/}
               {/*    <li className={styles.li}>*/}
               {/*        <a className={classnames("page-link", styles.firstPageLink)}*/}
               {/*           onClick={() => paginate(0, postsPerPage)}>1</a>*/}
               {/*    </li> : <></>*/}
               {/*}*/}

               {/*{currentPage > numVisiblePages - 2 && (*/}
               {/*    <li className={styles.li}>*/}
               {/*        <a className="page-link">*/}
               {/*            <BsThreeDots/>*/}
               {/*        </a>*/}
               {/*    </li>*/}
               {/*)}*/}
               <li className={classnames("page-item", styles.li, prevPage == -1 ? "disabled" : "")}>
                  <a className={classnames("page-link", prevPage == -1 ? "disabled" : "")} onClick={() => paginate(currentPage - 1, postsPerPage)}>
                     <BsArrowLeft/>
                  </a>
               </li>
               {visiblePages?.map((number) => (
                  <li key={number} className={classnames(styles.li, number == currentPage ? "active" : "")}>
                     <a onClick={() => paginate(number, postsPerPage)} className="page-link">
                        {number + 1}
                     </a>
                  </li>
               ))}
               {/*{currentPage < pageNumbers.length - 4 && (*/}
               {/*    <li className={styles.li}>*/}
               {/*        <a className="page-link">*/}
               {/*            <BsThreeDots/>*/}
               {/*        </a>*/}
               {/*    </li>*/}
               {/*)}*/}
               <li className={classnames("page-item", styles.li)}>
                  <a className={classnames("page-link", pageNumbers.length < nextPage ? "disabled" : "")} onClick={() => paginate(currentPage + 1, postsPerPage)}>
                     <BsArrowRight/>
                  </a>
               </li>
               {/*{currentPage < pageNumbers.length - 2 ?*/}
               {/*    <li className={styles.li}>*/}
               {/*        <a className={classnames("page-link", styles.lastPageLink)} onClick={() => paginate(pageNumbers.length - 1, postsPerPage)}>*/}
               {/*            {pageNumbers.length}*/}
               {/*        </a>*/}
               {/*    </li>*/}
               {/*    : <></>}*/}
            </ul>
         </nav>
      </React.Fragment>
   );
};

export default Paginator;
