import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export function getLatestTournament() {

   const url = `${ApiEndpoints.GET_LATEST_TOURNAMENT}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export function getTournamentById(ID) {

   const url = `${ApiEndpoints.GET_TOURNAMENT}/${ID}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export function getAllTournaments(searchQuery, currentPage, postsPerPage, sort, type, pageLang, authToken) {

   const url = `${ApiEndpoints.GET_TOURNAMENT}?attribute=${searchQuery}&language=${pageLang}&page=${currentPage}&size=${postsPerPage}&sort=${sort}&type=${type}`;

   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export function getKnockoutTournamentById(authToken, tournamentId, lang) {

   const url = `${ApiEndpoints.GET_KNOCKOUT_TOURNAMENT_BY_ID}/${tournamentId}?lang=${lang}`;
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export function getTournamentTeams(authToken, tournamentId, lang) {

   const url = `${ApiEndpoints.GET_TOURNAMENT_TEAMS_BY_ID}/${tournamentId}?lang=${lang}`;

   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export function checkTournamentRegistration(tournamentId, userName) {

   const url = `${ApiEndpoints.GET_TOURNAMENT_REGISTRATION}/${tournamentId}/${userName}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export function getUserRewardCategory(tournamentId) {

   const url = `${ApiEndpoints.GET_USER_REWARD_CATEGORY}/${tournamentId}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}


