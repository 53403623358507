import React from "react";
import {connect} from "react-redux";
import Styles from "./SocialButton.module.scss"
import {useLocation, useNavigate} from "react-router-dom";
import * as actions from "../../store/actions";
import {useTranslation} from "react-i18next";

function GoogleLogOutButton(props) {
   let navigate = useNavigate();
   const location = useLocation();
   const redirect = location.state;
   const {t} = useTranslation();

   const logout = () => {
      props.onLogout()
      if (props === null || props.path === undefined || props?.path?.includes("user")) {
         return navigate("/")

      } else {
         return navigate(`${props.path}`)
      }
   };

   return (
      <button onClick={logout} className={Styles.logout}> {t("GOOGLE_LOG_OUT.LOG_OUT")}</button>
   );

}

const mapStateToProps = state => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
   }
}

const mapDispatchToProps = dispatch => {
   return {
      onLogout: () => dispatch(actions.logout())
   }
}
export default connect(mapStateToProps, mapDispatchToProps)(GoogleLogOutButton);
