import React, {useEffect, useState} from "react";
import Classnames from "classnames";
import Styles from "./LatestMatch.module.scss";
import MatchTimer from "../../components/MatchTimer/MatchTimer";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import styles from "../Match/Match.module.scss";
import shareIcon from "../../assets/images/icons/share_icon.svg";

const LatestMatch = (props) => {
   console.log(props)
   const location = useLocation();
   const overCount = (teamOvers, teamBalls) => {

      const overs = teamBalls !== 6
         ? teamOvers - 1
         : teamOvers

      const balls = teamBalls !== 6
         ? teamBalls
         : 0
      return overs + "." + balls;

   }
   const {t, i18n} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);
   return (
      <React.Fragment>

         {/* Live */}
         {(props.tournamentDetails?.active == true && props.latestMatch?.live == true && props.tournamentDetails?.toStart == false && props.latestMatch) ?
            <div className={Classnames(Styles.LatestMatch, Styles.LatestMatchLive)}>
               <div className={Classnames(Styles.NowPlayingBlock)}>
                  <Link to={{pathname: `/match/${props?.latestMatch?.matchId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                     <div className={Classnames(Styles.NowPlayingContainer)}>

                        <div className={Classnames(styles.MoreInfoBtn, "MoreInfoBtn")}>
                           <img src={shareIcon} alt={""}/>
                        </div>

                        <div className={Classnames(Styles.Row1)}>
                           {/* <label className={Classnames(Styles.liveTag)}><span></span>{t("LATEST_MATCH.LIVE")}ss</label> */}
                           <div className="TournamentMatchesLiveTag">
                              <div className={Classnames(Styles.PlayNowLiveTagContainer, "PlayNowLiveTagContainer")}>
                                 <div className={Classnames(Styles.PlayNowLiveTagWrapper)}></div>
                                 <span className={Classnames(Styles.PlayNowLiveTag, "PlayNowLiveTag matchStatslbl-")}>
                                    <span className={Classnames(Styles.BlinkingDot, "BlinkingDot livedot blinking")}></span>
                                    <div className={Classnames(Styles.LiveTag)}>{t("MATCHES.LIVE_NOW")}</div>
                                 </span>
                              </div>
                           </div>
                           {/* <h2>{t("LATEST_MATCH.NOW_PLAYING")}</h2> */}
                        </div>
                        <div className={Classnames(Styles.Row2)}>
                           <div className={Classnames(Styles.SubRow)}>
                              <div className={Classnames(Styles.PlayerThumbImg)} style={{backgroundImage: `url(${props.latestMatch?.team1PicURL})`}}></div>
                              <h4>
                                 {props.latestMatch?.team1Name ?? t("LATEST_MATCH.TBA")}

                              </h4>

                              <div className={Classnames(Styles.BattBallIcon)}>
                                 {props.latestMatch?.team1Id == props.latestMatch?.battingTeamId ? (
                                    <span className={styles.teamBat}></span>) : (
                                    <span className={styles.teamBall}></span>
                                 )}
                              </div>
                              <div>
                                 <span>{props.latestMatch?.team1Runs ?? '0'}/{props.latestMatch?.team1Wickets ?? '0'}</span>
                                 <label className="body-text5 color-gray2">
                                    {props.latestMatch?.team1Overs || props.latestMatch?.team1Balls ? (
                                       <label className="oversLbl">({overCount(props.latestMatch?.team1Overs, props.latestMatch?.team1Balls)})</label>
                                    ) : (
                                       <label className="oversLbl empty">(0.0)</label>
                                    )}
                                 </label>
                              </div>
                           </div>
                           <div className={Classnames(Styles.SubRow)}>
                              <div className={Classnames(Styles.PlayerThumbImg)} style={{backgroundImage: `url(${props.latestMatch?.team2PicURL})`}}></div>
                              <h4> {props.latestMatch?.team2Name ?? t("LATEST_MATCH.TBA")}

                              </h4>

                              <div className={Classnames(Styles.BattBallIcon)}>
                                 {props.latestMatch?.team2Id == props.latestMatch?.battingTeamId ? (
                                    <span className={styles.teamBat}></span>) : (
                                    <span className={styles.teamBall}></span>
                                 )}
                              </div>
                              <div>
                                 <span>{props.latestMatch?.team2Runs ?? '0'}/{props.latestMatch?.team2Wickets ?? '0'}</span>
                                 <label className="body-text5 color-gray2">
                                    {props.latestMatch?.team2Overs || props.latestMatch?.team2Balls ? (
                                       <label className="oversLbl">({overCount(props.latestMatch?.team2Overs, props.latestMatch?.team2Balls)})</label>
                                    ) : (
                                       <label className="oversLbl empty">(0.0)</label>
                                    )}
                                 </label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Link>
               </div>
            </div>
            : <></>}
         {/*Upcoming Match*/}
         {(props.tournamentDetails?.active == true && props.latestMatch?.live == false && props.latestMatch?.toStart == true && props.tournamentDetails?.toStart == false && props.latestMatch) ?
            <div className={Classnames(Styles.LatestMatch)}>
               <div className={Classnames(Styles.UpcomingBlock)}>
                  <Link to={{pathname: `/match/${props?.latestMatch?.matchId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                     <div className={Classnames(Styles.UpComingContainer)}>
                        <div className={Classnames(styles.MoreInfoBtn, "MoreInfoBtn")}>
                           <img src={shareIcon} alt={""}/>
                        </div>
                        <div className={Classnames(Styles.Row1)}>
                           <label className={Classnames(Styles.upcomingTag)}><span></span>{t("LATEST_MATCH.UPCOMING")}</label>
                           {/*<h2>Next</h2>*/}
                        </div>
                        <div className={Classnames(Styles.Row2)}>
                           <div className={Classnames(Styles.SubRow)}>
                              <div><img className={Classnames(Styles.Logo)} src={props.latestMatch?.team1PicURL} alt={''}/></div>
                              <h4> {props.latestMatch?.team1Name ?? t("LATEST_MATCH.TBA")}</h4>
                              <div className={Classnames(Styles.BattBallIcon)}>
                              </div>
                              <div>
                                 <span>{props.latestMatch?.team1Runs ?? '0'}/{props.latestMatch?.team1Wickets ?? '0'}</span>
                                 <label className="body-text5 color-gray2">
                                    {props.latestMatch?.team1Overs || props.latestMatch?.team1Balls ? (
                                       <label className="oversLbl">({overCount(props.latestMatch?.team1Overs, props.latestMatch?.team1Balls)})</label>
                                    ) : (
                                       <label className="oversLbl empty">(0.0)</label>
                                    )}
                                 </label>
                              </div>
                           </div>
                           <div className={Classnames(Styles.SubRow)}>
                              <div><img className={Classnames(Styles.Logo)} src={props.latestMatch?.team2PicURL} alt={''}/></div>
                              <h4> {props.latestMatch?.team2Name ?? t("LATEST_MATCH.TBA")} </h4>
                              <div className={Classnames(Styles.BattBallIcon)}>
                              </div>
                              <div>
                                 <span>{props.latestMatch?.team2Runs ?? '0'}/{props.latestMatch?.team2Wickets ?? '0'}</span>
                                 <label className="body-text5 color-gray2">
                                    {props.latestMatch?.team2Overs || props.latestMatch?.team2Balls ? (
                                       <label className="oversLbl">({overCount(props.latestMatch?.team2Overs, props.latestMatch?.team2Balls)})</label>
                                    ) : (
                                       <label className="oversLbl empty">(0.0)</label>
                                    )}
                                 </label>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Link>
               </div>
            </div>
            : <></>}
         {/* Scheduled */}
         {(props.tournamentDetails?.active == false && props.tournamentDetails?.toStart == true) &&
            <MatchTimer targetDate={props.tournamentDetails?.startTime} matchId={props?.latestMatch?.matchId}/>
         }


         {/* Completed */}
         {((props.tournamentDetails?.active == false || (props.latestMatch?.live == false && props.latestMatch?.toStart == false)) && props.tournamentDetails?.toStart == false && props.latestMatch) ?
            <div className={Classnames(Styles.CompletedMatchInfoBox)}>
               <Link to={{pathname: `/match/${props?.latestMatch?.matchId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                  <div className={Classnames(Styles.ContentWrapper)} style={{cursor: 'pointer'}}>

                     <div className={Classnames(styles.MoreInfoBtn, "MoreInfoBtn")}>
                        <img src={shareIcon} alt={""}/>
                     </div>

                     <div className={Classnames(Styles.Row)}>
                        <label className={Classnames(Styles.completedTag)}>{t("LATEST_MATCH.COMPLETED")}</label>
                        <div className={Classnames(Styles.ResultInfoWrapper)}>
                           <div className={Classnames(Styles.LogoWrapper, Styles.PlayerThumbImg)} style={{backgroundImage: `url(${props.latestMatch?.team1PicURL})`}}>
                              {props.latestMatch?.winnerId === props.latestMatch?.team1Id &&
                                 <div className={Classnames(Styles.CrownWrapper)}></div>}
                              {/* <img src={props.latestMatch?.team1PicURL} alt={''}/> */}
                           </div>
                           <div className={Classnames(Styles.TeamName)}>
                              <h4>{props.latestMatch?.team1Name ?? t("LATEST_MATCH.TBA")}</h4>
                           </div>
                           {/*<div className={Classnames(Styles.BatOrBall)}>*/}
                           {/*   <img src={Ball}/>*/}
                           {/*</div>*/}
                           <div className={Classnames(Styles.TotalWrapper)}>
                              <span className={props.latestMatch?.winnerId == props.latestMatch?.team1Id ? Classnames(Styles.Winner) : ""}>{props.latestMatch?.team1Runs ?? '0'}/{props.latestMatch?.team1Wickets ?? '0'}</span>
                              <label className="body-text5 color-gray2">
                                 {props.latestMatch?.team1Overs || props.latestMatch?.team1Balls ? (
                                    <label className="oversLbl">({overCount(props.latestMatch?.team1Overs, props.latestMatch?.team1Balls)})</label>
                                 ) : (
                                    <label className="oversLbl empty">(0.0)</label>
                                 )}
                              </label>
                           </div>
                        </div>
                     </div>

                     <div className={Classnames(Styles.Row)}>
                        <div className={Classnames(Styles.ResultInfoWrapper)}>
                           <div className={Classnames(Styles.LogoWrapper, Styles.PlayerThumbImg)} style={{backgroundImage: `url(${props.latestMatch?.team2PicURL})`}}>
                              {props.latestMatch?.winnerId === props.latestMatch?.team2Id &&
                                 <div className={Classnames(Styles.CrownWrapper)}></div>}
                              {/* <img src={props.latestMatch?.team2PicURL} alt={""}/> */}
                           </div>
                           <div className={Classnames(Styles.TeamName)}>
                              <h4>{props.latestMatch?.team2Name ?? t("LATEST_MATCH.TBA")}</h4>
                           </div>
                           {/*<div className={Classnames(Styles.BatOrBall)}>*/}
                           {/*   <img src={Bat}/>*/}
                           {/*</div>*/}
                           <div className={Classnames(Styles.TotalWrapper)}>
                              <span className={props.latestMatch?.winnerId == props.latestMatch?.team2Id ? Classnames(Styles.Winner) : ""}>{props.latestMatch?.team2Runs ?? '0'}/{props.latestMatch?.team2Wickets ?? '0'}</span>
                              <label className="body-text5 color-gray2">{props.latestMatch?.team2Overs || props.latestMatch?.team2Balls ? (
                                 <label
                                    className="oversLbl">({overCount(props.latestMatch?.team2Overs, props.latestMatch?.team2Balls)})</label>
                              ) : (
                                 <label className="oversLbl empty">(0.0)</label>
                              )}</label>
                           </div>
                        </div>
                     </div>
                  </div>
               </Link>
            </div> : <></>
         }
      </React.Fragment>
   )
}


export default LatestMatch;
