import {useEffect, useState} from "react";
import * as LeaderBoardService from "../services/LeaderBoardService";

const useRewardConfig = (tournamentId, type = "TOURNAMENT") => {
   const [rewardConfig, setRewardConfig] = useState({});

   useEffect(() => {
      const fetchRewardsConfig = async () => {
         try {
            const response = await LeaderBoardService.getRewardConfig(tournamentId, type);
            if (response?.apiStatus) {
               setRewardConfig(response?.rewardConfigDTOS?.reduce((rewardsObj, reward) => {
                  rewardsObj[reward?.rank] = reward
                  return rewardsObj
               }, {}))
            }
         } catch (error) {
            console.error("Error:", error);
         }
      }

      if (tournamentId) {
         fetchRewardsConfig();
      }
   }, [tournamentId, type]);

   return {rewardConfig};
}

export default useRewardConfig;
