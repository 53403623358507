export const COMMON_TOASTS = Object.freeze({
   ERROR_OCCURED_GETTING_NOTIFICATIONS_TRY_RELOADING_THE_PAGE: 'Error Occurred Getting Notifications.Try Reloading the Page.',
   ERROR_OCCURED_WHEN_GETTING_USER_FOLLOWINGS_PLEASE_TRY_AGAIN: 'Error Occurred When Getting User Followings Please Try Again.',
   ERROR_OCCURED_WHEN_GETTING_LEADERBOARD_PLEASE_TRY_AGAIN: 'Error Occurred When Getting Leaderboard. Please Try Again.',
   UNAUTHORIZED_REQUEST: 'Your Token Has Been Expired. Please Login Again To Continue..',
})

export const USER_PROFILE_TOASTS = Object.freeze({
   ERROR_OCCURED_GETTING_USER_DETAILS_TRY_RELOADING_THE_PAGE: 'Error Occurred Getting User Details Try Reloading The Page.',
   ERROR_OCCURED_GETTING_USER_FAVOURITE_TEAMS_TRY_RELOADING_THE_PAGE: 'Error Occurred Getting User Favourite Teams Try Reloading The Page.',
   ERROR_OCCURED_GETTING_USER_ARTWORKS_TRY_RELOADING_THE_PAGE: 'Error Occurred Getting User Artworks Try Reloading The Page.',
   ERROR_OCCURED_DELETING_USER_ASSET_TRY_AGAIN: 'Error Occurred Deleting User Asset.Try Again.',
   ERROR_OCCURED_UPDATING_USER_ASSET_TRY_AGAIN: 'Error Occurred Updating User Asset.Try Again.',
   ERROR_OCCURED_UPDATING_USER_DETAILS_TRY_AGAIN: 'Error Occurred Updating User Details.Try Again.',
   ERROR_OCCURED_DELETING_USER_PROFILE: 'Error Occurred Deleting User Profile.Try Again.',
   PROFILE_IMAGE_DELETE_ERROR: 'Error Occurred While Deleting the Profile Image. PLease Try Again!',
   COVER_IMAGE_DELETE_ERROR: 'Error Occurred While Deleting the Cover Image. PLease Try Again!',
   GOOGLE_ASSIGNED_IMAGE_DELETE: 'Sorry! You Are Not Allowed To Delete The Google Assigned Profile Image!',

   PROFILE_IMAGE_UPDATED_SUCCESSFULLY: 'Profile Image Updated Successfully!',
   COVER_IMAGE_UPDATED_SUCCESSFULLY: 'Cover Image Updated Successfully!',
   USER_DETAILS_UPDATED_SUCCESSFULLY: 'User Details Updated Successfully!',
   NO_CHANGES_MADE_TO_USER_DETAILS: 'No changes made to user details!',
   IMAGE_DELETED_SUCCESSFULLY: 'Image Deleted Successfully!',
})

export const COMMUNITY_TOASTS = Object.freeze({
   ERROR_OCCURRED_GETTING_DESIGN_CATEGORIES_TRY_RELOADING_THE_PAGE: 'Error Occurred Getting Design Categories.Try Reloading The Page.',
   ERROR_OCCURRED_GETTING_DESIGN_TYPES_TRY_RELOADING_THE_PAGE: 'Error Occurred When Getting User Followings. Please Try Again.',
   ASSET_UPLOAD_FAILED_PLEASE_TRY_AGAIN: 'Asset Upload Failed. Please Try Again.',
})

export const AUTHENTICATION_TOASTS = Object.freeze({
   INVALID_FACEBOOK_LOGIN: 'Invalid Facebook Login',
   INVALID_GOOGLE_LOGIN: 'Invalid Google Login',
   ERROR_OCCURRED_GETTING_DESIGN_TYPES_TRY_RELOADING_THE_PAGE: 'Error Occurred When Getting User Followings. Please Try Again.',
   ASSET_UPLOAD_FAILED_PLEASE_TRY_AGAIN: 'Asset Upload Failed. Please Try Again.',
   ERROR_CHECKING_EMAIL_AVAILABILITY: "Email Checking Error. Please Try Again",
   ERROR_OCCURRED_WHILE_LOGIN: 'Error Occurred While Logging In. Please Try Again.',
   ERROR_OCCURRED_SENDING_CODE: "Error Occurred While Sending Secret Code. Please Try Again.",
   TOURNAMENT_IS_BLOCKED: "Tournament is blocked for your region."
})

