import React, {useEffect, useState} from "react";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";
import styles from "./Match.module.scss";
import classNames from "classnames";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import * as StatisticsService from "../../services/StatisticsService";
import Scorecard from "../../components/Scorecard/Scorecard";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import {connect} from "react-redux";
import {BsBoxArrowUp, BsChevronLeft, BsChevronRight} from "react-icons/bs";
import MatchScoreHeader from "./MatchScoreHeader";
import ScheduledMatchSection from "../ScheduledMatch/ScheduledMatchSection";
import * as InteractionService from "../../services/InteractionService";
import {toast} from "react-toastify";
import * as ScheduleService from "../../services/SchduleService"
import * as LeaderBoardService from "../../services/LeaderBoardService";
import * as actions from "../../store/actions";
import {getConf} from "../../services/ConfigService";
import {MATCH_PAGE} from "../../utilities/ConfigConstants";
import PubNubQuizBot from "../../components/QuizBot/PubNubQuizBot";
import PubNubHandler from "../../services/PubNubHandlers/MatchDataHandler";
import PubNubLeaderBoardHandler from "../../services/PubNubHandlers/MatchLeaderBoardHandler";
import * as PubNubService from "../../services/PubNubService";
import LeaderBoardModal from "../../components/LeaderBoardRow/LeaderBoardModal";
import {formatDate, formatNumberToK, formatNumberToKWithoutRounding, formatTime, toolTipProvider} from "../../helpers/CommonFunctions";
import {getDisplayName, getPosition, getProfileImage, getUpDownSameIconByDirection} from "../../helpers/LeaderBoardHelpers";
import {useTranslation} from "react-i18next";
import NotificationSection from "../../components/Notifcation/NotificationSection";
import SocialShareModal from "../../components/Modals/SocialShareModal/SocialShareModal";
import Modal from "react-bootstrap/Modal";
import HowToUseQBot from "../../components/Modals/HowToUseQBot/HowToUseQBot";
import HowDoesBotWork from "../../components/Modals/HowDoesBotWork/HowDoesBotWork";
import ScheduledMatchPopUp from "../../components/Modals/ScheduledMatchPopUp/ScheduledMatchPopUp";
import FantasyBot from "../../components/FantasyBot/FantasyBot";
import TournamentPubnubHandler from "../../services/PubNubHandlers/TournamentPubNubHandler";
import UserDataChannelHandler from "../../services/PubNubHandlers/UserDataChannelHandler";
import HowFantasyWorksModal from "../../components/Modals/HowFantasyWorks/HowFantasyWorks";
import FantasyPointCalculation from "../../components/Modals/HowFantasyWorks/FantasyPointCalculation";
import YouTubeStream from "../../components/YouTubeStream/YouTubeStream";
import PowerBoosterPurchaseModal from "../../components/Modals/PowerBoosterPurchase/PowerBoosterPurchase";
import PlayerInfoHistoryModal from "../../components/Modals/PlayerInfoHistory/PlayerInfoHistory";
import OmniPoints from "../../assets/images/icons/24_xp.webp";

const Match = (props) => {


   const location = useLocation();
   const returnUrl = new URLSearchParams(location.search).get('returnUrl');
   let {matchId} = useParams();
   let navigate = useNavigate();
   const [matchData, setMatchData] = useState({});
   let [loadingInProgress, setLoading] = useState(true);
   const [userNotifications, setUserNotifications] = useState([]);
   const [show, setShow] = useState(false);
   const [totalElements, setTotalElements] = useState(0);
   const [screenSize, setScreenSize] = useState(window.innerWidth);
   const [currentUser, setCurrentUser] = useState(null);
   const [firstFourLBUsers, setFirstFourLBUsers] = useState([]);
   const uuid = localStorage.getItem("uuid");
   const pathname = window.location.pathname;
   const [pageLang] = useState(localStorage.getItem('language'));
   const [pubnubRewardsToken, setPubnubRewardsToken] = useState(null);
   const [connectRewardsPubnub, setConnectRewardsPubnub] = useState(null);
   const [showVideoStream, setShowVideoStream] = useState(true);

   const signUpHandler = () => {
      sessionStorage.setItem('redirectPath', pathname)
      navigate("/login")
   }
   const [connectPubnub, setConnectPubnub] = useState(false);
   const [token, setToken] = useState(null);
   const [leaderboardToken, setLeaderboardToken] = useState(null);
   const [connectLeaderboard, setConnectLeaderboard] = useState(false);
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [isOpenSocialShareModal, setIsOpenSocialShareModal] = useState(false)
   const [ballHistory, setBallHistory] = useState(null)
   const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);
   const [showHowDoesBotWorkModal, setShowHowDoesBotWorkModal] = useState(true);
   const [isPreviouslyLogged] = useState(localStorage.getItem("previouslyLogged"));
   const [isQbotGuide] = useState(localStorage.getItem("isQbotGuide"));
   const [nextGotItIndex, setNextGotItIndex] = useState(0)
   const [tipIndexCounter, setTipIndexCounter] = useState(1)
   const [showScheduledMatchPopUp, setShowScheduledMatchPopUp] = useState(false);
   const [firstLoad, setFirstLoad] = useState(true);
   const [pubNubToken, setPubNubToken] = useState(null);
   const [userConnectPubnub, setUserConnectPubnub] = useState(false);
   const [showHowFantasyWorksModal, setShowHowFantasyWorksModal] = useState(false);
   const [showFantasyPointCalculationModal, setShowFantasyPointCalculationModal] = useState(false);
   const [showPlayerInfoHistoryModal, setShowPlayerInfoHistoryModal] = useState(false);
   const [showBoosterPurchaseModal, setShowBoosterPurchaseModal] = useState(false);
   const [selectedPlayer, setSelectedPlayer] = useState(null);
   const [refreshFantasy, setRefreshFantasy] = useState(true);
   const [fantasyBotPubNubData, setFantasyBotPubNubData] = useState(null);

   const handleVideoStreamShowAndHide = (state) => {
      const STREAM_SWITCH_ON_TOAST = "STREAM_SWITCH_ON_TOAST";
      const STREAM_SWITCH_OFF_TOAST = "STREAM_SWITCH_OFF_TOAST";

      if (state === true) {
         toast.loading(t("MATCH.SWITCHING_STREAM_ON"), {
            toastId: STREAM_SWITCH_ON_TOAST
         })
      } else {
         toast.loading(t("MATCH.SWITCHING_STREAM_OFF"), {
            toastId: STREAM_SWITCH_OFF_TOAST
         })
      }

      setTimeout(() => {
         setShowVideoStream(state)
         toast.dismiss(STREAM_SWITCH_ON_TOAST)
         toast.dismiss(STREAM_SWITCH_OFF_TOAST)
      }, 1000)
   }

   useEffect(() => {
      if (isPreviouslyLogged == "false" && !isQbotGuide) {
         setShowMoreInfoModal(true);
      }
   }, [isPreviouslyLogged]);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const gotItHandler = () => {
      setNextGotItIndex(nextGotItIndex + 1)
      setTipIndexCounter(tipIndexCounter + 1)
   }

   const tipCancelHandler = () => {
      setNextGotItIndex(-1)
   }

   const handleReturn = () => {
      if (returnUrl) {
         navigate(returnUrl, {state: location.search});
      } else {
         // http://localhost:3000/match/1713510367?returnUrl=%2Fmatches&currentPage=0&type=all
         navigate('/tournaments');
      }
   };

   const handleNextMatch = async () => {
      const toastId = toast.loading(`${t("MATCH.GETTING_NEXT_MATCH_DETAILS")}`)
      await ScheduleService.getNextMatch(matchId).then((response) => {
         if (response.apiStatus === true) {

            toast.update(toastId, {
               render: `${t("MATCH.MATCH_AVAILABLE_REDIRECTING")}`,
               type: toast.TYPE.SUCCESS,
               autoClose: 5000,
               closeButton: null,
               isLoading: false
            })

            const newUrl = `/match/${response.id}` + `?returnUrl=${encodeURIComponent(location.pathname)}`;

            setTimeout(() => {
               window.location.href = newUrl;
            }, 1000);

         } else {
            toast.update(toastId, {
               render: `${t("MATCH.NO_MATCHES_SCHEDULED_YET")}`,
               type: toast.TYPE.ERROR,
               autoClose: 5000,
               closeButton: null,
               isLoading: false
            })
         }
      });
   };


   const callbackFromTeamPoints = (message) => {
      if (message) {
         getLeaderBoardData();
      } else {
         console.log(message);
      }
   }

   const messageFromRewardsCallback = (message) => {
      if (message) {
         getLeaderBoardData();
      } else {
         console.log(message);
      }
   }

   const messageFromUserLeaderboardCallback = (message) => {
      if (message) {
         getLeaderBoardData();
      } else {
         console.log(message);
      }
   }

   const messageFromLeaderboardCallback = (message) => {
      if (message) {
         getLeaderBoardData();
      } else {
         console.log(message);
      }
   }


   const showTwitchFrame = (status) => {
   };

   function handleMoreInfoClose() {
      setShowMoreInfoModal(false);
      localStorage.setItem('isQbotGuide', true);
   }

   function handleShowHowDoesBotWorkModal() {
      setShowHowDoesBotWorkModal(false);
   }

   const getMatchStats = async () => {
      await StatisticsService.getMatchStat(matchId, props.Token).then((response) => {
         if (response.data.apiStatus) {
            setMatchData(response.data.match);
            setLoading(false);
         } else if (response.status == 400) {
            toast.error(`You haven't subscribed to the tournament.`);
            setTimeout(() => {
               navigate('/tournaments');
            }, 1000);
         }
      });
   };

   const handleNewNotificationReceive = (messages) => {
      if (messages?.notifications && messages?.notifications?.length > 0) {
         getNotifications();
      }
   };

   const handelWalletDataRecived = (messages) => {
   }

   const handleFantasyUserPointsTotal = (message) => {
      if (message?.tournamentId === matchData?.tournamentId) {
         setFantasyBotPubNubData(message);
      }
   }

   /*
   * Uncomment this code if you want to get prediction history (Q-Bot)
   * */
   // useEffect(() => {
   //    const fetchData = async () => {
   //
   //       try {
   //          await ResolverService.getPredictionHistory(props.userName, matchId).then(response => {
   //             if (response != null) {
   //                setBallHistory(response?.predictionResultsDto)
   //             }
   //          });
   //
   //       } catch (error) {
   //          console.error("Error:", error);
   //       }
   //
   //    }
   //
   //    fetchData();
   // }, []);

   useEffect(() => {
      const fetchData = async () => {
         if (props.isAuthenticated) {
            try {
               PubNubService.requestAccessTokenQuiz(props.userName, matchId, matchData?.tournamentId, props.Token).then(response => {
                  console.log("tour", props.userName, matchId, matchData?.tournamentId, props.Token, response)
                  if (response != null) {
                     setLeaderboardToken(response);
                     setConnectLeaderboard(true);
                     setPubnubRewardsToken(response);
                     setConnectRewardsPubnub(true);
                     localStorage.setItem("pubnubLeaderboardToken", response);
                  }
               });
            } catch (error) {
               console.error("Error:", error);
            }
         }
      }

      fetchData();
   }, [matchId, props.userName, loadingInProgress]);

   useEffect(() => {

      const fetchData = async () => {
         try {
            await PubNubService.requestAccessTokenQuiz(props.userName, 1234, 1234, props.Token).then(response => {
               if (response != null) {
                  setPubNubToken(response);
                  setUserConnectPubnub(true);
               }
            });

         } catch (error) {
            console.error("Error:", error);
         }

      };
      fetchData();
   }, [uuid]);

   useEffect(() => {
      const fetchData = async () => {

         try {
            PubNubService.requestAccessTokenMatch(props.userName || localStorage.getItem("uuid"), matchId, props.Token).then(response => {
               if (response != null) {
                  setToken(response);
                  setConnectPubnub(true);
                  localStorage.setItem("pubnubMatchToken", response);
               }
            });

            // setToken(response);
            // pubnub.setAuthKey(response); // Update PubNub authKey
         } catch (error) {
            console.error("Error:", error);
         }

         // setToken(localStorage.getItem("pubnubMatchToken"));
         // pubnub.setAuthKey(localStorage.getItem("pubnubMatchToken")); // Set the token from local storage

      };
      fetchData();
   }, [matchId, props.userName, uuid])

   useEffect(() => {

      matchData?.toStart === false ? setLoading(false) : setLoading(true);

      getMatchStats();

   }, [matchId]);


   // useEffect(() => {
   //    if (!Cookies.get("RUserId")) {
   //       const cookieValue = uuidv4();
   //       setCookie(cookieValue);
   //       Cookies.set("RUserId", `${cookieValue}`, {
   //          expires: 90, path: "", secure: true,
   //       });
   //    } else {
   //       const name = "RUserId=";
   //       const decodedCookie = decodeURIComponent(document.cookie);
   //       const ca = decodedCookie.split(";");
   //       for (const element of ca) {
   //          let c = element;
   //          while (c.charAt(0) === " ") {
   //             c = c.substring(1);
   //          }
   //          if (c.indexOf(name) === 0) {
   //             cookie = c.substring(name.length, c.length);
   //          }
   //       }
   //    }
   //
   //    const UserConnectionDTO = {
   //       matchId: matchId, userCookie: cookie, userId: props?.userId,
   //    };
   //
   //    const createNewLiveUser = ApplicationStaticsService.createLiveUser(UserConnectionDTO);
   //    createNewLiveUser.then((response) => {
   //    });
   // }, []);

   useEffect(() => {
      if (props.isAuthenticated) {
         setLoading(true);
         getNotifications();
      }
   }, [props.userName, props.Token]);

   const getNotifications = async () => {
      await InteractionService.GetNotificationsByUserName(props.userName.toLowerCase(), props.Token, 0, 'notifiedTime,desc', 10, pageLang).then((response) => {
         if (response?.data?.apiStatus) {
            setUserNotifications(response?.data?.notifications);
            setLoading(false);
         } else if (response.status == 401) {
            // props.onLogout();
            //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
         } else {
            // toast.error(COMMON_TOASTS.ERROR_OCCURED_GETTING_NOTIFICATIONS_TRY_RELOADING_THE_PAGE);
         }
      })
   }

   // useEffect(() => {
   //    return () => {
   //       const removeLiveUser = () => {
   //          const UserConnectionDTO = {
   //             matchId: matchId, userCookie: cookie, userId: props?.userId,
   //          };
   //          const removeLiveUser = ApplicationStaticsService.updateLiveUser(UserConnectionDTO);
   //          removeLiveUser.then((response) => {
   //          });
   //       };
   //       removeLiveUser();
   //    };
   // }, []);

   // useEffect(() => {
   //    const unloadHandler = async () => {
   //       try {
   //          const UserConnectionDTO = {
   //             matchId: matchId, userCookie: cookie, userId: props?.userId,
   //          };
   //          const removeLiveUser = ApplicationStaticsService.updateLiveUser(UserConnectionDTO);
   //          removeLiveUser.then((response) => {
   //          });
   //       } catch (error) {
   //          console.error(error);
   //       }
   //    };
   //
   //    window.addEventListener("beforeunload", unloadHandler);
   //
   //    return () => {
   //       window.removeEventListener("beforeunload", unloadHandler);
   //    };
   // }, []);

   useEffect(() => {

      if (matchData?.tournament?.tournamentId) {
         getLeaderBoardData();
      }

   }, [matchData?.live, matchData?.tournament?.tournamentId, matchId]);

   const handleScheduledMatchPopUpClose = () => {
      setShowScheduledMatchPopUp(false);
   }

   const getLeaderBoardData = () => {
      const tournamentId = matchData?.tournament?.tournamentId;
      if (props.isAuthenticated && props.userName) {
         LeaderBoardService.getTournamentLeaderBoard(0, 4, tournamentId, 'rank', true, props.Token).then((res) => {
            if (res?.apiStatus) {
               setFirstFourLBUsers(res?.results);
               setTotalElements(res?.totalElements);
            }
         })

         LeaderBoardService.getLBUserByUserNameAndTournamentId(props.userName, tournamentId, props.Token).then((res) => {
            if (!res?.rejectMessages?.[0]) {
               setCurrentUser(res?.position);
            }
         })

         // LeaderBoardService.getLBMaxAvgRightAnsweredUserByTournamentId(tournamentId).then((res) => {
         //    if (res?.apiStatus) {
         //       setMaxAvgRightAnsUser(res)
         //    }
         // });
      }
   }

   // handle show scheduled match pop up
   useEffect(() => {
      if (matchData?.toStart === true && matchData.live === false && firstLoad) {
         setFirstLoad(false);

         // check if the popup is already shown
         const numberOfScheduledMatchPopUpShown = sessionStorage.getItem("numberOfScheduledMatchPopUpShown");
         if (numberOfScheduledMatchPopUpShown > 1) {
            return;
         } else {
            const numberOfShown = numberOfScheduledMatchPopUpShown ? parseInt(numberOfScheduledMatchPopUpShown) + 1 : 1;
            sessionStorage.setItem("numberOfScheduledMatchPopUpShown", numberOfShown.toString());
         }

         setShowScheduledMatchPopUp(true);
         setTimeout(() => {
            handleScheduledMatchPopUpClose();
         }, 10000);
      }

   }, [firstLoad, matchData.live, matchData?.toStart]);

   // update screen size
   useEffect(() => {
      const handleResize = () => {
         setScreenSize(window.innerWidth);
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   const isPresentCurrentUserInFirst3LBUsers = firstFourLBUsers?.find(leader => leader?.userName === currentUser?.userName);

   function handleLeaderBoardShowAll() {
      setShow(true);
   }

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div className={styles.loaderContainer}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);

   if (!translationsLoaded) {
      return;
   }
   const avgRightAnsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.AVERAGE_RIGHT_ANSWERS"), "top")
   const numOfMatchesAnsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.NUMBER_OF_MATCHES_ANSWERED"), "top")

   const leaderBoardTypicalUserTableRow = (leader) => {
      return (
         <tr key={leader.userName} className={leader?.userName === currentUser?.userName && classNames(styles.leaderBoardHigh)}>
            {!translationsLoaded ? <> {loadingIndicator} </> : <>
               <td>{getPosition(leader?.position, "matchTypUsr")}</td>
               <td className={classNames(styles.userInfoRowWrapper, "text-truncate ps-0 pe-0")} style={{width: "auto"}}>
                  <div className="row p-0">
                     <div className={classNames(styles.userInfoCell, "col-md-12 col-lg-4 col-12 p-0")} style={{lineHeight: "40px"}}>
                        {toolTipProvider(t("LEADER_BOARD_ROW.YOU_CANN'T_VISIT_A_PRIVATE_USER'S_PROFILE"), 'right', <Link to={leader?.isPrivate ? '' : {
                           pathname: `/user/${btoa(leader.userName)}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }} style={{textDecoration: 'none', cursor: `${leader?.isPrivate ? "default" : "pointer"}`}}>
                           <div style={{display: 'block', float: 'left'}}>{getProfileImage(leader?.profilePicUrl, leader?.firstName, leader?.lastName, false, leader?.userName)}</div>
                           <span>{getDisplayName(leader?.firstName, leader?.lastName, leader?.userName, 13)}</span>
                        </Link>, leader?.isPrivate)}
                     </div>
                     {/* <div className="col-lg-3 col-12 p-0 mt-2 mt-md-0" style={{lineHeight: "40px"}}>
                        {leader?.history?.slice(0, 5).reverse().map((hisObj) => getCorrectWrongIconByHistoryStatus(hisObj?.status))}
                     </div> */}

                     <div className={classNames(styles.userInfoCell2, " col-lg-6 col-8 p-0 mt-2 mt-md-0")} style={{lineHeight: "40px"}}>
                        {/*{avgRightAnsToolTip(<label className="CorrectPresent" style={{width: "49%", margin: "0px 4px 0px 0px"}}>*/}
                        {/*   <>{(leader?.average && leader.average.toFixed(2)) || 0} <span*/}
                        {/*      className={classNames(styles.tableText)}>(AVG.)</span></>*/}
                        {/*</label>, true)}*/}
                        {numOfMatchesAnsToolTip(<label className="CorrectPresent" style={{width: "49%", margin: "0px"}}>
                           <>{leader?.matches || 0} <span
                              className={classNames(styles.tableText)}>(MTS.)</span></>
                        </label>, true)}
                     </div>
                  </div>
               </td>
               <td>
                  <div className="row p-0">

                     <div className="row p-0">
                        <div className="col-2 text-end p-0">
                           <img src={OmniPoints} alt={""}/>
                        </div>
                        <div className={classNames(styles.LeaderPoints, "float-right col-5 p-0 text-end")}>
                           {toolTipProvider(leader?.points, 'top', <span>&nbsp;&nbsp;{formatNumberToKWithoutRounding(leader?.points)}</span>,
                              leader?.points > 999)}
                        </div>
                        <div className="col-5 p-0">
                           &nbsp;&nbsp;&nbsp;{getUpDownSameIconByDirection(leader?.direction)}
                        </div>
                     </div>
                  </div>
               </td>
            </>}
         </tr>
      )
   }

   const currentUserScoreTableRow = (
      <tr className={classNames(styles.leaderBoardHigh)}>
         {!translationsLoaded ? <> {loadingIndicator} </> : <>
            <td>{getPosition(currentUser?.position, "matchCurrUsr")}</td>
            <td className="text-truncate ps-0 pe-0">
               <div className="row p-0">
                  <div className="col-12 p-0 col-md-12 col-lg-4 d-flex"
                       style={{lineHeight: "40px", alignItems: "center"}} role={'button'}
                       onClick={() => {
                          navigate(`/user/${btoa(currentUser.userName)}?returnUrl=${encodeURIComponent(location.pathname)}`);
                       }}>
                     {getProfileImage(currentUser?.profilePicUrl, currentUser?.firstName, currentUser?.lastName, false, currentUser?.userName)}
                     {getDisplayName(currentUser?.firstName, currentUser?.lastName, currentUser?.userName, 16)}
                  </div>
                  {/* <div className="col-12 p-0 col-md-6  col-lg-3 mt-2 mt-md-0" style={{lineHeight: "40px"}}>
                     {currentUser?.history?.slice(0, 5).reverse().map((hisObj) => getCorrectWrongIconByHistoryStatus(hisObj?.status))}
                  </div> */}
                  <div className=" col-lg-6 col-8 p-0 mt-2 mt-md-0" style={{lineHeight: "40px"}}>
                     {/*{avgRightAnsToolTip(<label className="CorrectPresent"*/}
                     {/*                           style={{width: "49%", margin: "0px"}}>*/}
                     {/*   <>{(currentUser?.average && currentUser?.average.toFixed(2)) || 0} <span className={classNames(styles.tableText)}>(AVG.)</span></>*/}
                     {/*</label>, true)}*/}
                     {numOfMatchesAnsToolTip(<label className="CorrectPresent"
                                                    style={{width: "49%", margin: "0px"}}>
                        <>{currentUser?.matches || 0} <span className={classNames(styles.tableText)}>(MTS.)</span></>
                     </label>, true)}
                  </div>
               </div>
            </td>
            <td>
               <div className="row p-0">
                  <div className="col-2 text-end p-0">
                     <img src={OmniPoints} alt={""}/>
                  </div>
                  <div className="float-right col-5 p-0 text-end greenText-">
                     {toolTipProvider(currentUser?.points, 'top', <span>&nbsp;&nbsp;{formatNumberToKWithoutRounding(currentUser?.points)}</span>,
                        currentUser?.points > 999)}
                  </div>
                  <div className="col-5 p-0">
                     &nbsp;&nbsp;&nbsp;{getUpDownSameIconByDirection(currentUser?.direction)}
                  </div>
               </div>
            </td>
         </>}
      </tr>);

   /*global leader board config change end*/
   const messageFromCallback = (message) => {
      try {
         let json = JSON.parse(message);
         setMatchData(prevState => ({
            ...json.body,
            teamOne: prevState.teamOne,
            teamTwo: prevState.teamTwo,
            tournament: json.body.tournament || prevState.tournament,
         }));


      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }
   const messageFromLeaderBoardCallback = (message) => {
      try {
         let json = JSON.parse(message);
         if (json.body.results) {
            setFirstFourLBUsers(json.body.results);
            setTotalElements(json.body.totalElements);
         }
      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }
   const messageFromUserLeaderBoardCallback = (message) => {
      try {
         let json = JSON.parse(message);
         console.log(json)
         if (json.body.position) {
            setCurrentUser(json.body.position);
         }
         // if (json.body.results) {
         //    setFirstFourLBUsers(json.body.results);
         //    setTotalElements(json.body.totalElements);
         // }
      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }
   const socialShareModalHandler = (value, matchId) => {
      setIsOpenSocialShareModal(value)
   }

   const fantasyBotComponent = <FantasyBot matchData={matchData}
                                           refreshFantasy={refreshFantasy}
                                           pubNubData={fantasyBotPubNubData}
                                           setShowPlayerInfoHistoryModal={(player) => {
                                              setSelectedPlayer(player)
                                              setShowPlayerInfoHistoryModal(true);
                                           }}
                                           setShowBoosterPurchaseModal={(player) => {
                                              setSelectedPlayer(player)
                                              setShowBoosterPurchaseModal(true);
                                           }}
                                           setShowHowFantasyWorksModal={setShowHowFantasyWorksModal}
   />

   return (<React.Fragment>
      {/* ball - https://storage.googleapis.com/meta11/serviceplatform/ball.png*/}
      {/* bat - https://storage.googleapis.com/meta11/serviceplatform/bat.png*/}

      <div>
         {connectPubnub && props.isAuthenticated && props.userName ? <PubNubHandler onDataReceived={messageFromCallback} token={token} matchId={matchId}/> : <></>}

         {connectLeaderboard && props.isAuthenticated ?
            <PubNubLeaderBoardHandler matchData={matchData} tournamentId={matchData?.tournamentId} onLeaderBoardData={messageFromLeaderBoardCallback} onUserLeaderBoardData={messageFromUserLeaderBoardCallback} token={leaderboardToken} subscribe={"Leaderboard-" + matchData?.tournamentId}/> : ""}

         {(matchData?.tournamentId && connectRewardsPubnub && pubnubRewardsToken != null && props.isAuthenticated) ?
            <TournamentPubnubHandler
               onTeamPoints={callbackFromTeamPoints}
               onDataReceived={messageFromRewardsCallback}
               onUserLeaderboardReceived={messageFromUserLeaderboardCallback}
               onLeaderboardReceived={messageFromLeaderboardCallback}
               token={pubnubRewardsToken}
               tournamentId={matchData?.tournamentId}
               userName={props.userName}
               page={0}
               size={4}/>
            : <>
               {console.log(
                  matchData?.tournamentId,
                  connectRewardsPubnub,
                  pubnubRewardsToken != null,
                  props.isAuthenticated
               )}
            </>}
         <div className="pb-4">
            <div className="col-12">
               <div className={classNames(styles.leftGradient)}></div>
               <div className={classNames(styles.rightGradient)}></div>
               <div className={classNames(styles.ResponceCont, "col-12", "row", "ps-1", "pe-1", "ps-xxl-0", "pe-xxl-0")}>
                  <div className="container p-0">
                     <div className="row gx-0">
                        <div className="col-12">
                           <NavbarSecondary page="events"/>
                        </div>
                     </div>
                     {!translationsLoaded ? <> {loadingIndicator} </> :
                        <div className="row gx-0">
                           <div className={classNames(styles.headerBtnWrapper, "col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9")}>
                              <button onClick={() => handleReturn()} className="blackBtn mobile-hide-">
                                 <BsChevronLeft/>{t("MATCH.BACK")}
                              </button>

                              <div className={classNames(styles.StreamSwitch)}>
                                 <input type="checkbox" id="toggle-switch" checked={showVideoStream && matchData?.live}
                                        disabled={!matchData?.live}
                                        onChange={() => handleVideoStreamShowAndHide(!showVideoStream)}/>
                                 <label htmlFor="toggle-switch"
                                        style={{cursor: matchData?.live ? "pointer" : "not-allowed"}}>
                                    <span className={classNames(styles.icon1)}/>
                                    <span className={classNames(styles.icon2)}/>
                                 </label>
                              </div>

                              {(getConf(MATCH_PAGE.SOCIAL_SHARE_BUTTON)) ?
                                 <button className={classNames(styles.matchShareBtnWrapper, "blackBtn")} onClick={() => {
                                    socialShareModalHandler(true, matchId)
                                 }}>
                                    <BsBoxArrowUp/>{t("MATCH.SHARE_MATCH")}
                                 </button> : <></>}

                              <button onClick={() => handleNextMatch()} className="blackBtn d-none- d-sm-block"
                                      style={{float: "right"}}>
                                 {t("MATCH.NEXT_MATCH")}<BsChevronRight style={{left: 12}}/>
                              </button>
                           </div>

                           <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 col-xxl-9">
                              {matchData?.toStart === true || (matchData?.live == false && matchData?.matchEnd == false && matchData?.winningTeamId == null) ?
                                 <>
                                    {/*<GuidanceTip page="match" gotItIndex={2} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}
                                    <ScheduledMatchSection tossWinnerId={matchData?.tossWinnerId} matchData={matchData} matchId={matchId} firstThreeLBUsers={firstFourLBUsers?.slice(0, 3)}
                                                           currentUserScoreTableRow={currentUser ? currentUserScoreTableRow : null}
                                                           isPresentCurrentUserInFirst3LBUsers={isPresentCurrentUserInFirst3LBUsers}
                                                           leaderBoardTypicalUserTableRow={leaderBoardTypicalUserTableRow}
                                                           isAuthenticated={props.isAuthenticated}
                                                           showTwitchVideo={showTwitchFrame}
                                    />
                                 </>
                                 : <>
                                 </>}

                              {matchData?.toStart != true ?
                                 <>
                                    {loadingInProgress ? (
                                       <div className={styles.loaderContainer}>
                                          <div className="lds-facebook">
                                             <div></div>
                                             <div></div>
                                             <div></div>
                                          </div>
                                       </div>
                                    ) : (
                                       <MatchScoreHeader matchData={matchData} token={props.Token}/>)
                                    }
                                    {matchData?.live == true && showVideoStream ?
                                       <div className="row gx-0" style={{marginTop: "20px"}}>
                                          <div className="col-12 mb-4">
                                             {getConf(MATCH_PAGE.YOUTUBE_VIDEO_ID) ?
                                                <YouTubeStream videoId={getConf(MATCH_PAGE.YOUTUBE_VIDEO_ID)}/> :
                                                <ReactTwitchEmbedVideo
                                                   width={"100%"}
                                                   height={screenSize < 1200 ? 200 : 400}
                                                   layout={"video"}
                                                   channel={process.env.REACT_APP_TWITCH_CHANNEL_NAME}
                                                />
                                             }
                                          </div>
                                       </div> : <></>
                                    }
                                 </>
                                 :
                                 <>
                                 </>}
                              {(getConf(MATCH_PAGE.SHOW_FANTASYBOT_OR_QUIZBOT) == "qbot") && !loadingInProgress ?
                                 <div className="d-block d-xl-none mb-3">
                                    {props.isAuthenticated && screenSize < 1200 ?
                                       <PubNubQuizBot ballHistory={ballHistory} tournamentId={matchData?.tournamentId} matchId={matchId} matchData={matchData}/> : <div className={"p-3 Font14"}>{t("MATCH.PLEASE")} &
                                          <button onClick={signUpHandler} style={{padding: "0px 3px 0px 3px", border: "none", outline: "none"}} className="stat-val scoreLbl1">
                                             {t("MATCH.LOGIN")}
                                          </button>
                                          {t("MATCH.TO_ANSWER_Q_BOT_AND_WIN_REWARDS")}</div>}
                                 </div> :
                                 (getConf(MATCH_PAGE.SHOW_FANTASYBOT_OR_QUIZBOT) == "fantasy") && !loadingInProgress ?
                                    <div className="d-block d-xl-none mb-3 d-block d-xl-none">
                                       {fantasyBotComponent}
                                    </div> :
                                    <></>}
                              <div className={classNames(styles.ScoreBoardRow, "row gx-0 ScoreBoardRow")}>
                                 <div className="col-12">
                                    {
                                       (matchData?.hasOwnProperty("matchInningList") || matchData?.matchInningList) ?
                                          <div className="row gx-0 mt-2">
                                             <Scorecard matchData={matchData}/>
                                          </div> :
                                          <div className="row gx-0">
                                             {/*<PreMatchScheduleCard matchData={matchData}/>*/}
                                          </div>
                                    }
                                 </div>
                              </div>
                              {matchData?.toStart !== true || matchData?.live === true ? <>
                                 {totalElements !== 0 ?
                                    <>{props?.isAuthenticated ?
                                       <>
                                          {/* <div className="row gx-0">
                                                        <div className="col-12 d-flex justify-content-end mb-3">
                                                            <span className={classNames(styles.matchIdWrapper)}>
                                                            <label className={classNames(styles.matchIdLbl)}> {t("match.match id")}: </label><label className={classNames(styles.matchIdVal)}>{matchId}
                                                                {toolTipProvider(t("match.copy match link to clipboard"), 'top',  <a onClick={(event)=>{
                                                                  handleCopy(event,   `https://meta11.com/match/${matchId}`, t("match.match link has been copied to the clipboard"))}}>
                                                                    <FaRegCopy/></a>, true)}
                                                            </label>
                                                            </span>
                                                        </div>
                                                    </div> */}

                                          {getConf(MATCH_PAGE.SHOW_MATCH_LEADERBOARD) ?
                                             <>
                                                {/* <div className={classNames(styles.guidanceTipWrapper)}>
                                                            <GuidanceTip gotItIndex={2} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler}/>
                                                        </div>        */}
                                                <div className={classNames(styles.questWrap, styles.questWrapRow, styles.LiveLeaderboard, "col-12 p-0")} style={{position: "relative"}}>

                                                   <div className={classNames(styles.header, "row")}>
                                                      <h2 className="col-7">{t("MATCH.LEADERBOARD")}</h2>
                                                      <div className={classNames(styles.showAll, "col-5")}
                                                           onClick={() => handleLeaderBoardShowAll()}>{t("MATCH.SHOW_ALL")}
                                                         {toolTipProvider(totalElements, 'right', <span>&nbsp;({formatNumberToK(totalElements)})</span>, totalElements > 999)}
                                                      </div>
                                                   </div>
                                                   {/*table*/}

                                                   <table border="0" cellPadding="0" cellSpacing="0"
                                                          className={classNames(styles.leaderBoard)} style={{border: "0"}}>
                                                      <tr>
                                                         <td colSpan={3} style={{padding: "0 !important"}}>
                                                            <div className={classNames(styles.guidanceTipWrapper)}>

                                                               {/*<GuidanceTip page="match" gotItIndex={2} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}
                                                            </div>
                                                         </td>
                                                      </tr>

                                                      {firstFourLBUsers?.slice(0, 3)?.map((leader) => leaderBoardTypicalUserTableRow(leader))}
                                                      {(!isPresentCurrentUserInFirst3LBUsers && currentUser) ? currentUserScoreTableRow : <></>}
                                                      <tr>
                                                         <td colSpan={3} style={{padding: "0 !important"}}>
                                                            {t("MATCH.THIS_LEADERBOARD_CONTAINS_FANTASY_XP_FOR_THE_SELECTED_MATCH_ONLY")}
                                                         </td>
                                                      </tr>
                                                   </table>
                                                </div>
                                             </> : <></>}
                                       </> :
                                       <div
                                          className={classNames(styles.questWrap, 'mb-1', "col-12", "col-md-6", "col-xl-12", "p-0", "d-none d-xl-block")}>
                                          <div className={"p-3 Font16"}>{t("MATCH.PLEASE")} <Link to="/login" state={{path: pathname}} style={{color: "white"}}>{t("MATCH.LOGIN")}</Link>
                                             {t("MATCH.TO_VIEW_WHAT_POSITION_YOU_HOLD_IN_LEADERBOARD")}
                                          </div>
                                       </div>
                                    }
                                    </> : <>
                                    </>}

                                 <div className="row gx-0">
                                    <div
                                       className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                       <div className="row gx-0">
                                          <div className="col-12 d-none">
                                             <div className="adImgWrap" style={{marginBottom: 25}}>
                                                <img
                                                   src="https://storage.googleapis.com/meta11/serviceplatform/StaticBanner1.webp"
                                                   alt=""/>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </> : <> </>}
                           </div>
                           <div className={classNames(styles.QbotCol, "col-12 col-xl-3 col-xxl-3 ps-0 ps-xl-4 ")}>
                              <div className="row p-0">
                                 {(getConf(MATCH_PAGE.SHOW_FANTASYBOT_OR_QUIZBOT) == "qbot") && !loadingInProgress ?
                                    <div className={classNames(styles.questWrap, 'mb-4', "col-12 col-md-6", "col-xl-12", "p-0", "d-none d-xl-block")} style={{position: "relative"}}>

                                       {/*<GuidanceTip page="match" gotItIndex={0} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}
                                       {/*<GuidanceTip page="match" gotItIndex={4} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}

                                       {props.isAuthenticated ?
                                          <>
                                             <PubNubQuizBot ballHistory={ballHistory} tournamentId={matchData?.tournamentDTO?.id} matchId={matchId} matchData={matchData}/>
                                          </> : <div className={"p-3 Font14"}>{t("MATCH.PLEASE")}
                                             <button onClick={signUpHandler} style={{padding: "0px 3px 0px 3px", border: "none", outline: "none"}} className="stat-val scoreLbl1">
                                                {t("MATCH.LOGIN")}
                                             </button>
                                             {t("MATCH.TO_ANSWER_Q_BOT_AND_WIN_REWARDS")} </div>}
                                    </div>
                                    : (getConf(MATCH_PAGE.SHOW_FANTASYBOT_OR_QUIZBOT) === "fantasy") && !loadingInProgress ?
                                       <div className={classNames(styles.questWrap, 'mb-4', "col-12 col-md-6", "col-xl-12", "p-0", "d-none d-xl-block")} style={{position: "relative"}}>
                                          {fantasyBotComponent}
                                       </div> :
                                       <></>
                                 }
                                 {/* OVERVIEW */}
                                 {matchData?.toStart === true ?
                                    <div className="col-12 row g-0 col-xl-12 ps-0 ps-xl-0 p-0">
                                       {/*<GuidanceTip page="match" gotItIndex={3} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}
                                       <div className="col-12">
                                          <div className={classNames(styles.NotitWrap, "p-0", "pt-1", "pb-1", " mb-4")}>
                                             <div className={classNames(styles.Text24Solina, "p-3")} style={{borderBottom: "1px solid #FFFFFF0F", border: "1px solid #FFFFFF0F"}}>{t("MATCH.OVERVIEW")} </div>
                                             <div className={classNames(styles.text14OS, "pt-3", "pb-3", "p-3")} style={{lineHeight: "20px"}}>
                                                Start:
                                                <br/>
                                                <div className={classNames(styles.text14OSW, "pt-2", "pb-2")}>
                                                   <img src="https://storage.googleapis.com/meta11/serviceplatform/fi_calendar.webp"></img>
                                                   &nbsp;{formatDate(matchData?.matchStartTime) || '--'}&nbsp;at&nbsp;{formatTime(matchData?.matchStartTime) || '--'}
                                                </div>
                                                {t("MATCH.GET_READY_FOR_AI_POWERED_CRICKET_ACTION_ON_META_11")} {t("MATCH.IT_S")} {" "}
                                                {matchData?.teamOne?.name || " "} vs {matchData?.teamTwo?.name || " "}&nbsp;
                                                {t("MATCH.IN_A_FANTASY_MATCH_THAT_PROMISES_EXCITEMENT_BEYOND_BOUNDARIES")}
                                             </div>
                                          </div>
                                       </div>
                                    </div> : <></>}

                                 <div className="col-12 col-md-6 col-xl-12 ps-0 ps-xl-0 p-0 ps-0 ps-md-4">
                                    {props.isAuthenticated ? <>
                                       {/*<GuidanceTip page="match" gotItIndex={1} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}
                                       {(userConnectPubnub && pubNubToken != null && props.isAuthenticated) ?
                                          <UserDataChannelHandler
                                             handleNewNotificationReceive={handleNewNotificationReceive}
                                             handelWalletDataRecived={handelWalletDataRecived}
                                             handleFantasyUserPointsTotal={handleFantasyUserPointsTotal}
                                             pubNubToken={pubNubToken}/> : <></>}
                                       <NotificationSection notifications={userNotifications} t={t}/>
                                    </> : <></>}
                                 </div>
                              </div>
                              {matchData?.toStart === true ? <></> :
                                 <div className="row gx-0">
                                    <div className="col-12 mb-lg-4 d-none d-xl-block mb-0 mb-sm-0 mb-md-0 ">
                                       <div className="adImgWrap">
                                          <img
                                             src="https://storage.googleapis.com/meta11/serviceplatform/StaticBanner1.webp"
                                             alt=""/>
                                       </div>
                                    </div>
                                 </div>
                              }
                           </div>
                        </div>
                     }
                  </div>

               </div>
            </div>
            <Modal show={showMoreInfoModal} onHide={handleMoreInfoClose}>
               <Modal.Body>
                  <HowToUseQBot handleMoreInfoClose={handleMoreInfoClose}/>
               </Modal.Body>
            </Modal>
            {getConf(MATCH_PAGE.HOW_DOES_BOT_WORK_MODAL) && showHowDoesBotWorkModal ?
               <HowDoesBotWork handleShowHowDoesBotWorkModal={handleShowHowDoesBotWorkModal}/>
               : <></>
            }
            {showHowFantasyWorksModal && <HowFantasyWorksModal
               handleModalClose={() => {
                  setShowHowFantasyWorksModal(false);
               }}
               handleShowHowFantasyPoints={() => {
                  setShowFantasyPointCalculationModal(true);
               }}/>}
            {showFantasyPointCalculationModal && <FantasyPointCalculation handleModalClose={() => {
               setShowFantasyPointCalculationModal(false);
            }}/>}
            {showPlayerInfoHistoryModal && <PlayerInfoHistoryModal
               data={{
                  playerId: selectedPlayer?.playerId,
                  playerName: selectedPlayer?.playerName,
                  playerImage: selectedPlayer?.playerPictureURL,
                  tournamentId: matchData?.tournamentId
               }}
               handleModalClose={() => {
                  setShowPlayerInfoHistoryModal(false);
               }}/>}

            {showBoosterPurchaseModal && <PowerBoosterPurchaseModal
               player={selectedPlayer}
               tournamentId={matchData?.tournamentId}
               refreshFantasy={() => {
                  setRefreshFantasy(prev => !prev)
               }}
               handleModalClose={() => {
                  setShowBoosterPurchaseModal(false)
               }}
            />}
            <div>
               {!translationsLoaded ? <> </> : <>
                  <Footer/>
               </>}
            </div>
         </div>
      </div>

      <LeaderBoardModal
         isShow={show}
         setShow={setShow}
         initialUsers={firstFourLBUsers}
         totalElements={totalElements}
         currentUser={currentUser}
         location={location}
         tournamentIdByProps={matchData?.tournamentId}
         authToken={props.Token}
      />
      <SocialShareModal
         isShowModal={isOpenSocialShareModal}
         modalCloseHandler={socialShareModalHandler}
         sharePath={`/match/${matchId}`}
         currentUser={null}
         isAuthenticated={props.isAuthenticated}
         isSharingPoints={false}
      />

      {getConf(MATCH_PAGE.UP_COMING_MATCH_INTRO_MODAL) ?
         <ScheduledMatchPopUp
            showModal={showScheduledMatchPopUp}
            closeModal={handleScheduledMatchPopUpClose}
            matchStartTime={matchData?.matchStartTime}
         /> : <></>
      }

   </React.Fragment>);
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken != null || undefined,
      userId: state.auth.userId,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      refresh: state.auth.refreshToken
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()), onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};
export default connect(mapStateToProps, mapDispatchToProps)(Match);
