import React, {useEffect, useRef, useState} from "react";
import Classnames from "classnames";
import styles from "./PowerBoosterPurchaseModal.module.scss";
import {connect} from "react-redux";
// import MoreInfoIcon from "../../../assets/images/icons/more_info_icon.svg";
import SprtIcon from "../../../assets/images/icons/24_sprts_token.webp";
import Clock from "../../../assets/images/icons/clock.svg";
import {ReactComponent as FlashIcon} from "../../../assets/images/icons/flash.svg";
import {getBoosterRulesPoints} from "../../../services/FantasyService";
import {calculateTimeLeft, getBoosterFullTimeInMinutes, getTimeDisplayText} from "../../../helpers/CommonFunctions";
import {getPlayerProfileById} from "../../../services/DigitalAssetService";
import * as WalletService from "../../../services/WalletService";
import {purchaseFantasyPlayerBoost} from "../../../services/WalletService";
import {toast} from "react-toastify";
import BoostPurchaseConfirmationModal from "../BoostPurchaseConfirmation/BoostPurchaseConfirmationModal";
import Chevron from "../../../assets/images/icons/chevron-left.svg";
import {BOOSTER_EXTRA_TIME, TRANSACTION_STATUS} from "../../../utilities/Constants";
import {Puff} from "react-loading-icons";
import SequenceLoginModal from "../../Wallet/SequenceLoginModal/SequenceLoginModal";
import RenderTextWithImages from "../../RenderTextWithImages/RenderTextWithImages";
import useScreenSize from "../../../hooks/useScreenSize";

const PowerBoosterPurchaseModal = ({
                                      player, tournamentId, handleModalClose, userName, token, refreshFantasy
                                   }) => {
   const pageLang = localStorage.getItem('language');
   const boostWrapperRef = useRef(null);
   const [isLoading, setIsLoading] = useState(true);
   const [isPurchasing, setIsPurchasing] = useState(false);
   const [boosters, setBoosters] = useState([]);
   const [selectedBooster, setSelectedBooster] = useState(null);
   const [selectedRate, setSelectedRate] = useState(0);
   const [playerInfo, setPlayerInfo] = useState(null);
   const [selectedTimesOfEachBooster, setSelectedTimesOfEachBooster] = useState({});
   const [currentlyActiveBooster, setCurrentlyActiveBooster] = useState(null);
   const [remainingTime, setRemainingTime] = useState("");

   const [showPurchaseConfirmationModal, setShowPurchaseConfirmationModal] = useState(false);
   const [showSequenceLoginModal, setShowSequenceLoginModal] = useState(false);
   const [isTransactionStatusModalOpen, setIsTransactionModalOpen] = useState(false);
   const [buySprtsPackageId, setBuySprtsPackageId] = useState(0);
   const [transactionHash, setTransactionHash] = useState(0);
   const [transactionAmount, setTransactionAmount] = useState(0);
   const [transactionStatus, setTransactionStatus] = useState(null);
   const [purchaseMaticAmount, setPurchaseMaticAmount] = useState(0);
   const [requestCreditAmount, setRequestCreditAmount] = useState(0);
   const [walletBalance, setWalletBalance] = useState(2.43);

   const {screenWidth} = useScreenSize();

   const handleBoosterSelect = (booster) => {
      setSelectedBooster(booster);
      setSelectedRate(selectedTimesOfEachBooster[booster?.boosterId]);
   }

   const handleBoosterValidForSelect = (e) => {
      if (e?.target?.value) {
         const value = JSON.parse(e.target.value);
         setSelectedRate(value);
         setSelectedTimesOfEachBooster(prevState => ({
            ...prevState, [selectedBooster?.boosterId]: value
         }))
      }
   }

   const handleBoostBtnClick = () => {
      if (selectedRate?.tokens > walletBalance) {
         setRequestCreditAmount(selectedRate?.tokens - walletBalance);
         setShowSequenceLoginModal(true)
      } else {
         setShowPurchaseConfirmationModal(true);
      }
   }

   const handlePurchaseBooster = () => {
      if (!selectedRate || isPurchasing) {
         return;
      }

      setIsPurchasing(true);
      const toastId = toast.loading("Applying booster to player " + player?.playerName);

      const data = {
         playerId: player?.playerId, tournamentId: tournamentId, userId: userName, boosterDTOList: [{
            boosterId: selectedRate?.boostId, minutes: selectedRate?.minutes,
         }],
      }

      const errorToast = (toastId, message) => {
         toast.update(toastId, {
            render: message, type: toast.TYPE.ERROR, autoClose: 5000, closeButton: null, isLoading: false
         });
      }

      purchaseFantasyPlayerBoost(token, data).then((response) => {
         if (response?.apiStatus) {
            toast.update(toastId, {
               render: "Booster purchased successfully",
               type: toast.TYPE.SUCCESS,
               autoClose: 5000,
               closeButton: null,
               isLoading: false
            });

            refreshFantasy && refreshFantasy();

            setShowPurchaseConfirmationModal(false);
            handleModalClose();
         } else if (response?.data?.rejectMessages) {
            errorToast(toastId, response?.data?.rejectMessages[0]);
         } else {
            errorToast(toastId, "Something went wrong");
         }
      }).catch((error) => {
         errorToast(toastId, "Something went wrong");
         console.error(error);
      }).finally(() => {
         setIsPurchasing(false);
      })
   }

   const animateScroll = (left) => {
      const startTime = performance.now();
      const start = boostWrapperRef.current.scrollLeft;
      const end = left;
      const duration = 300;

      const animate = (timestamp) => {
         const progress = (timestamp - startTime) / duration;
         boostWrapperRef.current.scrollLeft = start + (end - start) * progress;
         if (progress < 1) {
            requestAnimationFrame(animate);
         }
      }
      requestAnimationFrame(animate);
   }

   const handleLeftChevron = () => {
      animateScroll(boostWrapperRef.current.scrollLeft - 300);
   };

   const handleRightChevron = () => {
      animateScroll(boostWrapperRef.current.scrollLeft + 300);
   };

   const handleTransactionStatus = (transactionStatus, transactionAmount, transactionHash) => {
      setTransactionAmount(transactionAmount);
      setTransactionHash(transactionHash);
      setIsTransactionModalOpen(true);

      if (transactionStatus === TRANSACTION_STATUS.SUCCESS) {
         setTransactionStatus(true);
      } else {
         setTransactionStatus(false);
      }
   }

   useEffect(() => {
      const activeBooster = player?.boosterRulePointsDTOS?.find(booster => {
         const endTime = new Date(booster?.endTime) - BOOSTER_EXTRA_TIME;
         if (booster?.active === true && new Date(endTime) > new Date()) {
            setSelectedBooster(boosters?.find(boosterDTO => {
               return boosterDTO?.boosterId === booster?.boosterId
            }));

            // scroll into view
            const el = document.getElementById(booster?.boosterId);
            if (el) {
               el.scrollIntoView({behavior: 'smooth', block: 'center'});
            }

            return booster
         }
      });

      if (activeBooster) {
         setCurrentlyActiveBooster(activeBooster);
         const allBoosterRatesOfActiveBooster = boosters?.find(booster => booster?.boosterId === activeBooster?.boosterId)?.boosterRates;
         const activeRates = allBoosterRatesOfActiveBooster?.find(rate => rate?.minutes === getBoosterFullTimeInMinutes(activeBooster));
         setSelectedRate(activeRates)
         setSelectedTimesOfEachBooster(prevState => ({
            ...prevState, [activeBooster?.boosterId]: activeRates
         }));
      }
   }, [boosters, player?.boosterRulePointsDTOS]);

   useEffect(() => {
      if (currentlyActiveBooster) {
         const interval = setInterval(() => {
            const endTime = new Date(currentlyActiveBooster?.endTime) - BOOSTER_EXTRA_TIME;

            if (new Date(endTime) > new Date()) {
               const timeLeft = calculateTimeLeft(endTime);

               if (timeLeft.days > 0) {
                  setRemainingTime(`${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`);
               } else if (timeLeft.hours > 0) {
                  setRemainingTime(`${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`);
               } else if (timeLeft.minutes > 0) {
                  setRemainingTime(`${timeLeft.minutes}m ${timeLeft.seconds}s`);
               } else if (timeLeft.seconds > 0) {
                  setRemainingTime(`${timeLeft.seconds}s`);
               }
            } else {
               setRemainingTime("");
               setCurrentlyActiveBooster(null);
            }
         }, 1000);
         return () => clearInterval(interval);
      }
   }, [currentlyActiveBooster]);

   useEffect(() => {
      const fetchBoosters = async () => {
         setIsLoading(true);

         getBoosterRulesPoints().then((response) => {
            if (response?.apiStatus && response?.boosterList?.length > 0) {
               const boosterList = response.boosterList
               setBoosters(boosterList);
               setSelectedBooster(boosterList[0]);
               setSelectedRate(boosterList[0]?.boosterRates[0]);

               boosterList.forEach(booster => {
                  setSelectedTimesOfEachBooster(prevState => ({
                     ...prevState, [booster.boosterId]: booster.boosterRates[0]
                  }))
               })

            }
         }).catch((error) => {
            console.error(error);
         }).finally(() => {
            setIsLoading(false);
         });
      }

      fetchBoosters();
   }, []);

   useEffect(() => {
      const fetchPlayerInfo = async () => {
         getPlayerProfileById(player?.playerId, pageLang)
            .then((response) => {
               if (response?.apiStatus === true) {
                  setPlayerInfo(response?.playerProfileDTO);
               }
            })
            .catch((error) => {
               console.log(error);
            })
            .finally(() => {
               setIsLoading(false);
            });
      }

      if (player?.playerId) {
         fetchPlayerInfo();
      }
   }, [pageLang, player?.playerId]);

   useEffect(() => {
      const fetchData = () => {
         if (token && userName) {
            WalletService.getOmniCreditsByUsername(token, userName)
               .then(res => {
                  if (res?.data?.apiStatus) {
                     setWalletBalance((res.data?.omniCredits));
                  }
               });
         }
      };

      fetchData();

   }, [token, userName]);

   const loadingIndicator = (<div className="text-center">
      <div className="lds-facebook PowerBoosterModalLoader">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   const boosterItem = (booster, index) => {
      if (!booster || booster?.boosterRates?.length <= 0) {
         return null;
      }

      return (<div key={index} id={booster?.boosterId} role={'button'}
                   className={Classnames(styles.BoostCard, booster?.styleClass, booster?.boosterName === selectedBooster?.boosterName && styles.Sellected)}
                   style={{
                      opacity: (!currentlyActiveBooster || currentlyActiveBooster?.boosterId === booster?.boosterId) ? 1 : 0.5,
                      cursor: currentlyActiveBooster ? "default" : "pointer"
                   }}
                   onClick={() => {
                      if (currentlyActiveBooster) return;
                      handleBoosterSelect(booster);
                   }}>
         <div className={Classnames(styles.TopLeft, "TopLeft")}></div>
         <div className={Classnames(styles.BottomRight, "BottomRight")}></div>

         <div className={Classnames("position-relative")}>
            <div className={Classnames(styles.CardHeader)}>

               {/* <span>{getTimeDisplayText(booster?.boosterRates[0]?.minutes)}</span> */}
               <div>
                  <label><img src={Clock}/> Valid for</label>
                  {currentlyActiveBooster && currentlyActiveBooster?.boosterId === booster?.boosterId ?
                     <select disabled={true}>
                        <option key={index} value={JSON.stringify(selectedRate)}>
                           {getTimeDisplayText(selectedRate?.minutes)}
                        </option>
                     </select> : <select name="hour" onChange={handleBoosterValidForSelect}
                                         value={JSON.stringify(selectedTimesOfEachBooster[booster?.boosterId])}
                                         disabled={!!currentlyActiveBooster}>
                        {booster?.boosterRates.map((boosterRate, index) => {
                           return <option key={index}
                                          value={JSON.stringify(boosterRate)}
                           >{getTimeDisplayText(boosterRate?.minutes)}</option>
                        })}
                     </select>}
               </div>
            </div>
            <div className={Classnames(styles.CardBody)}>
               <p>{booster?.boosterName?.replace("-", " ")}</p>
            </div>

         </div>
         <div className={Classnames(styles.CardFooter)}>
            {currentlyActiveBooster && currentlyActiveBooster?.boosterId === booster?.boosterId ?
               <span>{selectedRate?.tokens}</span> :
               <span>{selectedTimesOfEachBooster[booster?.boosterId]?.tokens}</span>}
            <img src={SprtIcon}/>
         </div>
      </div>);
   }

   const footerBackgroundSvg = (color) => (
      <svg width="196" height="134" viewBox="0 0 196 134" fill="none" xmlns="http://www.w3.org/2000/svg">
         <g filter="url(#filter0_b_11_13751)">
            <path
               d="M196 0V87.7176C196 88.4991 195.545 89.209 194.835 89.5351L98.8346 133.617C98.3048 133.86 97.6952 133.86 97.1654 133.617L1.16541 89.5351C0.455199 89.209 0 88.4991 0 87.7176V71.4094C0 70.5642 0.531341 69.8102 1.32733 69.526L196 0Z"
               fill={color} fill-opacity="0.8"/>
            <path
               d="M0.723214 87.7176V71.4094C0.723214 70.8699 1.06242 70.3885 1.57057 70.207L195.277 1.02625V87.7176C195.277 88.2165 194.986 88.6697 194.533 88.8779L98.5328 132.96C98.1946 133.115 97.8054 133.115 97.4672 132.96L1.4672 88.8779C1.01381 88.6697 0.723214 88.2165 0.723214 87.7176Z"
               stroke="url(#paint0_linear_11_13751)" stroke-opacity="0.5" stroke-width="1.44643"/>
         </g>
         <defs>
            <filter id="filter0_b_11_13751" x="-20" y="-20" width="236" height="173.799" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
               <feFlood flood-opacity="0" result="BackgroundImageFix"/>
               <feGaussianBlur in="BackgroundImageFix" stdDeviation="10"/>
               <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11_13751"/>
               <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_11_13751" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_11_13751" x1="67" y1="-70" x2="213.616" y2="-35.8911"
                            gradientUnits="userSpaceOnUse">
               <stop stop-color="#F9FF01"/>
               <stop offset="0.337452" stop-color="#42FF8F"/>
               <stop offset="0.756368" stop-color="#36FF99" stop-opacity="0"/>
               <stop offset="1" stop-color="#2FFF9E" stop-opacity="0.39"/>
            </linearGradient>
         </defs>
      </svg>)

   return (
      <div className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.FantasyMatchHistoryModal)}
           id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
           style={{display: "block"}}>

         {showSequenceLoginModal &&
            <SequenceLoginModal packageId={buySprtsPackageId} amount={purchaseMaticAmount} transactionStatus={handleTransactionStatus}
                                ominiCreditValue={requestCreditAmount} handleClose={() => {
               setShowSequenceLoginModal(false)
            }}
                                handleGoBack={() => {
                                   setShowSequenceLoginModal(false)
                                }}
            />}

         {showPurchaseConfirmationModal ? <BoostPurchaseConfirmationModal isPurchasing={isPurchasing}
                                                                          player={player?.playerName}
                                                                          booster={selectedBooster}
                                                                          rate={selectedRate}
                                                                          handleModalClose={() => {
                                                                             setShowPurchaseConfirmationModal(false)
                                                                          }}
                                                                          handleConfirmation={handlePurchaseBooster}
            /> :
            <div className={Classnames(styles.CreditPurchaseModal, styles.PowerBoosterPurchaseModal, "PowerBoosterPurchaseModal modal-dialog modal-dialog-centered")}
                 role="document">
               <div className={Classnames("modal-content", styles.modalContentContainer)}>
                  <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                          data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
                  </button>
                  <div className={Classnames(styles.UserProfileModalWrapper)}>
                     <div className={Classnames(styles.Radial1)}></div>
                     <div className={Classnames(styles.Radial2)}></div>
                     {/* <div className={Classnames(styles.Radial3)}></div> */}
                     <div className={Classnames(styles.EdgeRadial1)}></div>
                     <div className={Classnames(styles.EdgeRadial2)}></div>

                     <div className={Classnames(styles.ellipse, styles.e392)}></div>
                     <div className="row p-0">
                        <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                           <div className={Classnames(styles.TitleWrapper)}>
                              <h2>BOOST {player?.playerName}</h2>
                              <p>Select the boost and apply to this player</p>
                           </div>
                        </div>
                     </div>
                     <div className="row p-0">
                        {(boosters?.length > 4 || screenWidth <= 768) &&
                           <img src={Chevron} className={Classnames(styles.LeftChevron)} onClick={handleLeftChevron}/>}
                        <div ref={boostWrapperRef} className={Classnames(styles.BoostWrapper, "col-md-12222")}>
                           {isLoading ? loadingIndicator : boosters.map((booster, index) => boosterItem(booster, index))}
                        </div>
                        {(boosters?.length > 4 || screenWidth <= 768) &&
                           <img src={Chevron} className={Classnames(styles.RightChevron)}
                                onClick={handleRightChevron}/>}
                     </div>

                     <div className={Classnames(styles.PlayerProfileRow, "row p-0")}>
                        <div className={Classnames(styles.PlayerProfileWrapper)}>
                           <div className={Classnames(styles.Header, "row p-0")}>
                              <div>
                                 SR {playerInfo?.playerStat?.strikeRate?.toFixed(2)}
                              </div>
                              <div>
                                 ECON {playerInfo?.playerStat?.economy?.toFixed(2)}
                              </div>
                           </div>
                           <div className={Classnames(styles.BodyWrapper, "row p-0")}>
                              <div
                                 style={{backgroundImage: `url(${player?.playerPictureURL})`}}>
                              </div>
                              <div></div>
                           </div>
                           <div className={Classnames(styles.Footer)}>
                              {footerBackgroundSvg(selectedBooster?.colourCode)}
                              <div className={Classnames(styles.BoostIconWrapper, "BoostIconWrapper")}>
                                 <div className={Classnames(styles.Circle, "Circle")}>
                                    <div className={Classnames(styles.InnerCircle, "InnerCircle")}></div>
                                    <FlashIcon/>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className={Classnames(styles.BoostInfoWrapper)}>
                           <div>
                              <h3>{selectedBooster?.boosterName}</h3>
                              <span>Boost</span>
                           </div>
                           <RenderTextWithImages text={selectedBooster?.boosterDescription}/>
                           <div className={Classnames(styles.ValidityWrapper, "ValidityWrapper")}>
                              <div>
                                 Valid for : <label>{getTimeDisplayText(selectedRate?.minutes)}</label>
                              </div>
                        {/*<img src={MoreInfoIcon}/>*/}
                           </div>
                        </div>
                     </div>

                     <div className="row p-0">
                        <div className={Classnames(styles.ModalButtonRow, "row p-0")}>
                           <button className="lightGreenButton" onClick={handleModalClose}>
                              <text>Cancel</text>
                           </button>
                           {currentlyActiveBooster ? <div className={Classnames(styles.BoostBtn, "opacity-75")}>
                              <span>Boost in <label>{remainingTime === "" ?
                                 <Puff className={styles.puffWrapper} height={'1em'} strokeWidth={5}
                                       speed={2}/> : remainingTime}</label></span>
                              </div> :
                              <div className={Classnames(styles.BoostBtn)} role={'button'} onClick={handleBoostBtnClick}>
                                 <span>Boost <label>{selectedRate?.tokens}</label></span>
                                 <img src={SprtIcon}/>
                              </div>}
                        </div>
                     </div>
                  </div>
               </div>
            </div>}
      </div>)
}

const mapStateToProps = (state) => {
   return {
      userName: state.auth.userName, token: state.auth.accessToken
   };
};

export default connect(mapStateToProps)(PowerBoosterPurchaseModal);
