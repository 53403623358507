//Auth Actions

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_NAME = "UPDATE_NAME";
export const REFRESH_SESSION = "REFRESH_SESSION";
export const SET_AUTH_RIDIRECT_PATH = "SET_AUTH_RIDIRECT_PATH";
export const SELECT_USER_DETAILS = "SELECT_USER_DETAILS";
export const SESSION_TIMEOUT = "SESSION_TIMEOUT";
export const RESET_SESSION_TIMEOUT = "RESET_SESSION_TIMEOUT";
