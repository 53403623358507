import React, {useEffect, useState} from "react";
import Classnames from "classnames";
import Styles from "../../../containers/Tournament/KnockoutTournamentPage/KnockoutTournament.module.scss";
import koutDrawStyles from "./KnockoutMatchDraw.module.scss";
import PopupButton from "../../../assets/images/icons/PopupButton.svg";
import {Link, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

const KnockoutMatchDraw = (props) => {

   const [rounds, setRounds] = useState({});
   const [matchesData, setMatchesData] = useState(props.drawData);
   const location = useLocation();
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const loadingIndicator = (
      <div style={{textAlign: "center"}}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   );

   function extractMatchesByRound(matches) {
      const result = {};

      const extract = (matches, uniqueMatchIds = new Set()) => {
         if (!matches) return;

         matches.forEach((match) => {
            const {round} = match;
            if (!result[round] && round !== undefined) {
               result[round] = [];
            }
            if (round !== undefined) {
               if (!uniqueMatchIds.has(match.matchId)) {
                  result[round].push(match);
                  uniqueMatchIds.add(match.matchId);
               }
            }

            extract(match.matches, uniqueMatchIds);
         });
      };

      extract(matches);
      const lastRoundMatches = result[Object.keys(result).length];
      if (lastRoundMatches && lastRoundMatches.length > 0) {
         const additionalRound = Object.keys(result).length + 1;
         const lastObj = [{...lastRoundMatches[0], "lastRound": true}]
         result[additionalRound] = [...lastObj];
      }
      return result;
   }


   useEffect(() => {
      const matchesByRound = extractMatchesByRound(matchesData);
      setRounds(matchesByRound);
   }, [matchesData]);

   return (
      <>
         <div className="row p-0">
            <div className={Classnames(koutDrawStyles.TournamentSubText)}>Tournament</div>
         </div>
         <div className={Classnames(koutDrawStyles.KnockoutMatchDraw, "row p-0")}>
            <div className={Classnames(Styles.MatchDetails, "col p-0")}>
               <div className={Classnames(Styles.WinPrizes)}>
                  <div className={Classnames(Styles.Conner1)}></div>
                  <div className={Classnames(Styles.Conner2)}></div>
                  <div className={Classnames(Styles.Conner3)}></div>
                  <div className={Classnames(Styles.Conner4)}></div>

                  {(Object.entries(rounds).length) > 2 ?
                     <>
                        <div className={Classnames(Styles.Shade1)}></div>
                        <div className={Classnames(Styles.Shade2)}></div>
                     </> : <></>}
                  <div className={Classnames(Styles.Shade3)}></div>
                  <div className={Classnames(Styles.Shade4)}></div>

                  <div className={Classnames(Styles.WinPrizesWrapper)}>
                     <div className={Classnames(Styles.Tournament)}>
                        <div className={Classnames(Styles.TournamentView)}>
                           <div className={Classnames(Styles.tournamentContainer)}>


                              <div className={Classnames(Styles.tournamentBrackets)}>
                                 {props.tournamentDetails ?
                                    <>
                                       {Object.entries(rounds)?.map((round, i) => (
                                          <ul className={Classnames(Styles.bracket, Styles[`bracket${round[0]}`])}>
                                             {Object.entries(rounds).length == i + 1 ?
                                                <li className={Classnames(Styles.TeamItem)}>
                                                   <div>
                                                      <div className={Classnames(Styles.PopupButton)}>
                                                         <Link to={{
                                                            pathname: `/match/${round[1][0]?.matchId}`,
                                                            search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                                         }}>
                                                            <img src={PopupButton}/>
                                                         </Link>
                                                      </div>
                                                      <span>
                                                      {round[1][0]?.winnerPicUrl ?
                                                         <img src={round[1][0]?.winnerPicUrl} alt={''}/> :
                                                         <img className={koutDrawStyles.noProfPic}
                                                              src={'https://storage.googleapis.com/meta11/players/TBA.png'}
                                                              alt={''}/>}
                                                         </span>
                                                      <label>{round[1][0]?.winnerName ? round[1][0]?.winnerName : t("LATEST_MATCH.TBA")}</label>
                                                   </div>
                                                </li>
                                                :
                                                <>
                                                   {round[1]?.map((teamMatch, x) => (
                                                      <>
                                                         <li className={Classnames(Styles.TeamItem)}>
                                                            <div>
                                                               {round[1][0]?.matches?.[0]?.matchId ?
                                                                  <div className={Classnames(Styles.PopupButton)}>
                                                                     <Link to={{
                                                                        pathname: `/match/${round[1][x]?.matches?.[0]?.matchId}`,
                                                                        search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                                                     }}>
                                                                        <img src={PopupButton}/>
                                                                     </Link>
                                                                  </div> : <></>}
                                                               <span>
                                                               {teamMatch?.team1PicUrl ?
                                                                  <img src={teamMatch?.team1PicUrl} alt={''}/> :
                                                                  <img className={koutDrawStyles.noProfPic}
                                                                       src={'https://storage.googleapis.com/meta11/players/TBA.png'}
                                                                       alt={''}/>}
                                                            </span>
                                                               <label>{teamMatch?.team1Name ? teamMatch.team1Name : t("LATEST_MATCH.TBA")}</label>
                                                            </div>
                                                         </li>
                                                         <li className={Classnames(Styles.TeamItem)}>
                                                            <div>
                                                               {round[1][0]?.matches?.[1]?.matchId ?
                                                                  <div className={Classnames(Styles.PopupButton)}>
                                                                     <Link to={{
                                                                        pathname: `/match/${round[1][x]?.matches?.[1]?.matchId}`,
                                                                        search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                                                     }}><img src={PopupButton}/>
                                                                     </Link>
                                                                  </div> : <></>}
                                                               <span>
                                                               {teamMatch?.team2PicUrl ?
                                                                  <img src={teamMatch?.team2PicUrl} alt={''}/> :
                                                                  <img className={koutDrawStyles.noProfPic}
                                                                       src={'https://storage.googleapis.com/meta11/players/TBA.png'}
                                                                       alt={''}/>}
                                                            </span>
                                                               <label>{teamMatch?.team2Name ? teamMatch.team2Name : t("LATEST_MATCH.TBA")}</label>
                                                            </div>
                                                         </li>
                                                      </>
                                                   ))}</>
                                             }
                                          </ul>
                                       ))}
                                    </> :
                                    <div className="col-12">
                                       <div>
                                          {loadingIndicator}
                                       </div>
                                    </div>
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};

export default connect(mapStateToProps)(KnockoutMatchDraw);
