import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export const checkUserFantasySubscription = async (tournamentId, username, authToken) => {
   const url = `${ApiEndpoints.GET_USER_FANTASY_SUBSCRIPTION}/${tournamentId}/${username}`;

   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export const getAllPlayersByTournamentId = async (tournamentId, teamId) => {
   const url = `${ApiEndpoints.GET_PLAYERS_BY_TOURNAMENT_ID}/${tournamentId}${teamId ? `?team=${teamId}` : ''}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response.data;
      });
};

export const getFantasyTeamByUsernameAndTournamentId = async (username, tournamentId, authToken) => {
   const url = `${ApiEndpoints.GET_FANTASY_TEAM_BY_USERNAME_AND_TOURNAMENT_ID}/${tournamentId}/${username}`;

   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export const postUserFantasyTeam = async (username, tournamentId, teamName, team, authToken) => {
   const url = `${ApiEndpoints.POST_USER_FANTASY_TEAM}`;
   const requestBody = {
      fantasyTeamName: teamName,
      fantasyTournamentUserTeamDTOList: team,
      tournamentId: tournamentId,
      userName: username
   };

   return AxiosInstance()
      .post(url, requestBody, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export const getPlayerFantasyMatchHistory = async (playerId, tournamentId, userId, authToken) => {
   const url = `${ApiEndpoints.GET_PLAYER_FANTASY_MATCH_HISTORY}/${playerId}/${tournamentId}/${userId}`;

   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export const getFantasyRules = async () => {
   const url = `${ApiEndpoints.GET_FANTASY_RULES}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export const getUserFantasyTournamentHistory = async (username, sort, size, page, authToken) => {
   const url = `${ApiEndpoints.GET_USER_FANTASY_TOURNAMENT_HISTORY}?username=${username}&sort=${sort}&size=${size}&page=${page}`;

   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export const getBoosterRulesPoints = async () => {
   const url = `${ApiEndpoints.GET_BOOSTER_RULES_POINTS}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}

export const getPlayerBoostHistory = async (playerId, tournamentId, userId) => {
   const url = `${ApiEndpoints.GET_PLAYER_BOOST_HISTORY}/${tournamentId}/${userId}/${playerId}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response.data;
      });
}
