import classnames from "classnames";
import styles from "../../../containers/UserProfile/userProfile.module.scss";
import {BsChevronLeft, BsChevronRight, BsTrophyFill} from "react-icons/bs";
import twodots from "../../../assets/images/2dots.svg";
import React, {useEffect, useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import * as UserService from "../../../services/UserService";
import {formatDate, formatTime} from "../../../helpers/CommonFunctions";
import {useTranslation} from "react-i18next";

function BotHistoryModel({show, handleClose, token, matchInfo, encodedUserName, matchId, questionIndex}) {
   const match = matchInfo.map[matchId].matchDto;
   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
   const [questions, setQuestions] = useState([]);
   const [numberOfQuestions, setNumberOfQuestions] = useState(0);
   const [numberOfAnswers, setNumberOfAnswers] = useState(0);
   const [totalPoints, setTotalPoints] = useState(0);
   const [answers, setAnswers] = useState([]);
   const [userAnswer, setUserAnswer] = useState(null);
   const [userAnswerHistoryResponse, setUserAnswerHistoryResponse] = useState([]);
   const [over, setOver] = useState(0);
   const [inning, setinning] = useState(0);
   const [apiStatus, setApiStatus] = useState("loading");
   const {t} = useTranslation();
   let totalOvers = 0;

   var inningKey;
   var inningValue;
   var overArray;
   var overKey;

   useEffect(() => {
      UserService.getUserQuestionHistory(token, encodedUserName, matchId, 0, 9, "createdTime%2Cdesc").then((res) => {
         setApiStatus(res.data?.apiStatus)
         if (res.data?.apiStatus) {
            setUserAnswerHistoryResponse(res.data.userAnswerHistoryResponse);
            if (res.data.userAnswerHistoryResponse.userAnswers) {
               setUserAnswer(res.data.userAnswerHistoryResponse.userAnswers);
               setNumberOfQuestions(res.data.userAnswerHistoryResponse.userAnswers.length);
            }
            setTotalPoints(res.data.userAnswerHistoryResponse.totalMatchPoints);
         }
      })
   }, []);

   useEffect(() => {

      if (userAnswer?.length > 0) {
         setAnswers([]);
         const currentQuestion = userAnswer[currentQuestionIndex];
         if (userAnswer[currentQuestionIndex]?.answers) {
            setNumberOfAnswers(userAnswer[currentQuestionIndex].answers.length);
            const answerButtons = userAnswer[currentQuestionIndex].answers.length > 0 ?
               userAnswer[currentQuestionIndex].answers.map((answer, index) => (
                  currentQuestion.isCorrect ? (
                        answer.answer == currentQuestion.userAnswer ? (
                           <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">{answer.answer}</Tooltip>}>
                              <div className={classnames(styles.AnswerBtn)}>
                                 <button className={classnames(styles.greenBtn, "q-btn")} key={index}>
                                    <span className="body-text2 color-white">{answer.answer}</span>
                                 </button>
                              </div>
                           </OverlayTrigger>
                        ) : answer.answer == currentQuestion.correctAnswer ? (
                           <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">{answer.answer}</Tooltip>}>
                              <div className={classnames(styles.AnswerBtn)}>
                                 <button className={classnames(styles.greenBorderBtn, "me-4")} key={index}>
                                    <span className="body-text2 color-white">{answer.answer}</span>
                                 </button>
                              </div>
                           </OverlayTrigger>
                        ) : (
                           <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">{answer.answer}</Tooltip>}>

                              <div className={classnames(styles.AnswerBtn)}>
                                 <button className={classnames(styles.blackBtn, "q-btn")} key={index}>{answer.answer}</button>
                              </div>

                           </OverlayTrigger>
                        )
                     ) :
                     (answer.answer == currentQuestion.userAnswer ? (
                           <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">{answer.answer}</Tooltip>}>
                              <div className={classnames(styles.AnswerBtn)}>
                                 <button className={classnames(styles.redSolidBtn)} key={index}>
                                    <span className="body-text2 color-white">{answer.answer}</span>
                                 </button>
                              </div>
                           </OverlayTrigger>
                        ) : answer.answer == currentQuestion.correctAnswer ? (
                           <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">{answer.answer}</Tooltip>}>
                              <div className={classnames(styles.AnswerBtn)}>
                                 <button className={classnames(styles.greenBorderBtn, "me-4")} key={index}><span className="body-text2 color-white">{answer.answer}</span></button>
                              </div>
                           </OverlayTrigger>
                        ) : (
                           <OverlayTrigger placement="top"
                                           overlay={<Tooltip id="tooltip1">{answer.answer}</Tooltip>}>
                              <div className={classnames(styles.AnswerBtn)}>
                                 <button className={classnames(styles.blackBtn, "q-btn")} key={index}>{answer.answer}</button>
                              </div>
                           </OverlayTrigger>
                        )
                     )
               )) : (
                  currentQuestion.isCorrect ? (
                     <OverlayTrigger placement="top"
                                     overlay={<Tooltip id="tooltip1">{currentQuestion.userAnswer}</Tooltip>}>
                        <div className={classnames(styles.AnswerBtn)}>
                           <button className={classnames(styles.greenBtn, "q-btn")}>
                              <span className="body-text2 color-white">
                              {currentQuestion.userAnswer}
                              </span>
                           </button>
                        </div>
                     </OverlayTrigger>
                  ) : (
                     <OverlayTrigger placement="top"
                                     overlay={<Tooltip id="tooltip1">{currentQuestion.userAnswer}</Tooltip>}>
                        <div className={classnames(styles.AnswerBtn)}>
                           <button className={classnames(styles.redSolidBtn)}>
                              <span className="body-text2 color-white">
                              {currentQuestion.userAnswer}
                              </span>
                           </button>
                        </div>
                     </OverlayTrigger>
                  )
               )
            setQuestions(currentQuestion.question);
            setAnswers(answerButtons);
         } else if (userAnswer[currentQuestionIndex][1]) {
            const inningPart =
               <div className={classnames(styles.OverSummary, "row")}>
                  <div className={classnames(styles.ballBodyWrapper)}>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][1][0]?.balls[0]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][1][0]?.balls[0].answer}</span>
                           {/*userAnswerHistoryResponse.inningSummary[1][0].balls[0].isCorrect*/}
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][1][0]?.balls[0]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][1][0]?.balls[0]?.points : 0}</div>
                     </div>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][1][0]?.balls[1]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][1][0]?.balls[0].answer}</span>
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][1][0]?.balls[1]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][1][0]?.balls[1]?.points : 0}</div>
                     </div>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][1][0]?.balls[2]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][1][0]?.balls[0].answer}</span>
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][1][0]?.balls[2]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][1][0]?.balls[2]?.points : 0}</div>
                     </div>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][1][0]?.balls[3]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][1][0]?.balls[0].answer}</span>
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][1][0]?.balls[3]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][1][0]?.balls[3]?.points : 0}</div>
                     </div>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][1][0]?.balls[4]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][1][0]?.balls[0].answer}</span>
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][1][0]?.balls[4]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][1][0]?.balls[4]?.points : 0}</div>
                     </div>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][1][0]?.balls[5]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][1][0]?.balls[0].answer}</span>
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][1][0]?.balls[5]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][1][0]?.balls[5]?.points : 0}</div>
                     </div>
                  </div>
                  <h7>{t("BOT_HISTORY_MODEL.INNING")} {userAnswer[currentQuestionIndex][1][0]?.overSummary?.inningId} {t("BOT_HISTORY_MODEL.OVER")} {userAnswer[currentQuestionIndex][1][0]?.overSummary?.overId} {t("BOT_HISTORY_MODEL.SUMMARY")}</h7>
                  <p className={classnames(styles.points)}>{userAnswer[currentQuestionIndex][1][0]?.overSummary?.totalPoints} pts</p>

                  <div className={classnames(styles.summaryWrapper)}>

                     <div className={classnames(styles.borderHr)}></div>

                     <label>{t("BOT_HISTORY_MODEL.CORRECT_ANSWERS")} <span>{userAnswer[currentQuestionIndex][1][0]?.overSummary?.predictionPoints} pts</span></label><br/>
                     <label>{t("BOT_HISTORY_MODEL.STREAK_BONUS")} <span>{userAnswer[currentQuestionIndex][1][0]?.overSummary?.streakBonusPoints} pts</span></label><br/>
                     <label>{t("BOT_HISTORY_MODEL.3_IN_A_ROW_BONUS")} <span>{userAnswer[currentQuestionIndex][1][0]?.overSummary?.consecutivePoints} pts</span></label><br/>
                     <label>{t("BOT_HISTORY_MODEL.SIX_PREDICTION_POINTS")} <span>{userAnswer[currentQuestionIndex][1][0]?.overSummary?.sixPredictionPoints} pts</span></label><br/>
                     <div className={classnames(styles.borderHr)}></div>
                     <label>{t("BOT_HISTORY_MODEL.TOTAL_POINTS")} <span>{userAnswer[currentQuestionIndex][1][0]?.overSummary?.totalPoints} pts</span></label>
                  </div>
               </div>
            setAnswers(inningPart);
         } else {
            const inningPart =
               <div className={classnames(styles.OverSummary, "row")}>
                  <div className={classnames(styles.ballBodyWrapper)}>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][2][0]?.balls[0]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][2][0]?.balls[0].answer}</span>
                           {/*userAnswerHistoryResponse.inningSummary[2][0].balls[0].isCorrect*/}
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][2][0]?.balls[0]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][2][0]?.balls[0]?.points : 0}</div>
                     </div>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][2][0]?.balls[1]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][2][0]?.balls[0].answer}</span>
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][2][0]?.balls[1]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][2][0]?.balls[1]?.points : 0}</div>
                     </div>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][2][0]?.balls[2]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][2][0]?.balls[0].answer}</span>
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][2][0]?.balls[2]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][2][0]?.balls[2]?.points : 0}</div>
                     </div>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][2][0]?.balls[3]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][2][0]?.balls[0].answer}</span>
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][2][0]?.balls[3]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][2][0]?.balls[3]?.points : 0}</div>
                     </div>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][2][0]?.balls[4]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][2][0]?.balls[0].answer}</span>
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][2][0]?.balls[4]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][2][0]?.balls[4]?.points : 0}</div>
                     </div>
                     <div className={classnames(styles.ballWrapper,
                        userAnswer[currentQuestionIndex][2][0]?.balls[5]?.isCorrect ? "greenBall" :
                           "redBall")}>
                        <div className={classnames(styles.ballBody)}>
                           <span>{userAnswer[currentQuestionIndex][2][0]?.balls[0].answer}</span>
                        </div>
                        <div className={classnames(styles.pointsCell)}>{userAnswer[currentQuestionIndex][2][0]?.balls[5]?.points > 0 ? "+ " + userAnswer[currentQuestionIndex][2][0]?.balls[5]?.points : 0}</div>
                     </div>
                  </div>
                  <h7>{t("BOT_HISTORY_MODEL.INNING")} {userAnswer[currentQuestionIndex][2][0]?.overSummary?.inningId} {t("BOT_HISTORY_MODEL.OVER")} {userAnswer[currentQuestionIndex][2][0]?.overSummary?.overId} {t("BOT_HISTORY_MODEL.SUMMARY")}</h7>
                  <p className={classnames(styles.points)}>{userAnswer[currentQuestionIndex][2][0]?.overSummary?.totalPoints} pts</p>
                  <div className={classnames(styles.summaryWrapper)}>
                     <div className={classnames(styles.borderHr)}></div>
                     <label>{t("BOT_HISTORY_MODEL.CORRECT_ANSWERS")} <span>{userAnswer[currentQuestionIndex][2][0]?.overSummary?.predictionPoints} pts</span></label><br/>
                     <label>{t("BOT_HISTORY_MODEL.STREAK_BONUS")} <span>{userAnswer[currentQuestionIndex][2][0]?.overSummary?.streakBonusPoints} pts</span></label><br/>
                     <label>{t("BOT_HISTORY_MODEL.3_IN_A_ROW_BONUS")} <span>{userAnswer[currentQuestionIndex][2][0]?.overSummary?.consecutivePoints} pts</span></label><br/>
                     <label>{t("BOT_HISTORY_MODEL.SIX_PREDICTION_POINTS")} <span>{userAnswer[currentQuestionIndex][2][0]?.overSummary?.sixPredictionPoints} pts</span></label><br/>
                     <div className={classnames(styles.borderHr)}></div>
                     <label>{t("BOT_HISTORY_MODEL.TOTAL_POINTS")} <span>{userAnswer[currentQuestionIndex][2][0]?.overSummary?.totalPoints} pts</span></label>
                  </div>
               </div>
            setAnswers(inningPart);
         }
      }
   }, [userAnswer, numberOfQuestions, currentQuestionIndex]);

   const handleNextQuestion = () => {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
   };

   const handlePreviousQuestion = () => {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
   };

   const loadingIndicator = (<div style={{textAlign: "center"}}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   );

   return (
      <div className="row gx-0 mb-4">
         {numberOfQuestions > 0 ?
            <div className={classnames(styles.historyContainer, "col-12")}>
               <div className={classnames(styles.OverviewBox, styles.borderTop2)}>
                     <span className={classnames(styles.topSectionModal, "p-2")}>
                       <button className="blackBtn -mobile-hide" onClick={handleClose}>
                           <BsChevronLeft/>{t("BOT_HISTORY_MODEL.BACK")}
                       </button>
                       <label className={classnames(styles.itemDate, "color-gray2")}>
                         {match?.matchStartTime ? formatDate(match?.matchStartTime, true, true) + " " : ''}
                          {match?.matchStartTime ? formatTime(match?.matchStartTime) : ''}
                       </label>
                       <button className="blackBtn color-textturquiose button-text3 -mobile-hide lower-case" style={{height: 42, marginBottom: 15}}>
                           <BsTrophyFill className="color-white"/>{totalPoints > 0 ? " + " + totalPoints : 0} {t("BOT_HISTORY_MODEL.POINTS")}
                       </button>
                     </span>
                  <span className={classnames(styles.bottomSection)}>
                                    <span className={classnames(styles.leftPlayer)}>
                                      <span className={classnames(styles.player1Details)}>
                                      <label className={classnames(styles.player1Details, styles.name, "body-text3-b-italic upper-case color-white")}>
                                          {match?.team1Name}
                                         {match?.winnerId ==
                                         match?.team1Id ?
                                            <span className={classnames(styles.cupIcon)}></span> : ''}
                                      </label>
                                      <label className="heading4 bold color-gray2 mb-0">
                                      <div style={{
                                         color: match?.winnerId == match?.team1Id ? "rgb(23, 234, 133)" : "rgb(149, 166, 183)"
                                      }}>
                                        {match?.team1Runs ? match?.team1Runs : 0}/{match?.team1Wickets ? match?.team1Wickets : 0}
                                      </div>
                                    </label>
                                      <label className="body-text4 color-gray2 mb-0">
                                          (
                                         {match?.team1Balls !== 6
                                            ? match?.team1Overs - 1
                                            : match?.team1Overs}
                                         .
                                         {match?.team1Balls !== 6
                                            ? match?.team1Balls
                                            : 0}
                                         )
                                      </label>
                                      </span>
                                      <img src={match?.team1PicURL}/>
                                    </span>
                                    <span className={classnames(styles.midleDots)}>
                                      <img src={twodots}/>
                                    </span>
                                    <span className={classnames(styles.rightPlayer)}>
                                      <img src={match?.team2PicURL}/>
                                      <span className={classnames(styles.player2Details)}>
                                      <label className={classnames(styles.player1Details, styles.name, "body-text3-b-italic upper-case color-white")}>
                                          {match?.winnerId == match?.team2Id ?
                                             <span className={classnames(styles.cupIcon)}></span> : ''}
                                         {match?.team2Name}
                                      </label>
                                      <label className="heading4 bold color-textturquiose mb-0">
                                      <div style={{color: match?.winnerId == match?.team2Id ? "rgb(23, 234, 133)" : "rgb(149, 166, 183)"}}>
                                        {match?.team2Runs ? match?.team2Runs : 0}/{match?.team2Wickets ? match?.team2Wickets : 0}
                                      </div>
                                    </label>
                                      <label className="body-text4 color-gray2 mb-0">
                                          (
                                         {match?.team2Balls == null ? 0 : match?.team2Balls !== 6
                                            ? match?.team2Overs - 1
                                            : match?.team2Overs}
                                         .
                                         {match?.team2Balls == null ? 0 : match?.team2Balls !== 6
                                            ? match?.team2Balls
                                            : 0}
                                         )
                                      </label>
                                      </span>
                                    </span>
                                    </span>
                  <div className={classnames(styles.questionSection)}>
                     <div className={classnames(styles.questionArea)}>
                        <div className={classnames(styles.questions, "row")}>
                           {userAnswer[currentQuestionIndex].answers ? (
                              <>
                                 <h2 className="upper-case">{questions}</h2>
                                 <div className={classnames(styles.answerBtnWrapper, "d-flex",
                                    numberOfAnswers > 3 ? "moreThanthree" :
                                       numberOfAnswers > 2 ? "threeAnswers" :
                                          numberOfAnswers > 1 ? "twoAnswers" : "oneAnswer ")}>
                                    <div className={classnames(styles.AnswerGrid)}>{answers}</div>
                                 </div>
                                 {userAnswer[currentQuestionIndex].question == "So, who`s gonna snag the trophy today, hotshot?" ?
                                    <div className={classnames(styles.winnerPrediction)}>
                                       <span>{t("BOT_HISTORY_MODEL.WINNER_PREDICTION")}</span>
                                       <span className={classnames(styles.points)}>{userAnswer[0]?.points > 0 ? "+" + userAnswer[currentQuestionIndex]?.points : 0} {t("BOT_HISTORY_MODEL.POINTS")}</span>
                                    </div> : <>
                                       <div className={classnames(styles.winnerPrediction)}>
                                          <span>{t("BOT_HISTORY_MODEL.POINTS")}:</span>
                                          <span className={classnames(styles.points)}>{userAnswer[currentQuestionIndex]?.points > 0 ? "+" + userAnswer[currentQuestionIndex]?.points : 0} {t("BOT_HISTORY_MODEL.POINTS")}</span>
                                       </div>
                                    </>
                                 }
                              </>
                           ) : (<>{answers}</>)}
                        </div>
                        {/* ///////// */}
                     </div>
                     <div classnames="row">
                        {currentQuestionIndex > 0 && (
                           <button className="blackBtn -mobile-hide" style={{float: "left"}} onClick={handlePreviousQuestion}>
                              <BsChevronLeft/>{t("BOT_HISTORY_MODEL.PREVIOUS_QUESTION")}
                           </button>
                        )}
                        {currentQuestionIndex < numberOfQuestions - 1 && (
                           <button className="blackBtn -mobile-hide" style={{float: "right"}} onClick={handleNextQuestion}>
                              {t("BOT_HISTORY_MODEL.NEXT_QUESTION")}<BsChevronRight style={{left: 12}}/>
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </div>
            :
            <>{apiStatus == false ?
               <div className={classnames(styles.OverviewBox, styles.borderTop2)}>
                                    <span className={classnames(styles.topSectionModal, "p-2")}>
                                      <button className="blackBtn -mobile-hide" onClick={handleClose}>
                                          <BsChevronLeft/>{t("BOT_HISTORY_MODEL.BACK")}
                                      </button>
                                    </span>
                  <span className={classnames(styles.bottomSection)}>
                                    <span className={classnames(styles.noDataMsg)}>{t("BOT_HISTORY_MODEL.NO_DATA")}</span>
                        </span>
               </div>
               :
               <>
                  <div className="col-12">
                     <div className={classnames(styles.OverviewBox, styles.borderTop2)}>
                        {loadingIndicator}
                     </div>
                  </div>
               </>}
            </>
         }
      </div>
   );
}

export default BotHistoryModel;
