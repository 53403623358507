import React, {useEffect, useState} from "react";
import PubNub from "pubnub";
import {PUBNUB_KEYS} from "../../utilities/Constants";
import {connect} from "react-redux";
import {pubNubConfigConstants} from "../../utilities/PubNubConfigConstants";


const PubNubPredictionHandler = (props) => {

   const [channels, setChannels] = useState([`${pubNubConfigConstants.PREDICTION}-${props.matchId}`]);
   const [pubnub, setPubNub] = useState(null);

   useEffect(() => {
      // Initialize PubNub if the user is authenticated
      if (props.isAuthenticated) {
         const pubnubInstance = new PubNub({
            publishKey: PUBNUB_KEYS.PUBLISH_KEY,
            subscribeKey: PUBNUB_KEYS.SUBSCRIBE_KEY,
            uuid: props.userName || 'default-uuid',
            authKey: props.token,
         });
         setPubNub(pubnubInstance);
         return () => {
            console.log("Cleaning up PubNub");
            pubnubInstance.unsubscribeAll();
            pubnubInstance.stop();
         };
      }
   }, [props.isAuthenticated, props.token, props.userName]);

   useEffect(() => {
      // Subscribe to channels when PubNub instance is available and channels are updated
      if (pubnub) {
         pubnub.addListener({message: handleMessage});
         pubnub.subscribe({channels});
         console.log("Subscribed to channels:", channels);
      }
      // Unsubscribe from channels when component unmounts
      return () => {
         if (pubnub) {
            pubnub.removeListener({message: handleMessage});
            pubnub.unsubscribe({channels});
         }
      };
   }, [channels, pubnub]);

   const handleMessage = event => {
      let message = event.message;

      props.onDataReceivedPrediction(message)
   }

   const pubNubMessageSender = (message, channel) => {
      pubnub.getSubscribedChannels({
         includePresence: true,
      }, function (status, response) {
         if (status.error) {
            console.error('Error fetching subscribed channels:', status.errorData);
         } else {
            console.log('Subscribed Channels:', response.channels);
         }
      });

      let jsonString = JSON.stringify(message);
      console.log("/////*** -- PUBLISH PREDICTION -- *** ///// :", jsonString);

      // let userChannel = `${props.userName}-${props.matchId}`
      // pubnub.subscribe({userChannel,
      //     withPresence: true});
      try {
         console.log("****ENTERED LOG****");
         pubnub.publish({
            message: JSON.parse(jsonString),
            channel: channel,
         });
      } catch (e) {
         console.log("****Publish Exception****", e)
      }
   }

   const pubNubSubscriber = (channel) => {
      pubnub.subscribe(channel)
      setChannels(prevState => [...prevState, channel])
      console.log("channels subscribe", channel)
   }

   const pubNubUnSubscriber = (channel) => {
      pubnub.unsubscribe(channel)
   }

   useEffect(() => {
      if (props.sendMessage != null) {
         pubNubMessageSender(props.sendMessage.message, props.sendMessage.channel)
      } else {
         console.log("message is null")
      }

   }, [props.sendMessage]);

   useEffect(() => {
      if (props.subscribe != null) {
         pubNubSubscriber(props.subscribe)
      }
   }, [props.subscribe]);

   useEffect(() => {
      if (props.unSubscribe != null) {
         pubNubUnSubscriber(props.unSubscribe)
      }
   }, [props.unSubscribe]);
   return (<></>)
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email
   };
}

export default connect(mapStateToProps)(PubNubPredictionHandler);
