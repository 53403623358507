import CreditIcon from "../assets/images/icons/24_sprts_token.webp";
import BoostStart from "../assets/images/icons/boost_Start.svg";
import BoostTimeoutWarning from "../assets/images/icons/boost_timeout_warning.svg";
import BoostEndIcon from "../assets/images/icons/boost_end.svg";
import RewardsNotifi from "../assets/images/icons/Rewards_notifi.svg";

export const PlayingRolesEN = {
   OPENING_BOWLER: "OPENING-BOWLER",
   BOWLER: "BOWLER",
   MIDDLE_ORDER_BATSMAN: "MIDDLE-ORDER-BATSMAN",
   OPENING_BATSMAN: "OPENING-BATSMAN",
   BATSMAN: "BATSMAN",
   "ALL-ROUNDER": "ALL-ROUNDER",
};

export const PlayingRolesHI = {
   OPENING_BOWLER: "ओपनिंग बॉलर",
   BOWLER: "गेंदबाज",
   MIDDLE_ORDER_BATSMAN: "मध्यक्रम के बल्लेबाज",
   OPENING_BATSMAN: "ओपनिंग बल्लेबाज़",
   BATSMAN: "बल्लेबाज",
   "ALL-ROUNDER": "आल राउंडर",
};

export const BowlingStylesEN = {
   RIGHT_ARM_FAST: "RIGHT ARM, FAST",
   RIGHT_ARM_FAST_MEDIUM: "RIGHT ARM, FAST MEDIUM",
   RIGHT_ARM_MEDIUM_FAST: "RIGHT ARM, MEDIUM FAST",
   RIGHT_ARM_MEDIUM: "RIGHT ARM, MEDIUM",
   RIGHT_ARM_MEDIUM_SLOW: "RIGHT ARM, MEDIUM SLOW",
   RIGHT_ARM_SLOW_MEDIUM: "RIGHT ARM, SLOW MEDIUM",
   RIGHT_ARM_SLOW: "RIGHT ARM, SLOW",
   LEFT_ARM_FAST: "LEFT ARM, FAST",
   LEFT_ARM_FAST_MEDIUM: "LEFT ARM, FAST MEDIUM",
   LEFT_ARM_MEDIUM_FAST: "LEFT ARM, MEDIUM FAST",
   LEFT_ARM_MEDIUM: "LEFT ARM, MEDIUM",
   LEFT_ARM_MEDIUM_SLOW: "LEFT ARM, MEDIUM SLOW",
   LEFT_ARM_SLOW_MEDIUM: "LEFT ARM, SLOW MEDIUM",
   LEFT_ARM_SLOW: "LEFT ARM, SLOW",
   RIGHT_ARM_OFF_BREAK: "RIGHT ARM, OFF BREAK",
   RIGHT_ARM_LEG_BREAK: "RIGHT ARM, LEG BREAK",
   RIGHT_ARM_LEG_BREAK_GOOGLY: "RIGHT ARM, LEG BREAK GOOGLY",
   LEFT_ARM_ORTHODOX: "LEFT ARM, ORTHODOX",
   LEFT_ARM_WRIST_SPIN: "LEFT ARM, WRIST SPIN",
   LEFT_ARM_GOOGLY: "LEFT ARM, GOOGLY",
   MEDIUM_FACE: "MEDIUM-FACE"
};

export const BowlingStylesHI = {
   RIGHT_ARM_FAST: "दाहिना हाथ, तेज़",
   RIGHT_ARM_FAST_MEDIUM: "दाहिना हाथ, तेज़ मध्यम",
   RIGHT_ARM_MEDIUM_FAST: "दाहिना हाथ, मध्यम तेज़",
   RIGHT_ARM_MEDIUM: "दाहिना हाथ, मध्यम",
   RIGHT_ARM_MEDIUM_SLOW: "दाहिना हाथ, मध्यम धीमा",
   RIGHT_ARM_SLOW_MEDIUM: "दाहिना हाथ, धीमा माध्यम",
   RIGHT_ARM_SLOW: "दाहिना हाथ, धीमा",
   LEFT_ARM_FAST: "बायां हाथ, तेज",
   LEFT_ARM_FAST_MEDIUM: "बायां हाथ, तेज़ मध्यम",
   LEFT_ARM_MEDIUM_FAST: "बायां हाथ, मध्यम तेज",
   LEFT_ARM_MEDIUM: "बायां हाथ, मध्यम",
   LEFT_ARM_MEDIUM_SLOW: "बायां हाथ, मध्यम धीमा",
   LEFT_ARM_SLOW_MEDIUM: "बायां हाथ, धीमा माध्यम",
   LEFT_ARM_SLOW: "बायां हाथ, धीमा",
   RIGHT_ARM_OFF_BREAK: "दाहिना हाथ, ऑफ ब्रेक",
   RIGHT_ARM_LEG_BREAK: "दाहिना हाथ, पैर टूट गया",
   RIGHT_ARM_LEG_BREAK_GOOGLY: "दाहिना हाथ, पैर ब्रेक गुगली",
   LEFT_ARM_ORTHODOX: "बायां हाथ, रूढ़िवादी",
   LEFT_ARM_WRIST_SPIN: "बायां हाथ, कलाई घूमना",
   LEFT_ARM_GOOGLY: "बायां हाथ, गुगली",
   MEDIUM_FACE: "मध्यम चेहरा"
};

export const NotificationTypeLogos = {
   COMMUNITY_LOGO: 'https://storage.googleapis.com/meta11/serviceplatform/NotificationType04_2.svg',
   FAVORITE_PLAYER_LOGO: "https://storage.googleapis.com/meta11/serviceplatform/NotificationType05.svg",
   FAVOURITE_TEAM_LOGO: "https://storage.googleapis.com/meta11/serviceplatform/NotificationType05.svg",
   MATCH_START_LOGO: "https://storage.googleapis.com/meta11/serviceplatform/NotificationType03.svg",
   PASSWORD_CHANGE_LOGO: "https://storage.googleapis.com/meta11/serviceplatform/NotificationType02.svg",
   QUESTIONNAIRE_LOGO: "https://storage.googleapis.com/meta11/serviceplatform/NotificationType01.svg",
   OMNI_PURCHASE_LOGO: CreditIcon,
   LEADERBOARD_RANK_REWARD: RewardsNotifi,
   ABOUT_TO_EXPIRE_FANTASY_PLAYER_BOOSTER: BoostTimeoutWarning,
   FANTASY_PLAYER_BOOSTER_EXPIRATION: BoostEndIcon,
   FANTASY_PLAYER_BOOSTER_PURCHASING: BoostStart
};

export const BattingStylesEN = {
   RIGHT_HAND: "RIGHT-HANDER",
   LEFT_HAND: "LEFT-HANDER"
};

export const BattingStyleEN = {
   RIGHT_HAND: "RIGHT  HANDER",
   LEFT_HAND: "LEFT  HANDER"
};

export const BattingStyleHI = {
   RIGHT_HAND: "दाएँ हाथ वाला",
   LEFT_HAND: "बायां हाथ"
};

export const BattingStylesHI = {
   RIGHT_HAND: "दाएँ हाथ वाला",
   LEFT_HAND: "बायां हाथ"
};

export const PlayingHandEN = {
   RIGHT_HAND: "RIGHT-HAND",
   LEFT_HAND: "LEFT-HAND"
};
export const PlayingHandHI = {
   RIGHT_HAND: "दांया हाथ",
   LEFT_HAND: "बायां हाथ"
};


export const PlayerHittingRanks = {
   ROOKIE: "ROOKIE",
   NOVICE: "NOVICE",
   SKILLED: "SKILLED",
   CHAMPION: "CHAMPION",
   LEGEND: "LEGEND"
}

export const PlayerHittingRanksHI = {
   ROOKIE: "धोखेबाज़",
   NOVICE: "नौसिखिए",
   SKILLED: "कुशल",
   CHAMPION: "चैंपियन",
   LEGEND: "दंतकथा"
}

export const StrikeRateHI = {
   WEAK: "कमज़ोर",
   MODERATE: "मध्यम",
   GOOD: "अच्छा",
   STRONG: "मज़बूत",
   EXCELLENT: "उत्कृष्ट"
};

export const StrikeRateEN = {
   WEAK: "WEAK",
   MODERATE: "MODERATE",
   GOOD: "GOOD",
   STRONG: "STRONG",
   EXCELLENT: "EXCELLENT"
};

export const FavLengthEN = {
   YORKER_LENGTH: "YORKER LENGTH",
   FULL: "FULL",
   FULL_TOSS: "FULL TOSS",
   GOOD: "GOOD",
   SHORT_PITCH: "SHORT PITCH",
   BOUNCER: "BOUNCER",
   SLOT: "SLOT"
};

export const FavLengthHI = {
   YORKER_LENGTH: "यॉर्कर लंबाई",
   FULL: "भरा हुआ",
   FULL_TOSS: "पूर्ण टॉस",
   GOOD: "अच्छा",
   SHORT_PITCH: "छोटी पिच",
   BOUNCER: "शेख़ीबाज़",
   SLOT: "छेद"
};

export const AVAILABILITY_CHECK_STATUS = {
   AVAILABLE: "AVAILABLE",
   UNAVAILABLE: "UNAVAILABLE",
   IN_PROGRESS: "IN_PROGRESS",
   ERROR: "ERROR",
}

export const NOTIFICATION_TYPES = {
   ABOUT_TO_EXPIRE_FANTASY_PLAYER_BOOSTER: "Boost Expiring Soon",
   FANTASY_PLAYER_BOOSTER_EXPIRATION: "Boost Expired",
   FANTASY_PLAYER_BOOSTER_PURCHASING: "Boost Purchase",
   FAVORITE_PLAYER: "Favourite Player",
   FAVOURITE_TEAM: "Favourite Team",
   LEADERBOARD_RANK_REWARD: "Leaderboard Rank",
   LEADERBOARD_RANK_REWARD_MATCH: "Match Reward",
   MATCH_START: "Match Start",
   OMNI_PURCHASE: "Omni Purchase",
   OMNI_REWARD: "Omni Reward",
   PASSWORD_CHANGE: "Password Change",
}
