export const pubNubConfigConstants = {

   FEATURED_TOURNAMENTS: "FeaturedTournaments-all-0-2",
   MAIN_LEADERBOARD: "main-leaderboard-0-9-RANK",
   MATCH_DATA: "MatchData",
   LEADERBOARD: "Leaderboard",
   ONGOING_TOURNAMENTS: "TournamentPage-all-0-5",
   TOURNAMENT_MATCH: "Matches",
   TOURNAMENT_REWARD: "rewards",
   TOURNAMENT_TEAM_POINTS: "tournament_team_points",
   USER_NOTIFICATION_HI: "Notification-hi",
   USER_NOTIFICATION_EN: "Notification-en",
   MATCHES: "Matches",
   LIVE_CHAT_RECEIVE: "LiveChatReceive",
   PREDICTION: "prediction"

}
