import React, {useEffect, useState} from "react";
import PubNub from "pubnub";
import {PUBNUB_KEYS} from "../../utilities/Constants";
import {connect} from "react-redux";
import {parseISO} from 'date-fns';
import {englishDataset, englishRecommendedTransformers, RegExpMatcher, TextCensor,} from 'obscenity';
import {pubNubConfigConstants} from "../../utilities/PubNubConfigConstants";

const PubNubLiveChatHandler = (props) => {

   const [channels, setChannels] = useState([pubNubConfigConstants.LIVE_CHAT_RECEIVE]);
   const [startTimeToken, setStartTimeToken] = useState(null);
   const [endTimeToken, setEndTimeToken] = useState(null);
   const [timeTokens, setTimeTokens] = useState([])
   const [currentTime, setCurrentTime] = useState(null);
   const [reactions, setReactions] = useState(null);

   //TODO userName inject
   let pubnub;
   // Create the PubNub instance outside the useEffect
   pubnub = new PubNub({
      publishKey: PUBNUB_KEYS.PUBLISH_KEY,
      subscribeKey: PUBNUB_KEYS.SUBSCRIBE_KEY,
      uuid: props.isAuthenticated ? props.userName : localStorage.getItem("uuid"),
      authKey: props.token, // Initialize with token, which may be null initially
   });

   useEffect(() => {
      getReaction(startTimeToken, currentTime)
   }, [])

   useEffect(() => {
      getReaction(startTimeToken, currentTime)
   }, [props.fetchReactions])

   useEffect(() => {
      const listenerParams = {message: handleMessage};
      const presenceListener = {
         presence: function (presenceEvent) {
            if (presenceEvent.action === 'leave') {
               console.log('Connection lost:', presenceEvent.uuid);
               // Implement reconnect logic here
               pubnub.reconnect();
            }
         },
      };

      const statusListener = {
         status: function (statusEvent) {
            if (statusEvent.category === 'PNTimeoutCategory') {
               console.log('Timeout detected. Reconnecting...');
               // Implement reconnect logic here
               pubnub.reconnect();
            }
         },
      };

      pubnub.addListener(listenerParams);
      pubnub.addListener(presenceListener);
      pubnub.addListener(statusListener);
      pubnub.subscribe({channels});

      return () => {
         pubnub.unsubscribe({channels});
         pubnub.removeListener(listenerParams);
         pubnub.removeListener(presenceListener);
         pubnub.removeListener(statusListener);
      };
   }, [channels, props.isAuthenticated, props.userName]);

   useEffect(() => {
      if (timeTokens.length > 0) {
         let minToken = Math.min(...timeTokens);
         let maxToken = Math.max(...timeTokens);
         setStartTimeToken(minToken);
         setEndTimeToken(maxToken);
      }
   }, [timeTokens]);

   useEffect(() => {
      historyFetcher(true);
   }, [])

   const historyFetcher = async (fetch) => {
      if (fetch) {
         let start = startTimeToken?.toString();
         let end = endTimeToken?.toString();
         if (start == null) {
            pubnub.time((status, response) => {
               if (status.error) {
                  console.error(status.error);
               } else {
                  const currentTimetoken = response.timetoken;
                  setCurrentTime(currentTimetoken);
                  console.log('Current Timetoken:', currentTimetoken);
               }
            });

            pubnub.fetchMessages({
                  channels: ["LiveChatReceive"],
                  start: currentTime,
                  count: 10
               },
               async function async(status, response) {
                  if (response?.channels?.LiveChatReceive?.length > 0) {
                     let filtered = await response?.channels?.LiveChatReceive?.filter(
                        (message) => message.timetoken != startTimeToken
                     );
                     filtered?.sort((a, b) => b.timetoken - a.timetoken)?.map(event => {
                        handleHistoryMessage(event)
                     })
                     await getReaction(start, currentTime);
                  }
               }
            )

         } else {

            pubnub.fetchMessages({
                  channels: ["LiveChatReceive"],
                  start: start,
                  count: 10
               },
               async function async(status, response) {
                  if (response?.channels?.LiveChatReceive?.length > 0) {
                     let filtered = await response?.channels?.LiveChatReceive?.filter(
                        (message) => message.timetoken != startTimeToken
                     );
                     filtered?.sort((a, b) => b.timetoken - a.timetoken)?.map(event => {
                        handleHistoryMessage(event)
                     })
                     await getReaction(start, currentTime);
                  }
               }
            )

         }
      }
   }
   useEffect(() => {
      historyFetcher(props.fetchHistory)
   }, [props.fetchHistory])


   const handleMessage = event => {
      setTimeTokens(prevState => [...prevState, +event.timetoken])
      let message = event.message;
      const matcher = new RegExpMatcher({...englishDataset.build(), ...englishRecommendedTransformers});
      const censor = new TextCensor();
      const matches = matcher.getAllMatches(message?.message);

      let censored = {
         ...message,
         "message": censor.applyTo(message?.message, matches),
         "timestamp": parseISO(message?.timestamp),
         "timetoken": event.timetoken
      }
      props.onDataReceivedQuizbot(censored)
   }
   const handleHistoryMessage = (event) => {
      setStartTimeToken(null);
      setTimeTokens([])

      setTimeTokens(prevState => [...prevState, +event.timetoken])
      let message = event.message;
      const matcher = new RegExpMatcher({...englishDataset.build(), ...englishRecommendedTransformers});
      const censor = new TextCensor();
      const matches = matcher.getAllMatches(message?.message);

      let censored = {
         ...message,
         "message": censor.applyTo(message?.message, matches),
         "timestamp": parseISO(message?.timestamp),
         "timetoken": event.timetoken
      }

      props.onDataHistory(censored)
   }
   const handleReactions = (reactions) => {
      props.onReactionFetch(reactions)
   }

   const pubNubMessageSender = (message, channel) => {
      let jsonString = JSON.stringify(message);

      try {
         pubnub
            .publish({
               message: JSON.parse(jsonString),
               channel: channel,
            });
      } catch (e) {
         console.log(e)
      }

   }
   const pubNubSubscriber = (channel) => {

      pubnub.subscribe(channel)
      setChannels(prevState => [...prevState, channel])
      console.log("channels subscribe", channel)
   }

   const pubNubUnSubscriber = (channel) => {
      pubnub.unsubscribe(channel)
   }

   useEffect(() => {
      if (props.sendMessage != null) {
         pubNubMessageSender(props.sendMessage.message, props.sendMessage.channel)
      } else {
         console.log("message is null")
      }

   }, [props.sendMessage]);
   useEffect(() => {
      if (props.subscribe != null) {
         pubNubSubscriber(props.subscribe)
      }
   }, [props.subscribe]);

   useEffect(() => {
      if (props.unSubscribe != null) {
         pubNubUnSubscriber(props.unSubscribe)
      }
   }, [props.unSubscribe]);

   useEffect(() => {
      if (props?.msgAction != null && Object.keys(props.msgAction).length != 0) {
         addReaction(props.msgAction.channel, props.msgAction.messageTimetoken, props.msgAction.type, props.msgAction.value)
      }
   }, [props.msgAction])

   useEffect(() => {
      if (Object.keys(props.removeMsgAction).length != 0) {
         removeMsgReaction(props.removeMsgAction.channel, props.removeMsgAction.messageTimetoken, props.removeMsgAction.actionTimetoken);
         setReactions(prevReactions =>
            prevReactions.filter(reaction =>
               !(reaction.messageTimetoken === props.removeMsgAction.messageTimetoken &&
                  reaction.actionTimetoken === props.removeMsgAction.actionTimetoken)
            )
         );
      }
   }, [props.removeMsgAction])

   const addReaction = async (channel, messageTimeToken, type, value) => {
      if (!reactions?.some(reaction => reaction?.uuid == props.userName && reaction?.value == value && reaction?.messageTimetoken == messageTimeToken)) {
         try {
            const result = await pubnub.addMessageAction({
               channel: channel,
               messageTimetoken: messageTimeToken,
               action: {
                  type: type,
                  value: value,
               },
            });
            console.log("add", result)
            await getReaction(currentTime, startTimeToken)
         } catch (status) {
            console.log("failed reaction", messageTimeToken)
            if (type == "read") {
               setReactions(prev => [...prev, {
                  messageTimetoken: messageTimeToken,
                  type: type,
                  uuid: props.userName,
                  value: value,
                  actionTimetoken: true
               }])
            }
            console.log(status);
         }
      } else {
         console.log("reaction already added")
      }

   }
   const removeMsgReaction = async (channel, messageTimeToken, actionTimetoken) => {
      try {
         const result = await pubnub.removeMessageAction({
            channel: channel,
            messageTimetoken: messageTimeToken,
            actionTimetoken: actionTimetoken
         }).then(response => {
            console.log("response", response)
         });
         // getReaction(startTimeToken,currentTime)
      } catch (status) {
         console.log(status);
      }
   }
   const getReaction = async (start, end) => {
      try {
         await pubnub.getMessageActions({
            channel: "LiveChatReceive",
            end: currentTime,
            limit: 300,
         }).then(response => {
            if (response?.data?.length > 0) {
               console.log(response?.data)
               let reactionArray = response?.data?.filter(reaction => reaction?.value == "read" && reaction?.uuid == props.userName);
               let withoutRead = response?.data?.filter(reaction => reaction?.value != "read");
               let finalArray = [...withoutRead, ...reactionArray]
               setReactions(finalArray)
               handleReactions(finalArray);

            }
         })
         // console.log(result)

      } catch (status) {
         console.log(status);
      }
   }
   return (<></>)
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email
   };
}

export default connect(mapStateToProps)(PubNubLiveChatHandler);
