import React, {useEffect, useState} from "react";
import classNames from "classnames";
import styles from "../../containers/Match/Match.module.scss";
import {BsChevronDown} from "react-icons/bs";
import Table from "react-bootstrap/Table";
import {Link, useLocation} from "react-router-dom";
import classes from "./Scorecard.module.scss"
import "../../assets/css/global.css";
import {useTranslation} from "react-i18next";
import {getOverSummary} from "../../services/StatisticsService";
import {getBallActionOutcome} from "../../helpers/CommonFunctions";
import {getPlayingOrder} from "../../services/SchduleService";

const Scorecard = ({matchData}) => {
   const location = useLocation();
   const [inning, setInning] = useState(0);
   const [ShowManualChange, setShowManualChange] = useState(false);
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [inningExtras, setInningExtras] = useState({
      inning0: {
         Extras: 0,
         Byes: 0,
         LB: 0,
         NB: 0,
         WD: 0,
         P: 0,
      },
      inning1: {
         Extras: 0,
         Byes: 0,
         LB: 0,
         NB: 0,
         WD: 0,
         P: 0,
      },
   });
   let [loadingInProgress, setLoading] = useState(true);
   const [show, setShow] = useState("none");
   const [overSummary, setOverSummary] = useState(Array(12).fill(null));
   const [battingList, setBattingList] = useState(null);

   const updateOverSummary = (ballList) => {
      if (!ballList) return;

      const ballListObj = ballList?.reduce((obj, item) => {
         return {
            ...obj,
            [item?.numberOfOver]: {
               ...obj[item?.numberOfOver],
               [item?.ballOfOver]: item
            }
         }
      }, {})

      const overNumbers = Object.keys(ballListObj)?.sort((a, b) => Number(a) - Number(b))

      let updatedBallList = []

      overNumbers?.map((overNumber) => {
         const overData = ballListObj[overNumber];

         for (let i = 1; i <= 6; i++) {
            if (!overData[i]) {
               updatedBallList = [...updatedBallList, null]
            } else {
               updatedBallList = [...updatedBallList, overData[i]]
            }
         }

         return overNumber
      })

      const listLen = updatedBallList?.length || 0

      // if listLen is less than 7 means it only have balls of 1 over(current over)
      // so fill the last over balls with null
      if (listLen < 7) {
         setOverSummary([...Array(6).fill(null), ...updatedBallList, ...Array(6 - listLen).fill(null)])
      } else if (listLen < 13) {
         setOverSummary([...updatedBallList, ...Array(12 - listLen).fill(null)])
      }
   }

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);

   useEffect(() => {
      matchData?.matchId ? setLoading(false) : setLoading(true);
   }, [matchData?.matchId]);

   useEffect(() => {
      if (matchData?.matchInningList) {
         if (!loadingInProgress && matchData?.matchInningList[0] !== undefined) {
            let extra = 0;
            let wides = 0;
            let noBalls = 0;
            matchData?.matchInningList[0]?.playerBowlingProfileList?.map((player) => {
               extra += player.noBalls + player.wides;
               wides += player.wides;
               noBalls += player.noBalls;
               setInningExtras((prevState) => ({
                  ...prevState,
                  inning0: {
                     Extras: extra,
                     Byes: 0,
                     LB: 0,
                     NB: noBalls,
                     WD: wides,
                     P: 0,
                  },
               }));
            });
         }
         if (!loadingInProgress && matchData?.matchInningList[1] !== undefined) {
            // setInning(1);
            let extra = 0;
            let wides = 0;
            let noBalls = 0;
            matchData?.matchInningList[1]?.playerBowlingProfileList?.map((player) => {
               extra += player.noBalls + player.wides;
               wides += player.wides;
               noBalls += player.noBalls;
               setInningExtras((prevState) => ({
                  ...prevState,
                  inning1: {
                     Extras: extra,
                     Byes: 0,
                     LB: 0,
                     NB: noBalls,
                     WD: wides,
                     P: 0,
                  },
               }));
            });
         }
      }

   }, [matchData, loadingInProgress]);

   useEffect(() => {
      if (!ShowManualChange) {
         if (matchData?.matchInningList[1] !== undefined && matchData?.matchInningList[1] !== null && matchData?.live === true) {
            setInning(1);
         } else {
            setInning(0);
         }
      }
   }, [ShowManualChange, matchData]);

   useEffect(() => {
      const fetchOverSummary = async () => {
         getOverSummary(matchData?.matchId).then((res) => {
            if (res?.apiStatus && res?.matchOverSummaryDtoList) {
               const ballList = res?.matchOverSummaryDtoList || []
               updateOverSummary(ballList);
            }
         })
      }

      if (matchData?.matchId) {
         fetchOverSummary()
      }
   }, [matchData?.matchId]);

   useEffect(() => {
      if (matchData?.matchOverSummaryDtoList) {
         updateOverSummary(matchData?.matchOverSummaryDtoList);
      }
   }, [matchData?.matchOverSummaryDtoList]);

   useEffect(() => {
      const fetchBattingList = async () => {
         const teamId = inning === 0 ? matchData?.matchInningList[0]?.battingTeam?.teamId : matchData?.matchInningList[0]?.bowlingTeam?.teamId
         if (!teamId) return
         getPlayingOrder(matchData?.matchId, teamId)
            .then((res) => {
               if (res?.players) {
                  setBattingList(prevState => ({...prevState, [inning]: res.players}));
               }
            })
            .catch((err) => {
               console.log(err);
            })
      }

      if (matchData?.matchId && !battingList?.[inning]) {
         fetchBattingList();
      }
   }, [battingList, inning, matchData]);

   const handleDropDown = () => {
      if (show == "block") {
         setShow("none");
      } else if (show == "none") {
         setShow("block");
      }
   };
   return (

      <div className="col-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
         {!translationsLoaded ? <></> : <>
            {loadingInProgress || !matchData.matchInningList ? (
               <>
                  {
                     loadingInProgress ? <div className={classNames(styles.questWrap)}>
                        <div className={classNames(styles.content, "paddingScoreCard")}>
                           <h3>{t("SCORE_CARD.LOADING_SCOREBOARD_DATA")}</h3>
                        </div>
                     </div> : <> {!matchData.matchInningList ?
                        <div className={classNames(styles.questWrap)}>
                           <div className={classNames(styles.content, "paddingScoreCard")}>
                              <h3>{t("SCORE_CARD.SCOREBOARD_DATA_NOT_AVAILABLE_UNTIL_THE_MATCH_STARTS")}</h3>
                           </div>
                        </div> : <></>
                     }
                     </>
                  }
               </>

            ) : (
               <div className={classNames(styles.questWrap)}>
                  <div className={classNames(styles.header)}>
                     <h2>{t("SCORE_CARD.SCOREBOARD")}{" "}
                        {
                           matchData?.matchInningList?.length === 0 ? <></> :
                              <a className="teamDropdown">{matchData?.matchInningList[inning] === undefined || !matchData?.matchInningList[0].battingTeam?.teamId ? (
                                 <>{

                                    inning === 0 ? (<span className="dropImgWrap">

                                <Link to={{
                                   pathname: `/team/${matchData?.teamOne?.teamId}`,
                                   search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                }}>
                                  <img src={matchData?.teamOne?.teamPictureURL} style={{borderRadius: '50%'}}/>
                                </Link>
                              </span>) : (<span className="dropImgWrap">
                                <Link to={{
                                   pathname: `/team/${matchData?.teamTwo?.teamId}`,
                                   search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                }}>
                                  <img src={matchData?.teamTwo?.teamPictureURL} style={{borderRadius: '50%'}}/>
                                </Link>
                              </span>)
                                 } </>
                              ) : (
                                 <span className="dropImgWrap">
                                    <Link to={{
                                       pathname: `/team/${matchData?.matchInningList[inning]?.battingTeam?.teamId}`,
                                       search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                    }}>
                                      <img src={matchData?.matchInningList[inning]?.battingTeam?.teamPictureURL}
                                           style={{borderRadius: '50%'}}/>
                                    </Link>
                              </span>)}
                                 {matchData?.matchInningList[inning] === undefined || !matchData?.matchInningList[0].battingTeam?.teamId ? (
                                    <>{
                                       inning === 0 ? <label className={"body-text3 color-white"}
                                                             onClick={handleDropDown}>{matchData?.teamOne?.name}</label> :
                                          <label className={"body-text3 color-white"}
                                                 onClick={handleDropDown}>{matchData?.teamTwo?.name}</label>
                                    }</>

                                 ) : (
                                    <label className={"body-text3 color-white"}
                                           onClick={handleDropDown}>{matchData?.matchInningList[inning]?.battingTeam?.name}</label>
                                 )}
                                 <BsChevronDown onClick={handleDropDown}/>
                                 <ul style={{display: `${show}`, position: "absolute", width: "max-content"}}>
                                    <li className={inning == 0 ? styles.dropdownSelectedItem : ""} onClick={(e) => {
                                       e.preventDefault();
                                       setInning(0);
                                       setShowManualChange(true);
                                       setShow("none");
                                    }}>

                                       {matchData?.matchInningList[0]?.battingTeam?.teamId ? matchData?.matchInningList[0]?.battingTeam?.name : matchData?.teamOne?.name}</li>
                                    <li className={inning == 1 ? styles.dropdownSelectedItem : ""} onClick={(e) => {
                                       e.preventDefault();
                                       setInning(1);
                                       setShowManualChange(true);
                                       setShow("none");
                                    }}>
                                       {matchData?.matchInningList[0]?.battingTeam?.teamId ? matchData?.matchInningList[0]?.bowlingTeam?.name : matchData?.teamTwo?.name}
                                    </li>
                                 </ul>
                              </a>
                        }

                     </h2>
                  </div>
                  {/* ----------------------------------- */}
                  <div className={classNames(styles.matchsummaryWrapper, "matchsummary mt-3 mb-2")}>
                     <div className={classNames(styles.MatchSummaryHeader, "upperDiv font-italic row")}>
                        {matchData?.matchInningList[inning] == undefined || matchData?.matchInningList[inning]?.bowlerPositionOfOver == null || matchData?.matchInningList[inning]?.numberOfOvers == null ? (

                           <span className="statwrap m-0">
                                <div className="th-playe-name pl20">{t("SCORE_CARD.INNING_NOT_STARTED_YET")}</div>
                           </span>) : (
                           <div className={classNames(styles.Col1, "m-0 p-0")}>
                              <span className="statwrap m-0">
                                 <label className="stat-lbl p-0">{t("SCORE_CARD.TOTAL")} : </label>
                                 <span className="stat-val col p-0">
                                    {" "}
                                    {matchData?.matchInningList[inning]?.runs +
                                       "/" +
                                       matchData?.matchInningList[inning]?.wickets}
                                    &nbsp;

                                    (
                                    {(matchData?.matchInningList[inning]
                                       ?.bowlerPositionOfOver !== 6
                                       ? matchData?.matchInningList[inning]?.numberOfOvers -
                                       1
                                       : matchData?.matchInningList[inning]?.numberOfOvers) == -1 ? 0 : (matchData?.matchInningList[inning]?.bowlerPositionOfOver !== 6 ? matchData?.matchInningList[inning]?.numberOfOvers - 1 : matchData?.matchInningList[inning]?.numberOfOvers)}
                                    .
                                    {matchData?.matchInningList[inning]
                                       ?.bowlerPositionOfOver !== 6
                                       ? matchData?.matchInningList[inning]
                                          ?.bowlerPositionOfOver
                                       : 0}
                                    )
                                    {" "}
                                 </span>
                              </span>

                              <span className="statwrap  m-0">
                                 <label className="stat-lbl">{t("SCORE_CARD.RUN")} :</label>
                                 {(matchData?.matchInningList?.length > 0 && matchData?.matchInningList[inning]?.runs !== null && matchData?.matchInningList[inning]?.numberOfOvers != null) ? (
                                    <span className="stat-val p-0">
                                            {
                                               !isNaN(matchData?.matchInningList[inning]?.runs) && !isNaN(matchData?.matchInningList[inning]?.numberOfOvers) ?
                                                  (matchData?.matchInningList[inning]?.runs / matchData?.matchInningList[inning]?.numberOfOvers).toFixed(2) :
                                                  0
                                            }
                                    </span>
                                 ) : (
                                    <span className="stat-val p-0">0</span>
                                 )}
                              </span>
                           </div>
                        )}
                        {((matchData?.matchInningList[1] === undefined && inning === 0) || (matchData?.matchInningList[1] !== undefined && inning === 1)) &&
                           <div className={classNames(styles.OverSummary)}>
                              <div>
                                 <label className="stat-lbl col p-0">Current Over</label>
                                 <div className={classNames(styles.Over)}>
                                    {overSummary?.slice(6, 12).map((obj, index) => (
                                       <div className={classNames(styles.Ball)} key={index}>
                                          <div>{getBallActionOutcome(obj?.actionOutCome)}</div>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                              {overSummary[0] !== null && (
                                 <div>
                                    <label className="stat-lbl col p-0">Last Over</label>
                                    <div className={classNames(styles.Over)}>
                                       {overSummary?.slice(0, 6).map((obj, index) => (
                                          <div className={classNames(styles.Ball)} key={index}>
                                             <div>{getBallActionOutcome(obj?.actionOutCome)}</div>
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                              )}
                           </div>
                        }
                     </div>
                     <div className={classNames(styles.extrasRow, "statdiv font-italic")}>
                              <span className="statwrap" style={{justifyContent: "right", alignContent: "start"}}>
                                <span className="stat-lbl"
                                      style={{minWidth: "120px", width: "100%"}}>{t("SCORE_CARD.EXTRAS")}: <span
                                   className="stat-val">{" "}
                                   {inning == 0 ? inningExtras?.inning0?.Extras : inningExtras?.inning1?.Extras}</span></span>

                              </span>
                        <span className={classes.statwrap}>
                                <span className={classes.statlbl}>B :</span>
                                <span className={classes.statval}>
                                  {" "}{inning == 0 ? inningExtras?.inning0?.Byes : inningExtras?.inning1?.Byes}</span>
                              </span>
                        <span className={classes.statwrap}>
                                <span className={classes.statlbl}>LB :</span>
                                <span className={classes.statval}>
                                  {inning == 0 ? inningExtras?.inning0?.LB : inningExtras?.inning1?.LB}</span>
                              </span>
                        <span className={classes.statwrap}>
                                <span className={classes.statlbl}>NB :</span>
                                <span className={classes.statval}>
                                  {inning == 0 ? inningExtras?.inning0?.NB : inningExtras?.inning1?.NB}
                                </span>
                              </span>
                        <span className={classes.statwrap}>
                                <span className={classes.statlbl}>WD :</span>
                                <span className={classes.statval}>
                                  {inning == 0 ? inningExtras.inning0?.WD : inningExtras.inning1?.WD}
                                </span>
                              </span>
                        <span className={classes.statwrap}>
                                <span className={classes.statlbl}>P :</span>
                                <span className={classes.statval}>
                                  {inning == 0 ? inningExtras?.inning0?.P : inningExtras?.inning1?.P}
                                </span>
                              </span>
                     </div>
                  </div>
                  {/* ----------------------------------- */}
                  <div className={classNames(styles.content, styles.battersWrp,)} style={{padding: "0px"}}>

                     <Table striped bordered hover className="mt-2">
                        {/* <Table scoreTb>*/}
                        <thead>
                        <tr>
                           <th className="th-playe-name pl20 col-5"
                               style={{textAlign: "left"}}>{t("SCORE_CARD.BATTER")}</th>
                           <th></th>
                           <th className="td-highlited-txt font-italic">R</th>
                           <th className="font-italic">B</th>
                           <th className="font-italic">4S</th>
                           <th className="font-italic">6S</th>
                           <th className="font-italic">S/R</th>
                        </tr>
                        </thead>
                        <tbody>
                        <>
                           {(battingList?.[inning] === undefined || battingList?.[inning]?.length < 1) && (
                              <tr>
                                 <td colSpan={7} className="text-center">Loading...</td>
                              </tr>
                           )}
                           {battingList?.[inning]?.map((p) => {
                              const player = matchData?.matchInningList[inning]?.playerBattingProfileList?.find((obj) => obj?.battingPlayer?.playerId === p?.playerId);
                              const isCurrentBattingPlayer = matchData?.matchInningList[inning]?.currentBattingPlayer?.playerId && (matchData?.matchInningList[inning]?.currentBattingPlayer?.playerId === player?.battingPlayer?.playerId);

                              return (
                                 <tr key={player?.playerBattingProfileId}>
                                    <td className=" pl20 row">
                                                <span className="col-12 col-md-6 p-0" style={{textAlign: "left"}}>
                                                  <Link to={{
                                                     pathname: `/player/${p?.playerId}`,
                                                     search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                                  }} style={{
                                                     textDecoration: "none",
                                                     color: player ? "inherit" : "#95A6B7"
                                                  }}>
                                                    {p?.name}
                                                  </Link>
                                                </span>
                                       <span className="col-12 col-md-6 p-0"
                                             style={{textAlign: "left"}}>{player?.dismissalType === "BOWLED" ? "b" : ""}{" "}{player?.bowledBy}</span>
                                    </td>
                                    <td
                                       className={classes.currentPlayer}>{isCurrentBattingPlayer ?
                                       <span className={styles.teamBat}></span> : ""}</td>
                                    <td className={player ? "td-highlited-txt" : "def-text"}>{player?.runs ?? "-"}</td>
                                    <td className="def-text">{player?.bowls ?? "-"}</td>
                                    <td className="def-text">{player?.fours ?? "-"}</td>
                                    <td className="def-text">{player?.sixes ?? "-"}</td>
                                    <td
                                       className="def-text">{" "}{player?.runs >= 0 ? (player?.bowls === 0 ? 0 : ((player?.runs / player?.bowls) * 100)?.toFixed(2) || 0) : "-"}
                                    </td>
                                 </tr>
                              )
                           })}
                        </>
                        </tbody>
                     </Table>
                     {/* <div className={classNames(styles.matchsummaryWrapper, "matchsummary mt-3 mb-2")}>
                        <div className="upperDiv font-italic">
                           {matchData?.matchInningList[inning] == undefined || matchData?.matchInningList[inning]?.bowlerPositionOfOver == null || matchData?.matchInningList[inning]?.numberOfOvers == null ? (
                              <span className="statwrap m-0">
                                <div className="th-playe-name pl20">{t("SCORE_CARD.INNING_NOT_STARTED_YET")}</div>
                              </span>) : (
                              <span className="statwrap m-0">
                                <span className="stat-lbl col-4 p-0">{t("SCORE_CARD.TOTAL")} : </span>
                                <span className="stat-val col-8 p-0">
                                  {" "}
                                   <label className="scoreLbl1  m-0 p-0">
                                    {matchData?.matchInningList[inning]?.runs +
                                       "/" +
                                       matchData?.matchInningList[inning]?.wickets}
                                      &nbsp;

                                      (
                                      {(matchData?.matchInningList[inning]
                                         ?.bowlerPositionOfOver !== 6
                                         ? matchData?.matchInningList[inning]?.numberOfOvers -
                                         1
                                         : matchData?.matchInningList[inning]?.numberOfOvers) == -1 ? 0 : (matchData?.matchInningList[inning]?.bowlerPositionOfOver !== 6 ? matchData?.matchInningList[inning]?.numberOfOvers - 1 : matchData?.matchInningList[inning]?.numberOfOvers)}
                                      .
                                      {matchData?.matchInningList[inning]
                                         ?.bowlerPositionOfOver !== 6
                                         ? matchData?.matchInningList[inning]
                                            ?.bowlerPositionOfOver
                                         : 0}
                                      )
                                  </label>
                                   {" "}
                                </span>
                            </span>)}
                           <span className="statwrap" style={{justifyContent: "right"}}>
                              <span className="stat-lbl">{t("SCORE_CARD.RUN")}</span>
                              {(matchData?.matchInningList?.length > 0 && matchData?.matchInningList[inning]?.runs !== null && matchData?.matchInningList[inning]?.numberOfOvers != null) ? (
                                 <span className="stat-val">
                                            {
                                               !isNaN(matchData?.matchInningList[inning]?.runs) && !isNaN(matchData?.matchInningList[inning]?.numberOfOvers) ?
                                                  (matchData?.matchInningList[inning]?.runs / matchData?.matchInningList[inning]?.numberOfOvers).toFixed(2) :
                                                  0
                                            }
                                        </span>
                              ) : (
                                 <>
                                    <span className="stat-val">0</span>
                                 </>
                              )}
                            </span>
                        </div>
                        <div className={classNames(styles.extrasRow, "statdiv font-italic")}>
                              <span className="statwrap" style={{justifyContent: "right", alignContent: "start"}}>
                                <span className="stat-lbl" style={{minWidth: "120px", width: "100%"}}>{t("SCORE_CARD.EXTRAS")}: <span className="stat-val">{" "}
                                   {inning == 0 ? inningExtras?.inning0?.Extras : inningExtras?.inning1?.Extras}</span></span>

                              </span>
                           <span className={classes.statwrap}>
                                <span className={classes.statlbl}>B :</span>
                                <span className={classes.statval}>
                                  {" "}{inning == 0 ? inningExtras?.inning0?.Byes : inningExtras?.inning1?.Byes}</span>
                              </span>
                           <span className={classes.statwrap}>
                                <span className={classes.statlbl}>LB :</span>
                                <span className={classes.statval}>
                                  {inning == 0 ? inningExtras?.inning0?.LB : inningExtras?.inning1?.LB}</span>
                              </span>
                           <span className={classes.statwrap}>
                                <span className={classes.statlbl}>NB :</span>
                                <span className={classes.statval}>
                                  {inning == 0 ? inningExtras?.inning0?.NB : inningExtras?.inning1?.NB}
                                </span>
                              </span>
                           <span className={classes.statwrap}>
                                <span className={classes.statlbl}>WD :</span>
                                <span className={classes.statval}>
                                  {inning == 0 ? inningExtras.inning0?.WD : inningExtras.inning1?.WD}
                                </span>
                              </span>
                           <span className={classes.statwrap}>
                                <span className={classes.statlbl}>P :</span>
                                <span className={classes.statval}>
                                  {inning == 0 ? inningExtras?.inning0?.P : inningExtras?.inning1?.P}
                                </span>
                              </span>
                        </div>
                     </div> */}
                     <div className="bowlindWrapper">
                        <Table striped bordered hover>
                           <thead>
                           <tr className="font-italic">
                              <th className="th-playe-name pl20 text-left">{t("SCORE_CARD.BOWLING")}</th>
                              <th></th>
                              <th className="td-highlited-txt">O</th>
                              <th>M</th>
                              <th>R</th>
                              <th>W</th>
                              <th>NB</th>
                              <th>WD</th>
                              <th>E/R</th>
                           </tr>
                           </thead>
                           <tbody>
                           {matchData?.matchInningList[inning] == undefined || !matchData?.matchInningList[inning]?.bowlingTeam?.teamId ? (
                              <tr>
                                 <td className="th-playe-name pl20">{t("SCORE_CARD.YET_TO_BOWL")}</td>
                              </tr>
                           ) : (
                              <>
                                 {matchData?.matchInningList[inning]?.playerBowlingProfileList?.map((player) => (
                                    <tr key={player?.bowlingPlayer?.playerId}>
                                       <td className=" pl20">
                                                    <span className="tdmainTxt text-left">
                                                        <Link to={{
                                                           pathname: `/player/${player?.bowlingPlayer?.playerId}`,
                                                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                                        }} style={{textDecoration: "none"}}>
                                                        {player?.bowlingPlayer?.name}
                                                        </Link>
                                                    </span>
                                       </td>
                                       <td
                                          className={classes.currentPlayer}>{matchData?.matchInningList[inning]?.lastBowlingPlayer?.playerId == player?.bowlingPlayer?.playerId ?
                                          <span className={styles.teamBall}></span> : ""}</td>
                                       <td className="def-text">{" "}{Math.floor(player?.bowls / 6)}.{player?.bowls % 6}
                                       </td>
                                       <td className="def-text">{player?.maidens}</td>
                                       <td className="td-highlited-txt">{player?.runs}</td>
                                       <td className="def-text">{player?.wickets}</td>
                                       <td className="def-text">{player?.noBalls}</td>
                                       <td className="def-text">{player?.wides}</td>
                                       <td className="def-text">{" "}{(player?.runs / (player?.bowls / 6)).toFixed(2)}
                                       </td>
                                    </tr>
                                 ))}
                              </>
                           )}
                           </tbody>
                        </Table>
                     </div>
                  </div>
               </div>
            )}</>}
      </div>
   );
};

export default Scorecard;
