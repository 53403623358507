import {connect} from "react-redux";
import Classnames from "classnames";
import styles from "../SequenceLoginModal/SequenceLoginModal.module.scss";
import React, {useEffect, useState} from "react";
import CreditIcon from "../../../assets/images/icons/24_sprts_token.webp";
import * as WalletService from "../../../services/WalletService";
import {AUTHTOKEN_ERRORS, TRANSACTION_STATUS} from "../../../utilities/Constants";
import {sequence} from "0xsequence";
import {toast} from "react-toastify";
import {useLocation} from "react-router-dom";
import * as UserService from "../../../services/UserService";
import Styles from "../../../containers/Register/VerifyCode.module.css";
import CreditPackagesModal from "../Purchase/CreditPackagesModal/CreditPackagesModal";
import SequenceLoginModal from "../SequenceLoginModal/SequenceLoginModal";
import CreditPurchaseStatusModal from "../Purchase/CreditPurchaseStatusModal/CreditPurchaseStatusModal";
import {useTranslation} from "react-i18next";
import {decimalsWithoutRounding} from "../../../helpers/CommonFunctions";

function OMNICreditsBuyWithdrawalModal(props) {
   const {t} = useTranslation();
   const TOAST_ID = "toastId";
   const MINIMUM_WALLET_BALANCE = 30;

   const [showBuyWithDrawModal, setShowBuyWithDrawModal] = useState(true);
   const [showWithDrawModal, setShowWithDrawModal] = useState(false);
   const [showWithDrawOTPModal, setShowWithDrawOTPModal] = useState(false);
   const [showWithDrawSuccessModal, setShowWithDrawSuccessModal] = useState(false);

   const [loading, setLoading] = useState(false);
   const [calculateLoading, setCalculateLoading] = useState(false);
   const [latestMaticRate, setLatestMaticRate] = useState(0);
   const [latestUsdRate, setLatestUsdRate] = useState(0);
   const [walletDetails, setWalletDetails] = useState([]);
   const [withdrawableAmount, setWithdrawableAmount] = useState(0);
   const [withdrawAmount, setWithdrawAmount] = useState(0);
   const [calculatedMatic, setCalculatedMatic] = useState();
   const [cantWithdrawMsg, setCantWithdrawMsg] = useState("");
   const [address, setAddress] = useState('');
   const [userDetails, setUserDetails] = useState('')
   const location = useLocation();
   const inputRefs = [];
   const [verficationCode, setVerficationCode] = useState("");
   const [walletLoading, setWalletLoading] = useState(false);
   const [canWithdraw, setCanWithdraw] = useState(false);

   const [packageId, setPackageId] = useState(0);
   const [purchaseMaticAmount, setPurchaseMaticAmount] = useState(0);
   const [inputOmniValue, setInputOmniValue] = useState(0);
   const [isCreditPurchaseModalOpen, setIsCreditPurchaseModalOpen] = useState(false);
   const [isSequenceLoginModalOpen, setIsSequenceLoginModalOpen] = useState(false);
   const [isTransactionStatusModalOpen, setIsTransactionModalOpen] = useState(false);
   const [transactionHash, setTransactionHash] = useState(0);
   const [transactionAmount, setTransactionAmount] = useState(0);
   const [transactionStatus, setTransactionStatus] = useState(null);
   const [omniCreditsBalance, setOmniCreditsBalance] = useState(0);

   const handleSelectWithdraw = async () => {
      toast.info(t("USER_PROFILE_PAGE.EXCITING_NEWS_OMNI_CREDIT_WITHDRAWALS_WITH_A_30_CREDIT_MINIMUM_AVAILABLE_NEXT_UPDATE"));
      // const res = await WalletService.checkWithdrawEligibility(props.token);
      // if (res?.apiStatus) {
      //    setShowWithDrawModal(true);
      //    setShowBuyWithDrawModal(false);
      // } else {
      //    if (res?.data?.rejectMessages?.length > 0) {
      //       toast.error(res?.data?.rejectMessages[0], {
      //          toastId: TOAST_ID
      //       });
      //    }
      // }
   }

   const handleSelectBuy = () => {
      setIsCreditPurchaseModalOpen(true);
   }

   const handleWithdrawConfirm = async (walletAddress) => {
      if (cantWithdrawMsg || !withdrawAmount) {
         return;
      }
      const data = {
         description: "string",
         devDescription: "string",
         omniCredits: withdrawAmount,
         operation: "CREDIT",
         receiverUserName: "string",
         runningBalance: withdrawableAmount - withdrawAmount + MINIMUM_WALLET_BALANCE,
         senderUserName: "string",
         transactionDate: new Date(),
         transactionType: "WITHDRAW",
         userName: props?.userName,
         walletAddress: walletAddress
      }

      const res = await WalletService.sendWalletVerificationEmail(props?.token, data)

      if (res?.apiStatus) {
         setShowWithDrawOTPModal(true);
         setShowWithDrawModal(false);
      } else {
         if (res?.data?.rejectMessages?.length > 0) {
            toast.error(res?.data?.rejectMessages[0], {
               toastId: TOAST_ID
            });
         }
      }
   }

   const handleOtpSubmit = async () => {
      if (!verficationCode || verficationCode.length < 6) {
         return
      }
      const data = {
         code: verficationCode, userName: props.userName,
      }

      const res = await WalletService.verifyWithdrawCode(props.token, data);
      if (res?.apiStatus) {
         setShowWithDrawOTPModal(false);
         setShowWithDrawSuccessModal(true);
      } else {
         if (res?.data?.rejectMessages?.length > 0) {
            setCantWithdrawMsg(res?.data?.rejectMessages[0]);
         }
      }
   }

   const getTransactionRate = async () => {
      setCalculateLoading(true);
      await WalletService.getLatestExchangeRate().then(res => {
         if (res?.data?.apiStatus) {
            setLatestMaticRate(res?.data?.matic);
            setLatestUsdRate(res?.data?.usd);
            setLoading(false);
            setCalculateLoading(false);
         } else {
            setLoading(false);
            setCalculateLoading(false);
         }
      }).catch(e => {
         console.log(e);
         setCalculateLoading(false);
      })
   }

   const handleWithdrawAmountChange = (e) => {
      let value = e.target.value;

      // remove whitespaces
      value = value.replace(/\s/g, '');

      // remove leading zeros
      value = value.replace(/^0+/, '');

      // if the value is not a number
      if (isNaN(value) || value < 0) {
         setCantWithdrawMsg("Withdraw amount must be a positive number");
      } else if (value <= withdrawableAmount) {
         setWithdrawAmount(e.target.value);
         setCantWithdrawMsg("");
      } else {
         setCantWithdrawMsg(t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.YOU_CANT_WITHDRAW_THIS_AMOUNT"));
      }

   }

   const buySPRTHandler = () => {
      setIsCreditPurchaseModalOpen(true);
   }

   const showWithDrawOTPModalHandler = () => {
      setAddress("");
      setWalletLoading(true);

      async function connectWallet() {
         const wallet = await sequence.initWallet(process.env.REACT_APP_SEQUENCE_PROJECT_ACCESS_KEY, {
            defaultNetwork: 'polygon'
         });

         try {
            const connectDetails = await wallet.connect({
               app: 'Test Project - dev',
               askForEmail: true,
               authorize: true,
               // And pass settings if you would like to customize further
               settings: {
                  theme: "dark",
                  signInOptions: [
                     'email',
                     'google',
                     'apple'
                  ],
                  bannerUrl: "https://storage.googleapis.com/meta11/serviceplatform/WalletHeader.png",
                  includedPaymentProviders: ["moonpay"],
                  defaultFundingCurrency: "matic",
                  lockFundingCurrencyToDefault: true,
               }
            }).then((resp) => {
               console.log('---------------------------');
               console.log(resp);
            });
            const address = await wallet.getAddress();

            if (connectDetails.connected) {
               toast.success(t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.CONNECTED_TO_THE_WALLET_SUCCESSFULLY"));
               setAddress(address);
            }
         } catch (e) {
            console.log(e);
            setWalletLoading(false);
         }
      }

      connectWallet();

   }

   const handleInputChange = (e, index) => {

      e.target.value = e.target.value.replace(/[^0-9]/g, '');

      const inputLength = e.target.value.length;

      // Move focus to next input field when a digit is entered and input length is 1
      if (inputLength === 1 && index < inputRefs.length - 1) {
         inputRefs[index + 1].focus();
      }
      const updatedNumber = inputRefs.map((input) => input.value).join('');
      setVerficationCode(updatedNumber);
   };

   const loadingIndicator = (<div style={{textAlign: "center"}}>
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   useEffect(() => {
      const fetchData = () => {
         if (props.token && props.userName) {
            setCalculateLoading(true);
            WalletService.getOmniCreditsByUsername(props.token, props.userName)
               .then(res => {
                  console.log("setWalletDetails", res.data)
                  if (res?.data?.apiStatus) {
                     setWalletDetails(res.data);
                     console.log("setWalletDetails", res.data)
                     setOmniCreditsBalance(res.data?.omniCredits);
                     setWithdrawableAmount((res.data?.omniCredits) - MINIMUM_WALLET_BALANCE);
                     setCalculateLoading(false);
                  } else if (res?.status === 400 && res?.data?.rejectMessages[0] === AUTHTOKEN_ERRORS.AUTHENTICATION_TOKEN_HAS_EXPIRED) {
                     // props.onLogout();
                     setCalculateLoading(false);
                  }
               });
         }
      };

      fetchData();

   }, [props.token, props.userName, props.onLogout]);

   useEffect(() => {
      getTransactionRate();

      UserService.GetUserByUserName(props.userName, props.Token).then((response) => {
         if (response.apiStatus) {
            setUserDetails(response.user)
         }
      })
   }, []);

   useEffect(() => {
      if (withdrawableAmount < 0) {
         setWithdrawableAmount(0);
         setCalculatedMatic(0);
         setCanWithdraw(true)
      } else {
         const CalculatedMatic = withdrawableAmount * latestMaticRate;
         setCalculatedMatic(CalculatedMatic);
      }

   }, [withdrawableAmount, latestMaticRate]);

   function closeModal() {
      setIsCreditPurchaseModalOpen(false);
      setIsSequenceLoginModalOpen(false);
   }

   const openSequenceLoginModal = (packageId, maticValue, ominiCreditValue) => {
      setPackageId(packageId);
      setPurchaseMaticAmount(maticValue);
      setInputOmniValue(parseFloat(ominiCreditValue))
      setIsSequenceLoginModalOpen(true);
   }

   const handleGoBack = () => {
      setIsSequenceLoginModalOpen(false);
      setIsCreditPurchaseModalOpen(true);
   }

   const handleTransactionStatus = (transactionStatus, transactionAmount, transactionHash) => {
      setTransactionAmount(transactionAmount);
      setTransactionHash(transactionHash);
      setIsTransactionModalOpen(true);

      if (transactionStatus === TRANSACTION_STATUS.SUCCESS) {
         setTransactionStatus(true);
      } else {
         setTransactionStatus(false);
      }
   }

   const closeAllModals = () => {
      setIsCreditPurchaseModalOpen(false);
      setIsSequenceLoginModalOpen(false);
      setIsTransactionModalOpen(false);
      props.handleClose();
   }

   async function connectWallet() {
      const wallet = await sequence.initWallet(process.env.REACT_APP_SEQUENCE_PROJECT_ACCESS_KEY, {
         defaultNetwork: 'amoy' //prod->'polygon' , QA-> 'amoy'
      });
      const connectDetails = await wallet.connect({
         app: 'Meta11 Wallet',
         askForEmail: true,
         authorize: true,
         // And pass settings if you would like to customize further
         settings: {
            theme: "dark",
            signInOptions: [
               'email',
               'google',
               'apple'
            ],
            bannerUrl: "https://storage.googleapis.com/meta11/serviceplatform/WalletHeader.png", // 3:1 aspect ratio, 1200x400 works best
            includedPaymentProviders: ["transak"],
            defaultFundingCurrency: "matic",
            lockFundingCurrencyToDefault: true,
         }
      });

      const walletAddress = await wallet.getAddress();

      if (connectDetails.connected && walletAddress) {
         toast.success(t("SEQUENCE_LOGIN_MODAL.CONNECTED_TO_THE_WALLET_SUCCESSFULLY"));
         setWalletDetails(connectDetails);
         setAddress(walletAddress);
         handleWithdrawConfirm(walletAddress);
      }
   }

   return (
      <React.Fragment>
         <div className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal)}
              id="exampleModalCenter"
              tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
              style={{display: "block"}}>
            {showBuyWithDrawModal ?
               <div className={Classnames(styles.SequenceLoginModal, "modal-dialog modal-dialog-centered")}
                    role="document">
                  <div className={Classnames("modal-content", "p-4", styles.modalContentContainer)}>
                     {calculateLoading ? loadingIndicator : <>
                        <div className="w-100 p-2">
                           <button className={Classnames(styles.closeButton, "close")} type="button"
                                   onClick={props.handleClose} data-dismiss="modal" aria-label="Close">
                         <span className={styles.closeButton} aria-hidden="true">
                            <img alt='Close Icon'
                                 src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                         </span>
                           </button>
                        </div>
                        <div className={Classnames(styles.UserProfileModalWrapper)}>
                           <div className={Classnames(styles.circle1)}></div>
                           <div className={Classnames(styles.circle2)}></div>
                           <div className={Classnames(styles.circle3)}></div>
                           <div className={Classnames("position-relative")}>
                              <div className="row p-0">
                                 <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                                    {/* <img src={DeleteAccHeaderImg} className={Classnames(styles.DeleteAccHeaderImg)}/> */}
                                    <h4>{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.BUY_WITHDRAW")}</h4>
                                 </div>
                              </div>
                              <div className="row p-0">
                                 <div className={Classnames(styles.popText)}>
                                    <div className="text-center pb-2">
                                       <div className="w-100 mt-2">1 <img src={CreditIcon}
                                                                          alt={""}/> = {(latestMaticRate).toFixed(4)} Matic
                                       </div>
                                       <div className="w-100">
                                          <button type="button" className={Classnames(styles.BuyButton, "mt-2")}
                                                  onClick={handleSelectBuy}>{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.BUY_$SPRTS_TOKENS")}</button>
                                       </div>
                                    </div>
                                    <div className={Classnames(styles.borderTop2, "text-center pt-4 mt-4")}>
                                       <div
                                          className="w-100 mt-2">{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.YOU_CAN_WITHDRAW")} {decimalsWithoutRounding(withdrawableAmount, 1)}{" "}
                                          <img src={CreditIcon}
                                               alt={""}/> {t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.AND_GET")} {calculatedMatic?.toFixed(1)} Matic
                                       </div>
                                       <div className="w-100">
                                          <button type="button" className={Classnames(styles.BuyButton, "mt-2")}
                                                  disabled={canWithdraw}
                                                  onClick={handleSelectWithdraw}>{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.WITHDRAW_$SPRTS_TOKENS")}</button>
                                       </div>
                                       <div
                                          className="mt-3 mb-3 text-l d-flex">{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.A_MINIMUM_OF_30_$SPRTS_TOKENS_SHOULD_REMAIN_IN_YOUR_WALLET")}</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </>}
                  </div>
               </div> : showWithDrawModal ?
                  <div className={Classnames(styles.SequenceLoginModal, "modal-dialog modal-dialog-centered")}
                       role="document">
                     <div className={Classnames("modal-content", "p-4", styles.modalContentContainer)}>
                        <div className="w-100 p-2">
                           <button className={Classnames(styles.closeButton, "close")} type="button"
                                   onClick={props.handleClose} data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
                           </button>
                        </div>
                        <div className={Classnames(styles.UserProfileModalWrapper)}>
                           <div className={Classnames(styles.circle1)}></div>
                           <div className={Classnames(styles.circle2)}></div>
                           <div className={Classnames(styles.circle3)}></div>
                           <div className="row p-0">
                              <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                                 <h4>{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.WITHDRAW")}</h4>
                              </div>
                           </div>
                           <div className="row p-0 col-10">
                              <div>
                                 <div className={Classnames("text-center ")}>
                                    <div className="w-100 mt-2 text-start">{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.AVAILABLE_AMOUNT")}&nbsp;:
                                       &nbsp;&nbsp;<img src={CreditIcon} alt={""}/>&nbsp;
                                       {decimalsWithoutRounding(omniCreditsBalance, 1)}
                                    </div>
                                    <div
                                       className="w-100 mt-2 text-start">{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.PERMITTED_AMOUNT")}&nbsp;:
                                       &nbsp;&nbsp;<img src={CreditIcon} alt={""}/>&nbsp;
                                       {decimalsWithoutRounding(withdrawableAmount, 1)}
                                    </div>
                                    <div className={Classnames(styles.popText, "mt-3 mb-3 text-l d-flex")} style={{fontSize: '14px'}}>
                                       **{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.A_MINIMUM_OF_30_$SPRTS_TOKENS_SHOULD_REMAIN_IN_YOUR_WALLET")}
                                    </div>
                                    <div className="mt-4 row">
                                       <div
                                          className="p-0 float-start w-50 mt-2 d-flex">{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.ENTER_AMOUNT_TO_WITHDRAW")}</div>
                                       <input type="text" className={Classnames(styles.unscrew, "float-start")}
                                              onChange={handleWithdrawAmountChange}></input>
                                       <div className={Classnames(styles.DivHigh)}>{cantWithdrawMsg ? <div
                                          className={Classnames(styles.TextError, "p-0 float-start mt-2")}>{cantWithdrawMsg}</div> : <></>}</div>
                                    </div>


                                    <div className={Classnames("text-center pt-4 mb-4 row")}>
                                       <div className="w-100">
                                          <button type="button" className={Classnames(styles.GrayButton, "mt-2")}
                                                  onClick={props.handleClose}>
                                             <text>{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.CANCEL")}</text>
                                          </button>
                                          <button type="button" className={Classnames(styles.BuyButton, "mt-2 ms-3")}
                                                  style={{cursor: (cantWithdrawMsg || !withdrawAmount) ? "not-allowed" : "pointer"}}
                                                  disabled={cantWithdrawMsg || !withdrawAmount}
                                                  onClick={connectWallet}>
                                             {t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.CONNECT")}
                                          </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  : showWithDrawOTPModal ?
                     <div className={Classnames(styles.SequenceLoginModal, "modal-dialog modal-dialog-centered")}
                          role="document">
                        <div className={Classnames("modal-content", "p-4", styles.modalContentContainer)}>
                           <div className="w-100 p-2">
                              <button className={Classnames(styles.closeButton, "close")} type="button"
                                      onClick={props.handleClose} data-dismiss="modal" aria-label="Close">
                                           <span className={styles.closeButton} aria-hidden="true">
                                              <img alt='Close Icon'
                                                   src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                                           </span>
                              </button>
                           </div>
                           <div className={Classnames(styles.UserProfileModalWrapper)}>
                              <div className={Classnames(styles.circle1)}></div>
                              <div className={Classnames(styles.circle2)}></div>
                              <div className={Classnames(styles.circle3)}></div>
                              <div className="row p-0">
                                 <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                                    <h4>{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.WITHDRAW")}</h4>
                                 </div>
                              </div>
                              <div className="row p-0">
                                 <div className={Classnames(styles.popText)}>
                                    <div className={Classnames("text-center  col-10 row")}>
                                       <div
                                          className="mt-3 mb-3">{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.WE_HAVE_SENT_A_CODE_TO_YOUR_EMAIL")}
                                          <span>{userDetails.email}</span>. {t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.PLEASE_ENTER_THE_CODE_BELOW_TO_CONFIRM_WITHDRAWAL_OF")} {withdrawAmount}{' '}
                                          <img
                                             src={CreditIcon} alt={""}/></div>
                                       <div className="d-flex justify-content-center">
                                          {Array.from({length: 6}, (_, i) => (<input
                                             key={i}
                                             type=""
                                             name="name"
                                             placeholder=""
                                             size="1"
                                             autoComplete="off"
                                             maxLength={1}
                                             className={Classnames(Styles.NumIn)}
                                             ref={(input) => (inputRefs[i] = input)} // Create a reference to the input field
                                             onChange={(e) => handleInputChange(e, i)} // Call handleInputChange on input change
                                          />))}
                                       </div>
                                       {cantWithdrawMsg ? <div
                                          className={Classnames(styles.TextError, "p-0 float-start mt-2")}>{cantWithdrawMsg}</div> : <></>}
                                       <div className={Classnames("text-center pt-4 mb-4 w-100")}>
                                          <button type="button" className={Classnames(styles.GrayButton, "mt-2")}
                                                  onClick={props.handleClose}>
                                             <text>{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.CANCEL")}</text>
                                          </button>
                                          <button type="button" className={Classnames(styles.BuyButton, "mt-2 ms-3")}
                                                  style={{cursor: (!verficationCode || verficationCode.length < 6) ? "not-allowed" : "pointer"}}
                                                  onClick={handleOtpSubmit}>{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.CONFIRM")}</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     : showWithDrawSuccessModal ?
                        <div className={Classnames(styles.SequenceLoginModal, "modal-dialog modal-dialog-centered")}
                             role="document">
                           <div className={Classnames("modal-content", "p-4", styles.modalContentContainer)}>
                              <div className="w-100 p-2">
                                 <button className={Classnames(styles.closeButton, "close")} type="button"
                                         onClick={props.handleClose} data-dismiss="modal" aria-label="Close">
                                           <span className={styles.closeButton} aria-hidden="true">
                                              <img alt='Close Icon'
                                                   src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                                           </span>
                                 </button>
                              </div>
                              <div className={Classnames(styles.UserProfileModalWrapper)}>
                                 <div className={Classnames(styles.circle1)}></div>
                                 <div className={Classnames(styles.circle2)}></div>
                                 <div className={Classnames(styles.circle3)}></div>
                                 <div className="row p-0">
                                    <div className="d-flex justify-content-center">
                                       <img src={"https://storage.googleapis.com/meta11/serviceplatform/PinkGirl.webp"}
                                            alt={""} className={Classnames(styles.PopImg)}/>
                                    </div>
                                    <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                                       <h4>{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.WITHDRAW")}</h4>
                                    </div>
                                 </div>
                                 <div className="row p-0">
                                    <div className={Classnames(styles.popText)}>
                                       <div className={Classnames("text-center col-10 row")}>
                                          <div className="mt-3 mb-3">
                                             {t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.YOUR_WITHDRAWAL_REQUEST_IS_SUCCESSFUL_OUR_TEAM_WILL_TRANSFER_THE_FUNDS_WITHIN_2_3_BUSINESS_DAYS")}
                                          </div>
                                          <div className="w-100 mb-4">
                                             <button type="button" className={Classnames(styles.BuyButton, "mt-2")}
                                                     onClick={props.handleClose}>{t("OMNI_CREDITS_BUY_WITHDRAWAL_MODAL.OKAY")}</button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> : <></>}
         </div>
         <>
            {isCreditPurchaseModalOpen ?
               <CreditPackagesModal handleClose={closeModal} handleProceedClose={openSequenceLoginModal}/> : <></>}

            {isSequenceLoginModalOpen ?
               <SequenceLoginModal handleClose={closeModal} handleGoBack={handleGoBack} packageId={packageId}
                                   amount={purchaseMaticAmount} transactionStatus={handleTransactionStatus}
                                   ominiCreditValue={inputOmniValue}/> : <></>}

            {isTransactionStatusModalOpen && transactionStatus != null ?
               <CreditPurchaseStatusModal transactionStatus={transactionStatus} transactionAmount={transactionAmount}
                                          transactionHash={transactionHash}
                                          closeAllModals={closeAllModals} sprtsAmount={inputOmniValue}></CreditPurchaseStatusModal> : <></>}
         </>
      </React.Fragment>);
}

const mapStateToProps = (state) => {
   return {
      token: state.auth.accessToken,
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      oAuthProviderType: state.auth.oAuthProviderType,
      userName: state.auth.userName,
      name: state.auth.name,
      email: state.auth.email,
   };
};

export default connect(mapStateToProps)(OMNICreditsBuyWithdrawalModal);
