export const USER_PROFILE_PAGE = Object.freeze({
   SHOW_USER_PROFILE_LEADERBOARD: 'SHOW_USER_PROFILE_LEADERBOARD',
   SHOW_MY_FAVOURITES: 'SHOW_MY_FAVOURITES',
   SHOW_USER_PROFILE_NOTIFICATIONS: 'SHOW_USER_PROFILE_NOTIFICATIONS',
   SHOW_USER_PROFILE_ADVERTISEMENT_URL: 'SHOW_USER_PROFILE_ADVERTISEMENT_URL',
   OWNED: 'OWNED',
   BOT_HISTORY: 'BOT_HISTORY',
   QBOT_MATCH_HISTORY_SHOW_AFTER: 'QBOT_MATCH_HISTORY_SHOW_AFTER',
   SOCIAL_SHARE_BUTTON: 'SOCIAL_SHARE_BUTTON'
})

export const MATCH_PAGE = Object.freeze({
   SHOW_MATCH_LEADERBOARD: 'SHOW_MATCH_LEADERBOARD',
   SHOW_FANTASYBOT_OR_QUIZBOT: 'SHOW_FANTASYBOT_OR_QUIZBOT',
   SHOW_MATCH_ADVERTISEMENT_URL: 'SHOW_MATCH_ADVERTISEMENT_URL',
   SOCIAL_SHARE_BUTTON: 'SOCIAL_SHARE_BUTTON',
   HOW_DOES_BOT_WORK_MODAL: "HOW_DOES_BOT_WORK_MODAL",
   UP_COMING_MATCH_INTRO_MODAL: "UP_COMING_MATCH_INTRO_MODAL",
   DISABLE_QBOT: "DISABLE_QBOT",
   YOUTUBE_VIDEO_ID: "YOUTUBE_VIDEO_ID"
})

export const SCHEDULED_MATCH_SECTION_PAGE = Object.freeze({
   SCHEDULED_MATCH_SECTION_PAGE_LEADERBOARD: 'SCHEDULED_MATCH_SECTION_PAGE_LEADERBOARD'
})


export const TEAM_PROFILE_PAGE = Object.freeze({
   SHOW_KITS_SPONSORS: 'SHOW_KITS_SPONSORS',
   SHOW_NEWS: 'SHOW_NEWS'
})

export const MATCHUPS_PAGE = Object.freeze({
   SHOW_MATCH_UPS_ADVERTISEMENT_URL: 'SHOW_MATCH_UPS_ADVERTISEMENT_URL',
})

export const PLAYERS_PAGE = Object.freeze({
   SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL: 'SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL',
   SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL2: 'SHOW_PLAYERS_PAGE_ADVERTISEMENT_URL2'
})

export const LEADERBOARD_PAGE = Object.freeze({
   LEADERBOARD_TOURNAMENT_ID: 'LEADERBOARD_TOURNAMENT_ID',
   SOCIAL_SHARE_BUTTON: 'SOCIAL_SHARE_BUTTON',
   LINE_CHART_LEADERBOARD: 'LINE_CHART_LEADERBOARD'
})

export const METALUX_TIMER = Object.freeze({
   METALUX_TIMER_TIMESTAMP: 'METALUX_TIMER_TIMESTAMP',
   SHOW_METALUX_TIMER: 'SHOW_METALUX_TIMER',
   METALUX_TIMER_TOURNAMENTID: 'METALUX_TIMER_TOURNAMENTID',
   METALUX_REDIRECT_URL: 'METALUX_REDIRECT_URL'
})

export const NAVBAR = Object.freeze({
   SHOW_NAVBAR_TOURNAMENTS_LINK: 'SHOW_NAVBAR_TOURNAMENTS_LINK',
   SHOW_NAVBAR_MATCHUPS_LINK: 'SHOW_NAVBAR_MATCHUPS_LINK',
   SHOW_NAVBAR_TEAMS_LINK: 'SHOW_NAVBAR_TEAMS_LINK',
   SHOW_NAVBAR_LEADERBOARD_LINK: 'SHOW_NAVBAR_LEADERBOARD_LINK',
   SHOW_NAVBAR_FANTASY_LINK: 'SHOW_NAVBAR_FANTASY_LINK',
})

export const STREAM_CHAT = Object.freeze({
   VERIFIED_USERS: 'VERIFIED_USERS',
   ENABLE_CHAT_WITH_AI: 'ENABLE_CHAT_WITH_AI'
})

export const GUIDANCE_TIP = Object.freeze({
   GUIDANCE_TIP_SHOW: 'GUIDANCE_TIP_SHOW'
})

export const WALLET = Object.freeze({
   XP_TO_SPRTS_RATIO: 'XP_TO_SPRTS_RATIO',
   BIRDEYE_API_KEY: 'BIRDEYE_API_KEY',
   BIRDEYE_WALLET_ADDRESS: 'BIRDEYE_WALLET_ADDRESS'
})

export const FANTASY_TEAM = Object.freeze({
   MAX_PLAYER_COUNT: 'MAX_PLAYER_COUNT'
})

export const BOOST = Object.freeze({
   EXTRA_TIME: 'BOOSTER_EXTRA_TIME'
})
