import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export function getTeamKitSponsors(type, teamId, size, page, sort) {
   // https://qa.meta11.com/api/digitalAssets/team/kit-sponsor/SPONSOR?page=0&size=9&sort=id%2Cdesc&team=3
   const url = `${ApiEndpoints.GET_TEAM_KIT_SPONSORS_BY_TEAMID}/${type}?page=${page}&size=${size}&sort=${sort}&team=${teamId}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}


export const getTeamProfileDetailsById = (teamId, lang) => {
   const url = `${ApiEndpoints.GET_TEAM_BY_TEAMID}/${teamId}?lang=${lang}`
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getTeamLatestNews = (teamId, page, size, sort) => {
   // https://qa.meta11.com/api/digitalAssets/team/news?page=0&size=10&sort=id%2Cdesc&team=3
   const url = `${ApiEndpoints.GET_TEAM_NEWS_BY_TEAMID}?page=${page}&size=${size}&sort=${sort}&team=${teamId}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getAllPlayersCards = (lang, attribute, page, size, sort) => {
   // https://qa.meta11.com/api/digitalAssets/player/card?attribute=pla15&page=0&size=9&sort=playerId%2Cdesc
   const url = `${ApiEndpoints.GET_ALL_PLAYERS_CARDS}?lang=${lang}&attribute=${attribute}&page=${page}&size=${size}&sort=${sort}`;
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getFavouritePlayerCardsByUserName = (userName, page, size, sort) => {
   // https://qa.meta11.com/api/digitalAssets/player/card/favourite/1?page=0&size=9&sort=playerId%2Cdesc
   const url = `${ApiEndpoints.GET_FAVOURITE_PLAYER_CARDS_BY_USER_ID}/${userName}?page=${page}&size=${size}&sort=${sort}`;
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getFavouriteTeamCardsByUserName = (userName, page, size, sort) => {
   // https://qa.meta11.com/api/digitalAssets/player/card/favourite/1?page=0&size=9&sort=playerId%2Cdesc
   const url = `${ApiEndpoints.GET_FAVOURITE_TEAM_CARDS_BY_USER_ID}/${userName}?page=${page}&size=${size}&sort=${sort}`;
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getPlayerLatestNews = (playerId, page, size, sort) => {
   // https://qa.meta11.com/api/digitalAssets/team/news?page=0&size=10&sort=id%2Cdesc&team=3
   const url = `${ApiEndpoints.GET_PLAYER_NEWS_BY_PLAYER_ID}?page=${page}&size=${size}&sort=${sort}&team=${playerId}`;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const getPlayerCardById = (playerId, lang) => {
   // https://qa.meta11.com/api/digitalAssets/player/card?attribute=pla15&page=0&size=9&sort=playerId%2Cdesc
   const url = `${ApiEndpoints.GET_ALL_PLAYERS_CARDS}/${playerId}?lang=${lang}`;
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
export const getAllTeamsCardsAssetData = (search, page, posts, lang) => {
   return AxiosInstance()
      .get(`${ApiEndpoints.GET_TEAM_PROFILE_CARDS}?attribute=${search}&lang=${lang}&page=${page}&size=${posts}`)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getPlayerProfileById = (playerId, lang) => {

   const url = `${ApiEndpoints.GET_PLAYER_PROFILE_BY_ID}/${playerId}?lang=${lang}`;
   return AxiosInstance().get(url).then((response) => {
      return response.data;
   })
      .catch((error) => {
         return error.response;
      })
}
