import React from 'react';

// Function to render the text with images
const RenderTextWithImages = ({text}) => {
   // Use a regular expression to identify the img tags and replace them with React <img /> components
   const parseTextWithImages = (text) => {
      if (!text) {
         return [];
      }

      const imgTagRegex = /<img\s+src="([^"]+)"\s*\/?>/g;

      // Replace img tags with JSX <img /> elements
      const parts = text.split(imgTagRegex).map((part, index) => {
         if (part.match(/^https?:\/\//)) {
            // If the part is a URL, return an img tag
            return <img key={index} src={part} alt="dynamic-img" style={{height: '20px'}}/>;
         } else {
            // Otherwise, return the text part
            return part;
         }
      });

      return parts;
   };

   return (
      <div>
         {parseTextWithImages(text).map((part, index) => (
            <React.Fragment key={index}>{part}</React.Fragment>
         ))}
      </div>
   );
};


export default RenderTextWithImages;
