import React from "react";
import Styles from "../../containers/Match/Match.module.scss";
import Classnames from "classnames";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";
import {getConf} from "../../services/ConfigService";
import {MATCH_PAGE} from "../../utilities/ConfigConstants";
import YouTubeStream from "../YouTubeStream/YouTubeStream";

const TwitchStream = () => {

   return (
      <React.Fragment>
         <div className={Classnames(Styles.Radial1)}></div>
         <div className={Classnames(Styles.Radial2)}></div>
         <div className={Classnames(Styles.termsAndConditionsPage)}>
            <div className={Classnames(Styles.bodyPrivacy)}>
               <div className={Classnames(Styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
                  <div className="col-12 p-0 row">
                     <NavbarSecondary page="events"/>
                  </div>
                  <div className={Classnames(Styles.pageWrapper, "col-12 col-md-9 row mt-2 p-0")}>
                     {getConf(MATCH_PAGE.YOUTUBE_VIDEO_ID) ?
                        <YouTubeStream videoId={getConf(MATCH_PAGE.YOUTUBE_VIDEO_ID)}/> :
                        <ReactTwitchEmbedVideo
                           width={"100%"}
                           height={"500"}
                           layout={"video"}
                           channel={process.env.REACT_APP_TWITCH_CHANNEL_NAME}
                        />}
                  </div>
               </div>
            </div>
         </div>
      </React.Fragment>
   );
};

export default TwitchStream;
