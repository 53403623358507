import React, {useEffect, useState} from "react";
import {BsXLg} from "react-icons/bs";
import {useTranslation} from "react-i18next";

const HowToWinRewards = ({handleMoreInfoClose}) => {
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>);

   return (
      <div className="qBotFaqModal">
         {!translationsLoaded ? <>{loadingIndicator}</> :
            <>
               <a className="closeModal" onClick={() => handleMoreInfoClose()} style={{cursor: 'pointer'}}>
                  <BsXLg/>
               </a>
               <h1 className="modal-main-header mb-3">{t("HOW_TO_WIN_REWARDS.HOW_TO_WIN_REWARDS")}</h1>
               <div className="modal-topic-big text-center mb-3 color-white">{t("HOW_TO_WIN_REWARDS.UNLOCK_YOUR_POTENTIAL_WITH_META_11_REWARDS")} </div>

               <div className="modal-topic-small color-white mt-4">
                  {t("HOW_TO_WIN_REWARDS.OMNI_CREDITS_DEFINITION")} 💳
               </div>
               <div className="modal-Para-small text-start">
                  {t("HOW_TO_WIN_REWARDS.OMNI_CREDITS_ARE_THE_DIGITAL_CURRENCY_THAT_POWERS_TRANSACTIONS_AND_REWARDS_ON_META_11")}
                  {t("HOW_TO_WIN_REWARDS.THEY_SERVE_AS_DIGITAL_TOKENS_OF_VALUE_THAT_CAN_BE_USED_FOR_TRANSACTIONS_PARTICIPATION_IN_TOURNAMENTS")}
               </div>
               <div className="modal-topic-small color-white mt-4">
                  {t("HOW_TO_WIN_REWARDS.WHAT_CAN_YOU_DO_WITH_OMNI_CREDITS")} ❓
               </div>
               <div className="modal-Para-small text-start">
                  {t("HOW_TO_WIN_REWARDS.WITH_OMNI_CREDITS_YOU_CAN_PARTICIPATE_IN_TOURNAMENTS_WITH_LIMITED_ACCESS(PAY_2_PLAY)AND_MUCH_MORE")}
                  {t("HOW_TO_WIN_REWARDS.WONDERING_HOW_TO_GET_STARTED_HERES_HOW_YOU_CAN_WIN_OMNI_CREDITS_AND_UNLOCK_EXCITING_REWARDS")}
               </div>
               <div className="modal-topic-small color-white mt-4">
                  {t("HOW_TO_WIN_REWARDS.HOW_TO_EARN_THEM")}🎮
               </div>
               <div className="modal-Para-small text-start">
                  {t("HOW_TO_WIN_REWARDS.PLAY_QBOT_TO_EARN_OMNI_POINTS_IF_YOURE_LUCKY_AND_KNOW_ABOUT_CRICKET_YOU_WILL_GET_AROUND_30_POINTS_PER_MATCH")}
                  {t("HOW_TO_WIN_REWARDS.THESE_POINTS_ARE_THEN_CONVERTED_TO_OMNI_CREDITS_AND_WILL_BE_ADDED_TO_YOUR_WALLET")}
                  {t("HOW_TO_WIN_REWARDS.AFTERWARDS_YOU_CAN_USE_OMNI_CREDITS_TO_ENTER_PAY_2_PLAY_TOURNAMENTS")}
                  <br/><br/>
                  {t("HOW_TO_WIN_REWARDS.PARTICIPATE_IN_TOURNAMENTS")} 🏅:
                  {t("HOW_TO_WIN_REWARDS.COMPETE_IN_OUR_PAY_2_PLAY_TOURNAMENTS_AND_AIM_FOR_THE_TOP_3_SPOTS_TO_WIN_EARN_OMNI_CREDITS")}
                  {t("HOW_TO_WIN_REWARDS.IF_YOURE_NOT_IN_THE_TOP_3_DONT_WORRY_WEVE_GOT_YOU_COVERED")}
                  {t("HOW_TO_WIN_REWARDS.50_OF_THE_USERS_IN_THE_TOURNAMENT_ALWAYS_GET_REWARDED")}
               </div>

               <div className="modal-Para-small mt-4">
                  <ol>
                     <li>{t("HOW_TO_WIN_REWARDS.PAY_THE_GIVEN_AMOUNT_OF_OMNI_CREDITS_TO_GAIN_ACCESS_FOR_A_PAID_PAY_2_PLAY_TOURNAMENT")}</li>
                     <li>{t("HOW_TO_WIN_REWARDS.ONCE_THE_TOURNAMENT_MATCHES_BEGIN_YOU_CAN_TAKE_PART_AND_PLAY_THE_QBOT")}
                        {t("HOW_TO_WIN_REWARDS.OMNI_POINTS_WHICH_YOU_EARN_FROM_THE_PAY_2_PLAY_TOURNAMENT_WONT_BE_CONVERTED_TO_OMNI_CREDITS")}
                     </li>
                     <li>{t("HOW_TO_WIN_REWARDS.THE_LEADERBOARD_POSITION_CHANGES_BASED_ON_PERFORMANCE_WITH_REWARDS_INCREASING_AS_MORE_PARTICIPANTS_JOIN_AND_ONLY_THE_TOP_50_WILL_RECEIVE_REWARDS")}</li>
                     <li>{t("HOW_TO_WIN_REWARDS.AFTER_PAY_2_PLAY_TOURNAMENT_ENDS_WINNERS_WILL_BE_ANNOUNCED_AND_OMNI_CREDITS_WILL_BE_DISTRIBUTED_BASED_ON_THE_USER'S_LEADERBOARD_POSITION")}
                        {t("HOW_TO_WIN_REWARDS.YOULL_RECEIVE_AN_EMAIL_AND_NOTIFICATIONS_ABOUT_YOUR_REWARDS")}
                     </li>
                  </ol>
               </div>
               <div className="modal-Para-small text-start">
                  {t("HOW_TO_WIN_REWARDS.50_OF_THE_USERS_IN_THE_TOURNAMENT_ALWAYS_GET_REWARDED")}
               </div>


               <div className="modal-topic-small color-white mt-4">
                  {t("HOW_TO_WIN_REWARDS.PURCHASING_OMNI_CREDITS")} 💰
               </div>
               <div className="modal-Para-small text-start">
                  {t("HOW_TO_WIN_REWARDS.IF_YOURE_LOOKING_TO_BOOST_YOUR_OMNI_CREDITS_BALANCE")}
               </div>
               <div className="modal-Para-small mt-4">
                  <ol>
                     <li>{t("HOW_TO_WIN_REWARDS.LOG_IN_TO_META_11_PORTAL_USING_GOOGLE,FACEBOOK,OR_EMAIL_AND_SELECT_THE_WALLET(SECTION_WITH_OMNI_CREDIT_ICON)LOCATED_IN_THE_NAVIGATION_BAR_TO_PURCHASE_OMNI_CREDITS")}</li>
                     <li>{t("HOW_TO_WIN_REWARDS.SELECT_THE_OMNI_CREDITS_AMOUNT_AND_CLICK_PROCEED_THEN_THE_LOGIN_POP_UP_APPEARS_FOR_AUTHENTICATION")} </li>
                     <li>{t("HOW_TO_WIN_REWARDS.AFTER_AUTHENTICATION_YOU_CAN_BUY_MATIC_USING_DEBIT/CREDIT_CARDS_OR_EXISTING_CRYPTO_IN_YOUR_SEQUENCE_WALLET_TO_COMPLETE_THE_TRANSACTION")}
                        {t("HOW_TO_WIN_REWARDS.ALSO_YOU_CAN_USE_UPAY_QR_PAYMENT_WHICH_IS_SUPPORTED_ONLY_FOR_INDIA")}</li>
                     <li>{t("HOW_TO_WIN_REWARDS.PURCHASED_OMNI_CREDITS_WILL_BE_DEPOSITED_TO_YOUR_WALLET_WITHIN_FEW_MINUTES")}</li>
                  </ol>
               </div>


               <div className="modal-topic-small color-white mt-4">
                  {t("HOW_TO_WIN_REWARDS.WITHDRAWING_OMNI_CREDITS")} 💰
               </div>
               <div className="modal-Para-small text-start">
                  {t("HOW_TO_WIN_REWARDS.IF_YOURE_LOOKING_TO_USE_YOUR_OMNI_CREDITS_IN_THE_REAL_WORLD")}
               </div>
               <div className="modal-Para-small mt-4">
                  <ol>
                     <li>{t("HOW_TO_WIN_REWARDS.LOG_IN_TO_META_11_PORTAL_USING_GOOGLE,FACEBOOK,OR_EMAIL_AND_THEN_SELECT‘MY_PROFILE’FROM_THE_NAVIGATION_BAR_DROPDOWN_AFTER_LOGGING/SIGNING_IN")} </li>
                     <li>{t("HOW_TO_WIN_REWARDS.GO_TO_YOUR_PROFILE_AND_SELECT_OMNI_CREDITS_WITHDRAWAL_OPTION")} </li>
                     <li>{t("HOW_TO_WIN_REWARDS.AFTER_ENTERING_YOUR_SEQUENCE_WALLET_ADDRESS,YOUR_OMNI_CREDITS_WILL_BE_SENT_TO_YOU_AS_MATIC_COINS_WITHIN_2_3_BUSINESS_DAYS")}</li>
                  </ol>
               </div>

               <div className="modal-Para-small text-center">
                  {t("HOW_TO_WIN_REWARDS.REMEMBER_THE_MORE_YOU_ENGAGE_THE_MORE_YOU_EARN")}
                  {t("HOW_TO_WIN_REWARDS.JOIN_META_11_GET_REWARDED")} 🚀
               </div>
            </>}
      </div>
   )
}

export default HowToWinRewards;
