import {useEffect, useState} from "react";
import PubNub from "pubnub";
import {PUBNUB_KEYS} from "../../utilities/Constants";
import {connect} from "react-redux";
import {pubNubConfigConstants} from "../../utilities/PubNubConfigConstants";

const FeaturedTournamentsHandler = (props) => {
   const [channels, setChannels] = useState([pubNubConfigConstants.FEATURED_TOURNAMENTS]);

   let pubnub;
   // Create the PubNub instance outside the useEffect
   pubnub = new PubNub({
      publishKey: PUBNUB_KEYS.PUBLISH_KEY,
      subscribeKey: PUBNUB_KEYS.SUBSCRIBE_KEY,
      uuid: localStorage.getItem("uuid"),
      authKey: props.pubNubToken,
   });

   useEffect(() => {
      const handleMessage = (event) => {
         let message = event.message;
         props.onRecieved(message);
      };

      const handleStatus = (statusEvent) => {
         if (statusEvent.category === "PNConnectedCategory") {
            console.log("Successfully subscribed to channels:", channels);
         }
         if (statusEvent.error) {
            console.error("PubNub status error:", statusEvent);
         }
      };

      const listenerParams = {
         message: handleMessage,
         status: handleStatus,
      };

      pubnub.addListener(listenerParams);
      pubnub.subscribe({channels});
      console.log("Attempting to subscribe to channels:", channels);

      return () => {
         console.log("Unsubscribing from channels:", channels);
         pubnub.unsubscribe({channels});
         pubnub.removeListener(listenerParams);
      };
   }, [channels, props.isAuthenticated, props.userName]);

   const pubNubMessageSender = (message, channel) => {
      pubnub.publish({
         message: message,
         channel: channel,
      }, (status, response) => {
         if (status.error) {
            console.error("PubNub publish error:", status);
         } else {
            console.log("Message published successfully:", response);
         }
      });
   };

   const pubNubSubscriber = (channel) => {
      console.log("Subscribing to channel:", channel);
      pubnub.subscribe({channels: [channel]});
      setChannels([channel]);

      // Check and log subscribed channels
      const subscribedChannels = pubnub.getSubscribedChannels();
      console.log("Currently subscribed channels after subscribing:", subscribedChannels);
   };

   const pubNubUnSubscriber = (channel) => {
      console.log("Unsubscribing from channel:", channel);
      pubnub.unsubscribe({channels: [channel]});

      // Check and log subscribed channels
      const subscribedChannels = pubnub.getSubscribedChannels();
      console.log("Currently subscribed channels after unsubscribing:", subscribedChannels);
   };

   useEffect(() => {
      if (props.sendMessage) {
         pubNubMessageSender(props.sendMessage.message, props.sendMessage.channel);
      } else {
         console.log("sendMessage prop is null");
      }
   }, [props.sendMessage]);

   useEffect(() => {
      if (props.unSubscribe) {
         pubNubUnSubscriber(props.unSubscribe);
      }
   }, [props.unSubscribe]);

   return null;
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email
   };
}

export default connect(mapStateToProps)(FeaturedTournamentsHandler);
