import Classnames from "classnames";
import styles from "./SequenceLoginModal.module.scss";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {sequence} from "0xsequence";
import {toast} from "react-toastify";
import {SequenceIndexer} from '@0xsequence/indexer';
import {ethers} from "ethers";
import {connect} from "react-redux";
import {TRANSACTION_STATUS} from "../../../utilities/Constants";

function SequenceLoginModal(props) {

   const indexer = new SequenceIndexer('https://polygon-indexer.sequence.app', process.env.REACT_APP_SEQUENCE_PROJECT_ACCESS_KEY);
   const {t} = useTranslation();
   const [wallet, setWallet] = useState();
   const [chainId, setChainId] = useState(80002);
   const [walletDetails, setWalletDetails] = useState();
   const [address, setAddress] = useState('');
   const [isConnected, setIsConnected] = useState(false);
   const [walletBalance, setWalletBalance] = useState(0);
   const [transactionAmount, setTransactionAmount] = useState(0);
   const [isWalletOpened, setIsWalletOpened] = useState(false);

   useEffect(() => {
      try {
         const fetchData = async () => {
            await indexer.getEtherBalance({accountAddress: address})
               .then((balance) => {
                  setWalletBalance(balance.balance.balanceWei);
               })
         };

         fetchData();

      } catch (e) {
         console.log(e);
      }
   }, [address]);

   useEffect(() => {

      const convertOmniCreditToWei = () => {
         // 1 MATIC is equal to 1e18 Wei
         // eslint-disable-next-line no-undef
         const weiPerMatic = 1000000000000000000; // 10^18
         // eslint-disable-next-line no-undef
         const maticAmountBigInt = props.amount;
         setTransactionAmount(Math.trunc(maticAmountBigInt * weiPerMatic));
      }

      convertOmniCreditToWei();

   }, []);


   async function connectWallet() {

      setIsWalletOpened(true);
      const wallet = await sequence.initWallet(process.env.REACT_APP_SEQUENCE_PROJECT_ACCESS_KEY, {
         defaultNetwork: 'polygon' //prod->'polygon' , QA-> 'amoy'
      });
      const connectDetails = await wallet.connect({
         app: 'META11 Wallet',
         askForEmail: true,
         authorize: true,
         // And pass settings if you would like to customize further
         settings: {
            theme: "dark",
            signInOptions: [
               'email',
               'google',
               'apple'
            ],
            bannerUrl: "https://storage.googleapis.com/meta11/serviceplatform/WalletHeader.png", // 3:1 aspect ratio, 1200x400 works best
            includedPaymentProviders: ["transak", "moonpay"],
            defaultFundingCurrency: "matic",
            lockFundingCurrencyToDefault: true,
         }
      });

      const address = await wallet.getAddress();

      if (connectDetails.connected) {
         toast.success(t("SEQUENCE_LOGIN_MODAL.CONNECTED_TO_THE_WALLET_SUCCESSFULLY"));
         setIsConnected(true);
         setWalletDetails(connectDetails);
         setWallet(wallet);
         setAddress(address);
      }
   }

   async function sendTx() {
      // Part of the ERC20 ABI, so we can encode a `transfer` call
      const erc20Interface = new ethers.utils.Interface([
         'function makePayment(string calldata targetGameAccount, uint256 omniPackage) external payable'
      ])

      const wallet = sequence.getWallet()
      const signer = wallet.getSigner()

      // Sending 1.50 USDC, note USDC has 6 decimal places
      //const amount = ethers.WeiPerEther('1.50')

      // Encode an ERC-20 token transfer to recipient of the specified amount
      //m11QA username, packageId
      const data = erc20Interface.encodeFunctionData('makePayment', [props.userName, props.packageId])

      // Prepare Transaction object ,wei
      const tx: sequence.transactions.Transaction = {
         to: process.env.REACT_APP_SEQUENCE_CONTRACT_ADDRESS,
         data: data,
         value: '' + transactionAmount + ''
      }

      // Send the transaction via the signer to the blockchain :D The signer will prompt the user
      // sign+send the transaction, and once the user confirms it will be transmitted.
      await signer.sendTransaction(tx).then((txnResp => {
         if (txnResp.hash) {
            console.log('transaction hash:', txnResp.hash);
            toast.success(t("SEQUENCE_LOGIN_MODAL.TRANSACTION_HAS_BEEN_COMPLETED_SUCCESSFULLY"));
            props.transactionStatus(TRANSACTION_STATUS.SUCCESS, props.amount, txnResp.hash, props.ominiCreditValue);
         } else {
            toast.error(t("SEQUENCE_LOGIN_MODAL.TRANSACTION_WAS_FAILED_PLEASE_TRY_AGAIN_LATER"));
            props.transactionStatus(TRANSACTION_STATUS.ERROR, props.amount, txnResp.hash, props.ominiCreditValue);
         }
      })).catch((error) => {
         toast.error(error);
      })
   }

   async function openWallet() {
      if (isConnected) {
         wallet?.openWallet();
      }
   }

   return (
      <div className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal)} id="exampleModalCenter"
           tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
         <div className={Classnames(styles.SequenceLoginModal, "modal-dialog modal-dialog-centered")} role="document">
            <div className={Classnames("modal-content", "p-4", styles.modalContentContainer)}>
               <div className="w-100 p-2">
                  <button className={Classnames(styles.closeButton, "close")} type="button" onClick={props.handleClose} data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
                  </button>
               </div>
               <div className={Classnames(styles.UserProfileModalWrapper)}>
                  <div className={Classnames(styles.circle1)}></div>
                  <div className={Classnames(styles.circle2)}></div>
                  <div className={Classnames(styles.circle3)}></div>
                  <div className="row p-0">
                     <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                        {/* <img src={DeleteAccHeaderImg} className={Classnames(styles.DeleteAccHeaderImg)}/> */}
                        <h4>{t("SEQUENCE_LOGIN_MODAL.YOU_HAVE_REQUESTED")}<br/>{(props.ominiCreditValue)?.toFixed(4)} {t("SEQUENCE_LOGIN_MODAL.$SPRTS_CREDITS")}</h4>
                     </div>
                  </div>
                  <div className="row p-0">
                     <div className="col-md-12">
                        <p className="m-0">{t("SEQUENCE_LOGIN_MODAL.PLEASE_LOGIN_TO_SEQUENCE_AND_USE_YOUR_FAVORITE_TRANSACTION_METHOD_TO_GET_MATIC_AND_COMPLETE_YOUR_TRANSACTION")}</p>
                     </div>
                  </div>
               </div>

               <div className={Classnames(styles.photoModifyModelBtnRow, "p-3")}>

                  {isConnected ? <>
                        {walletBalance > transactionAmount ?
                           <button onClick={sendTx} type="button" className={Classnames(styles.LoginButton, 'float-right')}>{t("SEQUENCE_LOGIN_MODAL.PROCEED_TRANSACTION")}</button> :
                           <div>
                              <div className={styles.warnningTex}>{t("SEQUENCE_LOGIN_MODAL.NOT_ENOUGH_WALLET_BALANCE_TO_PROCEED")}</div>
                              <div>
                                 <button onClick={openWallet} type="button" className={Classnames(styles.LoginButton, 'float-right')}>{t("SEQUENCE_LOGIN_MODAL.OPEN_WALLET")}</button>
                              </div>
                           </div>
                        }
                     </> :
                     <button onClick={connectWallet} type="button" className={Classnames(styles.LoginButton, 'float-right')}>{t("SEQUENCE_LOGIN_MODAL.SEQUENCE_LOGIN")}</button>

                  }
                  <button type="button" className={styles.BlackSkBtn}
                          onClick={props.handleGoBack}>
                     <span>{t("SEQUENCE_LOGIN_MODAL.GO_BACK")}</span>
                  </button>
               </div>

            </div>
         </div>
      </div>
   );
}

const mapStateToProps = (state) => {
   return {
      token: state.auth.accessToken, isAuthenticated: state.auth.accessToken !== null, profileImage: state.auth.imageURL, oAuthProviderType: state.auth.oAuthProviderType, userName: state.auth.userName, name: state.auth.name
   };
};

export default connect(mapStateToProps)(SequenceLoginModal);
