import React, {useEffect, useState} from "react";
import styles from "./ScheduledMatch.module.scss";
import classNames from "classnames";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import * as ScheduleService from "../../services/SchduleService"
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AdvertisementSpace from "../../components/AdvertisementSpace/AdvertisementSpace";
import {ADVERTISEMENT_IMAGES} from "../../utilities/Constants";

const ScheduledMatch = (props) => {
   let navigate = useNavigate()
   let {matchId} = useParams();
   const [ScheduledMatchData, setScheduledMatchData] = useState({})
   const [teams, setTeams] = useState([])
   const [pageLang, setPageLang] = useState(localStorage.getItem('language'));
   useEffect(() => {
      const fetchData = async () => {
         const MatchData = ScheduleService.getScheduledMatch(matchId, pageLang);
         MatchData.then((response) => {
            if (response.apiStatus) {
               setScheduledMatchData(response.match)
               setTeams(response.match.teamProfiles)
            }
         })
      }
      fetchData()
   }, [])


   const TimestampConverter = (timeStamp) => {

      const date = new Date(timeStamp);
      const options = {
         year: "numeric",
         month: "long",
         day: "numeric",
         hour: "numeric",
         minute: "numeric",
         timeZone: "GMT"
      };
      const formattedDate = date.toLocaleDateString("en-US", options);

      return <>{formattedDate}</>;
   }

   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
         }}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);
   return (
      <React.Fragment>
         <div className="pb-4">
            <div className="row gx-4">
               <div className="col-12">
                  <div className="container">
                     <div className="row gx-4">
                        <div className="col-12">
                           <NavbarSecondary page="matches"/>
                        </div>
                     </div>
                     {!translationsLoaded ? <> {loadingIndicator} </> :
                        <>
                           <div className="col-12 p-0 pt-5 row">
                              <div className="text-start col-12 p-0 pb-3">
                                 <button onClick={() => navigate("/matches")}
                                         className={classNames(styles.flatBtn, "float-left", "p-2", "ps-3", "pe-3")}
                                         style={{width: "100px"}}><i
                                    class="fa-solid fa-angle-left"></i>&nbsp;&nbsp;{t("SCHEDULED_MATCH.BACK")}
                                 </button>
                              </div>
                              <div className="col-9 p-0 pt-1 row">
                                 <div className="col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 p-0 pe-0 pe-sm-0 pe-md-3 mb-4">
                                    <div className={classNames(styles.ProfilePane, "p-3", "pt-1", "pb-1")}>

                                       <div className="col-12 row p-3">
                                          <div className="col-2 p-0"><img width={50} src={`${teams[0]?.pictureURL}`}></img>
                                          </div>
                                          <div
                                             className={classNames(styles.text14OSI, "col-6", "pl-2")}>{teams[0]?.teamName}</div>
                                          <div className="col-4 text-end p-0">
                                             <font className={classNames(styles.text24SolinaItaBold)}>0/0</font><br/>
                                             <font className={classNames(styles.text12OS)}>(0.0)</font>
                                          </div>
                                       </div>
                                       <div className="col-12 row p-3">
                                          <div className="col-2 p-0"><img width={50} src={`${teams[1]?.pictureURL}`}></img>
                                          </div>
                                          <div className={classNames(styles.text14OSI, "col-6", "pl-2")}>{teams[1]?.teamName}</div>
                                          <div className="col-4 text-end p-0">
                                             <font className={classNames(styles.text24SolinaItaBold)}>0/0</font><br/>
                                             <font className={classNames(styles.text12OS)}>(0.0)</font>
                                          </div>
                                       </div>
                                    </div>

                                    <div className={classNames(styles.ProfilePane, "p-0", "pt-1", "pb-1", "mt-4")}>
                                       <div className={classNames(styles.Text20Solina, "p-3")} style={{borderBottom: "1px solid #FFFFFF0F"}}>{t("SCHEDULED_MATCH.OVERVIEW")}</div>
                                       <div className={classNames(styles.text14OS, "pt-3", "pb-3", "p-3")} style={{lineHeight: "20px"}}>
                                          Start:<br/>
                                          <div className={classNames(styles.text14OSW, "pt-2", "pb-2")}><img
                                             src="https://storage.googleapis.com/meta11/serviceplatform/fi_calendar.webp"></img>&nbsp;{TimestampConverter(ScheduledMatchData.startTime)}
                                          </div>
                                          {t("SCHEDULED_MATCH.WATCH_THE_LATEST_MATCH_IN_THE_SERIES_OF_SEMIFINALS_MUMBAI_ROYALTIES_AGAINST_BANGALORE_WOLVES")}
                                          {t("SCHEDULED_MATCH.CHECK_THE_SERIOUS_CONFRONTATION_BETWEEN_THE_TOP_PLAYERS_FROM_BOTH_TEAMS")}
                                          {t("SCHEDULED_MATCH.WHO_WILL_BE_THE_WINNER, ONLY_TIME_AND_SKILLS_WILL_SHOW")}

                                       </div>

                                    </div>


                                 </div>
                                 <div className="col-12 col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 p-0 ps-0 ps-sm-0 ps-md-2 pe-0 pe-sm-0 pe-md-0 mb-4">
                                    <div className={classNames(styles.ProfilePane, "p-0", "pt-1")}>
                                       <div className={classNames(styles.Text24Solina, "p-3")}
                                            style={{borderBottom: "1px solid #FFFFFF0F"}}>{t("SCHEDULED_MATCH.SQUADS")}
                                       </div>
                                       <div className="col-12 p-0 row">
                                          <div className="col-6 p-0" style={{borderRight: "1px solid #FFFFFF0F"}}>
                                             <div className="text-center p-3"
                                                  style={{borderBottom: "1px solid #FFFFFF0F"}}>

                                                <div className={classNames(styles.text14OSI, "pl-2")}><img
                                                   width={30} src={`${teams[0]?.pictureURL}`}
                                                   style={{width: "26px"}}></img>&nbsp;{teams[0]?.teamName.split(" ").length > 1 ? teams[0]?.teamName.split(" ").reduce((code, word) => code + word.slice(0, 1), "") : teams[0]?.teamName.slice(0, 3)}
                                                </div>
                                             </div>
                                             <ul className={classNames(styles.FavoUL)}>
                                                {
                                                   teams[0]?.playerProfiles.map((player) => (
                                                      <li key={player.playerId}><img
                                                         src={`${player.pictureURL}`}
                                                         style={{float: "left", marginRight: "10px"}}></img>
                                                         <div className="pl-2">{player.playerName}<br/>
                                                            <div
                                                               className={classNames(styles.text12OS, "pt-2")}>{player.playingRole}</div>
                                                         </div>
                                                      </li>
                                                   ))
                                                }

                                             </ul>
                                          </div>
                                          <div className="col-6 p-0">
                                             <div className="text-center p-3"
                                                  style={{borderBottom: "1px solid #FFFFFF0F"}}>

                                                <div className={classNames(styles.text14OSI, "pl-2")}><img
                                                   width={30} src={`${teams[1]?.pictureURL}`}
                                                   style={{width: "26px"}}></img>&nbsp;{teams[1]?.teamName.split(" ").length > 1 ? teams[1]?.teamName.split(" ").reduce((code, word) => code + word.slice(0, 1), "") : teams[1]?.teamName.slice(0, 3)}
                                                </div>


                                             </div>
                                             <ul className={classNames(styles.FavoUL)}>
                                                {
                                                   teams[1]?.playerProfiles.map((player) => (
                                                      <li key={player.playerId}><img
                                                         src={`${player.pictureURL}`}
                                                         style={{float: "left", marginRight: "10px"}}></img>
                                                         <div className="pl-2">{player.playerName}<br/>
                                                            <div
                                                               className={classNames(styles.text12OS, "pt-2")}>{player.playingRole}</div>
                                                         </div>
                                                      </li>
                                                   ))
                                                }

                                             </ul>
                                          </div>

                                       </div>
                                    </div>
                                 </div>

                              </div>

                              <AdvertisementSpace imageUrl1={ADVERTISEMENT_IMAGES.BANNER_4}/>
                           </div>
                        </>}


                  </div>
               </div>
            </div>
         </div>
      </React.Fragment>
   );
};

export default ScheduledMatch;
