import {connect} from "react-redux";
import classNames from "classnames";
import Styles from "./PointTable.module.scss";
import {Link} from "react-router-dom";
import styles from "../../../containers/Teams/Teams.module.scss";
import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {BsCheck2, BsDash, BsX} from "react-icons/bs";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const PointTable = (props) => {

   const {tournamentTeams, pointsLoaded} = props;
   const [translationsLoaded, setTranslationsLoaded] = useState(false);


   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const getMatchStatus = (matchStatus) => {
      if (matchStatus) {
         switch (matchStatus) {
            case "WIN":
               return (<div className="matchIndicator_tournament greenInd"><BsCheck2/></div>);
               break;
            case "LOST":
               return (<div className="matchIndicator_tournament purpleInd"><BsX/></div>);
               break;
            case "DRAW":
               return (<div className="matchIndicator_tournament grayInd"><BsDash/></div>);
               break;
            default:
               return (<div className="matchIndicator_tournament grayInd"><BsDash/></div>);
               break;
         }
      } else {
         return (<div className="matchIndicator_tournament grayInd"><BsDash/></div>);
      }
   };

   return (
      <>
         <div className="row">
            <div className="col">
               <h2>{t("LEAGUE_TOURNAMENT.POINTS_TABLE")}</h2>
            </div>
         </div>
         <div className="row p-0">
            <div className={classNames(Styles.MatchDetails, "col p-0 m-0")}>
               <div className={classNames(Styles.WinPrizes)}>
                  <div className={classNames(Styles.Conner1)}></div>
                  <div className={classNames(Styles.Conner2)}></div>
                  <div className={classNames(Styles.Conner3)}></div>
                  <div className={classNames(Styles.Conner4)}></div>

                  <div className={classNames(Styles.Shade1)}></div>
                  <div className={classNames(Styles.Shade2)}></div>
                  <div className={classNames(Styles.Shade3)}></div>
                  <div className={classNames(Styles.Shade4)}></div>

                  <div className={classNames(Styles.WinPrizesWrapper)}>
                     <div className={classNames(Styles.Tournament)}>

                        {tournamentTeams?.length > 0 ?
                           <div className={classNames(Styles.TournamentView)}>
                              <div className={classNames(Styles.PlayerContainerWrapper)}>
                                 <div className={classNames(Styles.PlayerContainer)}>
                                    <table>
                                       <thead>
                                       <tr>
                                          <th colSpan="2" className="text-start">{t("LEAGUE_TOURNAMENT.ALL_PLAYERS/TEAM")}</th>
                                          <th className="text-start">{t("LEAGUE_TOURNAMENT.LAST_5_MATCHES")}</th>
                                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">{"Match"}</Tooltip>}>
                                             <th style={{cursor: 'pointer'}}>M</th>
                                          </OverlayTrigger>
                                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">{"Win Count"}</Tooltip>}>
                                             <th style={{cursor: 'pointer'}}>W</th>
                                          </OverlayTrigger>
                                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">{"Loss Count"}</Tooltip>}>
                                             <th style={{cursor: 'pointer'}}>L</th>
                                          </OverlayTrigger>
                                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">{"Draw Count"}</Tooltip>}>
                                             <th style={{cursor: 'pointer'}}>D</th>
                                          </OverlayTrigger>
                                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">{"Net Run Rate"}</Tooltip>}>
                                             <th style={{cursor: 'pointer'}}>NRR</th>
                                          </OverlayTrigger>
                                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1">{"Points"}</Tooltip>}>
                                             <th style={{cursor: 'pointer'}}>PTS</th>
                                          </OverlayTrigger>
                                       </tr>
                                       </thead>
                                       <tbody>
                                       {pointsLoaded == true ? <>
                                             {tournamentTeams?.map((team, i) => (
                                                <tr>
                                                   <td className="text-start">{i + 1} &nbsp;&nbsp;
                                                      <Link to={`/team/${team?.teamId}`}>
                                                         <img src={team?.picUrl || 'https://storage.googleapis.com/meta11/players/Aman.webp'}/>
                                                      </Link>
                                                   </td>
                                                   <td className="text-start">
                                                      <span>{team?.name || "N/A"}</span>
                                                   </td>
                                                   <td className="text-start">
                                                      {team.matchResults ? <>
                                                            {team?.matchResults[0] ? getMatchStatus(team?.matchResults[0]) : <div className="matchIndicator_tournament ">-</div>}
                                                            {team?.matchResults[1] ? getMatchStatus(team?.matchResults[1]) : <div className="matchIndicator_tournament ">-</div>}
                                                            {team?.matchResults[2] ? getMatchStatus(team?.matchResults[2]) : <div className="matchIndicator_tournament ">-</div>}
                                                            {team?.matchResults[3] ? getMatchStatus(team?.matchResults[3]) : <div className="matchIndicator_tournament ">-</div>}
                                                            {team?.matchResults[4] ? getMatchStatus(team?.matchResults[4]) : <div className="matchIndicator_tournament">-</div>}
                                                         </> :
                                                         <div className={styles.emptyMatchStatus}>{t("LEAGUE_TOURNAMENT.NO_PAST_MATCH_DATA_AVAILABLE")}</div>
                                                      }
                                                   </td>
                                                   <td>{team?.matches}</td>
                                                   <td>{team?.winCount}</td>
                                                   <td>{team?.lossCount}</td>
                                                   <td>{team?.drawCount}</td>
                                                   <td>{(team?.netRunRate).toFixed(2)}</td>
                                                   <td>{team?.points || 0}</td>
                                                </tr>
                                             ))}</>
                                          : <>
                                             {!pointsLoaded ?
                                                <tr>
                                                   <td colSpan={8}>{t("LEAGUE_TOURNAMENT.NO_DATA_FOUND")}</td>
                                                </tr>
                                                :
                                                <tr>
                                                   <td colSpan={8}>
                                                      <div className="w-100 text-center">
                                                         <div className="lds-facebook">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                         </div>
                                                      </div>
                                                   </td>
                                                </tr>
                                             }
                                          </>}
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                           : <span>{t("MODAL.NO_DATA_AVAILABLE_AT_THE_MOMENT")}</span>}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )

}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};

export default connect(mapStateToProps)(PointTable);
