import React, {useEffect, useState} from "react";
import styles from "./Timer.module.scss";
import classNames from "classnames";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {calculateTimeLeft} from "../../../helpers/CommonFunctions";

const Timer = ({t, countDownTime}) => {

   const [timeLeft, setTimeLeft] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});
   const [keyForTimer, setKeyForTimer] = useState(0);

   useEffect(() => {
      const timer = setInterval(() => {
         if (timeLeft.days < 0 && timeLeft.hours < 0 && timeLeft.minutes < 0 && timeLeft.seconds < 0) {
            clearInterval(timer);
         } else {
            setTimeLeft(calculateTimeLeft(countDownTime));
            setKeyForTimer(prevKey => prevKey + 1)
         }
      }, 1000);

      return () => clearInterval(timer);
   }, [countDownTime]);

   return (<div className={classNames(styles.nextTournamentCol)}>
      <div className={classNames(styles.nextTournamentWrapper, "mx-auto")}>
         <div className={classNames(styles.countdown)}>
            {timeLeft.days > 0 && <div>
               <div className={classNames(styles.cercalBack)}>
                  <div className={classNames(styles.cercal)}>
                     <span>{String(timeLeft.days).padStart(2, '0')}</span>
                  </div>
               </div>
               <span>{t("HOME_PAGE.DAYS")}</span>
               <div className={classNames(styles.timerWrapper)}>
                  {timeLeft.days > 0 && <CountdownCircleTimer
                     key={`days-${keyForTimer}`}
                     isPlaying={false}
                     duration={30}
                     initialRemainingTime={timeLeft.days}
                     updateInterval={1}
                     strokeWidth={timeLeft.days < 0 ? 0 : 8}
                     strokeLinecap="round"
                     rotation="counterclockwise"
                     isSmoothColorTransition={true}
                     colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                     colorsTime={[10, 6, 3, 0]}
                     onComplete={() => ({shouldRepeat: true, delay: 1})}>
                     >
                  </CountdownCircleTimer>}
               </div>
            </div>}

            <div>
               <div className={classNames(styles.cercalBack)}>
                  <div className={classNames(styles.cercal)}>
                     <span>{timeLeft.minutes >= 0 ? <> {String(timeLeft.hours).padStart(2, '0')}</> : "00"}</span>
                  </div>
               </div>
               <span>{t("HOME_PAGE.HOURS")}</span>
               <div className={classNames(styles.timerWrapper)}>
                  {timeLeft.hours > 0 && <CountdownCircleTimer
                     key={`hours-${keyForTimer}`}
                     isPlaying={false}
                     duration={24}
                     initialRemainingTime={timeLeft.hours}
                     updateInterval={1}
                     strokeWidth={timeLeft.hours < 0 ? 0 : 8}
                     strokeLinecap="round"
                     rotation="counterclockwise"
                     isSmoothColorTransition={true}
                     colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                     colorsTime={[10, 6, 3, 0]}
                     onComplete={() => ({shouldRepeat: true, delay: 1})}>
                     >
                  </CountdownCircleTimer>}
               </div>
            </div>

            <div>
               <div className={classNames(styles.cercalBack)}>
                  <div className={classNames(styles.cercal)}>
                     <span>{timeLeft.minutes >= 0 ? <> {String(timeLeft.minutes).padStart(2, '0')}</> : "00"}</span>
                  </div>
               </div>
               <span>{t("HOME_PAGE.MINUTES")}</span>
               <div className={classNames(styles.timerWrapper)}>
                  {timeLeft.minutes > 0 && <CountdownCircleTimer
                     key={`minutes-${keyForTimer}`}
                     isPlaying={false}
                     duration={60}
                     initialRemainingTime={timeLeft.minutes}
                     updateInterval={1}
                     strokeWidth={timeLeft.minutes < 0 ? 0 : 8}
                     strokeLinecap="round"
                     rotation="counterclockwise"
                     isSmoothColorTransition={true}
                     colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                     colorsTime={[10, 6, 3, 0]}
                     onComplete={() => ({shouldRepeat: true, delay: 1})}>
                     >
                  </CountdownCircleTimer>}
               </div>
            </div>

            {timeLeft.days <= 0 && <div>
               <div className={classNames(styles.cercalBack)}>
                  <div className={classNames(styles.cercal)}>
                     <span>{timeLeft.seconds >= 0 ? <> {String(timeLeft.seconds).padStart(2, '0')}</> : "00"}</span>
                  </div>
               </div>
               <span>{t("HOME_PAGE.SECONDS")}</span>
               <div className={classNames(styles.timerWrapper)}>
                  {timeLeft.seconds > 0 && <CountdownCircleTimer
                     key={`seconds-${keyForTimer}`}
                     isPlaying={false}
                     duration={60}
                     initialRemainingTime={timeLeft.seconds}
                     strokeWidth={timeLeft.seconds < 0 ? 0 : 8}
                     strokeLinecap="round"
                     rotation="counterclockwise"
                     isSmoothColorTransition={true}
                     colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                     colorsTime={[10, 6, 3, 0]}
                     onComplete={() => ({shouldRepeat: true, delay: 1})}>
                     >
                  </CountdownCircleTimer>}
               </div>
            </div>}
         </div>
      </div>
   </div>)
}

export default Timer;
