import classNames from "classnames";
import React, {useEffect, useState} from "react";
import styles from "./PrivacyPolicyPage.module.scss";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import Footer from "../../components/Footer/Footer";
import {useTranslation} from "react-i18next";


const PrivacyPolicyPage = () => {
   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      });
   }, []);
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
         }}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);

   if (!translationsLoaded) {
      return loadingIndicator;
   }
   return (
      <React.Fragment>
         {/*<StreamChat></StreamChat>*/}
         <div className={classNames(styles.bodyPrivacy)}>
            <div className={classNames(styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>

               <div className="col-12 p-0 row">
                  <NavbarSecondary page="events"/>
               </div>
               <div className="col-12 col-md-9 row mt-2 p-0">
                  <div className={classNames(styles.pageTitle, "text-center")}>{t("PRIVACY_POLICY.PRIVACY_POLICY")}</div>

                  <div className={classNames(styles.sectionTitle)}>1. {t("PRIVACY_POLICY.INTRODUCTION")}</div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.BEHAVIOL_PTY_LTD_ACN_658_203_055 (“WE”, “US” AND “OUR”) IS_BOUND_BY_THE_PRIVACY_ACT_1988 (CTH) (PRIVACY_ACT) AND_THE_AUSTRALIAN_PRIVACY_PRINCIPLES (APPS)")}
                     {t("PRIVACY_POLICY.THIS_POLICY (POLICY) EXPLAINS_HOW_AND_WHY_WE_COLLECT, USE, HOLD_AND_DISCLOSE_YOUR_PERSONAL_INFORMATION")}
                  </div>

                  <div className={classNames(styles.sectionTitle)}>2. {t("PRIVACY_POLICY.DEFINITION")}</div>
                  <div className={classNames(styles.sectionContent)}>
                     <ul>
                        <li><b>{t("PRIVACY_POLICY.SERVICES")}</b>
                           {t("PRIVACY_POLICY.MEANS_THE_PROVISION_OF_A_PLATFORM_THAT_ALLOWS_YOU_TO_PARTICIPATE_IN_VIRTUAL_GAMING_OR_E_SPORT_AND_OTHER_SERVICES_IN_ACCORDANCE_WITH_OUR_CONSTITUTION_OR_THE_CONSTITUTION_OF_OUR_AFFILIATES")}
                           {t("PRIVACY_POLICY.AND")}
                        </li>
                        <li><b>{t("PRIVACY_POLICY.WEBSITE")}</b> {t("PRIVACY_POLICY.MEANS_THE_WEBSITE_AT")} https://www.behaviol.com/, {t("PRIVACY_POLICY.INCLUDING_DOMAIN_NAME_AND_ANY_CONTENT_THERE_OF) WHICH_MAY_BE_UPDATED_OR_CHANGED_FROM_TIME_TO_TIME")}
                        </li>
                     </ul>
                  </div>

                  <div className={classNames(styles.sectionTitle)}>3. {t("PRIVACY_POLICY.WHAT_IS_PERSONAL_INFORMATION?")}</div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.PERSONAL_INFORMATION_REFERS_TO_ANY_INFORMATION_OR_AN_OPINION_ABOUT_AN_IDENTIFIED_INDIVIDUAL_OR_AN_INDIVIDUAL_WHO_CAN_BE_REASONABLY_IDENTIFIED_FROM_THE_INFORMATION_OR_OPINION.")}
                     {t("PRIVACY_POLICY.INFORMATION_OR_AN_OPINION_MAY_BE_PERSONAL_INFORMATION_REGARDLESS_OF_WHETHER_IT_IS_TRUE.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.THIS_POLICY_COVERS_ALL_PERSONAL_INFORMATION_THAT_WE_COLLECT, USE_AND_PROCESS_WHICH_MEANS_INFORMATION_THAT (EITHER_IN_ISOLATION_OR_IN_COMBINATION_WITH_OTHER_INFORMATION) ENABLES_A_PERSON_TO_BE_IDENTIFIED_DIRECTLY_OR_INDIRECTLY")}
                  </div>

                  <div className={classNames(styles.sectionTitle)}>4. {t("PRIVACY_POLICY.PERSONAL_INFORMATION_THAT_WE_COLLECT_AND_HOLD")}</div>
                  <div className={classNames(styles.sectionContent)}>{t("PRIVACY_POLICY.WE_COLLECT_INFORMATION_ABOUT_YOU, YOUR_INTERACTIONS_WITH_US_AND_THE_SERVICES_YOU_USE.")}</div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.THE_TYPES_OF_PERSONAL_INFORMATION_WE_MAY_COLLECT_AND_HOLD_DEPEND_ON_THE_NATURE_OF_OUR_INTERACTION_WITH_YOU_AND_MAY_INCLUDE, IF_APPLICABLE")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     <ul>
                        <li>{t("PRIVACY_POLICY.IDENTIFYING_INFORMATION_SUCH_AS_YOUR_SURNAME, FIRST_NAME_AND_ANY_OTHER_NAMES (EITHER_PROVIDED_BY_YOU_OR_EXTRACTED_FROM_YOUR_IDENTITY_DOCUMENT_SUCH_AS_DRIVERS_LICENCE_OR_PASSPORT);")}
                        </li>
                        <li>{t("PRIVACY_POLICY.DESCRIPTIVE_INFORMATION_SUCH_AS_AGE, YEAR_OR_DATE_OF_BIRTH, SEX;")}</li>
                        <li>{t("PRIVACY_POLICY.CONTACT_INFORMATION_SUCH_AS_YOUR_ADDRESS, PHONE_NUMBER_AND_EMAIL_ADDRESS;")}</li>
                        <li>{t("PRIVACY_POLICY.INFORMATION_ASCERTAINED_ABOUT_YOU_FROM_SOCIAL_MEDIA_SUCH_AS_YOUR_PROFILE_PICTURE, LIKES, ALLOCATION_AND_FRIEND_LISTS;")}</li>
                        <li>{t("PRIVACY_POLICY.PAYMENT_INFORMATION_SUCH_AS_YOUR_CREDIT_CARD_DETAILS_AND_BANK_ACCOUNT_DETAILS;")}</li>
                        <li>{t("PRIVACY_POLICY.FINANCIAL_INFORMATION_AND_ANY_TRANSACTION_INFORMATION_SUCH_AS_BANK_STATEMENT_AND_TAX_FILE_NUMBER;")}</li>
                        <li>{t("PRIVACY_POLICY.GEO_LOCATION_DETAILS (IF_APPLICABLE) SUCH_AS_THE_LOCATION_FROM_WHICH_YOU_HAVE_COME_TO_THE_SITE_AND_THE_PAGES_YOU_HAVE_VISITED;")}</li>
                        <li>{t("PRIVACY_POLICY.TECHNICAL_DATA, SUCH_AS_IP_ADDRESS, THE_TYPES_OF_DEVICES_YOU_ARE_USING_TO_ACCESS_THE_WEBSITE, DEVICE_ATTRIBUTES, BROWSER_TYPE, LANGUAGE_AND_OPERATING_SYSTEM;")}</li>
                        <li>{t("PRIVACY_POLICY.HISTORY_OF_YOUR_PURCHASES_FROM_US_OR_THROUGH_A_THIRD_PARTY;")}</li>
                        <li>{t("PRIVACY_POLICY.INFORMATION_RELATING_TO_THE_USE_OF_THE_SERVICES_AND_DETAILS_OF_ENQUIRIES_OR_COMPLAINTS_YOU_MAKE; AND")}
                        </li>
                        <li>{t("PRIVACY_POLICY.ANY_OTHER_INFORMATION_THAT_WILL_BE_NECESSARY_FOR_US_TO_PROVIDE_THE_SERVICES.")}</li>
                     </ul>
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_WILL_NEVER_KNOWINGLY_COLLECT_PERSONAL_INFORMATION_FROM_INDIVIDUALS_UNDER_THE_AGE_OF_EIGHTEEN (18) YEARS.")}
                     {t("PRIVACY_POLICY.IF_WE_BECOME_AWARE_THAT_A_PERSON_UNDER_18_HAS_PROVIDED_PERSONAL_INFORMATION_TO_US, WE_WILL_REMOVE_SUCH_PERSONAL_INFORMATION_FROM_OUR_FILES")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_USE_COOKIES_ON_THE_WEBSITE.")}
                     {t("PRIVACY_POLICY.A_COOKIE_IS_A_SMALL_TEXT_FILE_THAT_THE_WEBSITE_MAY_PLACE_ON_YOUR_DEVICE_TO_STORE_INFORMATION.")}
                     {t("PRIVACY_POLICY.WE_MAY_USE_PERSISTENT_COOKIES (WHICH_REMAIN_ON_YOUR_COMPUTER_EVEN_AFTER_YOU_CLOSE_YOUR_BROWSER) TO_STORE_INFORMATION_THAT_MAY_SPEED_UP_YOUR_USE_OF_THE_WEBSITE_FOR_ANY_OF_YOUR_FUTURE_VISITS_TO_THE_WEBSITE.")}
                     {t("PRIVACY_POLICY.WE_MAY_ALSO_USE_SESSION_COOKIES (WHICH_NO_LONGER_REMAIN_AFTER_YOU_END_YOUR_BROWSING_SESSION) TO_HELP_MANAGE_THE_DISPLAY_AND_PRESENTATION_OF_INFORMATION_ON_THE_WEBSITE.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.YOU_MAY_REFUSE_TO_USE_COOKIES_BY_SELECTING_THE_APPROPRIATE_SETTINGS_ON_YOUR_BROWSER.")}
                     {t("PRIVACY_POLICY.HOWEVER, IF_YOU_DO_THIS, YOU_MAY_NOT_BE_ABLE_TO_USE_THE_FULL_FUNCTIONALITY_OF_THE_WEBSITE.")}
                  </div>

                  <div className={classNames(styles.sectionTitle)}>5. {t("PRIVACY_POLICY.HOW_DO_WE_COLLECT_YOUR_PERSONAL_INFORMATION?")}</div>
                  <div className={classNames(styles.sectionContent)}>{t("PRIVACY_POLICY.WE_MAY_COLLECT_PERSONAL_INFORMATION_ABOUT_YOU_WHEN")}</div>
                  <div className={classNames(styles.sectionContent)}>
                     <ul>
                        <li>{t("PRIVACY_POLICY.YOU_VISIT_THE_WEBSITE;")}</li>
                        <li>{t("PRIVACY_POLICY.YOU_ENQUIRE_OF_US_OR_OUR_AFFILIATES_IN_RELATION_TO_THE_SERVICES (EG_BY_CALLING_US, EMAILING_US_OR_INTERACTING_WITH_ANY_CHATBOT_ON_THE_WEBSITE, IF_APPLICABLE); OR")}
                        </li>
                        <li>{t("PRIVACY_POLICY.YOU_ENGAGE_OR_RETAIN_US_IN_THE_PROVISION_OF_THE_SERVICES;")}</li>
                        <li>{t("PRIVACY_POLICY.YOU_PROVIDE_IT_TO_US_THROUGH_BUSINESS_NETWORKING; AND")}</li>
                        <li>{t("PRIVACY_POLICY.WHEN_YOU_ACCESS, USE_AND_INTERACT_WITH_THE_WEBSITE. WE_DO_THIS_BY_USING_A_RANGE_OF_TOOLS_SUCH_AS_GOOGLE_ANALYTICS, SHOPIFY, FACEBOOK, BING.")}
                        </li>
                     </ul>
                  </div>

                  <div className={classNames(styles.sectionTitle)}>6. {t("PRIVACY_POLICY.WHY_DO_WE_COLLECT_AND_HOLD_YOUR_PERSONAL_INFORMATION_AND_HOW_DO_WE_USE_YOUR_PERSONAL_INFORMATION")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.IN_THE_TABLE_BELOW, WE_PRESENT_WHY_WE_COLLECT_AND_HOLD_YOUR_PERSONAL_INFORMATION, HOW_WE_USE_IT_AND_THE_REASONS_FOR_DOING_SO.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}></div>
                  <table className={classNames(styles.privacyTable1)}>
                     <tr>
                        <th>{t("PRIVACY_POLICY.WHY_WE_COLLECT_AND_HOLD_YOUR_PERSONAL_INFORMATION")}</th>
                        <th>{t("PRIVACY_POLICY.HOW_WE_USE_YOUR_PERSONAL_INFORMATION")}</th>
                        <th>{t("PRIVACY_POLICY.REASONS")}</th>
                     </tr>
                     <tr>
                        <td>{t("PRIVACY_POLICY.TO_PROVIDE_YOU_WITH_INFORMATION_ABOUT_THE_SERVICES")}</td>
                        <td>{t("PRIVACY_POLICY.WE_COLLECT_AND_HOLD_YOUR_ORDER_HISTORY_TO_DEVELOP, MARKET, SELL_OR_OTHERWISE_PROVIDE_SERVICES_OR_INFORMATION_TO_YOU.")}
                           <br/><br/>
                           {t("PRIVACY_POLICY.WE_ALSO_COLLECT_AND_HOLD_YOUR_NAME_AND_CONTACT_DETAILS_TO_PROVIDE_YOU_WITH_COPIES_OF_OUR_NEWSLETTER_AND_INFORMATION_ABOUT_OUR_PARTNERSHIPS_AND_EVENTS, CONTACT_YOU_ABOUT_SERVICES_RELATED_MATTERS, AND_PROVIDE_YOU_WITH_OTHER_MARKETING_OR_PROMOTIONAL_INFORMATION_WHERE_WE_ARE_PERMITTED_TO_DO_SO_IN_ACCORDANCE_WITH_APPLICABLE_LAWS.")}
                           {t("PRIVACY_POLICY.WE_ALSO_COLLECT_AND_HOLD_THIS_INFORMATION_TO_ENSURE_THAT_WE_DO_NOT_CONTACT_YOU_FOR_DIRECT_MARKETING_PURPOSES_IF_YOU_HAVE_ASKED_US_NOT_TO.")}
                        </td>
                        <td>{t("PRIVACY_POLICY.IT_IS_IN_OUR_LEGITIMATE_INTERESTS_TO_PROVIDE_YOU_WITH_THE_BEST_POSSIBLE_CUSTOMER_EXPERIENCE.")}
                           {t("PRIVACY_POLICY.PROVIDING_US_WITH_CERTAIN_PERSONAL_INFORMATION_IS_VOLUNTARY_BUT_WE_MAY_NOT_BE_ABLE_TO_PROCESS_YOUR_ORDER_AND_SEND_YOU_THE_REQUIRED_ORDER_ACKNOWLEDGEMENT_AND_SHIPPING_CONFIRMATION_E_MAILS_IF_YOU_DO_NOT_PROVIDE_US_WITH_CERTAIN_REQUESTED_INFORMATION")}
                        </td>
                     </tr>
                     <tr>
                        <td>{t("PRIVACY_POLICY.TO_PROCESS_YOUR_PAYMENTS_AND_PROTECT_YOU_AGAINST_FRAUDULENT_TRANSACTIONS.")}</td>
                        <td>{t("PRIVACY_POLICY.WE_COLLECT_AND_HOLD_YOUR_PERSONAL_INFORMATION_INCLUDING_YOUR_PAYMENT_DETAILS (CREDIT_CARD, DEBIT_CARD_AND/OR_OTHER_PAYMENT_DETAILS) TO_FULFIL_YOUR_PURCHASE_ORDERS_FOR_SERVICES.")}<br/><br/>
                           {t("PRIVACY_POLICY.WE_ALSO_COLLECT_AND_HOLD_THIS_INFORMATION_TO_KEEP_YOUR_PAYMENT_DETAILS_SAFE_AND_PROTECT_YOU_AGAINST_FRAUDULENT_TRANSACTIONS.")}
                           {t("PRIVACY_POLICY.WE_COLLECT_AND_HOLD_DETAILS_OF_YOUR_DEVICE_WHEN_YOU_SHOP_ON_THE_WEBSITE_TO_ENABLE_US_TO_DETECT_ANY_FRAUDULENT_TRANSACTIONS_OR_SUSPICIOUS_PURCHASING_ACTIVITY.")}
                        </td>
                        <td>{t("PRIVACY_POLICY.IT_IS_IN_OUR_LEGITIMATE_INTERESTS_TO_COLLECT_AND_HOLD_PERSONAL_INFORMATION_TO_KEEP_PAYMENTS_SECURE_AND_NECESSARY_FOR_THE_PERFORMANCE_OF_OUR_CONTRACT_WITH_YOU.")}
                        </td>
                     </tr>
                     <tr>
                        <td>{t("PRIVACY_POLICY.TO_LEARN_MORE_ABOUT_WHY_YOU_USE_CERTAIN_SERVICES.")}</td>
                        <td>{t("PRIVACY_POLICY.WE_COLLECT_AND_HOLD_YOUR_INFORMATION_TO_CONDUCT_INTERNAL_ADMINISTRATIVE_ACTIVITIES, RESEARCH, ANALYTICS, PLANNING_AND_SERVICES_DEVELOPMENT.")}
                           <br/><br/>{t("PRIVACY_POLICY.WE_USE_THIS_INFORMATION_FOR_OUR_INTERNAL_DEMOGRAPHIC_INSIGHTS_INTO_OUR_CUSTOMER, TO_OFFER_YOU_ENHANCED_SERVICES_ACCORDING_TO_YOUR_PREFERENCES, INCLUDING_BY_IDENTIFYING_RELEVANT_SERVICES_AND_EVENTS_WHICH_MAY_BE_OF_INTEREST_TO_YOU, AND_TO_PERSONALIZE_YOUR_EXPERIENCE_WITH_US.")}
                           <br/><br/>{t("PRIVACY_POLICY.TOGETHER_WITH_NON_PERSONAL_INFORMATION, WE_MAY_ALSO_USE_THIS_INFORMATION_FOR_OUR_INTERNAL_MARKETING_ANALYSIS_AND_DEMOGRAPHIC_STUDIES, TO_ANALYZE, PROFILE_AND_MONITOR_CUSTOMER_PATTERNS_SO_WE_CAN_CONSISTENTLY_IMPROVE_THE_SERVICES.")}
                           {t("PRIVACY_POLICY.THIS_MEANS_THAT_WE_CAN_OFFER_MORE_PERSONALIZED_AND_INTEGRATED_SHOPPING_AND_INTERACTIVE_EXPERIENCES_TO_OUR_CUSTOMERS_ACROSS_ALL_OUR_CHANNELS.")}
                        </td>
                        <td>{t("PRIVACY_POLICY.IT_IS_IN_OUR_LEGITIMATE_INTERESTS_TO_DEVELOP_THE_SERVICES_AND_MARKET_THE_RIGHT_SERVICES_TO_YOU.")}</td>
                     </tr>
                     <tr>
                        <td>{t("PRIVACY_POLICY.TO_IMPROVE_YOUR_EXPERIENCE_ON_THE_WEBSITE.")}</td>
                        <td>{t("PRIVACY_POLICY.WE_COLLECT_AND_HOLD_INFORMATION_SUCH_AS_YOUR_ACCOUNT_USERNAME_AND_PASSWORD_THAT_ARE_NEEDED_TO_ACCESS_THE_WEBSITE, IP_ADDRESS, INFORMATION_ABOUT_YOUR_PURCHASES_AND_YOUR_OTHER_ACTIVITY_ON_THE_WEBSITE_TO_IMPROVE_THE_WEBSITE, INCLUDING_TO_MODIFY_IT_TO_YOUR_USAGE, HISTORY_AND_PREFERENCES_AND_TROUBLESHOOT_PROBLEMS.")}
                        </td>
                        <td>{t("PRIVACY_POLICY.IT_IS_IN_OUR_LEGITIMATE_INTEREST_TO_ENSURE_WE_PROVIDE_YOU_WITH_A_SEAMLESS_ONLINE_EXPERIENCE.")}</td>
                     </tr>
                     <tr>
                        <td>{t("PRIVACY_POLICY.TO_ASSESS_THE_ONLINE_ACTIVITIES_OF_WEBSITE_USERS")}</td>
                        <td>{t("PRIVACY_POLICY.WE_COLLECT_AND_HOLD_INFORMATION_COLLECTED_BY_THE_WEBSITE_AUTOMATICALLY_AND_THROUGH_COOKIES_AND_OTHER_TECHNOLOGIES_TO_ASSESS_THE_ACTIVITIES_OF_OUR_USERS, TO_MEASURE_THE_INTEREST_IN_AND_USE_OF_THE_WEBSITE_AND_COMMUNICATIONS, AND_TO_CUSTOMIZE_THE_WEBSITE_AND_OUR_COMMUNICATIONS_WITH_YOU.")}
                           {t("PRIVACY_POLICY.WE_DO_THIS_ON_BOTH_AN_INDIVIDUAL_BASIS_AND_IN_THE_AGGREGATE.")}
                        </td>
                        <td>
                           {t("PRIVACY_POLICY.IT_IS_IN_OUR_LEGITIMATE_INTERESTS_TO_COLLECT_AND_HOLD_PERSONAL_INFORMATION_USING_COOKIES_AND_OTHER_TECHNOLOGIES_THAT_WE_NEED_TO_USE_TO_RUN_THE_WEBSITE.")}
                           {t("PRIVACY_POLICY.WHERE_REQUIRED_BY_APPLICABLE_LAW, WE_WILL_ASK_YOU_TO_CONSENT_TO_THE_USE_OF_COOKIES_THAT_ARE_NOT_NECESSARY_TO_RUN_THE_WEBSITE.")}
                        </td>
                     </tr>
                     <tr>
                        <td>{t("PRIVACY_POLICY.TO_UNDERSTAND_AND_ANALYZE_OUR_SALES, YOUR_NEEDS_AND_PREFERENCES.")}</td>
                        <td>{t("PRIVACY_POLICY.WE_MAY_USE_YOUR_INFORMATION_SUCH_AS_YOUR_GEOGRAPHICAL_LOCATION_TO_HELP_US_CONDUCT_FOCUSED_MARKET_RESEARCH (SUCH_AS_SURVEYS) BASED_ON_TRENDS_AND_COMMON_FACTORS_SO_THAT_WE_DEVELOP, ENHANCE, MARKET_AND_PROVIDE_THE_SERVICES_TO_MEET_YOUR_INDIVIDUAL_NEEDS.")}
                        </td>
                        <td>{t("PRIVACY_POLICY.IT_IS_IN_OUR_LEGITIMATE_INTERESTS_TO_COLLECT_AND_HOLD_PERSONAL_INFORMATION_TO_DEVELOP, ENHANCE, MARKET_AND_PROVIDE_THE_SERVICES_TO_YOU.")}
                        </td>
                     </tr>
                     <tr>
                        <td>{t("PRIVACY_POLICY.TO_UNDERSTAND_YOUR_PREFERENCES_BASED_ON_INFORMATION_INCLUDED_IN_YOUR_PROFILE_THAT_YOU_PROVIDED_TO_US (COMPLETED_ONLINE_OR_IN_OTHER_COMMUNICATIONS_YOU_SEND_TO_US)")}
                        </td>
                        <td>{t("PRIVACY_POLICY.WE_COLLECT_AND_HOLD_YOUR_INFORMATION_IN_THIS_WAY_TO_BETTER_UNDERSTAND_YOU. THIS_ALLOWS_US_TO_CONDUCT_INTERNAL_ADMINISTRATIVE_ACTIVITIES, RESEARCH, ANALYTICS, PLANNING_AND_PROJECT_DEVELOPMENT.")}
                        </td>
                        <td>{t("PRIVACY_POLICY.IT_IS_IN_OUR_LEGITIMATE_INTERESTS_TO_COLLECT_AND_HOLD_PERSONAL_INFORMATION_SO_THAT_WE_CAN_BETTER_PROVIDE_THE_SERVICES_TO_YOU.")}</td>
                     </tr>
                     <tr>
                        <td>{t("PRIVACY_POLICY.TO_RESPOND_TO_REQUESTS_OR_COMPLAINTS.")}</td>
                        <td>{t("PRIVACY_POLICY.IF_YOU_CONTACT_US_VIA_THE_WEBSITE, BY_EMAIL_OR_PHONE, OR_IN_PERSON, WE_WILL_COLLECT_YOUR_PERSONAL_INFORMATION_AND_USE_THIS_TO_IDENTIFY_YOU_AS_A_CUSTOMER, HELP_WITH_YOUR_QUERY, PROCESS_YOUR_ORDER, PROCESS_PAYMENTS, DELIVER_SERVICES, UPDATE_OUR_RECORDS_AND_GENERALLY_MANAGE_YOUR_ACCOUNT_WITH_US_UNDER_OUR_TERMS_WITH_YOU.")}
                        </td>
                        <td>{t("PRIVACY_POLICY.IT_IS_NECESSARY_FOR_US_TO_COLLECT_AND_HOLD_YOUR_PERSONAL_INFORMATION_TO_FULFIL_OUR_STATUTORY_AND/OR_CONTRACTUAL_OBLIGATIONS_TO_YOU.")}</td>
                     </tr>
                     <tr>
                        <td>{t("PRIVACY_POLICY.TO_ENSURE_THE_SECURITY_AND_INTEGRITY_OF_OUR_RESOURCES, INCLUDING_THE_WEBSITE.")}</td>
                        <td>{t("PRIVACY_POLICY.WE_WILL_COLLECT_AND_HOLD_PERSONAL_INFORMATION_TO_ASSESS_AND_ENHANCE_THE_SECURITY_AND_RELIABILITY_OF_OUR_REMOTE_AND_ELECTRONIC_RESOURCES, INCLUDING_ANALYSIS_OF_INFORMATION_COLLECTED_DURING_TECHNOLOGICAL_DEVELOPMENT, AND_PROGRAM_ENHANCEMENTS.")}
                        </td>
                        <td>{t("PRIVACY_POLICY.WE_COLLECT_AND_HOLD_YOUR_PERSONAL_INFORMATION_IN_AN_EFFORT_TO_PROVIDE_SAFE, RELIABLE_ACCESS_TO_OUR_SERVICES.")}</td>
                     </tr>
                     <tr>
                        <td>{t("PRIVACY_POLICY.TO_ASSESS_OR_ENSURE_COMPLIANCE_WITH_APPLICABLE_LAWS, REGULATIONS_AND_POLICIES.")}</td>
                        <td>{t("PRIVACY_POLICY.WE_MAY_COLLECT_AND_HOLD_YOUR_PERSONAL_INFORMATION_TO_AUDIT, CONFIRM, AND_DOCUMENT_COMPLIANCE_WITH_LEGAL, ADMINISTRATIVE, INDUSTRY_AND_ETHICAL_STANDARDS_INCLUDING_OUR_POLICIES_AND_PROCEDURES, CODE_OF_CONDUCT_AND_CORPORATE_RESPONSIBILITY_INITIATIVES.")}
                           {t("PRIVACY_POLICY.WE_WILL_ALSO_COLLECT_AND_HOLD_YOUR_PERSONAL_INFORMATION_TO_AUDIT_OUR_AFFILIATES’ AND_SERVICE_PROVIDERS’ COMPLIANCE_WITH_CONTRACTUAL_OBLIGATIONS_AS_WELL_AS_APPLICABLE_PRIVACY_AND_OTHER_STANDARDS.")}
                        </td>
                        <td>
                           {t("PRIVACY_POLICY.WE_COLLECT_AND_HOLD_YOUR_PERSONAL_INFORMATION_TO_OBEY_LAWS_AND_REGULATIONS, TO_ENFORCE_INTERNAL_POLICIES_AND_TO_PREVENT_AND_DETECT_FRAUD_AND_OTHER_PRACTICES_THAT_UNDERMINE_OUR_COMMITMENT_TO_FAIR_AND_ETHICAL_CONDUCT.")}
                        </td>
                     </tr>

                  </table>

                  <div className={classNames(styles.sectionTitle)}>7. {t("PRIVACY_POLICY.HOW_DO_WE_STORE, HOLD_AND_PROTECT_YOUR_PERSONAL_INFORMATION?")}</div>

                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_STORE_MOST_INFORMATION_ABOUT_YOU_IN_COMPUTER_SYSTEMS_AND_DATABASES_OPERATED_BY_EITHER_US_OR, IN_LIMITED_CIRCUMSTANCES, OUR_EXTERNAL_SERVICE_PROVIDERS.")}
                     {t("PRIVACY_POLICY.SOME_INFORMATION_ABOUT_YOU_IS_RECORDED_IN_PAPER_FILES_THAT_WE_STORE_REASONABLY_SECURELY")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>{t("PRIVACY_POLICY.WE_USE_BCRYPT_ENCRYPTION_ALGORITHM_AND_FULL_DISK_ENCRYPTION_TO_PROTECT_PERSONAL_INFORMATION_WHICH_WE_HOLD_FROM_MISUSE, INTERFERENCE_OR_LOSS, AND_FROM_UNAUTHORIZED_ACCESS, MODIFICATION_OR_DISCLOSURE.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_ARE_AWARE_OF_THE_GROWING_NUMBER_OF_CYBER_SECURITY_RISKS_AND_WE_STRIVE_TO_IMPLEMENT_APPROPRIATE_AND_REASONABLE_TECHNICAL_AND_ORGANIZATIONAL_SECURITY_MEASURES_TO_PREVENT_YOUR_PERSONAL_INFORMATION_FROM_BEING_ACCIDENTALLY_LOST_OR_USED, ACCESSED, MODIFIED_OR_DISCLOSED_WITHOUT_AUTHORIZATION.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.IN_ADDITION, WE_LIMIT_ACCESS_TO_YOUR_PERSONAL_INFORMATION_TO_OUR_EMPLOYEES_AND_SERVICE_PROVIDERS, AND_ONLY_THOSE_WHO_HAVE_A_LEGITIMATE_NEED_TO_KNOW_BY_VIRTUE_OF_THEIR_WORK_OR_THE_SERVICES_PROVIDED.")}
                     {t("PRIVACY_POLICY.THESE_PERSONS_WILL_ONLY_ACCESS_YOUR_PERSONAL_INFORMATION_IN_ACCORDANCE_WITH_OUR_INSTRUCTIONS_AND_WILL_BE_OBLIGED_TO_MAINTAIN_CONFIDENTIALITY.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_HAVE_PROCEDURES_TO_IMPLEMENT_IN_THE_EVENT_OF_ALLEGED_VIOLATIONS_OF_PERSONAL_INFORMATION_AND_WE_WILL_INFORM_YOU_AND_THE_RELEVANT_REGULATORY_AUTHORITIES, SHOULD_SUCH_A_CASE_ARISE, IN_ACCORDANCE_WITH_APPLICABLE_LEGAL_REQUIREMENTS.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_TAKE_REASONABLE_STEPS_TO_DESTROY_OR_DE_IDENTIFY_PERSONAL_INFORMATION_ONCE_WE_NO_LONGER_REQUIRE_IT_FOR_THE_PURPOSES_FOR_WHICH_IT_WAS_COLLECTED_OR_FOR_ANY_SECONDARY_PURPOSE_PERMITTED_UNDER_THE_AUSTRALIAN_PRIVACY_PRINCIPLES.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.OUR_CREDIT_CARD_TRANSACTIONS_ARE_FULFILLED_BY_AN_AUTHORIZED_BANKING_INSTITUTION. WHEN_COLLECTING_CREDIT_CARD_INFORMATION_FOR_ONLINE_PURCHASES, WE_OFFER_A_SECURED_SERVER_TRANSACTION_THAT_ENCRYPTS_YOUR_INFORMATION_IN_TRANSIT_TO_HELP_PREVENT_OTHERS_FROM_ACCESSING_IT_PERSONAL_INFORMATION_STORED_BY_US_WILL_ONLY_BE_ACCESSIBLE_BY_AUTHORIZED_EMPLOYEES_AND_AGENTS_WHO_REQUIRE_ACCESS_TO_PERFORM_THEIR_RESPONSIBILITIES.")}
                     {t("PRIVACY_POLICY.YOUR_CREDIT_CARD_DETAILS_ARE_ENCRYPTED_AND_THEN_REMOVED_FROM_OUR_SYSTEM_ONCE_YOUR_ORDER_HAS_BEEN_DISPATCHED.")}
                  </div>


                  <div className={classNames(styles.sectionTitle)}>
                     8. {t("PRIVACY_POLICY.HOW_LONG_WILL_WE_KEEP_YOUR_INFORMATION?")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_WILL_KEEP_YOUR_PERSONAL_INFORMATION_FOR_AS_LONG_AS_IT_IS_STILL_BEING_USED_AND_FOR_AS_LONG_AS_PERMITTED_BY_APPLICABLE_LAWS_AND_REGULATIONS_TO_ACHIEVE_THE_PURPOSE_FOR_WHICH_IT_WAS_COLLECTED, INCLUDING_TO_MEET_LEGAL_PROVISIONS_OR_HEALTH_SURVEILLANCE_REPORTING_REQUIREMENTS.")}
                     {t("PRIVACY_POLICY.YOUR_PERSONAL_INFORMATION_WILL_BE_KEPT_ON_A_DEDICATED_DATABASE, ON_AN_ACTIVE_BASIS, FOR_THE_DURATION_OF_THE_MARKETING_OF_THE_SERVICES_CONCERNED.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.IN_ORDER_TO_ASSESS_THE_APPROPRIATE_STORAGE_PERIOD_FOR_PERSONAL_INFORMATION, WE_TAKE_INTO_CONSIDERATION_THE_QUANTITY, NATURE_AND_SENSITIVITY_OF_THE_PERSONAL_INFORMATION, THE_POTENTIAL_RISK_OF_HARM_CAUSED_BY_THE_UNAUTHORIZED_USE_OR_DISCLOSURE_OF_YOUR_PERSONAL_INFORMATION, THE_PURPOSES_FOR_WHICH_WE_USE_YOUR_PERSONAL_INFORMATION, AND_WE_TRY_TO_DETERMINE_WHETHER_IT_CAN_ACHIEVE_THESE_PURPOSES_BY_OTHER_MEANS_WHILE_COMPLYING_WITH_THE_RELEVANT_LEGAL_PROVISIONS.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>{t("PRIVACY_POLICY.UNDER_CERTAIN_CIRCUMSTANCES, WE_MAY_ENSURE_THE_ANONYMITY_OF_YOUR_PERSONAL_INFORMATION_SO_THAT_IT_CAN_NO_LONGER_BE_ASSOCIATED_WITH_YOU, IN_WHICH_CASE_WE_MAY_KEEP_THIS_INFORMATION_FOR_A_LONGER_PERIOD_OF_TIME.")}
                  </div>


                  <div className={classNames(styles.sectionTitle)}>9. {t("PRIVACY_POLICY.WHO_DO_WE_DISCLOSE_YOUR_PERSONAL_INFORMATION_TO, AND_WHY?")}</div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.YOUR_PERSONAL_INFORMATION_WILL_BE_ACCESSIBLE_BY_OUR_STAFF, EMPLOYEES_AND_CONTRACTORS, BUT_ONLY_WHEN_NECESSARY_FOR_THE_PERFORMANCE_OF_THEIR_DUTIES.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_MAY_TRANSFER_OR_DISCLOSE_YOUR_PERSONAL_INFORMATION_TO_OUR_RELATED_COMPANIES_OR_BUSINESSES.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_MAY_DISCLOSE_PERSONAL_INFORMATION_TO_EXTERNAL_SERVICE_PROVIDERS (SUCH_AS_SURVEILLANCE_SERVICES, DATA_STORAGE_PROVIDERS, DATA_ANALYSIS_PROVIDERS_AND_TECHNICAL_SERVICE_PROVIDERS) SO_THAT_THEY_MAY_PERFORM_SERVICES_FOR_US_OR_ON_OUR_BEHALF.")}
                     {t("PRIVACY_POLICY.THEY_WILL_BE_REQUIRED_TO_ENSURE_THE_PROTECTION_OF_YOUR_PERSONAL_INFORMATION_AND_WILL_NOT_BE_AUTHORIZED_TO_USE_YOUR_PERSONAL_INFORMATION_FOR_THEIR_OWN_PURPOSES.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_WILL_ONLY_SHARE_YOUR_PERSONAL_INFORMATION_WITH_THIRD_PARTIES_IN_ORDER_TO_COMPLY_WITH_LEGAL_OBLIGATIONS.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>{t("PRIVACY_POLICY.WE_MAY_ALSO_DISCLOSE_YOUR_PERSONAL_INFORMATION_TO_THIRD_PARTIES_WHERE")}</div>
                  <div className={classNames(styles.sectionContent)}>
                     <ol
                        type={"A"}
                        className={classNames(styles.orderedList)}
                     >
                        <li> {t("PRIVACY_POLICY.WE_ARE_REQUIRED_OR_AUTHORIZED_BY_LAW_TO_DO_SO;")}</li>
                        <li> {t("PRIVACY_POLICY.YOU_HAVE_EXPRESSLY_CONSENTED_TO_THE_DISCLOSURE_OR_THE_CONSENT_MAY_BE_REASONABLY_INFERRED_FROM_THE_CIRCUMSTANCES; OR")}</li>
                        <li> {t("PRIVACY_POLICY.WE_ARE_OTHERWISE_PERMITTED_TO_DISCLOSE_THE_INFORMATION_UNDER_THE_PRIVACY_ACT.")}</li>
                     </ol>
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.IF_THE_OWNERSHIP_OR_CONTROL_OF_ALL_OR_PART_OF_OUR_BUSINESS_CHANGES, WE_MAY_TRANSFER_YOUR_PERSONAL_INFORMATION_TO_THE_NEW_OWNER.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.PLEASE_NOTE_THAT_THE_WEBSITE_MAY, FROM_TIME_TO_TIME, CONTAIN_LINKS_TO_AND_FROM_THE_WEBSITES_OF_OUR_PARTNERS_OR_AFFILIATES.")}
                     {t("PRIVACY_POLICY.IF_YOU_FOLLOW_A_LINK_TO_ANY_OF_THESE_WEBSITES, PLEASE_NOTE_THAT_THESE_WEBSITES_HAVE_THEIR_OWN_PRIVACY_POLICIES_AND_THAT_WE_HAVE_NO_CONTROL_OVER_HOW_THEY_MAY_USE_YOUR_PERSONAL_INFORMATION.")}
                     {t("PRIVACY_POLICY.THIS_IS_THE_SAME_FOR_YOUR_USE_OF_SOCIAL_MEDIA. YOU_SHOULD_CHECK_THE_PRIVACY_POLICIES_OF_THIRD_PARTY_WEBSITES_BEFORE_YOU_SUBMIT_ANY_PERSONAL_INFORMATION_TO_THEM.")}
                  </div>


                  <div className={classNames(styles.sectionTitle)}>10. {t("PRIVACY_POLICY.DO_WE_USE_YOUR_PERSONAL_INFORMATION_FOR_MARKETING?")}</div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_WILL_USE_YOUR_PERSONAL_INFORMATION_TO_OFFER_YOU_SERVICES_WE_BELIEVE_MAY_INTEREST_YOU, BUT_WE_WILL_NOT_DO_SO_IF_YOU_TELL_US_NOT_TO. THESE_SERVICES_MAY_BE_OFFERED_BY_US, OUR_RELATED_COMPANIES, OUR_OTHER_BUSINESS_PARTNERS_OR_OUR_SERVICE_PROVIDERS.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WHERE_YOU_RECEIVE_ELECTRONIC_MARKETING_COMMUNICATIONS_FROM_US, YOU_MAY_OPT_OUT_OF_RECEIVING_FURTHER_MARKETING_COMMUNICATIONS_BY_FOLLOWING_THE_OPT_OUT_INSTRUCTIONS_PROVIDED_IN_THE_COMMUNICATION.")}
                  </div>

                  <div className={classNames(styles.sectionTitle)}>11.{t("PRIVACY_POLICY.WHAT_IS_THE_PROCESS_FOR_COMPLAINING_ABOUT_A_BREACH_OF_PRIVACY?")}</div>
                  <div className={classNames(styles.sectionContent)}>{t("PRIVACY_POLICY.IF_YOU_BELIEVE_THAT_YOUR_PRIVACY_HAS_BEEN_BREACHED, PLEASE_SEND_US_AN_EMAIL (REFER_TO “HOW_TO_CONTACT_US?” SECTION_OF_THIS_POLICY) AND_PROVIDE_DETAILS_OF_THE_INCIDENT_SO_THAT_WE_CAN_INVESTIGATE_IT.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_REQUEST_THAT_COMPLAINTS_ABOUT_BREACHES_OF_PRIVACY_BE_MADE_IN_WRITING, SO_WE_CAN_BE_SURE_ABOUT_THE_DETAILS_OF_THE_COMPLAINT.")}
                     {t("PRIVACY_POLICY.YOUR_PRIVACY_COMPLAINTS_AND_ANY_OTHER_COMPLAINTS_SHOULD_BE_DIRECTED_TO_US_USING_THE_CONTACT_DETAILS_STATED_IN_THE “HOW_TO_CONTACT_US?” SECTION_OF_THIS_POLICY.")}.
                     {t("PRIVACY_POLICY.WE_WILL_ATTEMPT_TO_CONFIRM_AS_APPROPRIATE_AND_NECESSARY_WITH_YOU_YOUR_UNDERSTANDING_OF_THE_CONDUCT_RELEVANT_TO_THE_COMPLAINT_AND_WHAT_YOU_EXPECT_AS_AN_OUTCOME.")}
                     {t("PRIVACY_POLICY.WE_WILL_INFORM_YOU_WHETHER_WE_WILL_CONDUCT_AN_INVESTIGATION, THE_NAME, TITLE, AND_CONTACT_DETAILS_OF_THE_PERSON_CONDUCTING_THE_INVESTIGATION_AND_THE_ESTIMATED_COMPLETION_DATE_FOR_THE_INVESTIGATION_PROCESS.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.AFTER_WE_HAVE_COMPLETED_OUR_ENQUIRIES, WE_WILL_CONTACT_YOU, USUALLY_IN_WRITING, TO_ADVISE_THE_OUTCOME_AND_INVITE_A_RESPONSE_TO_OUR_CONCLUSIONS_ABOUT_THE_COMPLAINT.")}
                     {t("PRIVACY_POLICY.IF_WE_RECEIVE_A_RESPONSE_FROM_YOU, WE_WILL_ASSESS_IT_AND_ADVISE_IF_WE_HAVE_CHANGED_OUR_VIEW.")}
                  </div>


                  <div className={classNames(styles.sectionTitle)}>12. {t("PRIVACY_POLICY.ACCESS_TO_AND_CORRECTION_OF_YOUR_PERSONAL_INFORMATION")}</div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.YOU_MAY_ACCESS_OR_REQUEST_CORRECTION_OF_THE_PERSONAL_INFORMATION_THAT_WE_HOLD_ABOUT_YOU_BY_CONTACTING_US. OUR_CONTACT_DETAILS_ARE_SET_OUT_IN_THE “HOW_TO_CONTACT_US?” SECTION_OF_THIS_POLICY.")}
                     {t("PRIVACY_POLICY.THERE_ARE_SOME_CIRCUMSTANCES_IN_WHICH_WE_ARE_NOT_REQUIRED_TO_GIVE_YOU_ACCESS_TO_YOUR_PERSONAL_INFORMATION.")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.THERE_IS_NO_CHARGE_FOR_REQUESTING_ACCESS_TO_YOUR_PERSONAL_INFORMATION, BUT_WE_MAY_REQUIRE_YOU_TO_MEET_OUR_REASONABLE_COSTS_IN_PROVIDING_YOU_WITH_ACCESS (SUCH_AS_PHOTOCOPYING_COSTS_OR_COSTS_FOR_TIME_SPENT_ON_COLLATING_LARGE_AMOUNTS_OF_MATERIAL).")}
                  </div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.WE_WILL_RESPOND_TO_YOUR_REQUESTS_TO_ACCESS_OR_CORRECT_PERSONAL_INFORMATION_IN_A_REASONABLE_TIME_AND_WILL_TAKE_ALL_REASONABLE_STEPS_TO_ENSURE_THAT_THE_PERSONAL_INFORMATION_WE_HOLD_ABOUT_YOU_REMAINS_ACCURATE_AND_UP_TO_DATE.")}
                  </div>

                  <div className={classNames(styles.sectionTitle)}>13. {t("PRIVACY_POLICY.DELETING_YOUR_ACCOUNT")}</div>
                  <div className={classNames(styles.sectionContent)}>
                     {t("PRIVACY_POLICY.USERS_ARE_ENTITLED_TO_REQUEST_THEIR_USER_ACCOUNTS_AND_THEIR_PERSONAL_INFORMATION_BE_DELETED_BY_SENDING_AN_EMAIL_WITH_THEIR_WRITTEN_REQUEST_TO")}
                     <a style={{textDecoration: 'none', color: 'white'}} href="mailto:admin@behaviol.com"> admin@behaviol.com</a>.
                     {t("PRIVACY_POLICY.WE_WILL_RESPOND_WITHIN_30_DAYS_WITH_CONFIRMATION_THAT_ALL_YOUR_PERSONAL_DATA_HAS_BEEN_DELETED_FROM_OUR_SYSTEM_OR_RETAINED_ONLY_IN_ANONYMIZED_FORM.")}
                  </div>

                  <div className={classNames(styles.sectionTitle)}>14. {t("PRIVACY_POLICY.HOW_TO_CONTACT_US?")}</div>
                  <div className={classNames(styles.sectionContent)}>{t("PRIVACY_POLICY.IF_YOU_HAVE_ANY_QUESTIONS_OR_IF_YOU_WISH_TO_EXERCISE_ANY_OF_YOUR_RIGHTS, YOU_CAN_CONTACT_US_USING_THE_CONTACT_DETAILS_BELOW")}</div>
                  <div className={classNames(styles.sectionContent)}>{t("PRIVACY_POLICY.BEHAVIOL_PTY_LTD")}</div>
                  <div className={classNames(styles.sectionContent)}>{t("PRIVACY_POLICY.UNIT_105, 29_31_LEXINGTON_DRIVE, BELLA_VISTA, NEW_SOUTH_WALES, 2153_AUSTRALIA")}
                     info@behaviol.com
                  </div>
               </div>
               <Footer/>
            </div>
         </div>
      </React.Fragment>
   );

}
export default PrivacyPolicyPage;
