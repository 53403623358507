import React, {useEffect, useRef, useState} from "react";
import FacebookLogin from '@greatsumini/react-facebook-login';
import Styles from "./SocialButton.module.scss"
import {connect} from "react-redux";
import * as actions from '../../store/actions'
import * as UserService from '../../services/UserService'
import {useNavigate} from "react-router-dom";
import Classnames from "classnames";
import {AUTHENTICATION_TYPES, SOCIAL_APP_ID} from "../../utilities/Constants";
import {toast} from "react-toastify";
import {AUTHENTICATION_TOASTS} from "../../utilities/ToastMessages";
import {authRedirectHandler} from "../../helpers/CommonFunctions";
import FaceBookIcon from "../../assets/images/icons/faceBookIcon.png";

const FacebookLoginButton = (props) => {
   let navigate = useNavigate();
   const [click, setClick] = useState(false);
   const [accessToken, setAccessToken] = useState("");
   const [profileData, setProfileData] = useState({});
   const didMount = useRef(false);

   useEffect(() => {

      if (!didMount.current) {
         didMount.current = true;
         return
      }

      responseFacebook()
   }, [profileData]);

   useEffect(() => {
      setClick(props.disable)
   }, [props.disable])
   const handleDisableClick = () => {
      setClick(true);
      props.disableClick(true)
   }
   const handleClickClose = () => {
      setClick(false);
      props.disableClick(false)
   }
   const responseFacebook = async () => {
      const data = {
         accessToken: accessToken,
         oAuthProviderType: "facebook",
         "userName": profileData.userName,
         "profilePicURL": profileData.profilePicURL,
      }
      //TODO change the onAuth name
      const socialResponse = await UserService.socialLogin(data);
      if (socialResponse.loginStatus === true) {
         const name = socialResponse?.user?.name ? socialResponse.user.name : socialResponse?.user?.username?.split("@")[0];
         props.onAuth(socialResponse.accessToken, socialResponse.refreshToken, "FACEBOOK", socialResponse.user?.profilePicURL, profileData.email, name, profileData.email)
         localStorage.setItem("previouslyLogged", !socialResponse?.newUser)
         authRedirectHandler(navigate);

      } else {
         toast.error(AUTHENTICATION_TOASTS.INVALID_FACEBOOK_LOGIN);
         return navigate("/login");
      }

   }
   return (
      <FacebookLogin
         appId={SOCIAL_APP_ID.FACEBOOK}
         autoLoad={false}
         fields="name,email,picture"
         onSuccess={(response) => {
            setAccessToken(response.accessToken)
         }
         }
         onFail={(error) => {
            handleClickClose()
            setClick(false)
         }}
         onProfileSuccess={(response) => {
            setProfileData(
               {"userName": response.name, "profilePicURL": response.picture.data.url, "email": response.email}
            )
            if (props.type == AUTHENTICATION_TYPES.REGISTER) {
               window.dataLayer = window.dataLayer || [];
               window.dataLayer.push({
                  'event': 'signup',
                  'type': 'facebook',
                  'timeStamp': Date.now()
               });
            }
         }}
         callback={responseFacebook}
         render={renderProps => (
            <button disabled={click}
                    className={Classnames(Styles.BlackSkBtn)}
                    onClick={(e) => handleDisableClick()} onClickCapture={renderProps.onClick}>
               <img src={FaceBookIcon} alt={""}/>
               <text>{props.labalText}</text>
            </button>
         )}
      />
   )
}

const mapStateToProps = state => {
   return {
      isAuthenticated: state.auth.token !== null,
   }
}

const mapDispatchToProps = dispatch => {
   return {
      onAuth: (accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email) => dispatch(actions.auth(accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email))
   }
}
export default connect(mapStateToProps, mapDispatchToProps)(FacebookLoginButton);
