export const SubscribeUserToNewsLetter = async (userEmail) => {
   const JSONData = {email_address: userEmail, status: 'subscribed'};

   try {
      const response = await fetch('/members', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         'Authorization': 'Basic bb2899e5c30a4e0a8a6996c15a137f88-us18',
         },
         body: JSON.stringify(JSONData),
      });

      return {
         status: response.status,
         data: response.data
      };
   } catch (error) {
      console.error('An error occurred:', error);
   }
};
