import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {refreshToken, validateToken} from "../services/UserService";
import * as actions from "../store/actions";
import {decryptData} from "./SecureLocalStorage";

export function useAuthTokenValidator() {
   const [isFirstRender, setIsFirstRender] = useState(true)
   const dispatch = useDispatch();

   useEffect(() => {

      async function validateAndRefreshTokens() {
         if (decryptData(localStorage.getItem('accessToken')) === false || decryptData(localStorage.getItem("refreshToken")) === false) {
            console.log("error in decryption")
            dispatch(actions.logout());
         }
         try {
            const validateAuthAccess = await validateToken(decryptData(localStorage.getItem('accessToken')));

            if (validateAuthAccess.data.apiStatus === false) {
               const validateAuthRefresh = await validateToken(decryptData(localStorage.getItem("refreshToken")));

               if (validateAuthRefresh.data.apiStatus === true) {
                  if (decryptData(localStorage.getItem('userName')) !== null) {
                     const tokenResponse = await refreshToken(decryptData(localStorage.getItem('userName')), decryptData(localStorage.getItem("refreshToken")));
                     if (tokenResponse.data?.accessToken && tokenResponse.data?.refreshToken) {
                        dispatch(actions.refreshSession(tokenResponse.data.accessToken, tokenResponse.data.refreshToken));
                     } else {
                        dispatch(actions.logout());
                     }
                  }
               } else {
                  dispatch(actions.logout());
               }
            }
         } catch (error) {
            console.error('Error during token validation:', error);
            dispatch(actions.logout());
         }
      }

      if (isFirstRender) {
         validateAndRefreshTokens();
         setIsFirstRender(false);
      }
   }, [dispatch, isFirstRender]);

}
