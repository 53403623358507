import classNames from "classnames";
import styles from "../../containers/Leaderboard/LeaderBoard.module.scss";
import {Link} from "react-router-dom";
import {
   formatNumberToKWithoutRounding,
   getActualUsdValueFromPoints,
   getPlayerShowingName,
   getSprtsValueFromPoints,
   getUsdValueFromPoints,
   toolTipProvider
} from "../../helpers/CommonFunctions";
import React, {useEffect, useState} from "react";
import {getBonusPoint, getPosition, getProfileImage, getUpDownSameIconByDirection, pointsPerGame} from "../../helpers/LeaderBoardHelpers";
import {useTranslation} from "react-i18next";
import OmniPoints from "../../assets/images/icons/24_xp.webp";
import {ReactComponent as FlashIcon} from "../../assets/images/icons/flash.svg";

const LeaderBoardRow = ({leader, userNameOfCurrentUser, usdValue, selectedRadio, getGreenOrPurpleCap, location, handleViewTeamBtn, xpToSprtsRatio, rewardConfig}) => {
   const [playerName, setPlayerName] = useState("");

   const {t} = useTranslation();

   useEffect(() => {
      setPlayerName(getPlayerShowingName(leader))
   }, [leader])

   const positionToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.POSITION"), "top")
   const usdValueToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.USD_VALUE"), "top")

   return (
      <tr key={leader.userName}
          className={leader?.userName === userNameOfCurrentUser ? classNames(styles.leaderBoardHigh) : undefined}
          style={{position: "relative"}}>
         <td className={classNames(styles.multirow)}>
            <div>
               {positionToolTip(
                  <label className={classNames(styles.CorrectPresentCell, "CorrectPresent")}>
                     <>
                        <i>{getPosition(leader?.position)}</i>
                     </>
                  </label>, true)}
            </div>
         </td>
         <td className={classNames(styles.multirow)}>
            <div>
               {toolTipProvider(t("LEADER_BOARD_ROW.YOU_CANN'T_VISIT_A_PRIVATE_USER'S_PROFILE"), 'right', <div>
                  <Link to={leader.isPrivate && (leader?.userName != userNameOfCurrentUser) ? '' : {
                     pathname: `/user/${btoa(leader.userName)}`,
                     search: `returnUrl=${encodeURIComponent(location.pathname)}`
                  }} style={{textDecoration: 'none', cursor: `${leader?.isPrivate && (leader?.userName != userNameOfCurrentUser) ? "default" : "pointer"}`}}>
                     {getProfileImage(leader?.profilePicUrl, playerName.split(' ')[0], playerName.split(' ')[1])}
                  </Link>
                  <label className="CorrectPresent">
                     <>
                        {playerName}
                        {(selectedRadio == 'tournament') &&
                           <label className={classNames(styles.CorrectPresentCell, "CorrectPresent")}>
                              {getBonusPoint(leader?.position, leader?.points, rewardConfig)}
                           </label>}
                     </>
                  </label>
               </div>, (leader.isPrivate && (leader?.userName != userNameOfCurrentUser)))}

            </div>
         </td>
         <td className={classNames(styles.multirow)}>
            <div className="row p-0">
               <div className="col-2 p-0 mr-2">
                  <img className="" style={{cursor: 'default'}} src={OmniPoints}/>
               </div>
               <div className={classNames(styles.ViewTeamBtnCell, "float-right text-end col-5 p-0 greenText d-block")}>
                  {leader?.points > 999 ?
                     <div className={'flex-row gap-2'}>
                        {toolTipProvider(leader?.points, 'top',
                           <label
                              className="CorrectPresent">{formatNumberToKWithoutRounding(leader?.points)}</label>, true)}
                     </div> : <>
                        <label className="CorrectPresent">{formatNumberToKWithoutRounding(leader?.points)}</label>
                     </>}
               </div>
               <div className="col-2 p-0">
                  {getUpDownSameIconByDirection(leader?.direction)}
               </div>
            </div>
         </td>
         <td className={classNames(styles.multirow)}>
            {(() => {
               const usdValueFromPoints = getUsdValueFromPoints(leader?.points, xpToSprtsRatio, usdValue);
               const ActualUsdValueFromPoints = getActualUsdValueFromPoints(leader?.points, xpToSprtsRatio, usdValue);
               return toolTipProvider(
                  ActualUsdValueFromPoints,
                  'top',
                  <label className="CorrectPresent">{usdValueFromPoints}</label>,
                  true
               );
            })()}
         </td>

         <td className={classNames(styles.multirow)}>
            {usdValueToolTip(<label className="CorrectPresent">
               {getSprtsValueFromPoints(leader?.points, xpToSprtsRatio)}
            </label>, true)}
         </td>
         {selectedRadio === 'global' &&
            <td className={classNames(styles.multirow)}>
               <label className="CorrectPresent">
                  {leader?.userName === userNameOfCurrentUser ?
                     <div className={'flex-row gap-2'}>
                        {toolTipProvider(leader?.fantasyPoints, 'top', <label className="CorrectPresent">{formatNumberToKWithoutRounding(leader?.fantasyPoints) || 0}</label>, true)}
                     </div> :
                     <>
                        {toolTipProvider(leader?.fantasyPoints, 'top',
                           <label className="CorrectPresent">{formatNumberToKWithoutRounding(leader?.fantasyPoints) || 0}</label>, true)}
                     </>
                  }
               </label>
            </td>}
         <td className={classNames(styles.multirow)}>
            <label className="CorrectPresent"><>{leader?.matches || 0}</>
            </label>
         </td>
         <td className={classNames(styles.multirow)}>
            {toolTipProvider((pointsPerGame(leader?.matches, leader?.points)), 'top',
               <label className="CorrectPresent">
                  <>{formatNumberToKWithoutRounding(pointsPerGame(leader?.matches, leader?.points))}</>
               </label>, true)}
         </td>
         {selectedRadio === 'tournament' &&
            <td>
               <div className="Boostount">
                  <div className="BoostIndicatorIcon">
                     <div className="LeaderBoardBoostIconWrapper">
                        <div className={classNames(styles.Circle, "Circle")} style={{cursor: 'default'}}>
                           <div className={classNames(styles.InnerCircle, "InnerCircle")}></div>
                           <FlashIcon/>
                        </div>
                     </div>
                  </div>
                  <div className={classNames(styles.CurrentUserBoost, "BoostIndicatorIcon")}>
                     X <span> {leader?.boostCount ? leader?.boostCount : 0}</span>
                  </div>
               </div>

            </td>
         }
         <td>
            {leader?.userName === userNameOfCurrentUser && (selectedRadio == 'tournament') &&
               <button className={classNames(styles.ViewTeamBtn)} onClick={handleViewTeamBtn}>
                  {t("LEADER_BOARD_ROW.TEAM")}
               </button>}

         </td>
      </tr>
   )
}
export default LeaderBoardRow;
