import React, {useEffect, useMemo, useReducer, useRef, useState} from "react";
import styles from "./userProfile.module.scss";
import styless from "../../components/QuizBot/BallByBallCard.module.scss";
import classnames from "classnames";
import classNames from "classnames";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import * as UserService from "../../services/UserService";
import * as FavouriteService from "../../services/FavouriteService";
import * as FileService from "../../services/FileService";
import {ASSET_UPLOAD_TYPES, AUTHTOKEN_ERRORS, PIC_UPLOAD_SIZES, PROFILE_PRIVACY, SOCIAL_MEDIA_TYPES} from "../../utilities/Constants";
import Footer from "../../components/Footer/Footer";
import {BsBoxArrowUp, BsPencil, BsXLg} from "react-icons/bs";
import ProgressBar from "react-bootstrap/ProgressBar";
import ImageCrop from "../../components/ImageCrop/ImageCrop";
import useDebounce, {birthDayValidator, bytesToMegaBytes, formatDate, formatNumberToKWithoutRounding, formatTime, toolTipProvider} from "../../helpers/CommonFunctions";
import HeartActive from "../../assets/images/HeartActive.svg";
import HeartDefault from "../../assets/images/HeartDefault.svg";
import ProfileImgPlaceholder from "../../assets/images/ProfileImgPlaceholder.webp";
import TitileImgPlaceholder from "../../assets/images/TitileImgPlaceholder.webp";
import Logo_Q_BOT from "../../assets/images/Logo_Q_BOT.webp";
import twodots from "../../assets/images/2dots.svg"
import {HiExternalLink} from "react-icons/hi";
import {toast} from 'react-toastify';
import {COMMON_TOASTS, USER_PROFILE_TOASTS} from "../../utilities/ToastMessages";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import Modal from "react-bootstrap/Modal";
import * as InteractionService from "../../services/InteractionService";
import * as LeaderBoardService from "../../services/LeaderBoardService";
import {getConf} from "../../services/ConfigService";
import {MATCH_PAGE, USER_PROFILE_PAGE} from "../../utilities/ConfigConstants";
import {useTranslation} from "react-i18next";
import {getDisNameForFirst3Users, getDisplayName, getPosition, getProfileImage as renderProfileImg, getUpDownSameIconByDirection, handleProfileImageError,} from "../../helpers/LeaderBoardHelpers";
import Paginator from "../../components/Paginator/Paginator";
import BotHistoryModel from "../../components/Modals/BotHistoryModel/BotHistoryModel";
import {LBPrivateUserNavigateMessage} from "../../utilities/CommonMessages";
import NotificationSection from "../../components/Notifcation/NotificationSection";
import SocialShareModal from "../../components/Modals/SocialShareModal/SocialShareModal";
import {FaCheck} from "react-icons/fa6";
import {IoClose} from "react-icons/io5";
import DeleteAccHeaderImg from "../../assets/images/delete_profile_img.webp";
import OMNICreditsBuyWithdrawalModal from "../../components/Wallet/OMNICreditsBuyWithdrawalModal/OMNICreditsBuyWithdrawalModal";
import CreditIcon from "../../assets/images/icons/24_sprts_token.webp";
import OmniPoints from "../../assets/images/icons/24_xp.webp";
import * as WalletService from "../../services/WalletService";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import FantasyBotHistory from "../../components/FantasyBotHistory/FantasyBotHistory";
import UserDataChannelHandler from "../../services/PubNubHandlers/UserDataChannelHandler";
import * as PubNubService from "../../services/PubNubService";
import TeamsCreated from "../../assets/images/icons/Teams_created.svg";
import GloblePosition from "../../assets/images/icons/Globle_Position.svg";

const UserProfileV2 = (props) => {
   const {userName} = useParams();

   let encodedUserName = atob(userName);
   let SHOW_USER_PROFILE_ADVERTISEMENT_URL = getConf(USER_PROFILE_PAGE.SHOW_USER_PROFILE_ADVERTISEMENT_URL);
   const location = useLocation();
   const navigate = useNavigate();
   const [matchByUser, setMatchByUser] = useState([]);
   const [voteData, setVoteData] = useState([]);
   const [linkInvalidError, setLinkInvalidError] = useState([]);
   const [userLeaderBoard, setUserLeaderBoard] = useState([]);
   const [dateFormatError, setDateFormatError] = useState('')
   const [dateError, setDateError] = useState(false);
   const [birthDate, setBirthDate] = useState({
      day: '', month: '', year: ''
   })
   const [currentPage, setCurrentPage] = useState(0);
   const [postsPerPage, setPostsPerPage] = useState(3);
   const [totalElements, setTotalElements] = useState(0);
   const [modalMatchId, setModalMatchId] = useState(0);
   const [questionIndex, setQuestionIndex] = useState(0);
   const [showModal, setShowModal] = useState(false);
   const [loading, setLoading] = useState(false);
   const [sortedMatchArray, setSortedMatchArray] = useState([]);
   const qBotHistoryTitleRef = useRef(null);
   const [pageLang, setPageLang] = useState(localStorage.getItem('language'));
   const [nextGotItIndex, setNextGotItIndex] = useState(0)
   const [tipIndexCounter, setTipIndexCounter] = useState(1);
   const [isBuyWithdrawalModalShow, setIsBuyWithdrawalModalShow] = useState(false);
   const [walletDetails, setWalletDetails] = useState([]);
   const [subscribeChannel, setSubscribeChannel] = useState(null);
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);
   const [pubNubToken, setPubNubToken] = useState(null);
   const [connectPubnub, setConnectPubnub] = useState(false);

   const [isPersonalDetailsUpdated, setIsPersonalDetailsUpdated] = useState(false);
   const [beforeEditValues, setBeforeEditValues] = useState({});

   const handleOpenModal = (matchId, index) => {
      setShowModal(true);
      setModalMatchId(matchId);
      if (index) {
         setQuestionIndex(index);
      } else {
         setQuestionIndex(0);
      }

   };

   const handleCloseModal = () => {
      setShowModal(false);
   };

   const handleBuyWithdrawalModalClose = () => {
      setIsBuyWithdrawalModalShow(false)
   }

   const handleBuyWithdrawalModalOpen = () => {
      // toast.info(t("USER_PROFILE_PAGE.EXCITING_NEWS_OMNI_CREDIT_WITHDRAWALS_WITH_A_30_CREDIT_MINIMUM_AVAILABLE_NEXT_UPDATE"));
      setIsBuyWithdrawalModalShow(true)
   };

   const initialState = {
      userDetails: {},
      leaderBoardDetails: [],
      firstLeaderDetails: {},
      secondLeaderDetails: {},
      thirdLeaderDetails: {},
      forthLeaderDetails: null,
      userQuestionOverview: {},
      toggleStatus: false,
      showProgress: false,
      confirmSubmission: false,
      coverModalShow: false,
      imageType: "",
      maxFileSizeExceed: false,
      confirmAssetDelete: false,
      confirmAccountDelete: false,
      invalidFileUpload: false,
      progress: 0,
      uploadStatus: null,
      uploadButtonDisable: false,
      display: 'none',
      modalResponseShow: false,
      file: null,
      image: null,
      edit: false,
      email: props.email,
      joinedTime: "",
      emailValid: true,
      emailError: "",
      myCreations: [],
      userValid: false,
      favouritePlayers: [],
      userNotifications: [],
      favouriteTeams: [],
      userFollowings: [],
      user: {},
      userLeaderboardRank: 0,
      totalFavouriteTeamCount: 0,
      totalFavouritePlayerCount: 0,
      leaderBoardStatus: true,

      editedBio: '',
      editedMobile: '',
      editedFname: '',
      editedLname: '',
      editedEmail: props.email,
      editedFacebokLink: '',
      editedTwitterLink: '',
      editedInstagramLink: '',
      editedTikTokLink: '',
      editedDob: '',
      editedPrivacy: false,

      facebookLinkError: '',
      instagramLinkError: '',
      twitterLinkError: '',
      tiktokLinkError: '',

      isGettingUserByUsername: false,
      isGettingUserByUserNameWithoutToken: false,
      isGettingFavoritePlayers: false,
      isGettingFavoriteTeams: false,
      isGettingNotifications: false,
      isDeletingAsset: false,
      isShowingSocialModal: false,

      deleteUserModalShow: false,
      deleteConfirmation: false
   };

   const paginate = (pageNumber, postsPerPage) => {
      setPostsPerPage(Number(postsPerPage));
      setCurrentPage(pageNumber);
      setLoading(true);
   };

   const profileReducer = (state, action) => {
      switch (action.type) {
         case 'SET_USER_DETAILS':
            return {...state, userDetails: action.payload};
         case 'SET_FORTH_LEADERDETAILS':
            return {...state, forthLeaderDetails: action.payload};
         case 'SET_USER_QUESTION_OVERVIEW':
            return {...state, userQuestionOverview: action.payload};
         case 'SET_USER_LEADERBOARD_RANK':
            return {...state, userLeaderboardRank: action.payload};
         case 'SET_TOTAL_FAVOURITE_TEAM_COUNT':
            return {...state, totalFavouriteTeamCount: action.payload};
         case 'SET_USER_FOLLOWINGS':
            return {...state, userFollowings: action.payload};
         case 'SET_TOTAL_FAVOURITE_PLAYER_COUNT':
            return {...state, totalFavouritePlayerCount: action.payload};
         case 'SET_USER_LEADERBOARD':
            return {...state, leaderBoardDetails: action.payload};
         case 'SET_FIRST_LEADERDETAILS':
            return {...state, firstLeaderDetails: action.payload};
         case 'SET_SECOND_LEADERDETAILS':
            return {...state, secondLeaderDetails: action.payload};
         case 'SET_THIRD_LEADERDETAILS':
            return {...state, thirdLeaderDetails: action.payload};
         case 'SET_TOGGLE_STATUS':
            return {...state, toggleStatus: action.payload};
         case 'SHOW_PROGRESS':
            return {...state, showProgress: action.payload};
         case 'CONFIRM_SUBMISSION':
            return {...state, confirmSubmission: action.payload};
         case 'COVER_MODAL_SHOW':
            return {...state, coverModalShow: action.payload};
         case 'IMAGE_TYPE':
            return {...state, imageType: action.payload};
         case 'MAX_FILE_EXCEED':
            return {...state, maxFileSizeExceed: action.payload};
         case 'CONFIRM_ASSET_DELETE':
            return {...state, confirmAssetDelete: action.payload};
         case 'CONFIRM_ACCOUNT_DELETE':
            return {...state, confirmAccountDelete: action.payload};
         case 'INVALID_FILE_UPLOAD':
            return {...state, invalidFileUpload: action.payload};
         case 'PROGRESS':
            return {...state, progress: action.payload};
         case 'UPLOAD_STATUS':
            return {...state, uploadStatus: action.payload};
         case 'UPLOAD_BUTTON_DISABLE':
            return {...state, uploadButtonDisable: action.payload};
         case 'DISPLAY':
            return {...state, display: action.payload};
         case 'MODEL_RESPONSE_SHOW':
            return {...state, modalResponseShow: action.payload};
         case 'FILE':
            return {...state, file: action.payload};
         case 'IMAGE':
            return {...state, image: action.payload};
         case 'EDIT':
            return {...state, edit: action.payload};
         case 'EMAIL':
            return {...state, email: action.payload};
         case 'EDITED_FNAME':
            return {...state, editedFname: action.payload};
         case 'EDITED_EMAIL':
            return {...state, editedEmail: action.payload};
         case 'EDITED_MOBILE':
            return {...state, editedMobile: action.payload};
         case 'EDITED_LNAME':
            return {...state, editedLname: action.payload};
         case 'EDITED_BIO':
            return {...state, editedBio: action.payload};
         case 'EDITED_TWITTERLINK':
            return {...state, editedTwitterLink: action.payload};
         case 'EDITED_FACEBOOKLINK':
            return {...state, editedFacebokLink: action.payload};
         case 'EDITED_INSTAGRAMLINK':
            return {...state, editedInstagramLink: action.payload};
         case 'EDITED_TIKTOKLINK':
            return {...state, editedTikTokLink: action.payload};
         case 'EDITED_DOB':
            return {...state, editedDob: action.payload};
         case 'EDITED_PRIVACY':
            return {...state, editedPrivacy: action.payload};
         case 'JOINED_TIME':
            return {...state, joinedTime: action.payload};
         case 'EMAIL_VALID':
            return {...state, emailValid: action.payload};
         case 'EMAIL_ERROR':
            return {...state, emailError: action.payload};
         case 'MY_CREATIONS':
            return {...state, myCreations: action.payload};
         case 'EMAIL_VALIDATION':
            return {...state, emailValid: action.payload.emailValid, emailError: action.payload.emailError};
         case 'ASSET_DELETE_DETAILS':
            return {...state, image: action.payload.image, imageType: action.payload.imageType};
         case 'USER_VALIDATE':
            return {...state, userValid: action.payload};
         case 'LEADERBOARD_STATUS':
            return {...state, leaderBoardStatus: action.payload}
         case 'MODAL_VALUE_RESET':
            return {
               ...state,
               coverModalShow: action.payload.coverModalShow,
               modalResponseShow: action.payload.modalResponseShow,
               confirmAssetDelete: action.payload.confirmAssetDelete,
               confirmAccountDelete: action.payload.confirmAccountDelete,
               confirmSubmission: action.payload.confirmSubmission
            };
         case 'GET_IMAGE':
            return {
               ...state,
               image: action.payload.image,
               coverModalShow: action.payload.coverModalShow,
               modalResponseShow: action.payload.modalResponseShow,
               confirmSubmission: action.payload.confirmSubmission
            };
         case 'CLOSE_MODAL_VALUE_RESET':
            return {
               ...state,
               coverModalShow: action.payload.coverModalShow,
               display: action.payload.display,
               file: action.payload.file,
               modalResponseShow: action.payload.modalResponseShow,
               confirmAssetDelete: action.payload.confirmAssetDelete,
               confirmAccountDelete: action.payload.confirmAccountDelete,
               invalidFileUpload: action.payload.invalidFileUpload,
               uploadButtonDisable: action.payload.uploadButtonDisable,
               maxFileSizeExceed: action.payload.maxFileSizeExceed,
               blob: action.payload.blob,
               croppedArea: action.payload.croppedArea,
               croppedAreaPixels: action.payload.croppedAreaPixels
            };
         case 'SET_FAVOURITE_PLAYERS':
            return {...state, favouritePlayers: action.payload};
         case 'SET_USER_NOTIFICATIONS':
            return {...state, userNotifications: action.payload};
         case 'UPDATE_USER_NOTIFICATIONS':
            return {...state, userNotifications: [...action.payload, ...state.userNotifications]}
         case 'SET_FAV_RES_TRIGGER':
            return {...state, user: action.payload};
         case 'SET_FAVOURITE_TEAMS':
            return {...state, favouriteTeams: action.payload};
         case 'SET_FACEBOOK_LINK_ERROR':
            return {...state, facebookLinkError: action.payload};
         case 'SET_INSTAGRAM_LINK_ERROR':
            return {...state, instagramLinkError: action.payload};
         case 'SET_TWITTER_LINK_ERROR':
            return {...state, twitterLinkError: action.payload};
         case 'SET_TIKTOK_LINK_ERROR':
            return {...state, tiktokLinkError: action.payload};
         case 'IS_GETTING_USER_BY_USERNAME':
            return {...state, isGettingUserByUsername: action.payload};
         case 'IS_GETTING_USER_BY_USERNAME_WITHOUT_TOKEN':
            return {...state, isGettingUserByUserNameWithoutToken: action.payload};
         case 'IS_GETTING_FAVOURITE_PLAYERS':
            return {...state, isGettingFavoritePlayers: action.payload};
         case 'IS_GETTING_FAVOURITE_TEAMS':
            return {...state, isGettingFavoriteTeams: action.payload};
         case 'IS_GETTING_NOTIFICATIONS':
            return {...state, isGettingNotifications: action.payload};
         case 'IS_DELETING_ASSET':
            return {...state, isDeletingAsset: action.payload};
         case 'IS_SHOWING_SOCIAL_SHARE_MODAL':
            return {...state, isShowingSocialModal: action.payload};
         case 'IS_SHOWING_USER_DELETION_MODAL':
            return {...state, deleteUserModalShow: action.payload};
         case 'IS_USER_DELETION_CONFIRM':
            return {...state, deleteConfirmation: action.payload};
         default:
            return state;
      }
   }

   const [state, dispatch] = useReducer(profileReducer, initialState)

   const deleteUserProfile = async () => {
      const toastId = toast.loading(`${t("USER_PROFILE_PAGE.DELETING_YOUR_PROFILE_SEE_YOU_SOON")}`)
      UserService.deleteUser(props.userName, props.Token).then((response) => {
         if (response.data.apiStatus == true) {

            toast.update(toastId, {
               render: `${t("USER_PROFILE_PAGE.DELETE_SUCCESS_TAKING_YOU_HOME")}`,
               type: toast.TYPE.SUCCESS,
               autoClose: 5000,
               closeButton: null,
               isLoading: false
            })

            props.onLogout();
            navigate('/');
         } else {
            toast.update(toastId, {
               render: `${t("USER_PROFILE_PAGE.FAILED_TO_DELETE_YOUR_ACCOUNT_PLEASE_TRY_AGAIN_LATER")}`,
               type: toast.TYPE.ERROR,
               autoClose: 5000,
               closeButton: null,
               isLoading: false
            })
         }
      }).catch(err => console.error('Error occurred while deleting the user profile', err));
   }

   const GetUserByUserName = async () => {

      if (props.isAuthenticated && encodedUserName == props.userName && encodedUserName != null) {
         dispatch({type: 'IS_GETTING_USER_BY_USERNAME', payload: true});
         UserService.GetUserByUserName(encodedUserName, props.Token).then((response) => {
            if (response.apiStatus) {
               if (response.user.private && !props.isAuthenticated && !(response.user.username == props.userName)) {
                  navigate('/');
               }
               let dateTimeArray = String(response.user.joinedTime).split("T");
               let profileName = response.user.firstName + " " + (response.user.lastName ?? "");
               props.onProfileNameUpdate(profileName);
               dispatch({type: 'SET_USER_DETAILS', payload: response});
               dispatch({type: 'JOINED_TIME', payload: dateTimeArray[0]});
               dispatch({type: 'EDITED_FNAME', payload: response.user.firstName});
               dispatch({type: 'EDITED_LNAME', payload: (response.user.lastName ?? "")});
               dispatch({type: 'EDITED_BIO', payload: response.user.bio});
               dispatch({type: 'EDITED_MOBILE', payload: response.user.mobile});
               dispatch({type: 'EDITED_TWITTERLINK', payload: response.user.twitter});
               dispatch({type: 'EDITED_FACEBOOKLINK', payload: response.user.facebook});
               dispatch({type: 'EDITED_INSTAGRAMLINK', payload: response.user.instagram});
               dispatch({type: 'EDITED_TIKTOKLINK', payload: response.user.tiktok});
               dispatch({type: 'EDITED_DOB', payload: response.user.dob});
               dispatch({type: 'EDITED_PRIVACY', payload: response.user.private});

               if (response.user.dob) {
                  const date = response.user.dob.split('T')[0].split('-')
                  setBirthDate({...birthDate, day: date[2], month: date[1], year: date[0]});
               } else {
                  setBirthDate({...birthDate, day: '', month: '', year: ''});
               }
            } else if (response.status == 401) {
               // props.onLogout();
               navigate('/');
            } else {
               // toast.error(USER_PROFILE_TOASTS.ERROR_OCCURED_GETTING_USER_DETAILS_TRY_RELOADING_THE_PAGE);
            }
         })
            .catch(err => console.error('Error occurred when getting user by username', err))
            .finally(() => dispatch({type: 'IS_GETTING_USER_BY_USERNAME', payload: false}))
      } else {
         GetUserByUserNameWithoutToken();
      }
   }

   const GetUserByUserNameWithoutToken = async () => {
      dispatch({type: 'IS_GETTING_USER_BY_USERNAME_WITHOUT_TOKEN', payload: true});

      UserService.GetUserByUserNameWithoutToken(encodedUserName)
         .then((response) => {
            if (response.apiStatus) {
               if ((response.user.private && props.userName == null) || (response.user.private && (response.user.username != props.userName))) {
                  navigate('/');
               }

               let dateTimeArray = String(response.user.joinedTime).split("T");

               dispatch({type: 'SET_USER_DETAILS', payload: response});
               dispatch({type: 'JOINED_TIME', payload: dateTimeArray[0]});
               dispatch({type: 'EDITED_FNAME', payload: response.user.firstName});
               dispatch({type: 'EDITED_LNAME', payload: (response.user.lastName ?? "")});
               dispatch({type: 'EDITED_BIO', payload: response.user.bio});
               dispatch({type: 'EDITED_MOBILE', payload: response.user.mobile});
               dispatch({type: 'EDITED_TWITTERLINK', payload: response.user.twitter});
               dispatch({type: 'EDITED_FACEBOOKLINK', payload: response.user.facebook});
               dispatch({type: 'EDITED_INSTAGRAMLINK', payload: response.user.instagram});
               dispatch({type: 'EDITED_TIKTOKLINK', payload: response.user.tiktok});
               dispatch({type: 'EDITED_DOB', payload: response.user.dob});
               dispatch({type: 'EDITED_PRIVACY', payload: response.user.private});

               if (response.user.dob) {
                  const date = response.user.dob?.split('T')[0]?.split('-')
                  setBirthDate({...birthDate, day: date[2]});
                  setBirthDate({...birthDate, month: date[1]});
                  setBirthDate({...birthDate, year: date[0]});
               } else {
                  setBirthDate({...birthDate, day: ''});
                  setBirthDate({...birthDate, month: ''});
                  setBirthDate({...birthDate, year: ''});
               }

            } else {
               // toast.error(USER_PROFILE_TOASTS.ERROR_OCCURED_GETTING_USER_DETAILS_TRY_RELOADING_THE_PAGE);
            }
         })
         .catch(err => console.error('Error occurred when getting user without token', err))
         .finally(() => {
            dispatch({type: 'IS_GETTING_USER_BY_USERNAME_WITHOUT_TOKEN', payload: false})
         })
   }

   const handleNewNotificationReceive = (message) => {

      console.log("user data user profile", state.userNotifications)
      if (message?.notifications) {
         getNotifications();
      }
   }

   const handelWalletDataRecived = (messages) => {
   }

   useEffect(() => {

      const fetchData = async () => {
         try {
            const response = await PubNubService.requestAccessTokenQuiz(props.userName, 1234, 1234, props.Token).then(response => {
               if (response != null) {
                  setPubNubToken(response);
                  setConnectPubnub(true);
               }
            });

         } catch (error) {
            console.error("Error:", error);
         }

      };
      fetchData();
   }, [props.userName]);

   useEffect(() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
      if (props.isAuthenticated === false) {
         GetUserByUserNameWithoutToken();
      } else {
         GetUserByUserName();
      }
   }, [props.isAuthenticated, encodedUserName])
   const assetDeleteConfirmationDialog = (fileName, assetContainer) => {
      dispatch({
         type: 'CONFIRM_ASSET_DELETE', payload: true
      })
      dispatch({
         type: 'ASSET_DELETE_DETAILS', payload: {
            image: fileName, imageType: assetContainer
         }
      })
   }

   const accountDeleteConfirmationDialog = () => {
      dispatch({
         type: 'CONFIRM_ACCOUNT_DELETE', payload: true
      })
   }

   useEffect(() => {
      LeaderBoardService.getUserForGlobalLB(encodedUserName).then((res) => {
         if (res?.apiStatus) {
            dispatch({type: 'SET_FORTH_LEADERDETAILS', payload: res?.position})
         }
      })
   }, [encodedUserName]);

   useEffect(() => {
      UserService.getPastMatchesByUsername(encodedUserName, currentPage, postsPerPage, "matchId,desc", pageLang).then((res) => {
         if (res?.apiStatus) {
            if (res.map) {
               let sortedArray = Object.values(res.map)
                  .sort((a, b) => b.matchDto.matchId - a.matchDto.matchId);
               setSortedMatchArray(sortedArray);
            }
            setMatchByUser(res);
            setTotalElements(res.totalElements);
            setLoading(false);
         }
      })
   }, [currentPage]);

   const memoizedBirthDay = useMemo(() => ({
      day: birthDate.day, month: birthDate.month, year: birthDate.year
   }), [birthDate.day, birthDate.month, birthDate.year]);

   useEffect(() => {
      if (isNaN(birthDate.day) || parseInt(birthDate.day) > 31) {
         setBirthDate({...birthDate, day: ''})
      } else if (isNaN(birthDate.month) || parseInt(birthDate.month) > 12) {
         setBirthDate({...birthDate, month: ''})
      } else if (isNaN(birthDate.year)) {
         setBirthDate({...birthDate, year: ''})
      }

      let valid = birthDayValidator(birthDate.day, birthDate.month, birthDate.year);
      if (valid) {
         setDateFormatError("");
         setDateError(false)
      } else {
         setDateFormatError(`${t("USER_PROFILE_PAGE.BIRTHDATE_IS_NOT_VALID")}`);
         setDateError(true)
      }

   }, [memoizedBirthDay]);

   useEffect(() => {
      const fetchData = () => {
         if (props.Token && props.userName) {
            WalletService.getOmniCreditsByUsername(props.Token, props.userName)
               .then(res => {
                  if (res?.data?.apiStatus) {
                     setWalletDetails(res.data);
                  } else if (res?.status === 400 && res?.data?.rejectMessages[0] === AUTHTOKEN_ERRORS.AUTHENTICATION_TOKEN_HAS_EXPIRED) {
                     // props.onLogout();
                  }
               });
         }
      };

      fetchData();

   }, [props.Token, props.userName]);


   useEffect(() => {
      if (props.userName == encodedUserName) {
         dispatch({type: 'IS_GETTING_FAVOURITE_PLAYERS', payload: true});
         const getFavouritePlayers = async () => {
            FavouriteService.getFavourites(props.Token, "player", props.userName, 0, 6).then((response) => {
               if (response.data.apiStatus) {
                  dispatch({type: 'SET_FAVOURITE_PLAYERS', payload: response.data.userFavourites});
                  dispatch({type: 'SET_TOTAL_FAVOURITE_PLAYER_COUNT', payload: response.data.totalElements});
               } else if (response.status == 401) {
                  //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
               }
            })
               .catch((err) => console.error('Error occurred when getting favourite players'))
               .finally(() => dispatch({type: 'IS_GETTING_FAVOURITE_PLAYERS', payload: false}))
         }
         getFavouritePlayers();
      }
   }, [encodedUserName]);

   useEffect(() => {
      if (props.userName == encodedUserName) {
         const getFavouriteTeams = async () => {
            dispatch({type: 'IS_GETTING_FAVOURITE_TEAMS', payload: true});
            FavouriteService.getFavourites(props.Token, "team", props.userName, 0, 6).then((response) => {
               if (response.data.apiStatus) {
                  dispatch({type: 'SET_FAVOURITE_TEAMS', payload: response.data.userFavourites});
                  dispatch({type: 'SET_TOTAL_FAVOURITE_TEAM_COUNT', payload: response.data.totalElements});
               } else if (response.status == 401) {
                  //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
               } else {
                  toast.error(USER_PROFILE_TOASTS.ERROR_OCCURED_GETTING_USER_FAVOURITE_TEAMS_TRY_RELOADING_THE_PAGE);
               }
            })
               .catch((err) => console.error('Error occurred when getting favourite teams.', err))
               .finally(() => dispatch({type: 'IS_GETTING_FAVOURITE_TEAMS', payload: false}))
         }
         getFavouriteTeams();
      }
   }, [encodedUserName]);

   useEffect(() => {
      // Scroll to qBotHistoryTitleRef when modal is open
      if (showModal && qBotHistoryTitleRef.current) {
         qBotHistoryTitleRef.current.scrollIntoView({behavior: 'smooth'});
      }
   }, [showModal]);

   // useEffect(() => {
   //     if (props.userName == userName) {
   //         setLoading(true);
   //         const getUserArtworks = async () => {
   //             const myCreationsResponse = UserService.getArtworkByUserNameAndType(props.Token, 'UPLOADED', props.userName, 0, 3, 'createdTime,desc');
   //             myCreationsResponse.then((response) => {
   //                 if (response.data.apiStatus) {
   //                     dispatch({type: 'MY_CREATIONS', payload: response.data.artworks})
   //                     let votedData = [];
   //                     response?.data?.artworks?.map((artwork) => {
   //                         votedData.push({
   //                             artworkId: artwork?.artworkId, voted: artwork?.voted, count: artwork?.votes,
   //                         });
   //                         setVoteData([...voteData, ...votedData]);
   //                     });
   //                     setLoading(false);
   //                 } else if (response.status == 401) {
   //                     //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
   //                 } else {
   //                     toast.error(USER_PROFILE_TOASTS.ERROR_OCCURED_GETTING_USER_ARTWORKS_TRY_RELOADING_THE_PAGE);
   //                 }
   //             }).catch(err => console.log(err))
   //         }
   //         getUserArtworks();
   //     }
   // }, [userName]);


   const deleteAsset = async () => {
      let user = {};
      const imageName = state.image.split('/').pop();
      cancelEditProfile();
      if (state.image) {
         if (imageName.endsWith('.png') || imageName.endsWith('.jpg') || imageName.endsWith('.jpeg')) {
            const deleteAssetResponse = FileService.deleteAsset(imageName, state.imageType, props.Token, props.email, props.userName);
            deleteAssetResponse.then((response) => {
               if (response.data.apiStatus) {
                  let user = {};
                  if (state.imageType == ASSET_UPLOAD_TYPES.PROFILE) {
                     props.onProfileUpdate("");
                     user = {
                        email: props.email,
                        username: props.userName,
                        profilePicURL: null,
                        private: state.userDetails?.user?.private,
                     };
                  } else if (state.imageType == ASSET_UPLOAD_TYPES.COVER) {
                     user = {
                        email: props.email,
                        username: props.userName,
                        coverPicURL: null,
                        private: state.userDetails?.user?.private
                     };
                  }
                  const updateProfile = UserService.updateUser(user, props.Token);
                  dispatch({type: 'IS_DELETING_ASSET', payload: true});
                  updateProfile.then((response) => {
                     if (response.data.apiStatus) {
                        toast.success(USER_PROFILE_TOASTS.IMAGE_DELETED_SUCCESSFULLY);

                        GetUserByUserName();
                        dispatch({type: 'CONFIRM_ASSET_DELETE', payload: false});
                        dispatch({type: 'EDIT', payload: false});
                        dispatch({type: 'IS_DELETING_ASSET', payload: false});
                     }
                  })
               } else if (response.status == 401) {
                  //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
               } else {
                  toast.error(USER_PROFILE_TOASTS.ERROR_OCCURED_DELETING_USER_ASSET_TRY_AGAIN);
               }
            });
         } else {
            if (state.imageType == ASSET_UPLOAD_TYPES.PROFILE) {
               user = {
                  email: props.email,
                  username: props.userName,
                  profilePicURL: null,
                  private: state.userDetails?.user?.private,
               };
            } else if (state.imageType == ASSET_UPLOAD_TYPES.COVER) {
               user = {
                  email: props.email,
                  username: props.userName,
                  coverPicURL: null,
                  private: state.userDetails?.user?.private,
               };
            }
            await UserService.updateUser(user, props.Token).then((response) => {
               if (response.data.apiStatus) {
                  GetUserByUserName();
                  dispatch({type: 'CONFIRM_ASSET_DELETE', payload: false});
                  dispatch({type: 'EDIT', payload: false})
               } else if (response.status == 401) {
                  //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
               } else {
                  toast.error(USER_PROFILE_TOASTS.ERROR_OCCURED_DELETING_USER_ASSET_TRY_AGAIN);
               }
            }).catch(err => console.error("Error occurred when deleting the asset", err))
         }
      } else {
         dispatch({type: 'CONFIRM_ASSET_DELETE', payload: false});
      }
   }

   useEffect(() => {
      if (props.userName == encodedUserName) {
         getNotifications();
      }
   }, [encodedUserName, pageLang, props.Token, props.userName]);

   const getNotifications = async () => {
      dispatch({type: 'IS_GETTING_NOTIFICATIONS', payload: true});
      InteractionService.GetNotificationsByUserName(encodedUserName.toLowerCase(), props.Token, 0, 'notifiedTime,desc', 10, pageLang).then((response) => {
         if (response?.data?.apiStatus) {
            dispatch({type: 'SET_USER_NOTIFICATIONS', payload: response.data.notifications});
         } else if (response.status === 401) {
            //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
         } else {
            // toast.error(COMMON_TOASTS.ERROR_OCCURED_GETTING_NOTIFICATIONS_TRY_RELOADING_THE_PAGE);
         }
      })
         .catch((err) => console.error("Error occurred when getting notifications", err))
         .finally(() => dispatch({type: 'IS_GETTING_NOTIFICATIONS', payload: false}))
   }

// Commenting till user following functions are getting implemented
   // useEffect(() => {
   //     if (props.userId != userId) {
   //         setLoading(true);
   //         const getUserFollowingList = async () => {
   //             await UserService.getUserFollowingFollowers(props.Token, 'followings', props.userId, 0, 100, 'id,desc').then((response) => {
   //                 if (response.data.apiStatus) {
   //                     dispatch({type: 'SET_USER_FOLLOWINGS', payload: response.data.users});
   //                     getUserFollowIcon(userId, props.userId);
   //                     setLoading(false);
   //                 } else if (response.status == 401) {
   //                     toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
   //                 } else {
   //                     toast.error(COMMON_TOASTS.ERROR_OCCURED_WHEN_GETTING_USER_FOLLOWINGS_PLEASE_TRY_AGAIN);
   //                 }
   //             })
   //         }
   //         getUserFollowingList();
   //     }
   // }, [userId]);

   useEffect(() => {
      dispatch({type: 'EDITED_FACEBOOKLINK', payload: state.userDetails?.user?.facebook});
      dispatch({type: 'EDITED_TIKTOKLINK', payload: state.userDetails?.user?.tiktok});
      dispatch({type: 'EDITED_TWITTERLINK', payload: state.userDetails?.user?.twitter});
      dispatch({type: 'EDITED_INSTAGRAMLINK', payload: state.userDetails?.user?.instagram});
      dispatch({type: 'EDITED_DISCORDLINK', payload: state.userDetails?.user?.discord});

      dispatch({type: 'EDITED_FNAME', payload: state.userDetails?.user?.firstName});
      dispatch({type: 'EDITED_LNAME', payload: state.userDetails?.user?.lastName ?? ""});
      dispatch({type: 'EDITED_DOB', payload: state.userDetails?.user?.dob});
      dispatch({type: 'EDITED_MOBILE', payload: state.userDetails?.user?.mobile});
      dispatch({type: 'EDITED_BIO', payload: state.userDetails?.user?.bio});
      dispatch({type: 'EDITED_PRIVACY', payload: state.userDetails?.user?.private});
      dispatch({type: 'EDIT', payload: false});

   }, [state.userDetails?.user]);

   const onCropModalHide = async (modalStatus, uploadStatus, maxFileSizeExceed, fileUrl, type) => {
      if (maxFileSizeExceed) {
         dispatch({type: 'MAX_FILE_EXCEED', payload: true})
      }

      if (uploadStatus) {
         if (type == ASSET_UPLOAD_TYPES.COVER) {
            await UserService.updateUser({
               "coverPicURL": fileUrl, "username": props.userName, "private": state.userDetails?.user?.private,
            }, props.Token)
               .then((response) => {
                  if (response.data.apiStatus) {
                     dispatch({type: 'SET_USER_DETAILS', payload: response.data.user});
                     if (response.data.user.dob) {
                        const date = response.data.user.dob?.split('T')[0]?.split('-')
                        setBirthDate({...birthDate, day: date[2]});
                        setBirthDate({...birthDate, month: date[1]});
                        setBirthDate({...birthDate, year: date[0]});
                     } else {
                        setBirthDate({...birthDate, day: ''});
                        setBirthDate({...birthDate, month: ''});
                        setBirthDate({...birthDate, year: ''});
                     }
                     dispatch({type: 'EDIT', payload: false})
                     toast.success(USER_PROFILE_TOASTS.COVER_IMAGE_UPDATED_SUCCESSFULLY)
                  } else if (response.status === 401) {
                     //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
                  } else {
                     toast.error(USER_PROFILE_TOASTS.ERROR_OCCURED_UPDATING_USER_ASSET_TRY_AGAIN)
                  }
               })
               .catch(err => console.log(err))
         } else if (type == ASSET_UPLOAD_TYPES.PROFILE) {
            await UserService.updateUser({
               "profilePicURL": fileUrl, "username": props.userName, "private": state.userDetails?.user?.private,
            }, props.Token)
               .then((response) => {
                  if (response.data.apiStatus) {
                     props.onProfileUpdate(response.data.user.profilePicURL)
                     dispatch({type: 'SET_USER_DETAILS', payload: response.data.user});
                     if (response.data.user.dob) {
                        const date = response.data.user.dob?.split('T')[0]?.split('-')
                        setBirthDate({...birthDate, day: date[2]});
                        setBirthDate({...birthDate, month: date[1]});
                        setBirthDate({...birthDate, year: date[0]});
                     } else {
                        setBirthDate({...birthDate, day: ''});
                        setBirthDate({...birthDate, month: ''});
                        setBirthDate({...birthDate, year: ''});
                     }
                     dispatch({type: 'EDIT', payload: false})
                     toast.success(USER_PROFILE_TOASTS.PROFILE_IMAGE_UPDATED_SUCCESSFULLY);
                  } else if (response.status == 401) {
                     //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
                  } else {
                     toast.error(USER_PROFILE_TOASTS.ERROR_OCCURED_UPDATING_USER_ASSET_TRY_AGAIN);
                  }
               }).catch(err => console.log(err))
         }
      }

      if (!fileUrl) {
         dispatch({type: 'COVER_MODAL_SHOW', payload: false})
      }

      if (uploadStatus) {
         GetUserByUserName();
         dispatch({type: 'COVER_MODAL_SHOW', payload: false})
      }
   }

   const onEditActive = () => {
      setLinkInvalidError([]);
      setBeforeEditValues({
         editedBio: state.editedBio,
         editedMobile: state.editedMobile,
         editedFname: state.editedFname,
         editedLname: state.editedLname,
         editedFacebokLink: state.editedFacebokLink,
         editedTwitterLink: state.editedTwitterLink,
         editedInstagramLink: state.editedInstagramLink,
         editedTikTokLink: state.editedTikTokLink,
         editedDob: state.editedDob,
         editedPrivacy: state.editedPrivacy,
         dob: {
            day: birthDate.day,
            month: birthDate.month,
            year: birthDate.year
         }
      })
      dispatch({type: 'EDIT', payload: true})
   }

   const dobFormatter = () => {
      let day = birthDate.day;
      let month = birthDate.month;
      if (birthDate.year == '' || birthDate.month == '' || birthDate.day == '') return null;
      if (birthDate.day.length === 1) {
         day = `0${birthDate.day}`
      }
      if (birthDate.month.length === 1) {
         month = `0${birthDate.month}`
      }
      return `${birthDate.year}-${month}-${day}`
   };

   const isNotOkToSubmit = dateError || ((linkInvalidError.length !== 0) && (linkInvalidError.filter(e => e === '').length !== 4));

   const updateUserModel = {
      "bio": state.editedBio,
      "email": props.email,
      "facebook": state.editedFacebokLink,
      "firstName": state.editedFname,
      "instagram": state.editedInstagramLink,
      "lastName": state.editedLname ?? "",
      "mobile": state.editedMobile,
      "twitter": state.editedTwitterLink,
      "username": props.userName,
      "tiktok": state.editedTikTokLink,
      "dob": dobFormatter(),
      "private": state.editedPrivacy
   }

   useEffect(() => {
      const errorArr = [state.facebookLinkError, state.instagramLinkError, state.twitterLinkError, state.tiktokLinkError]
      setLinkInvalidError(errorArr);
   }, [state.facebookLinkError, state.instagramLinkError, state.twitterLinkError, state.tiktokLinkError]);

   const socialMediaLinkInputHandler = (mediaType, inputLink) => {
      setIsPersonalDetailsUpdated(true);
      switch (mediaType) {
         case SOCIAL_MEDIA_TYPES.FACEBOOK:
            dispatch({type: 'EDITED_FACEBOOKLINK', payload: inputLink})
            if (validateFacebookLink(inputLink)) {
               updateUserModel.facebook = inputLink;
               dispatch({type: 'SET_FACEBOOK_LINK_ERROR', payload: ''})
            } else {
               dispatch({type: 'SET_FACEBOOK_LINK_ERROR', payload: "Cannot Proceed with Invalid Facebook link."});
            }
            break;

         case SOCIAL_MEDIA_TYPES.INSTAGRAM:
            dispatch({type: 'EDITED_INSTAGRAMLINK', payload: inputLink})
            if (validateInstagramLink(inputLink)) {
               updateUserModel.instagram = inputLink;
               dispatch({type: 'SET_INSTAGRAM_LINK_ERROR', payload: ''})
            } else {
               dispatch({type: 'SET_INSTAGRAM_LINK_ERROR', payload: "Cannot Proceed with Invalid Instagram link."})
            }
            break;

         case SOCIAL_MEDIA_TYPES.TWITTER:
            dispatch({type: 'EDITED_TWITTERLINK', payload: inputLink})
            if (validateTwitterLink(inputLink)) {
               updateUserModel.twitter = inputLink;
               dispatch({type: 'SET_TWITTER_LINK_ERROR', payload: ''})
            } else {
               dispatch({type: 'SET_TWITTER_LINK_ERROR', payload: "Cannot Proceed with Invalid Twitter link."})
            }
            break;

         case SOCIAL_MEDIA_TYPES.TIKTOK:
            dispatch({type: 'EDITED_TIKTOKLINK', payload: inputLink})
            if (validateTiktokLink(inputLink)) {
               updateUserModel.tiktok = inputLink;
               dispatch({type: 'SET_TIKTOK_LINK_ERROR', payload: ''})
            } else {
               dispatch({type: 'SET_TIKTOK_LINK_ERROR', payload: "Cannot Proceed with Invalid Tiktok link."})
            }
            break;
      }
   }
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);

   const loadingIndicator = (<div className="w-100 text-center">
      <div className={styles.loaderContainer}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   </div>);

   const updateProfile = async () => {
      if (dateError) {
         toast.error(t("TOAST.INVALID_DATE_OF_BIRTH"));
      } else {
         if (!isNotOkToSubmit) {
            if (isPersonalDetailsUpdated) {
               await UserService.updateUser(updateUserModel, props.Token).then((response) => {
                  if (response.data.apiStatus) {
                     dispatch({type: 'SET_USER_DETAILS', payload: response.data});
                     if (response.data.user.dob) {
                        const date = response.data.user.dob?.split('T')[0]?.split('-')
                        setBirthDate({...birthDate, day: date[2]});
                        setBirthDate({...birthDate, month: date[1]});
                        setBirthDate({...birthDate, year: date[0]});
                     } else {
                        setBirthDate({...birthDate, day: ''});
                        setBirthDate({...birthDate, month: ''});
                        setBirthDate({...birthDate, year: ''});
                     }
                     toast.success(USER_PROFILE_TOASTS.USER_DETAILS_UPDATED_SUCCESSFULLY);
                     cancelEditProfile();
                  } else if (response.status == 401) {
                     //toast.error(COMMON_TOASTS.UNAUTHORIZED_REQUEST);
                  } else {
                     toast.error(USER_PROFILE_TOASTS.ERROR_OCCURED_UPDATING_USER_DETAILS_TRY_AGAIN);
                  }
               }).catch(err => console.log(err))
            } else {
               toast.info(USER_PROFILE_TOASTS.NO_CHANGES_MADE_TO_USER_DETAILS)
               cancelEditProfile();
            }
         }
      }
      setIsPersonalDetailsUpdated(false);
   }

   const cancelEditProfile = () => {
      // GetUserByUserName();
      dispatch({type: 'EDIT', payload: false})
      dispatch({type: 'EDITED_FNAME', payload: beforeEditValues.editedFname});
      dispatch({type: 'EDITED_LNAME', payload: beforeEditValues.editedLname});
      dispatch({type: 'EDITED_BIO', payload: beforeEditValues.editedBio});
      dispatch({type: 'EDITED_MOBILE', payload: beforeEditValues.editedMobile});
      dispatch({type: 'EDITED_TWITTERLINK', payload: beforeEditValues.editedTwitterLink});
      dispatch({type: 'EDITED_FACEBOOKLINK', payload: beforeEditValues.editedFacebokLink});
      dispatch({type: 'EDITED_INSTAGRAMLINK', payload: beforeEditValues.editedInstagramLink});
      dispatch({type: 'EDITED_TIKTOKLINK', payload: beforeEditValues.editedTikTokLink});
      dispatch({type: 'EDITED_DOB', payload: beforeEditValues.editedDob});
      dispatch({type: 'EDITED_PRIVACY', payload: beforeEditValues.editedPrivacy});
      setBirthDate(beforeEditValues.dob)
      setIsPersonalDetailsUpdated(false);

      setLinkInvalidError([]);
      window.scrollTo({top: 0, behavior: 'smooth'});
   }

   const getCoverImage = (event) => {
      setIsPersonalDetailsUpdated(true);
      dispatch({type: 'IMAGE_TYPE', payload: ASSET_UPLOAD_TYPES.COVER})
      const imageName = event.target.files[0].name;
      const fileSizeInMB = bytesToMegaBytes(event.target.files[0].size);
      if (fileSizeInMB >= 20) {
         dispatch({type: 'MAX_FILE_EXCEED', payload: true})
         return;
      }
      if (imageName.endsWith('.png') || imageName.endsWith('.jpg') || imageName.endsWith('.jpeg')) {
         dispatch({
            type: 'GET_IMAGE', payload: {
               image: event.target.files[0], coverModalShow: true, modalResponseShow: true, confirmSubmission: false
            }
         })
      } else {
         dispatch({type: 'INVALID_FILE_UPLOAD', payload: true});
      }
   }

   const getProfileImage = (event) => {
      setIsPersonalDetailsUpdated(true);
      dispatch({type: 'IMAGE_TYPE', payload: ASSET_UPLOAD_TYPES.PROFILE})
      const imageName = event.target.files[0].name;
      const fileSizeInMB = bytesToMegaBytes(event.target.files[0].size);
      if (fileSizeInMB >= 5) {
         dispatch({type: 'MAX_FILE_EXCEED', payload: true})
         return;
      }

      if (imageName.endsWith('.png') || imageName.endsWith('.jpg') || imageName.endsWith('.jpeg')) {

         dispatch({
            type: 'GET_IMAGE', payload: {
               image: event.target.files[0], coverModalShow: true, modalResponseShow: true, confirmSubmission: false
            }
         })
      } else {
         dispatch({type: 'INVALID_FILE_UPLOAD', payload: true});
      }
   }

   const closeModal = () => {
      dispatch({
         type: 'CLOSE_MODAL_VALUE_RESET', payload: {
            coverModalShow: false,
            display: 'none',
            file: null,
            modalResponseShow: false,
            confirmAssetDelete: false,
            confirmAccountDelete: false,
            invalidFileUpload: false,
            uploadButtonDisable: false,
            maxFileSizeExceed: false,
            blob: null,
            croppedArea: null,
            croppedAreaPixels: null
         }
      })
   }

   const onVote = (artworkId) => {
      UserService.upVoteArtwork(props.Token, artworkId).then((response) => {

      });
   };
   const onDownVote = (artworkId) => {
      UserService.downVoteArtwork(props.Token, artworkId).then((response) => {

      });
   };
   const handleVoteStatus = (artwork) => {
      if (voteData?.some((vote) => {
         return artwork?.artworkId === vote?.artworkId && vote?.voted === true;
      })) {
         return (<a onClick={() => {
            onDownVote(artwork?.artworkId);
            let updateVoteData = [];
            updateVoteData = voteData.map((item) => item?.artworkId === artwork?.artworkId ? {
               ...item, voted: false, count: item?.count - 1,
            } : item);
            setVoteData(updateVoteData);
         }}
                    className="heartsSection activeHeart">
            {/*<HeartDefault/>*/}
            <img src={HeartActive} alt="HeartActive" className="heartActive"/>
            <label>{voteData.find((item) => item.artworkId === artwork.artworkId)["count"]}</label>
         </a>);
      } else if (artwork.voted === false || voteData?.some((vote) => {
         return artwork?.artworkId === vote?.artworkId && vote?.voted === false;
      }) === true) {
         return (<a onClick={() => {
            onVote(artwork?.artworkId);
            let updateVoteData = [];
            updateVoteData = voteData.map((item) => item?.artworkId === artwork?.artworkId ? {
               ...item, voted: true, count: item?.count + 1,
            } : item);
            setVoteData(updateVoteData);
         }}
                    className="heartsSection">
            <img src={HeartDefault} alt="HeartDefault" className="heartDefault"/>

            <label>
               {voteData.find((item) => item?.artworkId === artwork?.artworkId)["count"]}
            </label>
         </a>);
      }
   };

   function validateFacebookLink(url) {
      if (!url) return true;
      const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/.*$/i;
      return facebookRegex.test(url);
   }

   function validateTwitterLink(url) {
      if (!url) return true;
      const twitterRegex = /^(https?:\/\/)?(www\.)?twitter\.com\/.*$/i;
      return twitterRegex.test(url);
   }

   function validateTiktokLink(url) {
      if (!url) return true;
      const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.*$/i;
      return tiktokRegex.test(url);
   }

   function validateInstagramLink(url) {
      if (!url) return true;
      const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/.*$/i;
      return instagramRegex.test(url);
   }

   function getUserPositionByUserName(encodedUserName) {
      if (userLeaderBoard && encodedUserName) {
         return userLeaderBoard.findIndex(leader => leader.userName?.toLowerCase() == encodedUserName.toLowerCase()) + 1;
      } else {
         return -1;
      }
   }

   useEffect(() => {

      if (state.userDetails.user) {
         const getUserLeaderBoard = async () => {
            await LeaderBoardService.getGlobalLeaderBoard(0, 9, 'rank', true).then((response) => {
               dispatch({type: 'LEADERBOARD_STATUS', payload: response?.apiStatus})
               if (response.apiStatus) {
                  if (response.totalElements != 0) {
                     setUserLeaderBoard(response.results);
                     if (state.userDetails.user.rank == 1 || state.userDetails.user.rank == 2 || state.userDetails.user.rank == 3) {
                        if (state.userDetails.user.rank == 1) {
                           dispatch({type: 'SET_FIRST_LEADERDETAILS', payload: state.userDetails.user});
                           dispatch({type: 'SET_SECOND_LEADERDETAILS', payload: response.results[1]});
                           dispatch({type: 'SET_THIRD_LEADERDETAILS', payload: response.results[2]});
                        } else if (state.userDetails.user.rank == 2) {
                           dispatch({type: 'SET_FIRST_LEADERDETAILS', payload: response.results[0]});
                           dispatch({type: 'SET_SECOND_LEADERDETAILS', payload: state.userDetails.user});
                           dispatch({type: 'SET_THIRD_LEADERDETAILS', payload: response.results[2]});
                        } else if (state.userDetails.user.rank == 3) {
                           dispatch({type: 'SET_FIRST_LEADERDETAILS', payload: response.results[0]});
                           dispatch({type: 'SET_SECOND_LEADERDETAILS', payload: response.results[1]});
                           dispatch({type: 'SET_THIRD_LEADERDETAILS', payload: state.userDetails.user});
                        }
                        dispatch({type: 'SET_USER_LEADERBOARD', payload: response.results.slice(3)});
                     } else {
                        dispatch({type: 'SET_FIRST_LEADERDETAILS', payload: response.results[0]});
                        dispatch({type: 'SET_SECOND_LEADERDETAILS', payload: response.results[1]});
                        dispatch({type: 'SET_THIRD_LEADERDETAILS', payload: response.results[2]});

                        const filteredArray = response.results.slice(3);

                        // dispatch({type: 'SET_FORTH_LEADERDETAILS', payload: state.userDetails.user});

                        const arrayWithoutCurrentUser = filteredArray.filter(leader => leader.userName != userName);

                        dispatch({type: 'SET_USER_LEADERBOARD', payload: arrayWithoutCurrentUser});
                     }
                  } else {
                     console.log("Leaderboard is Empty");
                  }

               } else {
                  toast.error(COMMON_TOASTS.ERROR_OCCURED_WHEN_GETTING_LEADERBOARD_PLEASE_TRY_AGAIN);
               }
            })
         }
         getUserLeaderBoard();
      }
   }, [state.userDetails, encodedUserName]);

   const onPrivacyChange = (type) => {
      setIsPersonalDetailsUpdated(true);
      dispatch({type: 'EDITED_PRIVACY', payload: (type === PROFILE_PRIVACY.PRIVATE)})
   }


   const selectedUserLBTableRow = Object.keys(state.forthLeaderDetails || {}).length !== 0 ?
      <tr className={styles.highlightedRow}>
         <td><i>{getPosition(state.forthLeaderDetails?.position) || "--"}</i></td>
         <td className="text-truncate " style={{maxWidth: "150px", lineHeight: "30px"}}>
            <div
               className="float-start">{renderProfileImg(state.forthLeaderDetails?.profilePicUrl, state.forthLeaderDetails?.firstName, state.forthLeaderDetails?.lastName)}</div>

            <div
               className="ms-2 float-start">{getDisplayName(state.forthLeaderDetails?.firstName, state.forthLeaderDetails?.lastName, state.forthLeaderDetails?.userName, 20)}</div>
         </td>
         <td>
            <div>{state.forthLeaderDetails?.matches}</div>
         </td>
         <td>
            <div className="row p-0">
               <div className="col-2 p-0">
                  <img className="" src={OmniPoints}/>
               </div>
               <div
                  className="float-right col-6 p-0 text-end ">
                  {toolTipProvider(state?.forthLeaderDetails?.points, 'top',
                     <span className="text-truncate" style={{cursor: "default"}}>
                        {formatNumberToKWithoutRounding(state?.forthLeaderDetails?.points, 2)}
                     </span>, true)}
                  &nbsp;&nbsp;
                  {getUpDownSameIconByDirection(state.forthLeaderDetails?.position)}</div>
            </div>
         </td>
      </tr> : <></>

   const topLeaderBoardUserTableRow = (leader) => (<tr>
      <td><i>{leader?.position}</i></td>
      <Link style={{textDecoration: "none"}} to={leader?.isPrivate ? '' : {
         pathname: `/user/${btoa(leader?.userName)}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`
      }}>
         <td>
            {renderProfileImg(leader?.profilePicUrl, leader?.firstName, leader?.lastName)}
         </td>
         {lBTypPrivateUserToolTip(<td className="text-truncate"
                                      style={{maxWidth: "200px", cursor: leader?.isPrivate && "default"}}>
            {getDisplayName(leader?.firstName, leader?.lastName, leader?.userName, 20)}
         </td>, leader?.isPrivate)}
         <td>
            {leader.user?.isPrivate ? <div className="col-2 float-left p-0">
               <i className="fa-sharp fa-solid fa-lock"
                  style={{fontSize: "12px", color: "FF0000"}}></i></div> : <div className="col-2 float-left p-0"></div>}

         </td>
      </Link>
      <td>
         <div className="text-justify">{leader?.matches}</div>
      </td>
      <td>
         <div className="row p-0">
            <div className="col-2 p-0">
               <img className="" src={OmniPoints}/>
            </div>
            <div className="float-right col-6 p-0 text-end greenText">
               {toolTipProvider(leader?.points, 'top',
                  <span className="text-truncate" style={{cursor: "default"}}>
                     {formatNumberToKWithoutRounding(leader?.points, 2)}
                  </span>, true)}
               &nbsp;&nbsp;{getUpDownSameIconByDirection(leader?.direction)}</div>
         </div>
      </td>
   </tr>)

   const coverPhotoSection = () => {
      if (state.edit) {
         return (<>
            <div className={classnames(styles.imgUpdateDiv, 'mt-3')}>
               <label htmlFor="coverImageUpload" id="chooseFileLabel" style={{cursor: 'pointer'}}>
                  <i className={classnames("fa-solid", "fa-image", styles.faPlus)}></i>
               </label>

               <input type="file" accept="image/*" onChange={getCoverImage} id="coverImageUpload"
                      onClick={(event) => {
                         event.target.value = null
                      }}
                      className={classnames("float-left", "mt-4", "float-right", styles.uploadBigBtn)}
                      style={{display: "none"}}/>
               <font>&nbsp;&nbsp;|&nbsp;&nbsp;</font>

               <label style={{cursor: 'pointer'}}>
                  <i className={classnames("fa-solid", "fa-trash-can", styles.faTrash)}
                     onClick={() => {
                        assetDeleteConfirmationDialog(state.userDetails.user.coverPicURL, ASSET_UPLOAD_TYPES.COVER)
                     }}></i>
               </label>
            </div>
            {state.userDetails.user?.coverPicURL ? <img src={state.userDetails.user?.coverPicURL} onError={(e) => {
                  e.target.src = {TitileImgPlaceholder}
               }} style={{height: '280px', width: "100%", border: "1px solid #FFFFFF0F"}}
                                                        alt={`${state.forthLeaderDetails?.firstName} Cover Photo`}></img> :
               <img src={TitileImgPlaceholder} alt="Placeholder"
                    style={{height: 'auto', width: "100%"}}/>}
         </>)
      }

      return (state.userDetails.user?.coverPicURL ?
         <img src={state.userDetails.user?.coverPicURL} style={{maxHeight: '280px', width: "100%"}}
              alt={`${state.forthLeaderDetails?.firstName} Cover Photo`}></img> :
         <img src={TitileImgPlaceholder} alt="Placeholder"
              style={{height: 'auto', width: "100%"}}/>)
   }

   const userProfileImageSection = () => {
      if (state.edit) {
         return (<>
            <div className={classnames(styles.imgUpdateDiv, "me-3", "mt-3")}>
               <label htmlFor="profileImageUpload" id="chooseFileLabel"
                      style={{cursor: 'pointer'}}>
                  <i className={classnames("fa-solid", "fa-image", styles.faPlus)}></i>
               </label>

               <input type="file" accept="image/*" onChange={getProfileImage}
                      id="profileImageUpload"
                      onClick={(event) => {
                         event.target.value = null
                      }}
                      className={classnames("float-left", "mt-4", "float-right", styles.uploadBigBtn)}
                      style={{display: "none"}}
               />
               <font>&nbsp;&nbsp;|&nbsp;&nbsp;</font>
               <label style={{cursor: 'pointer'}}>
                  <i className={classnames("fa-solid", "fa-trash-can", styles.faTrash)}
                     onClick={() => {
                        assetDeleteConfirmationDialog(state.userDetails?.user?.profilePicURL, ASSET_UPLOAD_TYPES.PROFILE)
                     }}></i>
               </label>
            </div>
            {state.userDetails.user?.profilePicURL ? <>
               <img src={`${state.userDetails.user?.profilePicURL}`}
                    alt={handleProfileImageError(state.userDetails.user?.firstName, state.userDetails.user?.lastName)}
                    style={{width: '100%', height: 'auto', borderRadius: '4%'}}></img>
            </> : <img src={`${ProfileImgPlaceholder}`} alt="placeholder"
                       style={{height: 'auto', width: "100%"}}/>}
         </>);
      }
      return (state.userDetails.user?.profilePicURL ? <>
         <img src={`${state.userDetails.user?.profilePicURL}`}
              alt={handleProfileImageError(state.userDetails.user?.firstName, state.userDetails.user?.lastName)}
              style={{width: '100%', height: 'auto', borderRadius: '4%'}}></img>
      </> : <img src={`${ProfileImgPlaceholder}`} alt="placeholder"
                 style={{height: 'auto', width: "100%"}}/>)
   }

   const nameEditHandler = (event) => {
      setIsPersonalDetailsUpdated(true);
      const input = event.target.value;
      const words = input.split(/\s+/).filter(word => word !== "");
      let firstName;
      let lastName;
      if (input) {
         switch (words.length) {
            case 1:
               firstName = words[0];
               lastName = '';
               break;
            case 2:
               firstName = words[0];
               lastName = words[1];
               break;
            default:
               lastName = words.pop();
               firstName = words.join(' ');
         }
      } else {
         firstName = null;
         lastName = null;
      }

      dispatch({type: 'EDITED_FNAME', payload: firstName})
      dispatch({type: 'EDITED_LNAME', payload: lastName});
   }

   const socialShareModalHandler = (value) => {
      dispatch({type: 'IS_SHOWING_SOCIAL_SHARE_MODAL', payload: value});
   }

   const userRankOfSelectedUser = getUserPositionByUserName(encodedUserName)

   const isFetchingData = state?.isGettingUserByUsername || state?.isGettingUserByUserNameWithoutToken || state?.isGettingFavoritePlayers || state?.isGettingFavoriteTeams || state?.isGettingNotifications || state?.isDeletingAsset;

   const isFetchingDataDebounce = useDebounce(isFetchingData, 400)
   if (!translationsLoaded) {
      return loadingIndicator;
   }

   const lBPrivateUserToolTip = toolTipProvider.bind(this, LBPrivateUserNavigateMessage, "bottom")
   const lBTypPrivateUserToolTip = toolTipProvider.bind(this, LBPrivateUserNavigateMessage, "right")

   const gotItHandler = () => {
      setNextGotItIndex(nextGotItIndex + 1)
      setTipIndexCounter(tipIndexCounter + 1)
   }

   const tipCancelHandler = () => {
      setNextGotItIndex(-1)
   }
   return (<React.Fragment>
      {/*<div className={classnames(styles.Radial3)}></div>*/}

      <div className={classnames(styles.userProfileWrapper, "pageWrapper col-12 row ps-2 pe-2 ps-xxl-0 pe-xxl-0")}>
         <div className="col-12 p-0 row">
            <NavbarSecondary page="matches"/>
         </div>

         <div className="col-12 p-0 pt-md-3 pt-0 row">

            <div className="col-xl-9 col-lg-12 p-0 mt-0 mt-xl-5 float-left ">
               {isFetchingDataDebounce ? <div className="text-center">
                  <div className={styles.loaderContainer}>
                     <div className="lds-facebook">
                        <div></div>
                        <div></div>
                        <div></div>
                     </div>
                  </div>
               </div> : <>
                  <div className="col-12 p-0">
                     {/*cover photo section*/}
                     {coverPhotoSection()}
                  </div>
                  {/*Profile pane*/}
                  {/* <div className={classnames(styles.ProfilePane, "col-12", "p-3", "mt-4", "row", "p-0 relative-sm")}></div> */}
                  <div
                     className={classnames(styles.ProfilePaneWrapper, "ProfilePane col-12 p-2 p-md-3 mt-4 row p-0 relative-sm")}>
                     {/*<GuidanceTip page="userProfile" gotItIndex={0} gotItHandler={gotItHandler}*/}
                     {/*             nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler}*/}
                     {/*             tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}

                     <div className={classnames(styles.ProfilePicCol, "float-left col-sm-12 col-sm-3 col-md-3 p-0")}>
                        {props.userId === encodedUserName ? <></> : <div className={classnames(styles.HeartIcon)}>
                        </div>}
                        {/*user profile image*/}
                        {userProfileImageSection()}
                        <div className="col-12 p-0 mt-2 d-md-none">
                           {(props.userName == encodedUserName && !state.edit) ? <>
                              <button className="blackBtn" onClick={onEditActive}
                                      style={{float: "right", marginBottom: "40px"}}><BsPencil/>
                                 <span>{t("USER_PROFILE_PAGE.EDIT")}</span>
                              </button>
                           </> : <></>}

                        </div>
                     </div>
                     <div
                        className={classnames(styles.rightCol, "col-sm-12 col-md-9 mt-0 mt-md-0 row p-0 pt-0 ps-3")}>

                        {/*<div className="col-9 col-md-9 mt-md-0 row p-0 pt-2 ps-0 ps-md-3" style={{position:"relative", marginBottom:0,}}>*/}
                        <div className={classnames(styles.userProfileNameCol, "col-9  p-0 col-sm-12")}>
                           {state.edit ? <>
                              <h3
                                 className={classnames(styles.Text32, "text-start ps-lg-0 pe-lg-0 p-mb-0 p-sm-0 p-0")}>
                                 {t("USER_PROFILE_PAGE.PERSONAL_DETAILS")}
                              </h3>
                              <div
                                 className={classnames(styles.Text18, styles.subTitle, "subTitle text-start ps-lg-0 pe-lg-0 p-md-0 p-sm-0 p-0", "mt-sm-0 mt-mb-3 mt-lg-3 mb-4")}>
                                 {t("USER_PROFILE_PAGE.ENTER_YOUR_DETAILS_SUCH_AS_YOUR_FULL_NAME, DATE_OF_BIRTH_AND_BIO")}
                              </div>
                           </> : <></>}

                           <div
                              className={classnames(styles.userProfileName, "col-12 profileText color-white", "float-start", "p-0", "row")}>
                              <div
                                 className={state.edit ? "p-0 float-start col-12 col-sm-7" : "pb-3 pl-0 float-start col-12"}>
                                 {state.edit ?
                                    <div className={classnames(styles.group, styles.NameGroup, "pe-1", "p-0")}>
                                       <input type="text"
                                              defaultValue={`${state?.editedFname} ${state?.editedLname}`}
                                              maxLength="20" onChange={nameEditHandler}/>
                                       <label>{t("USER_PROFILE_PAGE.FULL_NAME")}</label>
                                    </div> : <>
                                       {state.userDetails?.user?.firstName || ''}{' '}{state.userDetails?.user?.lastName || ' '}&nbsp;
                                    </>}
                              </div>
                              {state.edit ?
                                 <div className={classnames("float-start col-12 col-lg-5 p-0")}>
                                    <div className={classnames(styles.DatOfBirthWrapper, "mt-0 p-0")}>
                                       <div className={classnames(styles.group, "float-start ms-0 ms-md-1")}
                                            style={{width: "30%"}}>
                                          <input
                                             type="text"
                                             name="name"
                                             placeholder=""
                                             size="2"
                                             autoComplete="off"
                                             value={birthDate.day}
                                             maxLength={2}
                                             className={classnames(styles.NumIn)}
                                             onChange={(e) => {
                                                setIsPersonalDetailsUpdated(true);
                                                setBirthDate({...birthDate, day: e.target.value})
                                             }}
                                          />

                                          <label style={{fontSize: "12px", top: "10px", padding: "0"}}>DD</label>
                                       </div>
                                       <div className={classnames(styles.group, "float-start ms-1")}
                                            style={{width: "30%"}}>
                                          <input
                                             type="text"
                                             name="name"
                                             placeholder=""
                                             size="2"
                                             autoComplete="off"
                                             value={birthDate.month}
                                             maxLength={2}
                                             className={classnames(styles.NumIn)}
                                             onChange={(e) => {
                                                setIsPersonalDetailsUpdated(true);
                                                setBirthDate({...birthDate, month: e.target.value})
                                             }}
                                          />
                                          <label style={{fontSize: "12px", top: "10px", padding: "0"}}>MM</label>
                                       </div>
                                       <div className={classnames(styles.group, "float-start ms-1")}
                                            style={{width: "34%"}}>
                                          <input
                                             type="text"
                                             name="name"
                                             placeholder=""
                                             size="2"
                                             autoComplete="off"
                                             value={birthDate.year}
                                             maxLength={4}
                                             className={classnames(styles.NumIn)}
                                             onChange={(e) => {
                                                setIsPersonalDetailsUpdated(true);
                                                setBirthDate({...birthDate, year: e.target.value})
                                             }}
                                          />
                                          <label
                                             style={{fontSize: "12px", top: "10px", padding: "0"}}>YYYY</label>
                                       </div>
                                    </div>
                                    <span className={classnames(styles.TextError)}>&nbsp;{dateFormatError}</span>
                                 </div> : <> </>}
                           </div>
                           {state.edit ? <></> :
                              <div className={classnames(styles.statsmallText, " p-0 w-100")}>
                                 {props.userName == encodedUserName ? <div className="float-left me-3">{state.userDetails.user?.email}</div> : <></>}

                                 <div className={classnames(props.userName == encodedUserName ? styles.sepaBorder : '', " float-left")}>{state.userDetails.user?.private ? `${t("USER_PROFILE_PAGE.PRIVATE")}` : `${t("USER_PROFILE_PAGE.PUBLIC")}`}&nbsp;{t("USER_PROFILE_PAGE.ACCOUNT")}</div>
                              </div>
                           }
                        </div>
                        <div className="col-3- p-0 d-none d-md-block">
                           {(props.userName == encodedUserName && !state.edit) ?
                              <button className={classnames(styles.ProfileEditBtn, "blackBtn")}
                                      onClick={onEditActive} style={{float: "right"}}>
                                 <BsPencil/>
                                 <span>{t("USER_PROFILE_PAGE.EDIT")}</span>
                              </button>
                              : <></>}
                           {/*<GuidanceTip page="userProfile" gotItIndex={1} gotItHandler={gotItHandler}*/}
                           {/*             nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler}*/}
                           {/*             tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}

                        </div>
                        <div
                           className={classnames(styles.TexareatWrapper, styles.statsmallTextW, "mt-2", "p-0", "col-12", "col-md-9")}
                           style={{minHeight: "100px"}}>
                           {state.edit ?
                              <div className={classnames(styles.group)}>
                                            <textarea value={state.editedBio}
                                                      onChange={(event) => {
                                                         setIsPersonalDetailsUpdated(true);
                                                         const newValue = event.target.value;
                                                         if (newValue.length <= 200) {
                                                            dispatch({type: 'EDITED_BIO', payload: newValue});
                                                         }
                                                      }}>
                                            </textarea>
                                 <label>{t("USER_PROFILE_PAGE.BIO")}</label>
                              </div> : <>
                                 {(state.userDetails.user?.bio) ? (state.userDetails.user?.bio) : (props.userName === encodedUserName) ? (
                                    <i>{t("USER_PROFILE_PAGE.BIO_NOT_AVAILABLE.")}</i>) : (<></>)}
                              </>}
                        </div>

                        {/*Acc type CHECKBOX START*/}
                        <div className="d-md-block p-0">{state.edit ? <>
                           <h3
                              className={classnames(styles.Text32, "text-start", "text-start ps-lg-0 pe-lg-0 p-mb-0 p-sm-0 p-0 mt-4")}>{t("USER_PROFILE_PAGE.PRIVACY")}
                           </h3>
                           <div
                              className={classnames(styles.Text18, styles.subTitle, "text-start ps-lg-0 pe-lg-0 p-md-0 p-sm-0 p-0", "mt-sm-0 mt-mb-3 mt-lg-3 mb-4")}>
                              {t("USER_PROFILE_PAGE.SELECT_YOUR_PROFILE_TYPE")}
                           </div>
                           <div className={classnames("mt-0", "col-12", "p-0")}>
                              <div className="col-12 col-md-9 mt-0 p-0 row float-start">
                                 <div className="col-12 col-md-6 p-0 pe-0 pe-md-1">
                                    <div className={classnames(styles.RadioBtnBox)}>
                                       <div className={classnames(styles.LockBox)}>
                                          <img
                                             src="https://storage.googleapis.com/meta11/serviceplatform/unlock.png"
                                             alt={"unlock image"}/>
                                       </div>
                                       <div className={classnames(styles.LockText)}>
                                          {t("USER_PROFILE_PAGE.PUBLIC_PROFILE")}
                                       </div>
                                       <div className={classnames(styles.RadioBox)}>
                                          <input type="radio" name="public1" checked={!state.editedPrivacy}
                                                 id="exampleRadios1"
                                                 className={classnames("form-check-input", styles.radioFill)}
                                                 onClick={() => {
                                                    onPrivacyChange(PROFILE_PRIVACY.PUBLIC);
                                                 }}/>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-12 col-md-6 p-0 ps-0 ps-md-1 pt-3 pt-md-0">
                                    <div className={classnames(styles.RadioBtnBox, "row", "pl-1")}>
                                       <div className={classnames(styles.LockBox)}><img
                                          src="https://storage.googleapis.com/meta11/serviceplatform/lock.png"
                                          alt="lock image"/>
                                       </div>
                                       <div className={classnames(styles.LockText)}
                                            style={{paddingTop: "25px"}}>{t("USER_PROFILE_PAGE.PRIVATE_PROFILE")}
                                       </div>
                                       <div className={classnames(styles.RadioBox)}>
                                          <input type="radio" name="private1" checked={state.editedPrivacy}
                                                 id="exampleRadios2"
                                                 className={classnames("form-check-input", styles.radioFill)}
                                                 onClick={() => {
                                                    onPrivacyChange(PROFILE_PRIVACY.PRIVATE);
                                                 }}/>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </> : <></>}
                           {/*Acc type CHECKBOX Ends*/}</div>

                        {/*code removed*/}

                        {/*social links*/}
                        <div
                           className={classnames(styles.socialWrapperRow, "socialWrapperRow col-12 row p-0 relative-lg d-md-block")}>
                           <div
                              className={classnames(styles.socialWrapper, "col-12 row p-0 socialWrapper float-start", state.edit ? "col-md-9" : "col-md-6")}>
                              {state.edit ? <>
                                 <h3
                                    className={classnames(styles.Text32, "text-start", "text-start ps-lg-0 pe-lg-0 p-mb-0 p-sm-0 p-0 mt-4")}>
                                    {t("USER_PROFILE_PAGE.SOCIAL_PROFILES")}
                                 </h3>
                                 <div
                                    className={classnames(styles.Text18, styles.subTitle, "text-start ps-lg-0 pe-lg-0 p-md-0 p-sm-0 p-0", "mt-sm-0 mt-mb-3 mt-lg-3 mb-3")}>
                                    {t("USER_PROFILE_PAGE.ADD_YOUR_SOCIAL_NETWORKS_TO_YOUR_META_11_ACCOUNT")}
                                 </div>
                                 <div className="row col-12 col-md-6 mt-3 p-0 pe-0 pe-md-1">
                                    <div className={classnames(styles.RadioBtnBox, "row", "pl-1")}>
                                       <div className={classnames(styles.LockBox)}><img
                                          src="https://storage.googleapis.com/meta11/serviceplatform/bxl-facebook-square.png"
                                          alt="facebook image"/>
                                       </div>
                                       <div className={classnames(styles.LockText2, "p-0")}><input
                                          type="text" defaultValue={state.editedFacebokLink}
                                          onChange={(event) => {
                                             socialMediaLinkInputHandler(SOCIAL_MEDIA_TYPES.FACEBOOK, event.target.value)
                                          }}
                                          className={classnames(styles.SocialInput)}/></div>
                                    </div>
                                 </div>
                              </> : <Link to={state.userDetails.user?.facebook}
                                          style={{float: "left", width: "auto", padding: "0"}} target='_blank'>
                                 <div className={classnames(styles.SocialSmallIcons, "float-start")}
                                      style={{marginLeft: 0}}>
                                    <img
                                       src="https://storage.googleapis.com/meta11/serviceplatform/FaceBookIcon-14.png"
                                       alt='facebook Icon'></img>
                                 </div>
                              </Link>}

                              {state.edit ?
                                 <div className="row col-12 col-md-6 mt-3 p-0 ps-0 ps-md-1">
                                    <div className={classnames(styles.RadioBtnBox)}>
                                       <div className={classnames(styles.LockBox)}><img
                                          src="https://storage.googleapis.com/meta11/serviceplatform/bxl-instagram-alt.png"
                                          alt='instagram image'/>
                                       </div>
                                       <div className={classnames(styles.LockText2)}><input
                                          defaultValue={state.editedInstagramLink}
                                          onChange={(event) => {
                                             socialMediaLinkInputHandler(SOCIAL_MEDIA_TYPES.INSTAGRAM, event.target.value)
                                          }} type="text"
                                          className={classnames(styles.SocialInput)}/>
                                       </div>
                                    </div>
                                 </div>
                                 : <Link to={state.userDetails.user?.instagram}
                                         style={{float: "left", width: "auto", padding: "0"}} target='_blank'>
                                    <div className={classnames(styles.SocialSmallIcons, "float-start")}>
                                       <img src="https://storage.googleapis.com/meta11/serviceplatform/InstaIcon-14.png"
                                            alt='Instagram Icon'></img>
                                    </div>
                                 </Link>}

                              {state.edit ?
                                 <div className="row col-12 col-md-6 p-0 mt-3 mt-md-2 pe-1 ps-md-0">
                                    <div className={classnames(styles.RadioBtnBox)}>
                                       <div className={classnames(styles.LockBox)}><img
                                          src="https://storage.googleapis.com/meta11/serviceplatform/bxl-twitter.png"
                                          alt='Twitter Icon'/>
                                       </div>
                                       <div className={classnames(styles.LockText2)}><input
                                          defaultValue={state.editedTwitterLink} onChange={(event) => {
                                          socialMediaLinkInputHandler(SOCIAL_MEDIA_TYPES.TWITTER, event.target.value)
                                       }} type="text"
                                          className={classnames(styles.SocialInput)}/></div>
                                    </div>
                                 </div>
                                 : <Link to={state.userDetails.user?.twitter}
                                         style={{float: "left", width: "auto", padding: "0"}} target='_blank'>
                                    <div className={classnames(styles.SocialSmallIcons, "float-start")}>
                                       <img
                                          src="https://storage.googleapis.com/meta11/serviceplatform/TweetIcon-14.png"
                                          alt='Twitter Icon'></img>
                                    </div>
                                 </Link>}

                              {state.edit ?
                                 <div className="row col-12 col-md-6 p-0 mt-3 mt-md-2 ps-0 ps-md-1">
                                    <div className={classnames(styles.RadioBtnBox, "row", "pl-1")}>
                                       <div className={classnames(styles.LockBox)}><img
                                          src="https://storage.googleapis.com/meta11/serviceplatform/bxl-tiktok.png"
                                          alt='Tiktok Icon'/>
                                       </div>
                                       <div className={classnames(styles.LockText2, "p-0")}><input
                                          defaultValue={state.editedTikTokLink} onChange={(event) => {
                                          socialMediaLinkInputHandler(SOCIAL_MEDIA_TYPES.TIKTOK, event.target.value)
                                       }} type="text"
                                          className={classnames(styles.SocialInput)}/></div>
                                       {/*<div className={classnames(Styles.RadioBox)}><input type="radio" name="Social" id="exampleRadios6" class="form-check-input"/></div>*/}

                                    </div>
                                 </div>
                                 : <Link to={state.userDetails.user?.tiktok}
                                         style={{float: "left", width: "auto", padding: "0"}} target='_blank'>
                                    <div className={classnames(styles.SocialSmallIcons, "float-start")}
                                         style={{marginRight: 10}}>
                                       <img
                                          src="https://storage.googleapis.com/meta11/serviceplatform/TiktokIcon-14.png"
                                          alt='Tiktok Icon'></img>
                                    </div>
                                 </Link>}
                           </div>

                           {state.edit ? <div className="row p-0">
                              <div className={classnames(styles.DeleteAccountWrapper, "col-md-12")}>
                                 <h3> {t("USER_PROFILE_PAGE.DELETE_ACCOUNT")} </h3>
                                 <div
                                    className={classnames(styles.Text18, "text-start ps-lg-0 pe-lg-0 p-md-0 p-sm-0 p-0", "mt-sm-0 mt-mb-3 mt-lg-3 mb-4")}>
                                    {t("USER_PROFILE_PAGE.IF_YOU_DECIDE_TO_DELETE_YOUR_ACCOUNT")} {t("USER_PROFILE_PAGE.YOU’LL_NOT_BE_ABLE_TO_RESTORE_IT")}
                                 </div>
                                 <div className={classnames(styles.DeleteAccountBtnWrapper)}>
                                    <button className={classnames(styles.DeleteAccountBtn, "me-3")}
                                            onClick={() => {
                                               accountDeleteConfirmationDialog()
                                            }} data={t("USER_PROFILE_PAGE.DELETE_ACCOUNT")}>
                                       {/* <span>Delete account</span> */}
                                    </button>
                                 </div>
                              </div>
                           </div> : <></>}

                           {state.edit ? <div
                              className={classnames("body-text5 color-gray2", "col-3", "mt-3", "text-end ")}></div> : <></>}
                           {state.edit ?
                              <div
                                 className={classnames("body-text5 color-gray2", "col-12", !state.edit && "col-md-6", "p-0", "text-start  float-start")}>{t("USER_PROFILE_PAGE.JOINED")}: {formatDate(state.userDetails?.user?.joinedTime)}</div>
                              : <div
                                 className={classnames("body-text5 color-gray2", "col-12", !state.edit && "col-md-6", "mt-3", "text-md-end", "text-end float-end")}
                                 style={{marginTop: "-50px"}}>{t("USER_PROFILE_PAGE.JOINED")}: {formatDate(state.userDetails?.user?.joinedTime)}</div>}

                           {state.edit ?
                              <div className={classnames(styles.ErrWrapper, "ps-0 pt-4 pb-2 text-end  float-start")}>
                                 {linkInvalidError.map(err => <div key={err}
                                                                   className={classnames("body-text5 color-gray2", "col-6", "mt-3 p-0", "text-start")}
                                                                   style={{color: "red"}}>{err}</div>)}
                                 <button className={styles.BlackSkBtn} onClick={cancelEditProfile}>
                                    <span>{t("USER_PROFILE_PAGE.CANCEL")}</span>
                                 </button>
                                 <button className={classnames(styles.LoginButton, "me-3")}
                                         style={{cursor: isPersonalDetailsUpdated ? 'pointer' : 'not-allowed'}}
                                         disabled={isNotOkToSubmit} onClick={updateProfile}>
                                    <span>{t("USER_PROFILE_PAGE.SAVE")}</span>
                                 </button>
                              </div> : <></>}
                        </div>
                        {/*</div>*/}
                     </div>

                     {/**extra social link for design fix starts : Mobile Screens*/}

                     <div className="d-block- d-md-none- p-0" style={{display: "none"}}>{state.edit ? <>
                        <div
                           className={classnames(styles.Text32, "text-start", "text-start ps-lg-0 pe-lg-0 p-mb-0 p-sm-0 p-0 mt-4")}>
                           {t("USER_PROFILE_PAGE.PRIVACY")}
                        </div>
                        <div
                           className={classnames(styles.Text18, "text-start ps-lg-0 pe-lg-0 p-md-0 p-sm-0 p-0", "mt-sm-0 mt-mb-3 mt-lg-3 mb-4")}>
                           {t("USER_PROFILE_PAGE.SELECT_YOUR_PROFILE_TYPE")}
                        </div>
                        <div className={classnames("mt-0", "col-12", "p-0")}>
                           <div className="col-12 col-md-9 mt-0 p-0 row float-start">
                              <div className="col-12 col-md-6 p-0 pe-0 pe-md-1">
                                 <div className={classnames(styles.RadioBtnBox)}>
                                    <div className={classnames(styles.LockBox)}>
                                       <img
                                          src="https://storage.googleapis.com/meta11/serviceplatform/unlock.png"
                                          alt='Unlock Icon'/>
                                    </div>
                                    <div className={classnames(styles.LockText)}
                                         style={{paddingTop: "25px"}}>{t("USER_PROFILE_PAGE.PUBLIC_PROFILE")}
                                    </div>
                                    <div className={classnames(styles.RadioBox)}>
                                       <input type="radio" name="public2" checked={!state.editedPrivacy}
                                              id="exampleRadios3"
                                              className={classnames("form-check-input", styles.radioFill)}
                                              onClick={() => {
                                                 onPrivacyChange(PROFILE_PRIVACY.PUBLIC);
                                              }}/>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-12 col-md-6 p-0 ps-0 ps-md-1 pt-3 pt-md-0">
                                 <div className={classnames(styles.RadioBtnBox, "row", "pl-1")}>
                                    <div className={classnames(styles.LockBox)}><img
                                       src="https://storage.googleapis.com/meta11/serviceplatform/lock.png"
                                       alt='Lock Icon'/>
                                    </div>
                                    <div className={classnames(styles.LockText)}
                                         style={{paddingTop: "25px"}}>{t("USER_PROFILE_PAGE.PRIVATE_PROFILE")}
                                    </div>
                                    <div className={classnames(styles.RadioBox)}>
                                       <input type="radio" name="private2" checked={state.editedPrivacy}
                                              id="exampleRadios4"
                                              className={classnames("form-check-input", styles.radioFill)}
                                              onClick={() => {
                                                 onPrivacyChange(PROFILE_PRIVACY.PRIVATE);
                                              }}/>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </> : <></>}</div>


                     <div className={classnames("col-12 row p-0 relative-lg d-block- d-md-none")}
                          style={{display: "none"}}>
                        <div
                           className={classnames("col-12 row p-0 float-start m-0", state.edit ? "col-md-9" : "col-md-6")}>
                           {state.edit ? <>
                              <div
                                 className={classnames(styles.Text32, "text-start", "ssssss text-start ps-lg-0 pe-lg-0 p-mb-0 p-sm-0 p-0 mt-4")}>
                                 {t("USER_PROFILE_PAGE.SOCIAL_PROFILES")}
                              </div>
                              <div
                                 className={classnames(styles.Text18, "text-start ps-lg-0 pe-lg-0 p-md-0 p-sm-0 p-0", "mt-sm-0 mt-mb-3 mt-lg-3 mb-3")}>{t("USER_PROFILE_PAGE.ADD")}
                                 {t("USER_PROFILE_PAGE.YOUR_SOCIAL_NETWORKS_TO_YOUR_META_11_ACCOUNT")}
                              </div>
                              <div className="row col-12 col-md-6 mt-3 p-0 pe-0 pe-md-1">
                                 <div className={classnames(styles.RadioBtnBox, "row", "pl-1")}>
                                    <div className={classnames(styles.LockBox)}><img
                                       src="https://storage.googleapis.com/meta11/serviceplatform/bxl-facebook-square.png"
                                       alt='Facebook Icon'/>
                                    </div>
                                    <div className={classnames(styles.LockText2, "p-0")}><input
                                       type="text" value={state.editedFacebokLink}
                                       onChange={(event) => {
                                          setIsPersonalDetailsUpdated(true);
                                          dispatch({
                                             type: 'EDITED_FACEBOOKLINK', payload: event.target.value
                                          })
                                       }}
                                       className={classnames(styles.SocialInput)}/></div>
                                 </div>
                              </div>
                           </> : <Link to={state.userDetails.user?.facebook}
                                       style={{float: "left", width: "auto", padding: "0"}}>
                              <div className={classnames(styles.SocialSmallIcons, "float-start")}
                                   style={{marginLeft: 0}}>
                                 <img src="https://storage.googleapis.com/meta11/serviceplatform/FaceBookIcon-14.png"
                                      alt='Facebook Icon'></img>
                              </div>
                           </Link>}

                           {state.edit ?
                              <div className="row col-12 col-md-6 mt-3 p-0 ps-0 ps-md-1">
                                 <div className={classnames(styles.RadioBtnBox)}>
                                    <div className={classnames(styles.LockBox)}><img
                                       src="https://storage.googleapis.com/meta11/serviceplatform/bxl-instagram-alt.png"
                                       alt='Instagram Icon'/>
                                    </div>
                                    <div className={classnames(styles.LockText2)}><input
                                       value={state.editedInstagramLink}
                                       onChange={(event) => {
                                          setIsPersonalDetailsUpdated(true);
                                          dispatch({
                                             type: 'EDITED_INSTAGRAMLINK', payload: event.target.value
                                          })
                                       }} type="text"
                                       className={classnames(styles.SocialInput)}/>
                                    </div>
                                 </div>
                              </div>
                              : <Link to={state.userDetails.user?.instagram}
                                      style={{float: "left", width: "auto", padding: "0"}}>
                                 <div className={classnames(styles.SocialSmallIcons, "float-start")}>
                                    <img src="https://storage.googleapis.com/meta11/serviceplatform/InstaIcon-14.png"
                                         alt='Instagram Icon'></img>
                                 </div>
                              </Link>}

                           {state.edit ? <div className="row col-12 col-md-6 p-0 mt-3 mt-md-2 pe-1 ps-md-0">
                              <div className={classnames(styles.RadioBtnBox)}>
                                 <div className={classnames(styles.LockBox)}><img
                                    src="https://storage.googleapis.com/meta11/serviceplatform/bxl-twitter.png"
                                    alt='Twitter Icon'/>
                                 </div>
                                 <div className={classnames(styles.LockText2)}><input
                                    value={state.editedTwitterLink} onChange={(event) => {
                                    setIsPersonalDetailsUpdated(true);
                                    dispatch({
                                       type: 'EDITED_TWITTERLINK', payload: event.target.value
                                    })
                                 }} type="text"
                                    className={classnames(styles.SocialInput)}/></div>
                              </div>
                           </div> : <Link to={state.userDetails.user?.twitter}
                                          style={{float: "left", width: "auto", padding: "0"}}>
                              <div className={classnames(styles.SocialSmallIcons, "float-start")}>
                                 <img
                                    src="https://storage.googleapis.com/meta11/serviceplatform/TweetIcon-14.png"
                                    alt='Tweeter Icon'></img>
                              </div>
                           </Link>}

                           {state.edit ?
                              <div className="row col-12 col-md-6 p-0 mt-3 mt-md-2 ps-0 ps-md-1">
                                 <div className={classnames(styles.RadioBtnBox, "row", "pl-1")}>
                                    <div className={classnames(styles.LockBox)}><img
                                       src="https://storage.googleapis.com/meta11/serviceplatform/bxl-tiktok.png"
                                       alt='Tiktok Icon'/>
                                    </div>
                                    <div className={classnames(styles.LockText2, "p-0")}><input
                                       value={state.editedTikTokLink} onChange={(event) => {
                                       setIsPersonalDetailsUpdated(true);
                                       dispatch({
                                          type: 'EDITED_TIKTOKLINK', payload: event.target.value
                                       })
                                    }} type="text"
                                       className={classnames(styles.SocialInput)}/></div>
                                    {/*<div className={classnames(Styles.RadioBox)}><input type="radio" name="Social" id="exampleRadios6" class="form-check-input"/></div>*/}
                                 </div>
                              </div>
                              : <Link to={state.userDetails.user?.tiktok}
                                      style={{float: "left", width: "auto", padding: "0"}}>
                                 <div className={classnames(styles.SocialSmallIcons, "float-start")}
                                      style={{marginRight: 10}}>
                                    <img
                                       src="https://storage.googleapis.com/meta11/serviceplatform/TiktokIcon-14.png"
                                       alt='Tiktok Icon'></img>
                                 </div>
                              </Link>}

                           {state.edit ? <div
                              className={classnames("body-text5 color-gray2", "col-3 p-0", "text-end ")}></div> : <></>}
                           {state.edit ?
                              <div className={classnames("body-text5 color-gray2", "col-6 mt-2", !state.edit && "col-md-6", " p-0", "text-start")}>{t("USER_PROFILE_PAGE.JOINED")}: {formatDate(state.userDetails?.user?.joinedTime)}</div>
                              : <div className={classnames("body-text5 color-gray2", "col-6", !state.edit && "col-md-6", "p-0 mt-2", "text-md-end", "text-end float-end")}
                              >{t("USER_PROFILE_PAGE.JOINED")}: {formatDate(state.userDetails?.user?.joinedTime)}</div>}
                        </div>


                        {state.edit ?
                           <div className="ps-0 pt-4 pb-2 text-end mt-2 float-start">
                              {linkInvalidError.map(err => <div key={err}
                                                                className={classnames("body-text5 color-gray2", "col-12", "mt-3 mb-3", "text-start")}
                                                                style={{color: "red"}}>{err}</div>)}
                              <button className={classnames(styles.LoginButton, "me-3")} disabled={isNotOkToSubmit}
                                      style={{cursor: isPersonalDetailsUpdated ? 'pointer' : 'not-allowed'}}
                                      onClick={updateProfile}>
                                 <span>{t("USER_PROFILE_PAGE.SAVE")}</span>
                              </button>
                              <button className={styles.BlackSkBtn} onClick={cancelEditProfile}>
                                 <span>{t("USER_PROFILE_PAGE.CANCEL")}</span>
                              </button>
                           </div>
                           : <></>}
                     </div>

                     {/**extra social link for design fix ends : Mobile Screens */}

                  </div>
                  {/*Overview*/}
                  <div
                     className={classnames("heading3 color-white", "mt-5", "mb-3")}>{t("USER_PROFILE_PAGE.OVERVIEW")}</div>
                  <div className={classnames(styles.overviewWrapper, "col-12 p-0 row")}>
                     <div className={classnames(styles.CreditBox, "col-4 p-0 pe-1")}>
                        <div className={classnames(styles.OverviewBox, styles.borderTop1)}>

                           {encodedUserName == props.userName ?
                              <div className="text-center">
                                 <div className={classnames(styles.buyNumBoxYellow)}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip
                                       id="tooltip1">{walletDetails?.omniCredits}</Tooltip>}>
                                       <div
                                          className={classnames(styles.div1)}>{walletDetails.omniCredits ? formatNumberToKWithoutRounding(walletDetails?.omniCredits) : 0}
                                          &nbsp;<img src={CreditIcon} alt={""}/>
                                       </div>
                                    </OverlayTrigger>
                                    <div
                                       className={classnames(styles.div2)}>{walletDetails.omniPoints ? (walletDetails.omniPoints) : 0}&nbsp;
                                       <img src={OmniPoints} alt={""}/></div>
                                 </div>
                                 <button className={classnames(styles.BuyButton)}
                                         onClick={handleBuyWithdrawalModalOpen}>
                                    <span>{t("USER_PROFILE_PAGE.BUY_WITHDRAW")}</span><img src={CreditIcon}
                                                                                           alt={""}/>
                                 </button>
                              </div> : <>
                                 <div className={classnames(styles.mainText)}><img
                                    src="https://storage.googleapis.com/meta11/serviceplatform/token-icon.png"
                                    alt='Token Icon'
                                    style={{float: "left"}}></img>
                                    <span>{walletDetails.omniPoints ? formatNumberToKWithoutRounding(walletDetails?.omniCredits) : 0}</span>
                                 </div>
                                 <div
                                    className={classnames("body-text2 color-gray2 w-100", "ms-md-3 ms-0 mt-2", "mb-1")}>
                                    {t("USER_PROFILE_PAGE.TOTAL_POINTS_EARNED")}
                                 </div>
                              </>}
                        </div>
                     </div>
                     <div className="col-4 p-0 ps-1 pe-1">
                        <div className={classnames(styles.OverviewBox, styles.borderTop2)}>
                           <div className={classnames(styles.mainText)}><img
                              src={TeamsCreated}
                              alt='Quest Icon'
                              style={{float: 'left', width: '50px', height: '50px', padding: '5px', margin: '5px'}}></img>
                              <span>{state?.userDetails?.user?.fantasyTeams || 0}</span>
                           </div>
                           <div className={classnames("body-text2 color-gray2 w-100", "ms-md-3 ms-0 mt-2", "mb-1")}>
                              {t("USER_PROFILE_PAGE.FANTASY_TEAMS_CREATED")}
                           </div>

                        </div>
                     </div>

                     <div className="col-4 p-0 ps-1 pe-0">
                        <div className={classnames(styles.OverviewBox, styles.borderTop3)}>
                           <div className={classnames(styles.mainText)}>
                              <img src={GloblePosition}
                                   style={{float: 'left', width: '50px', height: '50px', margin: '5px'}} alt='Answer Icon'></img>
                              <span>{state?.forthLeaderDetails?.position}</span>
                           </div>
                           <div className={classnames("body-text2 color-gray2 w-100", "ms-md-3 ms-0 mt-2", "mb-1")}>
                              {t("USER_PROFILE_PAGE.GLOBAL_LEADERBOARD_POSITION")}
                           </div>
                        </div>
                     </div>
                     {/*<GuidanceTip page="userProfile" gotItIndex={2} gotItHandler={gotItHandler}*/}
                     {/*             nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler}*/}
                     {/*             tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}
                  </div>
                  <div className={classnames(styles.botHistory, "col-12 mb-5 row p-0")}>
                     <div className="col-12 col-lg-12 p-0 row pe-0">

                        {getConf(MATCH_PAGE.SHOW_FANTASYBOT_OR_QUIZBOT) === "qbot" ?
                           <>
                              <div ref={qBotHistoryTitleRef} id="qBotHistoryTitle"
                                   className={classnames(styles.qBotHistoryTitle, "mt-5", "mb-3 p-0", "float-start")}>
                                 <img style={{width: 120, float: "left", marginRight: 10}}
                                      src={Logo_Q_BOT}/>{t("USER_PROFILE_PAGE.HISTORY")}</div>
                              {showModal && <BotHistoryModel handleClose={handleCloseModal} token={props.Token}
                                                             matchInfo={matchByUser}
                                                             encodedUserName={encodedUserName} matchId={modalMatchId}
                                                             questionIndex={questionIndex}/>}
                              {!showModal &&

                                 <div className={classnames(styles.historyWrapperContainer, "row gx-0 mb-4")}>
                                    {sortedMatchArray.length !== 0 ? <>
                                       <div
                                          className={classnames(styles.participatedMatchesWrapper, "col-12 ps-2 pe-2 pt-4 pb-4 ps-sm-4 pe-sm-4 pt-sm-4 pb-sm-4")}
                                          style={{textAlign: "center", background: "rgba(9, 23, 37, 1)"}}>
                                          <h2
                                             className="heading3 w-100 upper-case">{t("USER_PROFILE_PAGE.YOUR_MATCHES_IN_WHICH_YOU_PARTICIPATED")}</h2>
                                          <label
                                             className="body-text3 w-100 color-gray2">{t("USER_PROFILE_PAGE.CLICK_ON_THE_MATCH_TO_FIND_OUT_YOUR_ANSWERS")}</label>
                                       </div>
                                       {!loading ? <div className={classnames(styles.playerHistoryWrapper, "col-12")}
                                                        style={{background: "rgba(9, 23, 37, 0.5)"}}>
                                          <ul>
                                             {sortedMatchArray.map(matchInfo => {
                                                const match = matchInfo?.matchDto;
                                                const userAnswers = matchInfo?.userAnswerEntity;
                                                return (<li key={match?.matchId} className={classnames(styles.oditem)}>
                                <span className={classnames(styles.playerHistory)}>
                                  <div className={classnames(styles.topSection)}>
                                    <div className="row">
                                    <div
                                       className="col-6 col-sm-6 col-md-6 col-lg-3 order-sm-1 order-md-1 order-1 order-lg-1">
                                    <label className={classnames(styles.itemDate)}>
                                      {match?.matchStartTime ? formatDate(match?.matchStartTime, true, true) + ", " : ''}
                                       {match?.matchStartTime ? formatTime(match?.matchStartTime) : ''}
                                    </label>
                                    </div>
                                    <div
                                       className="col-12 col-sm-12 col-md-12 col-lg-6 order-sm-3 order-md-3 order-3 order-lg-2"><h2
                                       className="$b2FontSize italic color-white upper-case">{match?.tournament ? match.tournament : t("USER_PROFILE_PAGE.SUPER_FOURS_TOURNAMENT")}</h2></div>

                                    <div
                                       className="col-6 col-sm-6 col-md-6 col-lg-3 order-sm-2 order-2 order-md-2 order-lg-3">
                                    {getConf(USER_PROFILE_PAGE.QBOT_MATCH_HISTORY_SHOW_AFTER) < match?.matchStartTime ?
                                       <button className={classnames(styles.showAllBtn, "float-right")}
                                               onClick={() => handleOpenModal(match?.matchId)}>{t("USER_PROFILE_PAGE.SHOW_ALL_ANSWERS")}
                                          <HiExternalLink/></button> : <></>}
                                    </div>
                                    </div>


                                  </div>
                                   {/* <span className={classnames(styles.mobLbl, "body-text2-b-italic upper-case mb-3")}>{t("userProfilePage.super fours tournament")}</span> */}
                                   <span className={classnames(styles.bottomSection)}>

                                    <span className={classnames(styles.leftPlayer)}>
                                      <span className={classnames(styles.player1Details)}>
                                      <label
                                         className={classnames(styles.player1Details, styles.name, "body-text3-b-italic upper-case color-white")}>

                                         {match?.winnerId == match?.team1Id ?
                                            <span className={classnames(styles.cupIcon)}></span> : ''}
                                         {match?.team1Name}
                                        </label>
                                      <label className="heading4 extra-bold color-gray2 mb-0">
                                        <div className="team2Score pe-0" style={{
                                           color: match?.winnerId == match?.team1Id ? "rgb(23, 234, 133)" : "rgb(149, 166, 183)",
                                           fontFamily: "inherit",
                                           fontStyle: "normal"
                                        }}>
                                          {match?.team1Runs ? match?.team1Runs : 0}/{match?.team1Wickets ? match?.team1Wickets : 0}
                                        </div>
                                      </label>
                                      <label className="body-text4 color-gray2 mb-0">
                                        (
                                         {match?.team1Balls !== 6 ? match?.team1Overs - 1 : match?.team1Overs}
                                         .
                                         {match?.team1Balls !== 6 ? match?.team1Balls : 0}
                                         )
                                      </label>
                                      </span>
                                      <img
                                         src={match?.team1PicURL}
                                      />
                                    </span>
                                    <span className={classnames(styles.midleDots)}>
                                      <img src={twodots}/>
                                    </span>
                                    <span className={classnames(styles.rightPlayer)}>
                                      <img src={match?.team2PicURL}/>
                                      <span className={classnames(styles.player2Details)}>
                                        <label className="body-text3-b-italic upper-case color-white"
                                               style={{position: "relative"}}>
                                        {match?.winnerId == match?.team2Id ?
                                           <div className={classnames(styles.cupIcon)}></div> : ''}
                                           <span>{match?.team2Name}</span></label>
                                      <label className="heading4 extra-bold color-textturquiose mb-0">
                                        <div className="team2Score ps-0" style={{
                                           color: match?.winnerId == match?.team2Id ? "rgb(23, 234, 133)" : "rgb(149, 166, 183)",
                                           fontFamily: "inherit",
                                           fontStyle: "normal"
                                        }}>
                                          {match?.team2Runs ? match?.team2Runs : 0}/{match?.team2Wickets ? match?.team2Wickets : 0}
                                        </div>
                                      </label>
                                      <label className="body-text4 color-gray2 mb-0">
                                        (

                                         {match?.team2Balls == null ? 0 : match?.team2Balls !== 6 ? match?.team2Overs - 1 : match?.team2Overs}
                                         .
                                         {match?.team2Balls == null ? 0 : match?.team2Balls !== 6 ? match?.team2Balls : 0}
                                         )
                                      </label>
                                      </span>
                                    </span>
                                  </span>
                                  <span>
                                  <span className={classnames(styless.ballSecondBody, "d-flex")}
                                        style={{justifyContent: "center"}}>
                                     {userAnswers?.matchWinner ? <span
                                        className={classnames(styless.bigBallWrapper, styless.bigBallGreen, styless.smallBall)}>
                                          <span className="color-black"><FaCheck/></span>
                                       </span> : <span
                                        className={classnames(styless.bigBallWrapper, styless.bigBallRed, styless.smallBall)}>
                                          <span className="color-black"><IoClose/></span>
                                       </span>}
                                     {userAnswers?.overSummaryList?.slice(0, 4).map((mark) => {
                                        if (mark) {
                                           return (<span
                                              className={classnames(styless.bigBallWrapper, styless.bigBallGreen, styless.smallBall)}>
                                          <span className="color-black">+{mark}</span>
                                       </span>);
                                        }
                                        return (<span
                                           className={classnames(styless.bigBallWrapper, styless.bigBallRed, styless.smallBall)}>
                                          <span className="color-black">{mark}</span>
                                       </span>)
                                     })}
                                    </span>
                                  </span>
                                   {/* <span className={classnames(styles.footerSect)}>
                                    <span>
                                      {userAnswers.slice(0, 8).map((userAnswer, index) => {
                                            if (userAnswer) {
                                               return (
                                                  <button className={classnames(styles.showAllBtn)} onClick={() => handleOpenModal(match?.matchId, index)}>
                                                <span className="me-1" key={index}>
                                                  <img src="https://storage.googleapis.com/meta11/serviceplatform/correctIco.png"/>
                                                </span>
                                                  </button>
                                               )
                                            } else {
                                               return (
                                                  <button className={classnames(styles.showAllBtn)} onClick={() => handleOpenModal(match?.matchId, index)}>
                                              <span className="me-1" key={index}>
                                                  <img src="https://storage.googleapis.com/meta11/serviceplatform/wrongIco.png"/>
                                                </span>
                                                  </button>)
                                            }
                                         }
                                      )
                                      }
                                    </span>
                                </span> */}
                                </span>
                                                </li>)
                                             })}
                                          </ul>
                                       </div> : <>
                                          <div style={{textAlign: "center", backgroundColor: 'rgb(9, 23, 37)'}}>
                                             <div className="lds-facebook">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                             </div>
                                          </div>
                                       </>}
                                       <div className={classnames(styles.userProfilePaginationWrapper)}>
                                          <div className={classnames("paginationWrapper col-12 p-1 pt-3")}
                                               style={{textAlign: "center", background: "rgba(9, 23, 37, 1)"}}>
                                             <Paginator postsPerPage={postsPerPage} totalElements={totalElements}
                                                        currentPage={currentPage} paginate={paginate} style/>
                                          </div>
                                       </div>
                                    </> : <>
                                       <div
                                          className={classnames(styles.participatedMatchesWrapper, "col-12 ps-2 pe-2 pt-4 pb-4 ps-sm-4 pe-sm-4 pt-sm-4 pb-sm-4")}
                                          style={{textAlign: "center", background: "rgba(9, 23, 37, 1)"}}>
                                          <h2
                                             className="heading4 w-100 upper-case">{t("USER_PROFILE_PAGE.PLEASE_INTERACT_WITH_THE_QBOT_TO_GET_YOUR_PAST_ANSWERS_HERE")}</h2>
                                       </div>
                                    </>}
                                 </div>}
                           </> : getConf(MATCH_PAGE.SHOW_FANTASYBOT_OR_QUIZBOT) === "fantasy" && props.isAuthenticated && encodedUserName == props.userName && <FantasyBotHistory encodedUserName={encodedUserName}/>}
                     </div>
                     {state.leaderBoardStatus && getConf(USER_PROFILE_PAGE.SHOW_USER_PROFILE_LEADERBOARD) && state.firstLeaderDetails?.points > 0 ?
                        <div className="col-12 col-lg-12 p-0 row pe-0 pe-lg-3">
                           <div
                              className={classnames(styles.leaderBoardTitle, "heading3 color-white", "mt-5", "mb-3 p-0", "float-start")}>
                              {t("USER_PROFILE_PAGE.LEADERBOARD")}
                              <div className={classnames(styles.shareBtnWrapper)}>
                                 {/* <img src={shareIcon} /> Share resulttopBtnWrapper */}
                                 {getConf(USER_PROFILE_PAGE.SOCIAL_SHARE_BUTTON) ?
                                    <button className="blackBtn" style={{float: "right"}} onClick={() => {
                                       socialShareModalHandler(true)
                                    }}>
                                       <BsBoxArrowUp/>{t("USER_PROFILE_PAGE.SHARE_RESULT")}
                                    </button> : <></>}
                              </div>
                           </div>

                           <div className={classnames(styles.OverviewBox, "p-0")}>
                              <div
                                 className={classnames(styles.SplashBox, styles.UserProfileSplashBox, "col-11", "p-3", "row")}>

                                 {/*                /!*2nd place user*!/*/}
                                 <div className={classNames(styles.leaderBox, "col-3", "p-0")}>
                                    {state.userDetails?.user?.position === 2 ? <>
                                       <div className={classnames(styles.curcleOut1)}>
                                          <div className={classnames(styles.upDown)}>
                                             {getUpDownSameIconByDirection(state.userDetails?.user?.position)}
                                          </div>
                                          <div className={classnames(styles.OutCurcle)}>
                                             <div className={classnames(styles.InCurcle)} onError={(e) => {
                                                e.target.style.backgroundImage = `url14px(${ProfileImgPlaceholder})`
                                             }}
                                                  style={{backgroundImage: `url(${state.userDetails?.user?.profilePicURL})`}}></div>
                                          </div>
                                          <div className={classnames(styles.curcleCount)}>2</div>
                                       </div>
                                       <Link to={state.userDetails?.user?.private ? '' : {
                                          pathname: `/user/${btoa(state.userDetails?.user?.userName)}`,
                                          search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                       }}>
                                          {lBPrivateUserToolTip(<div
                                             style={{cursor: state.userDetails?.user?.private && "default"}}
                                             className={classnames("body-text2 color-gray2", "text-center", "mt-3", "mb-3")}>{getDisNameForFirst3Users(state.userDetails?.user?.firstName, state.userDetails?.user?.lastName, state.userDetails?.user?.username)[0]}<br/>
                                             {getDisNameForFirst3Users(state.userDetails?.user?.firstName, state.userDetails?.user?.lastName, state.userDetails?.user?.username)[1]} {state.userDetails?.user?.private ?
                                                <i className="fa-sharp fa-solid fa-lock"
                                                   style={{fontSize: "12px", color: "FF0000"}}></i> : <></>}
                                          </div>, state.userDetails?.user?.private)}
                                       </Link>
                                       <div className={classnames(styles.marks, "text-center")}
                                            style={{lineHeight: "25px"}}>
                                          <img className="mr-1" src={OmniPoints}/>
                                          <font style={{fontSize: "14px", color: "#17EA85"}}>
                                             {formatNumberToKWithoutRounding(state.userDetails?.user?.tokens, 1)}
                                          </font>
                                       </div>
                                    </> : <>
                                       <div className={classnames(styles.curcleOut1)}>
                                          <div className={classnames(styles.upDown)}>
                                             {getUpDownSameIconByDirection(state.secondLeaderDetails?.position)}
                                          </div>
                                          <div className={classnames(styles.OutCurcle)}>
                                             <div className={classnames(styles.InCurcle)} onError={(e) => {
                                                e.target.style.backgroundImage = `url(${ProfileImgPlaceholder})`
                                             }}
                                                  style={{backgroundImage: `url(${state.secondLeaderDetails?.profilePicUrl})`}}></div>
                                          </div>
                                          <div className={classnames(styles.curcleCount)}>2</div>
                                       </div>
                                       <Link style={{textDecoration: "none"}}
                                             to={state.secondLeaderDetails?.isPrivate ? '' : {
                                                pathname: `/user/${btoa(state.secondLeaderDetails?.userName)}`,
                                                search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                             }}>
                                          {lBPrivateUserToolTip(<div
                                             style={{cursor: state.secondLeaderDetails?.isPrivate && "default"}}
                                             className={classnames(styles.trophyTextW, "text-center", "mt-3", "mb-3")}>{getDisNameForFirst3Users(state.secondLeaderDetails?.firstName, state.secondLeaderDetails?.lastName, state.secondLeaderDetails?.userName)[0]}<br/>
                                             {getDisNameForFirst3Users(state.secondLeaderDetails?.firstName, state.secondLeaderDetails?.lastName, state.secondLeaderDetails?.userName)[1]} {state.secondLeaderDetails?.isPrivate ?
                                                <i className="fa-sharp fa-solid fa-lock"
                                                   style={{fontSize: "12px", color: "FF0000"}}></i> : <></>}
                                          </div>, state.secondLeaderDetails?.isPrivate)}
                                       </Link>
                                       <div className={classnames(styles.marks, "text-center")}
                                            style={{lineHeight: "25px"}}>
                                          <img className="mr-1" src={OmniPoints}/>
                                          {toolTipProvider(state.secondLeaderDetails?.points, 'top',
                                             <font style={{
                                                fontSize: "14px",
                                                color: "#17EA85",
                                                cursor: "default"
                                             }}>{formatNumberToKWithoutRounding(state.secondLeaderDetails?.points, 1)}</font>, true)}
                                       </div>
                                    </>}
                                 </div>

                                 {/*1st place user*/}
                                 <div className="col-6 p-0 ">
                                    {state.userDetails?.user?.position == 1 ? <>
                                       <div className={classnames(styles.curcleOut2)}>
                                          <div className={classnames(styles.upDown)}><img
                                             src="https://storage.googleapis.com/meta11/serviceplatform/CrownVector.png"
                                             alt="Crown Icon"/></div>
                                          <div className={classnames(styles.OutCurcle)}>
                                             <div className={classnames(styles.InCurcle)} onError={(e) => {
                                                e.target.style.backgroundImage = `url(${ProfileImgPlaceholder})`
                                             }}
                                                  style={{backgroundImage: `url(${state.userDetails?.user?.profilePicURL})`}}></div>
                                          </div>
                                          <div className={classnames(styles.curcleCount)}>1</div>
                                       </div>
                                       <Link to={state.userDetails?.user?.private ? '' : {
                                          pathname: `/user/${btoa(state.userDetails?.user?.userName)}`,
                                          search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                       }}>
                                          {lBPrivateUserToolTip(<div
                                             style={{cursor: state.userDetails?.user?.private && "default"}}
                                             className={classnames(styles.trophyTextW, "text-center", "mt-3", "mb-3")}>{getDisNameForFirst3Users(state.userDetails?.user?.firstName, state.userDetails?.user?.lastName, state.userDetails?.user?.username)[0]}<br/>
                                             {getDisNameForFirst3Users(state.userDetails?.user?.firstName, state.userDetails?.user?.lastName, state.userDetails?.user?.username)[1]} {state.userDetails?.user?.private ?
                                                <i className="fa-sharp fa-solid fa-lock"
                                                   style={{fontSize: "12px", color: "FF0000"}}></i> : <></>}
                                          </div>, state.userDetails?.user?.private)}

                                       </Link>
                                       <div className={classnames(styles.marks, "text-center")}
                                            style={{lineHeight: "25px"}}>
                                          <img className="mr-1" src={OmniPoints}/>
                                          <font style={{
                                             fontSize: "14px", color: "#17EA85"
                                          }}>{formatNumberToKWithoutRounding(state.userDetails?.user?.tokens, 1)}</font>
                                       </div>
                                    </> : <>
                                       <div className={classnames(styles.curcleOut2)}>
                                          <div className={classnames(styles.upDown)}><img
                                             src="https://storage.googleapis.com/meta11/serviceplatform/CrownVector.png"
                                             alt="Crown Image"/></div>
                                          <div className={classnames(styles.OutCurcle)}>
                                             <div className={classnames(styles.InCurcle)} onError={(e) => {
                                                e.target.style.backgroundImage = `url(${ProfileImgPlaceholder})`
                                             }}
                                                  style={{backgroundImage: `url(${state.firstLeaderDetails?.profilePicUrl})`}}></div>
                                          </div>
                                          <div className={classnames(styles.curcleCount)}>1</div>
                                       </div>
                                       <Link style={{textDecoration: "none"}}
                                             to={state.firstLeaderDetails?.isPrivate ? '' : {
                                                pathname: `/user/${btoa(state.firstLeaderDetails?.userName)}`,
                                                search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                             }}>
                                          {lBPrivateUserToolTip(<div
                                             style={{cursor: state.firstLeaderDetails?.isPrivate && "default"}}
                                             className={classnames(styles.trophyTextW, "text-center", "mt-3", "mb-3")}>{getDisNameForFirst3Users(state.firstLeaderDetails?.firstName, state.firstLeaderDetails?.lastName, state.firstLeaderDetails?.userName)[0]}<br/>
                                             {getDisNameForFirst3Users(state.firstLeaderDetails?.firstName, state.firstLeaderDetails?.lastName, state.firstLeaderDetails?.userName)[1]} {state.firstLeaderDetails?.isPrivate ?
                                                <i className="fa-sharp fa-solid fa-lock"
                                                   style={{fontSize: "12px", color: "FF0000"}}></i> : <></>}
                                          </div>, state.firstLeaderDetails?.isPrivate)}

                                       </Link>
                                       <div className={classnames(styles.marks, "text-center")}
                                            style={{lineHeight: "25px"}}>
                                          <img className="mr-1" src={OmniPoints}/>
                                          {toolTipProvider(state.firstLeaderDetails?.points, 'top',
                                             <font className={(styles.trophyText)} style={{cursor: "default"}}>
                                                {formatNumberToKWithoutRounding(state.firstLeaderDetails?.points, 1)}</font>, true)}
                                       </div>
                                    </>}
                                 </div>

                                 {/*3rd place user*/}
                                 <div className={classNames(styles.leaderBox, "col-3", "p-0")}>
                                    {state.userDetails?.user?.position == 3 ? <>
                                       <div className={classnames(styles.curcleOut1)}>
                                          <div className={classnames(styles.upDown)}>
                                             {getUpDownSameIconByDirection(state.userDetails?.user?.position)}
                                          </div>
                                          <div className={classnames(styles.OutCurcle)}>
                                             <div className={classnames(styles.InCurcle)}
                                                  onError={(e) => {
                                                     e.target.style.backgroundImage = `url(${ProfileImgPlaceholder})`
                                                  }}
                                                  style={{backgroundImage: `url(${state.userDetails?.user?.profilePicURL})`}}></div>
                                          </div>
                                          <div className={classnames(styles.curcleCount)}>3</div>
                                       </div>
                                       <Link to={state.userDetails?.user?.private ? '' : {
                                          pathname: `/user/${btoa(state.userDetails?.user?.userName)}`,
                                          search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                       }}>
                                          {lBPrivateUserToolTip(<div
                                             style={{cursor: state.userDetails?.user?.private && "default"}}
                                             className={classnames("body-text2 color-white", "text-center", "mt-3", "mb-3")}>{getDisNameForFirst3Users(state.userDetails?.user?.firstName, state.userDetails?.user?.lastName, state.userDetails?.user?.username)[0]}<br/>
                                             {getDisNameForFirst3Users(state.userDetails?.user?.firstName, state.userDetails?.user?.lastName, state.userDetails?.user?.username)[1]} {state.userDetails?.user?.private ?
                                                <i className="fa-sharp fa-solid fa-lock"
                                                   style={{fontSize: "12px", color: "FF0000"}}></i> : <></>}
                                          </div>, state.userDetails?.user?.private)}
                                       </Link>
                                       <div className={classnames(styles.marks, "text-center")}
                                            style={{lineHeight: "25px"}}>
                                          <img className="mr-1" src={OmniPoints}/>
                                          <font className={(styles.trophyText)}>
                                             {formatNumberToKWithoutRounding(state.userDetails?.user?.tokens, 1)}
                                          </font>
                                       </div>
                                    </> : <>
                                       <div className={classnames(styles.curcleOut1)}>
                                          <div className={classnames(styles.upDown)}>
                                             {getUpDownSameIconByDirection(state.thirdLeaderDetails?.position)}
                                          </div>
                                          <div className={classnames(styles.OutCurcle)}>
                                             <div className={classnames(styles.InCurcle)}
                                                  onError={(e) => {
                                                     e.target.style.backgroundImage = `url(${ProfileImgPlaceholder})`
                                                  }}
                                                  style={{backgroundImage: `url(${state.thirdLeaderDetails?.profilePicUrl})`}}></div>
                                          </div>
                                          <div className={classnames(styles.curcleCount)}>3</div>
                                       </div>
                                       <Link style={{textDecoration: "none"}}
                                             to={state.thirdLeaderDetails?.isPrivate ? '' : {
                                                pathname: `/user/${btoa(state.thirdLeaderDetails?.userName)}`,
                                                search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                             }}>
                                          {lBPrivateUserToolTip(<div
                                             style={{cursor: state.thirdLeaderDetails?.isPrivate && "default"}}
                                             className={classnames(styles.trophyTextW, "text-center", "mt-3", "mb-3")}>{getDisNameForFirst3Users(state.thirdLeaderDetails?.firstName, state.thirdLeaderDetails?.lastName, state.thirdLeaderDetails?.userName)[0]}<br/>
                                             {getDisNameForFirst3Users(state.thirdLeaderDetails?.firstName, state.thirdLeaderDetails?.lastName, state.thirdLeaderDetails?.userName)[1]} {state.thirdLeaderDetails?.isPrivate ?
                                                <i className="fa-sharp fa-solid fa-lock"
                                                   style={{fontSize: "12px", color: "FF0000"}}></i> : <></>}
                                          </div>, state.thirdLeaderDetails?.isPrivate)}

                                       </Link>
                                       <div className={classnames(styles.marks, "text-center")}
                                            style={{lineHeight: "25px"}}>
                                          <img className="mr-1" src={OmniPoints}/>
                                          {toolTipProvider(state.thirdLeaderDetails?.points, 'top',
                                             <font className={(styles.trophyText)} style={{cursor: "default"}}>
                                                {formatNumberToKWithoutRounding(state.thirdLeaderDetails?.points, 1)}</font>, true)}

                                       </div>
                                    </>}
                                 </div>
                              </div>
                              {/*table*/}
                              <div className={classnames(styles.leaderBoardTableWrapper, "col-md-12")}>
                                 <table border="0" cellPadding="0" cellSpacing="0"
                                        className={classnames(styles.leaderBoard, "mt-4")}>
                                    <thead>
                                    <tr>
                                       <th style={{textAlign: "center"}}>{"№"}</th>
                                       <th>{t("USER_PROFILE_PAGE.LEADERBOARD_HEADING_ALL_PLAYERS")}</th>
                                       <th
                                          style={{width: "12%"}}>{t("USER_PROFILE_PAGE.LEADERBOARD_HEADING_GAMES_PLAYED")}</th>
                                       <th><span>{t("USER_PROFILE_PAGE.LEADERBOARD_HEADING_TOTAL_POINTS")}</span>
                                       </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {state.leaderBoardDetails?.map((leader) => {
                                       if (leader?.position == state.forthLeaderDetails?.position) {
                                          return <>{selectedUserLBTableRow}</>
                                       }
                                       return <>
                                          {topLeaderBoardUserTableRow(leader)}
                                       </>
                                    })}
                                    {(userRankOfSelectedUser === 0) ? selectedUserLBTableRow : <></>}
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                           {/*<GuidanceTip page="userProfile" gotItIndex={5} gotItHandler={gotItHandler}*/}
                           {/*             nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler}*/}
                           {/*             tipIndexCounter={tipIndexCounter}*/}
                           {/*             setTipIndexCounter={setTipIndexCounter}/>*/}

                        </div> : <></>}
                  </div>
               </>}
            </div>
            <div
               className={classnames(styles.userProfileRightCol, "col-12 col-xl-3 p-0 pt-2 d-flex d-xl-inline-block")}
               style={{flexDirection: "column"}}>
               {SHOW_USER_PROFILE_ADVERTISEMENT_URL ? <div className="row gx-1 gx-sm-5 w-100 order-4 order-xl-1">

                  <div className="col-12 float-right">
                     <img src={SHOW_USER_PROFILE_ADVERTISEMENT_URL}
                          style={{width: "100%"}} alt='Advertisment Icon'></img>
                  </div>
               </div> : <></>}
               {getConf(USER_PROFILE_PAGE.SHOW_USER_PROFILE_NOTIFICATIONS) ?
                  <div className="row gx-1 w-100 gx-sm-5 order-1 order-xl-2">
                     <div className="col-12 pt-4 float-right">
                        {(encodedUserName === props.userName) ? <>
                           {(connectPubnub && pubNubToken != null && props.isAuthenticated) ?
                              <UserDataChannelHandler handleNewNotificationReceive={handleNewNotificationReceive} handelWalletDataRecived={handelWalletDataRecived} pubNubToken={pubNubToken} subscribe={subscribeChannel}
                                                      onUnsubscribe={unSubscribeChannel}/> : <></>}
                           <NotificationSection notifications={state.userNotifications} t={t}/>
                           {/*<GuidanceTip page="userProfile" gotItIndex={3} gotItHandler={gotItHandler}*/}
                           {/*             nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler}*/}
                           {/*             tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}

                        </> : <></>}
                     </div>
                  </div> : <></>}
               <div className="row gx-1 w-100 gx-sm-5 order-2 order-xl-3">
                  <div className="col-12 pt-4 float-right">
                     <div className={classnames(styles.OverviewBox, "p-0")}>
                        <h2>{t("USER_PROFILE_PAGE.FAVORITE_TEAMS")}</h2>
                        <div className={classnames("body-text2 color-gray2 w-100", "mt-2", "mb-1")}>
                           <ul className={classnames(styles.FavMatchUpUL)}>
                              {state.favouriteTeams?.length > 0 ? <>
                                 {state.favouriteTeams?.map((team) => (
                                    <Link style={{textDecoration: "none"}} key={team.id} to={{
                                       pathname: `/team/${team.id}`,
                                       search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                    }}>
                                       <li style={{color: '#ffffff'}}>
                                          {team.picUrl ? <img src={team.picUrl} alt={`${team?.name} Image`} style={{
                                             marginRight: '10px', maxWidth: "42px", flexShrink: '0'
                                          }}></img> : <section
                                             className={classnames(styles.emptyFavsCircle, "float-start", "text-center")}
                                             style={{
                                                backgroundColor: 'transparent', marginRight: '10px'
                                             }}></section>}
                                          <span>{team.name}</span>
                                       </li>
                                    </Link>))}
                                 {(state.totalFavouriteTeamCount > 6) ? <Link to={{
                                    pathname: `/teams`, search: `userName=${state.userDetails?.user?.username}`
                                 }}>
                                    <li>{t("USER_PROFILE_PAGE.SHOW_MORE")} &#40;{state.totalFavouriteTeamCount - 6}&#41;</li>
                                 </Link> : <li></li>}
                              </> : <>
                                 <li>
                                       <span className="pl-2"
                                             style={{color: '#95A6B7'}}>{t("USER_PROFILE_PAGE.NO_TEAMS_FOUND")}</span>
                                 </li>
                              </>}
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row gx-1 w-100 gx-sm-5 mb-4 order-3 order-xl-4">
                  <div className="col-12 pt-4 float-right">
                     <div className={classnames(styles.OverviewBox, "p-0")}>
                        <h2>{t("USER_PROFILE_PAGE.FAVORITE_PLAYERS")}</h2>
                        <div className={classnames("w-100 body-text2 color-gray2", "mt-2", "mb-1")}>
                           <ul className={classnames(styles.FavPlayerUL)}>
                              {state.favouritePlayers?.length > 0 ? <>
                                 {state.favouritePlayers?.map((player) => (
                                    <Link style={{textDecoration: "none"}} key={player.id} to={{
                                       pathname: `/player/${player.id}`,
                                       search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                    }}>
                                       <li style={{
                                          display: "flex",
                                          justifyContent: "left",
                                          alignItems: "center",
                                          textDecoration: "none"
                                       }}>
                                          <img src={player?.picUrl} alt='Fav Player Image' style={{
                                             height: '62px',
                                             width: '42px',
                                             flexShrink: '0',
                                             float: 'left',
                                             marginRight: '10px'
                                          }}/>
                                          <span className="pl-2"
                                                style={{paddingTop: "10px", color: '#ffffff'}}>{player.name}
                                             <br/>
                                                        <div
                                                           className={classnames("body-text5 color-gray2")}>{t("USER_PROFILE_PAGE.BATSMAN")}</div>
                                                    </span>
                                       </li>
                                    </Link>))}
                                 {(state.totalFavouritePlayerCount > 6) ? <Link to={{
                                    pathname: `/players`, search: `userName=${state.userDetails?.user?.username}`
                                 }}>
                                    <li>{t("USER_PROFILE_PAGE.SHOW_MORE")} &#40;{state.totalFavouritePlayerCount - 6}&#41;</li>
                                 </Link> : <li></li>}
                              </> : <li>
                                    <span className="pl-2"
                                          style={{color: '#95A6B7'}}>{t("USER_PROFILE_PAGE.NO_PLAYERS_FOUND")}</span>
                              </li>}
                           </ul>
                        </div>
                        {/*<GuidanceTip page="userProfile" gotItIndex={4} gotItHandler={gotItHandler}*/}
                        {/*             nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler}*/}
                        {/*             tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div className={isFetchingData && classnames(styles.Footer)}>
         <Footer/>
      </div>

      <SocialShareModal
         isShowModal={state.isShowingSocialModal}
         modalCloseHandler={socialShareModalHandler}
         currentUser={state.forthLeaderDetails}
         isAuthenticated={props.isAuthenticated}
         sharePath={'/leaderboard'}
         isSharingPoints={true}
      />

      {isBuyWithdrawalModalShow ? <OMNICreditsBuyWithdrawalModal handleClose={handleBuyWithdrawalModalClose}/> : <></>}

      <Modal show={state.invalidFileUpload || state.maxFileSizeExceed}
             onHide={closeModal} className="">
         {state.invalidFileUpload ? <Modal.Body>
            <a className="closeModal">
               <BsXLg style={{cursor: 'pointer'}} onClick={closeModal}/>
            </a>
            <div className="text-center mb-2">
               <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" fill="currentColor"
                    viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="red"/>
                  <path
                     d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
                     fill="red"/>
               </svg>
            </div>


            <font className={classnames(styles.modalTitle, "w-100", "mb-3 pt-2 float-start text-center")}
                  id="exampleModalCenterTitle">{t("USER_PROFILE_PAGE.INVALID_FILE_UPLOAD")}</font>
            <div
               className={classnames(styles.modalText, "mt-3 text-center")}>{t("USER_PROFILE_PAGE.ACCEPTABLE_PNG_OR_JPEG_FILES, UP_TO")} {state.imageType == ASSET_UPLOAD_TYPES.COVER ? PIC_UPLOAD_SIZES.COVER : PIC_UPLOAD_SIZES.PROFILE}MB
            </div>

            <div className="mod-foot d-flex justify-content-end mt-4">
               <button onClick={closeModal} type="button" className="BlackSkBtn ">
                  <span>{t("USER_PROFILE_PAGE.CANCEL")}</span>
               </button>
            </div>
         </Modal.Body> : <Modal.Body>
            <a className="closeModal">
               <BsXLg style={{cursor: 'pointer'}} onClick={closeModal}/>
            </a>
            <div className="text-center mb-2">
               <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" fill="currentColor"
                    viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        fill="red"/>
                  <path
                     d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
                     fill="red"/>
               </svg>
            </div>

            <font className={classnames(styles.modalTitle, "w-100", "mb-3 pt-2 float-start text-center")}
                  id="exampleModalCenterTitle">{t("USER_PROFILE_PAGE.FILE_SIZE_EXCEEDS")}..!</font>
            <div
               className={classnames(styles.modalText, "mt-3 text-center")}>{t("USER_PROFILE_PAGE.MAX_FILE_SIZE_IS")} {state.imageType == ASSET_UPLOAD_TYPES.COVER ? PIC_UPLOAD_SIZES.COVER : PIC_UPLOAD_SIZES.PROFILE}MB
            </div>

            <div className="mod-foot d-flex justify-content-end mt-4">
               <button onClick={closeModal} type="button" className={classnames(styles.MaxFileSizeBtn, "lightGreenButton")}>
                  <span>{t("USER_PROFILE_PAGE.CANCEL")}</span>
               </button>
            </div>
         </Modal.Body>}
      </Modal>

      {state.confirmAssetDelete ?
         <div className={classnames("modal fade show", styles.modalBlur)} id="exampleModalCenter"
              tabIndex="-1" role="dialog"
              aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
            <div className={classnames(styles.userProfileModalWrapper, "modal-dialog modal-dialog-centered")}
                 role="document">
               <div className={classnames("modal-content", "p-4")}>
                  <div style={{textAlign: "right"}}>
                     <button className={classnames(styles.closeButton, "close")} type="button"
                             onClick={closeModal} data-dismiss="modal" aria-label="Close">
                                                    <span className={styles.closeButton} aria-hidden="true">
                                                       <img alt='Close Icon'
                                                            src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/></span>
                     </button>

                  </div>
                  <div className={classnames(styles.modalContainer, "p-4 text-center")}>
                     <div style={{display: "block", textAlign: "center"}}>
                        <div className="text-center mb-2">
                           <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" fill="currentColor"
                                viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                    fill="red"/>
                              <path
                                 d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
                                 fill="red"/>
                           </svg>
                        </div>

                        <font className={classnames(styles.modalTitle, "w-100", "mb-3")}
                              id="exampleModalCenterTitle">
                           {t("USER_PROFILE_PAGE.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_PHOTO?")}
                        </font>

                     </div>
                  </div>
                  <div className={classnames(styles.photoModifyModelBtnRow, "p-3")}>
                     <button type="button" className={styles.BlackSkBtn}
                             onClick={closeModal}>
                        <span>{t("USER_PROFILE_PAGE.CANCEL")}</span>
                     </button>
                     <button type="button" className={styles.LoginButton}
                             onClick={deleteAsset}>{t("USER_PROFILE_PAGE.DELETE")}
                     </button>
                  </div>
               </div>
            </div>
         </div> : <></>}

      {state.confirmAccountDelete ?
         <div className={classnames("modal fade show", styles.modalBlur)} id="exampleModalCenter"
              tabIndex="-1" role="dialog"
              aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
            <div className={classnames(styles.userProfileModalWrapper, "modal-dialog modal-dialog-centered")}
                 role="document">
               <div className={classnames("modal-content", "p-4")}>
                  <button className={classnames(styles.closeButton, "close")}
                          style={{position: "absolute", right: "15px", top: "10px", zIndex: "2"}} type="button"
                          onClick={closeModal} data-dismiss="modal" aria-label="Close">
                                                    <span className={styles.closeButton} aria-hidden="true">
                                                       <img alt='Close Icon'
                                                            src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/></span>
                  </button>
                  <div className={classNames(styles.UserProfileModalWrapper)}>
                     <div className={classNames(styles.circle1)}></div>
                     <div className={classNames(styles.circle2)}></div>
                     <div className={classNames(styles.circle3)}></div>
                     <div className="row p-0">
                        <div className={classNames(styles.UserProfileModal, "col-md-12")}>
                           <img src={DeleteAccHeaderImg} className={classNames(styles.DeleteAccHeaderImg)}/>
                           <h4>{t("USER_PROFILE_PAGE.ARE_YOU_SURE_YOU_WANT_TO_DELETE_YOUR_ACCOUNT")}</h4>
                        </div>
                     </div>
                     <div className="row p-0">
                        <div className={classNames(styles.InputRow, "col-md-12")}>
                           <label>{t("USER_PROFILE_PAGE.TYPE_YOUR_USERNAME_TO_CONFIRM_ACCOUNT_DELETION_YOUR_USERNAME")}</label>
                           <div>
                              {t("USER_PROFILE_PAGE.YOUR_USERNAME")} <label
                              className={classNames(styles.UserName)}>{props.userName}</label>
                           </div>
                           <input onChange={(e) => {
                              e.target.value == props.userName ? dispatch({
                                 type: "IS_USER_DELETION_CONFIRM", payload: true
                              }) : dispatch({
                                 type: "IS_USER_DELETION_CONFIRM", payload: false
                              })
                           }} type="text"></input>
                        </div>
                     </div>
                  </div>
                  <div className={classnames(styles.photoModifyModelBtnRow, "p-3")}>
                     <button type="button" className={styles.BlackSkBtn}
                             onClick={closeModal}>
                        <span>{t("USER_PROFILE_PAGE.NO,GET_ME_BACK")}</span>
                     </button>
                     <button disabled={!state?.deleteConfirmation} onClick={deleteUserProfile} type="button"
                             className={styles.LoginButton}>{t("USER_PROFILE_PAGE.YES, I'M_SURE")}
                     </button>
                  </div>
               </div>
            </div>
         </div> : <></>}

      {state.coverModalShow ? <div className={classnames("modal fade show", styles.modalBlur)} id="exampleModalCenter"
                                   tabIndex="-1" role="dialog"
                                   aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
                                   style={{display: "block"}}>
         <div className="modal-dialog modal-dialog-centered" role="document">
            {state.modalResponseShow ? <div style={{width: "100%"}}>
               {state.confirmSubmission ? <div></div> : <div className={classnames("modal-content", "p-4")}>
                  <div style={{textAlign: "right"}}>
                     {/*<button className={classnames(styles.closeButton, "close")} type="button"
                                                        onClick={closeModal}
                                                        data-dismiss="modal" aria-label="Close">
                                                    <span className={styles.closeButton}
                                                          aria-hidden="true"><img src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/></span>
                                                </button>*/}
                  </div>
                  <font
                     className={classnames(styles.modalTitle, "w-100", "mb-2", "mt-0", "text-center")}
                     id="exampleModalCenterTitle" style={{fontSize: "15px"}}>
                     {t("USER_PROFILE_PAGE.PLEASE_CROP_YOUR_IMAGE")}
                  </font>
                  <div className="text-center">
                     <ImageCrop image={state.image} onCropModalHide={onCropModalHide}
                                type={state.imageType} userId={props.userName}
                                email={props.email} Token={props.Token}/>
                  </div>
               </div>}
            </div> : <div className={classnames("modal-content", styles.modalContentCustom)}
                          style={{backgroundColor: "#f5f5f5"}}>
               <div className={classnames("modal-header", styles.modalFooterLine)}>
                  <div style={{display: "block", float: "left"}} className={"w-100"}>
                     <img src={"https://storage.googleapis.com/meta11/serviceplatform/slash-small.png"}
                          alt='Slash Icon'/>
                     <button className={classnames(styles.closeButton, "close")}
                             type="button"
                             onClick={closeModal}
                             data-dismiss="modal" aria-label="Close">
                                                    <span className={styles.closeButton}
                                                          aria-hidden="true">&times;</span>
                     </button>
                  </div>

                  <h5 className={classnames("modal-title", "w-100", styles.font15)}
                      id="exampleModalCenterTitle">
                     {t("USER_PROFILE_PAGE.PLEASE_HANG_ON_WHILE_IMAGE_IS_GETTING_READY")}...
                  </h5>
               </div>

               <div className="modal-body">
                  <div className="rounded p-2" style={{backgroundColor: "#e7e7e7"}}>
                     <img alt='File Icon'
                          src={"https://storage.googleapis.com/meta11/serviceplatform/file.png"} width={25}
                          height={31} className="mr-2 "/>
                     <font className={styles.fileName}>{state.file?.name}</font>
                  </div>

                  {state.showProgress === true ? <div className={"pb-2 pt-2"}>
                     <ProgressBar now={state.progress}
                                  label={`${state.progress}%`}/>
                  </div> : <></>}
               </div>

               <div className={classnames("modal-footer", styles.modalFooterLine)}
                    style={{backgroundColor: "#063145"}}>
                  <button type="button" style={{float: "right"}}
                          disabled={state.invalidFileUpload || state.showProgress || state.uploadButtonDisable}
                          className={styles.smallBtn}>{t("USER_PROFILE_PAGE.UPLOAD")}
                  </button>
               </div>
            </div>}
         </div>
      </div> : <></>}
      <div className={classnames(styles.Radial1)}></div>
      <div className={classnames(styles.Radial2)}></div>
   </React.Fragment>);
};
const mapDispatchToProps = (dispatch) => {
   return {
      onProfileUpdate: (imageURL) => dispatch(actions.updateUserProfile(imageURL)),
      onProfileNameUpdate: (name) => dispatch(actions.updateProfileName(name)),
      onLogout: () => dispatch(actions.logout()),
      onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};
const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null && state.auth.accessToken !== undefined,
      profileImage: state.auth.imageURL,
      userId: state.auth.userId,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileV2);
