import React, {useEffect, useState} from "react";
import Classnames from "classnames";
import styles from "./PlayerBoostHistoryModal.module.scss";
import {t} from "i18next";
import {getPlayerBoostHistory} from "../../../services/FantasyService";
import {connect} from "react-redux";
import {getBoosterFullTime} from "../../../helpers/CommonFunctions";
import RenderTextWithImages from "../../RenderTextWithImages/RenderTextWithImages";

const PlayerBoostHistoryModal = ({data, handleModalClose, userName}) => {
   const {playerId, playerName, tournamentId, tournamentName} = data;
   const [isLoading, setIsLoading] = useState(true);
   const [boostHistory, setBoostHistory] = useState([]);
   const [totalSpent, setTotalSpent] = useState(0);

   useEffect(() => {
      const fetchPlayerBoosterHistory = async () => {
         getPlayerBoostHistory(playerId, tournamentId, userName)
            .then((response) => {
               if (response?.apiStatus === true && response?.history?.length > 0) {
                  setBoostHistory(response?.history);
               }
               setIsLoading(false);
            })
            .catch((error) => {
               console.log(error);
               setIsLoading(false);
            });
      }

      if (playerId && tournamentId && userName) {
         fetchPlayerBoosterHistory();
      }
   }, [playerId, tournamentId, userName]);

   useEffect(() => {
      let total = 0;
      boostHistory?.map((boost) => {
         total += boost?.price;
      });
      setTotalSpent(total);
   }, [boostHistory]);

   const loadingIndicator = (<div className="text-center">
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   return (<div
      className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.FantasyMatchHistoryModal, styles.PlayerBoosterHistoryModal)}
      id="exampleModalCenter"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={{display: "block"}}>
      <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
         <div className={Classnames("modal-content", styles.modalContentContainer)}>
            <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                    data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
            </button>

            <div className={Classnames(styles.Radial1)}></div>
            <div className={Classnames(styles.Radial2)}></div>
            <div className={Classnames(styles.EdgeRadial1)}></div>
            <div className={Classnames(styles.EdgeRadial2)}></div>

            <div className={Classnames(styles.UserProfileModalWrapper)}>

               <div className={Classnames(styles.ellipse, styles.e392)}></div>
               <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     <div className={Classnames(styles.TitleWrapper)}>
                        <h4>{playerName}</h4>
                     </div>
                  </div>
               </div>

               {isLoading ? loadingIndicator :
                  (!boostHistory || boostHistory?.length <= 0) ?
                     <div className="row">
                        <div
                           className={Classnames(styles.NoFantasyHistoryData, "col")}>{t("FANTASY.YOU_HAVENT_BOOSTED_THIS_PLAYER_IN_THIS_TOURNAMENT")}</div>
                     </div> :
                     <>
                        <div className="row">
                           <div className={Classnames(styles.HeaderMsg, "col")}>Here is a detailed list of the boosts
                              you bought for {playerName} for {tournamentName}
                           </div>
                        </div>
                        <div className="row p-0">
                           <div className={Classnames(styles.FantasyHistoryTable)}>
                              <table>
                                 <thead>
                                 <tr>
                                    <th>#</th>
                                    <th>Boost</th>
                                    <th>Description</th>
                                    <th>Time</th>
                                    <th>Price</th>
                                 </tr>
                                 </thead>
                                 <tbody>
                                 {boostHistory?.map((boost, index) => (
                                    <tr key={boost?.boosterId} style={{height: "30px"}}>
                                       <td>{index + 1}</td>
                                       <td>{boost?.boosterName}</td>
                                       <td><RenderTextWithImages text={boost?.description}/></td>
                                       <td>{getBoosterFullTime(boost)}</td>
                                       <td>{boost?.price}</td>
                                    </tr>
                                 ))}

                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div className="row">
                           <div className={Classnames(styles.FooterMsg, "col")}>Total Spent on Boosts
                              for <span>{playerName}: <b>{totalSpent}</b></span> $SPRTS
                           </div>
                        </div>
                     </>}

               <div className="row p-0">
                  <div className={Classnames(styles.ModalButtonRow, "row p-0")}>
                     <button className="lightGreenButton" onClick={handleModalClose}>
                        <text>{t("FANTASY.CLOSE")}</text>
                     </button>
                  </div>
               </div>

            </div>
         </div>
      </div>
   </div>)
}

const mapStateToProps = (state) => {
   return {
      userName: state.auth.userName,
      token: state.auth.accessToken
   };
};

export default connect(mapStateToProps)(PlayerBoostHistoryModal);
