import React from "react";
import Classnames from "classnames";
import modalStyles from "./ScheduledMatchPopUp.module.scss";
import {useTranslation} from "react-i18next";
import Timer from "./Timer";
import {Link} from "react-router-dom";

const ScheduledMatchPopUp = ({showModal, closeModal, matchStartTime}) => {
   const {t} = useTranslation();

   if (!showModal) {
      return null;
   }

   return (
      <div className={Classnames("modal fade show ScheduledMatchPopUp", modalStyles.modalBlur, modalStyles.PaymentConfirmmodal, modalStyles.HowDoesBotWorkMmodal, modalStyles.ScheduledMatchPopUp)} id="exampleModalCenter"
           tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
           style={{display: "block"}}>
         <div className={Classnames(modalStyles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
            <div className={Classnames("modal-content", modalStyles.modalContentContainer)}>
               <button className={Classnames(modalStyles.closeButton, "close z-3")} type="button" data-dismiss="modal"
                       aria-label="Close" onClick={closeModal} style={{cursor: 'pointer'}}>
                   <span className={modalStyles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
               </button>
               <div className={Classnames(modalStyles.UserProfileModalWrapper)}>
                  <div className={Classnames(modalStyles.ellipse, modalStyles.eMulticolorHalf)}></div>
                  <div className={Classnames(modalStyles.ellipse, modalStyles.e392)}></div>

                  <div className="row p-0">
                     <div className={Classnames(modalStyles.UserProfileModal, "col-md-12")}>
                        <div className={Classnames(modalStyles.TitleWrapperm)}>
                           <h4>{t("MATCH.TIME_LEFT_FOR_THE_MATCH")}</h4>
                        </div>
                     </div>
                  </div>

                  <div className="row p-0">
                     <div className={Classnames(modalStyles.ModalBody, "col-md-12 ps-4 pe-4 text-center")}>
                        <Timer t={t} countDownTime={matchStartTime}/>
                        <p className={Classnames(modalStyles.HowToEngageTxt, "mt-4 mb-3")}>{t("MATCH.UNTIL_THEN_WATCH_HOW_TO_ENGAGE_IN_META_11")}</p>
                        <div className={Classnames(modalStyles.VideoRapper)}>
                           <video controls autoPlay playsInline muted loop style={{maxWidth: "100%"}}>
                              <source
                                 src="https://storage.googleapis.com/meta11/serviceplatform/videos/m11htp2.mp4"
                                 type="video/mp4"/>
                           </video>
                        </div>
                        <p className={Classnames(modalStyles.FaqLink, "mt-4 mb-5")}>{t("MATCH.OR_READ_OUR_FAQS")} {' '}
                           <Link to={'/#faq'}>{t("MATCH.HERE")}</Link>
                        </p>
                     </div>
                  </div>
                  <div className="row p-0">

                     <div className={Classnames(modalStyles.ellipse, modalStyles.redManEllipsRed)}></div>
                     <div className={Classnames(modalStyles.ellipse, modalStyles.eMulticolorFull)}></div>
                  </div>
                  <div className={Classnames(modalStyles.BtnRow, "row p-0")}>
                     <button type="button" data-dismiss="modal" aria-label="Close" onClick={closeModal} className="GrayButton">Close</button>
                  </div>
               </div>
            </div>
         </div>
      </div>)
}

export default ScheduledMatchPopUp;
