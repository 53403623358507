import React, {useEffect, useState} from "react";
import Styles from "./TermsAndConditions.module.scss"
import Classnames from "classnames"
import {Link, useNavigate} from "react-router-dom";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import Footer from "../../components/Footer/Footer";
import {useTranslation} from "react-i18next";

const TermsAndConditions = (props) => {
   let navigate = useNavigate();
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
         }}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);

   if (!translationsLoaded) {
      return loadingIndicator;
   }


   return (
      <React.Fragment>
         <div className={Classnames(Styles.Radial1)}></div>
         <div className={Classnames(Styles.Radial2)}></div>
         <div className={Classnames(Styles.termsAndConditionsPage)}>
            <div className={Classnames(Styles.bodyPrivacy)}>
               <div className={Classnames(Styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
                  <div className="col-12 p-0 row">
                     <NavbarSecondary page="events"/>
                  </div>
                  <div className={Classnames(Styles.pageWrapper, "col-12 col-md-9 row mt-2 p-0")}>
                     <div className={Classnames(Styles.pageTitle, "text-center")}>{t("TERMS_AND_CONDITIONS.TERMS_AND_CONDITIONS")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        {t("TERMS_AND_CONDITIONS.PLEASE_READ_THESE_TERMS_OF_USE_CAREFULLY_BEFORE_USING_THE_SERVICES_OFFERED_BY_BEHAVIOL_PTY_LTD_INCLUDING_ANY_OF_ITS_AFFILIATES_SUBSIDIARIES_AND_ASSIGNS_THESE_ARE_LEGALLY_BINDING_TERMS_AND_CONDITIONS_FOR_YOUR_USE_OF_THIS_WEBSITE_AND_THE_SERVICES_FEATURES_CONTENT_EXTENSIONS_PLATFORM_SOFTWARE_APPLICATIONS_OR_WIDGETS_OFFERED_BY_BEHAVIOL_PTY_LTD_INCLUDING_META_11_PLATFORM_SERVICES")}
                     </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        {t("TERMS_AND_CONDITIONS.PLEASE_NOTE_THAT_BEHAVIOL_PTY_LTD_RESERVES_THE_RIGHT_TO_MODIFY_THIS_AGREEMENT_AT_ITS_SOLE_DISCRETION_YOUR_CONTINUED_USE_OF_THE_SERVICES_AFTER_THAT_PERIOD_SIGNIFIES_YOUR_ACCEPTANCE_OF_THE_REVISED_AGREEMENT_BEHAVIOL_PTY_LTD_ENCOURAGES_YOU_TO_REGULARLY_REVIEW_THE_MOST_UPTODATE_VERSION_OF_THIS_AGREEMENT_WHENEVER_YOU_WISH_TO_ORDER_ANDOR_SUPPLY_SERVICES")}
                     </div>
                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.1_ABOUT_BEHAVIOL_PTY_LTD_META_11")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.WE_ARE_BEHAVIOL_PTY_LTD_BEHAVIOL_PROMOTER_WE_US_AND_OUR_THESE_TERMS_AND_CONDITIONS_TERMS_TC_SHALL_GOVERN_THE_USER_PARTICIPANT_YOU_AND_YOUR_USE_OF_THE_BEHAVIOL_WEBSITES_IE_WWWBEHAVIOLCOM_AND_WWW_META_11_COM_SITES_AND_THE_META_11_PLATFORM_INCLUDING_BUT_NOT_LIMITED_TO_ACCESSING_ANY_FEATURES_CONTENTS_EXTENSIONS_APPLICATIONS_WIDGETS_AND_SOFTWARE_SERVICES")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.BEHAVIOL_PTY_LTD_IS_THE_AUSTRALIAN_BASED_CREATIVE_STUDIO_REDEFINING_SPORTS_ENTERTAINMENT_THROUGH_ITS_FLAGSHIP_GAME_META_11_WITH_A_PASSION_FOR_MERGING_TECHNOLOGY_WITH_ENTERTAINMENT_IN_MEANINGFUL_SUSTAINABLE_AND_ACCESSIBLE_WAYS_BEHAVIOL_PTY_LTD_IS_COMMITTED_TO_ADDRESSING_THE_CHALLENGES_IN_THE_GAMING_INDUSTRY_THIS_DETERMINATION_LED_TO_THE_CREATION_OF_META_11_A_GROUND_BREAKING_CRICKET_GAMING_PLATFORM_THAT_PRIORITIZES_PLAYER_WELL_BEING_ENJOYMENT_AND_INCLUSIVITY")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THROUGH_META_11_FANTASY_AND_FUTURE_ITERATIONS_OF_THE_META_11_WORLD_OUR_MISSION_IS_TO_REDEFINE_ONLINE_GAMING_BOUNDARIES_BY_CREATING_IMMERSIVE_AI_DRIVEN_EXPERIENCES_THAT_ARE_FREE_TO_PLAY_REWARDING_AND_RESPONSIBLE")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THESE_TCS_APPLY_TO_THE_CRICKET_GAME_CONTESTS_TO_BE_CONDUCTED_BY_BEHAVIOL_AS_A_SERIES_OF_TOURNAMENTS")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.2_DEFINITIONS")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.APPLICABLE_LAWS_MEANS_ALL_APPLICABLE_LAWS_AND_REGULATIONS_THAT_GOVERN_THE_USE_OF_THE_META_11_PLATFORM_INCLUDING_JURISDICTION_SPECIFIC_LAWS_THAT_APPLY_BASED_ON_THEIR_LOCATION_SUCH_AS_AGE_RESTRICTIONS_ONLINE_GAMING_REGULATIONS_AND_TAX_REQUIREMENTS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.CONTESTS_REFERS_TO_A_SERIES_OF_TOURNAMENTS_ORGANIZED_BY_BEHAVIOL_ON_THE_META_11_PLATFORM")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.USER_REFERS_TO_ANY_INDIVIDUAL_WHO_REGISTERS_AND_PARTICIPATES_IN_THE_META_11_PLATFORMS_CONTESTS_OR_TOURNAMENTS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.META_11_REFERS_TO_THE_GAMING_PLATFORM_DEVELOPED_BY_BEHAVIOL_FOCUSING_ON_CRICKETBASED_GAMES_AND_ASSOCIATED_ENTERTAINMENT")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.META_11_FANTASY_REFERS_TO_A_VIRTUAL_CRICKET_GAME_THAT_ALLOWS_USERS_TO_SELECT_A_FANTASY_TEAM_BASED_ON_ARTIFICIAL_INTELLIGENCE_AVATARSCHARACTERS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.XP_ARE_THE_IN_GAME_POINTS_ALLOCATED_FOR_CORRECTLY_PREDICTING_OUTCOMES_OF_META_11_FANTASY_GAMES")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.SPRTS_ARE_DIGITAL_TOKENS_SUPPLIED_BY_A_SEPARATE_COMPANY_SPORTS_LAB_LTD_EVENTUALLY_THEY_CAN_BE_PURCHASED_AND_REDEEMED_FOR_FIAT_CURRENCY_OR_OTHER_FORMS_OF_CRYPTO_CURRENCY_THROUGH_THIRD_PARTY_PROVIDERS_SPRTS_ARE_GIVEN_AWAY_IN_FREE_2_PLAY_GAMES_AND_USED_WITHIN_THE_META_11_PLATFORM_FOR_IN_GAME_TRANSACTIONS_AND_PURCHASE_OF_IN_GAME_OFF_CHAIN_ITEMS_A_CERTAIN_NUMBER_OF_XP_CONVERTS_TO_SPRTS_SOLELY_AT_THE_DISCRETION_OF_BEHAVIOL")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.WINNER_REFERS_TO_A_USER_WHO_BY_PARTICIPATING_IN_A_TOURNAMENT_OR_CONTEST_ON_THE_META_11_PLATFORM_ACHIEVES_A_POSITION_THAT_QUALIFIES_FOR_ADDITIONAL_SPRTS_WINNERS_ARE_DETERMINED_BASED_ON_SPECIFIC_CRITERIA_OUTLINED_IN_THE_CONTEST_RULES_SUCH_AS_LEADERBOARD_RANKINGS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.CONTEST_RULES_REFER_TO_THE_SET_OF_RULES_THAT_GOVERN_META_11_FANTASY_THE_CONTEST_DETAILS_FOR_META_11_FANTASY_CAN_BE_FOUND_UNDER_FANTASY_GAMING_HERE_THESE_DETAILS_ARE_A_PART_AND_PARCEL_OF_THESE_TCS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.FREE_2_PLAY_F_2_P_REFERS_TO_GAMES_OR_GAME_MODES_ON_THE_META_11_PLATFORM_THAT_ENABLE_USERS_TO_PLAY_FOR_FREE_USERS_CAN_PLAY_THESE_GAMES_WITHOUT_ANY_ENTRY_FEE_OR_INGAME_PURCHASES_F_2_P_GAMES_IN_META_11_PLATFORM_ALLOW_USERS_TO_EARN_XP_FOR_FREE_WHICH_CAN_BE_CONVERTED_TO_SPRTS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.Power Play Games PPG refers to games where player cards are required to compete in Contests Player cards are in-game off-chain items that can be purchased using SPRTS Player cards provide access to PPG that result in greater conversion of XP to SPRTS than in free2play games")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.ACCEPTANCE_OF_TERMS_AND_CONDITIONS")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li> {t("TERMS_AND_CONDITIONS.CONSENT_TO_TERMS ")}
                              {t("TERMS_AND_CONDITIONS.BY_PARTICIPATING_IN_A_CONTEST, THE_USER_AGREES_TO_BE_BOUND_BY_THESE_TERMS_AND_CONDITIONS_AND_RECOGNIZES_BEHAVIOL'S_AUTHORITY_IN_FINALIZING_DECISIONS_RELATED_TO_THE_CONTEST.")}
                              {t("TERMS_AND_CONDITIONS.INFORMATION_ON_HOW_TO_ENTER_AND_PRIZES_FORM_PART_OF_THESE_TERMS_AND_CONDITIONS.")}
                              {t("TERMS_AND_CONDITIONS.ENTRIES_MUST_COMPLY_WITH_THESE_TERMS_AND_CONDITIONS_TO_BE_VALID.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THE_PROMOTER_IS_BEHAVIOL_PTY_LTD_ACN_658_203_055_OF_UNIT_105_29_31_LEXINGTON_DRIVE, BELLA_VISTA_NSW_2153, TRADING_AS “META_11”.")}
                              {t("TERMS_AND_CONDITIONS.TO_CONTACT_THE_PROMOTER, PLEASE_EMAIL_INFO@BEHAVIOL_COM")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.INTEGRITY_AND_FAIR_PLAY_THE_PROMOTER_UPHOLDS_THE_HIGHEST_STANDARDS_OF_INTEGRITY_AND_FAIRNESS_IN_THE_OPERATION_OF_ITS_CONTESTS.")}
                              {t("TERMS_AND_CONDITIONS.SHOULD_A_USER_ACT_IN_A_MANNER_DEEMED_DETRIMENTAL_TO_THE_CONTEST_OR_ITS_PARTICIPANTS, OR_THAT_CONTRAVENES_THESE_TERMS_AND_CONDITIONS, THE_PROMOTER_RESERVES_THE_RIGHT, AT_ITS_SOLE_DISCRETION, TO")}
                              <ol type={"i"}>
                                 <li>{t("TERMS_AND_CONDITIONS.DISQUALIFY_THE_PARTICIPANT_FROM_THE_CONTEST.")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.RECLAIM_DISTRIBUTED_BENEFITS_OR_PRIZES.")}</li>
                                 <li>
                                    {t("TERMS_AND_CONDITIONS.MANDATE_THE_RETURN_OF_ANY_AWARDED_PRIZES.")}
                                    {t("TERMS_AND_CONDITIONS.SUCH_DETRIMENTAL_CONDUCT_INCLUDES_BUT_ISNT_RESTRICTED_TO")}

                                 </li>
                                 <ul>
                                    <li>
                                       {t("TERMS_AND_CONDITIONS.MISREPRESENTATION")}
                                       {t("TERMS_AND_CONDITIONS.FALSIFYING_OR_MISREPRESENTING_PERSONAL_OR_FINANCIAL_DATA, WHICH_ENCOMPASSES_NAME, EMAIL, BANK_PARTICULARS, OR_ANY_OTHER_PERTINENT_DOCUMENTATION.")}
                                       {t("TERMS_AND_CONDITIONS.FINANCIAL_DECEIT")}
                                       {t("TERMS_AND_CONDITIONS.ENGAGING_IN_FINANCIAL_DECEIT, INCLUDING_BUT_NOT_LIMITED_TO_UNAUTHORIZED_USE_OF_CREDIT/DEBIT_TOOLS, E_WALLETS, OR_OTHER_FINANCIAL_MEANS_TO_PARTICIPATE_IN_A_CONTEST_OR_TO_STAKE_A_CLAIM_TO_A_PRIZE. IN_SUCH_CASES, THE_BURDEN_OF_PROOF_RESTS_WITH_THE_USER.")}
                                    </li>
                                    <li>{t("TERMS_AND_CONDITIONS.VIOLATION_OF_STANDARDS")}
                                       {t("TERMS_AND_CONDITIONS.BREACHING_CONTEST_RULES, THESE_TERMS_AND_CONDITIONS, OR_ANY_RELATED_TERMS_OF_USE.")}
                                    </li>
                                    <li>{t("TERMS_AND_CONDITIONS.UNAUTHORIZED_METHODS")}
                                       {t("TERMS_AND_CONDITIONS.PROCURING_POINTS_OR_AWARDS_THROUGH_UNAUTHORIZED_CHANNELS_LIKE_AUTOMATED_BOTS, SCRIPTS, OR_OTHER_AUTOMATED_PROCESSES.")}
                                    </li>
                                    <li>
                                       {t("TERMS_AND_CONDITIONS.DATA_HARVESTING")}
                                       {t("TERMS_AND_CONDITIONS.EMPLOYING_AUTOMATED_TOOLS, INCLUDING_BUT_NOT_LIMITED_TO_BOTS, CRAWLERS, SPIDERS, OR_SCRAPERS, TO_RETRIEVE, ACCESS, OR_COLLECT_DATA_FROM_THE_META_11_COM_WEBSITE_OR_ANY_USER, WITHOUT_EXPRESS_CONSENT, FOR_ANY_PURPOSE.")}
                                    </li>
                                    <li>
                                       {t("TERMS_AND_CONDITIONS.INTERFERENCE")}
                                       {t("TERMS_AND_CONDITIONS.MANIPULATING_OR_ATTEMPTING_TO_MANIPULATE_THE_ADMINISTRATION, SECURITY_MEASURES, OR_ANY_UNDERLYING_SOFTWARE_OF_A_CONTEST.")}
                                    </li>
                                    <li>
                                       {t("TERMS_AND_CONDITIONS.PRIVACY_VIOLATION")}
                                       {t("TERMS_AND_CONDITIONS.EXTRACTING_OR_MISUSING_INFORMATION_OF_OTHER_USERS_WITHOUT_EXPLICIT_CONSENT.")}
                                       {t("TERMS_AND_CONDITIONS.THIS_INCLUDES_UNSOLICITED_COMMUNICATIONS, MASS_EMAILING_TO_META_11_PARTICIPANTS, OR_UNWARRANTED_EMAIL_DISTRIBUTION, WHETHER_TO_SELECT_USERS_OR_IN_BULK_OR_OTHERWISE.")}
                                    </li>
                                    <li>
                                       {t("TERMS_AND_CONDITIONS.PLATFORM_ABUSE")}
                                       {t("TERMS_AND_CONDITIONS.ENGAGING_IN_ABUSIVE_BEHAVIOR_ON_THE_WEBSITE. EXAMPLES_INCLUDE_BUT_ARE_NOT_LIMITED_TO_THE_USE_OF_INAPPROPRIATE_LANGUAGE, SLURS, OR_DISRESPECTFUL_REMARKS.")}
                                    </li>
                                 </ul>
                              </ol>
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.PARTICIPANT_ELIGIBILITY_AND_RESTRICTIONS")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.AGE_REQUIREMENT")}
                              {t("TERMS_AND_CONDITIONS.ENTRY_IS_GENERALLY_SUBJECT_TO_B_BELOW_OPEN_TO_THOSE_WHO_ARE_AGED")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.GEOGRAPHIC_RESTRICTIONS")}
                              {t("TERMS_AND_CONDITIONS.BEHAVIOL_MAY_IN_LINE_WITH_PREVAILING_APPLICABLE_LAWS_RESTRICT_INDIVIDUALS_FROM_CERTAIN_JURISDICTIONS_FROM_TAKING_PART_IN_THE_CONTESTS_TO_KNOW_MORE_ABOUT_SUCH_RESTRICTED_JURISDICTIONS_PLEASE_REFER_TO_SECTION_14_OF_THESE_TCS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.VALID_EMAIL")}
                              {t("TERMS_AND_CONDITIONS.USERS_MUST_MAINTAIN_A_LEGITIMATE_AND_ACTIVE_EMAIL_ACCOUNT_TO_PARTAKE_IN_ANY_CONTEST")}
                           </li>
                           <li>{t("TERMS_AND_CONDITIONS.INDEMNITY_AND_RELEASE_USERS, BY_PARTICIPATING_IN_CONTESTS_AND_ACCESSING_THE_PROMOTER’S_META_11_FANTASY_GAME, THEREBY_RELEASE_AND_COMMIT_TO_HOLDING_THE_PROMOTER, ITS_DIRECTORS, STAFF, PARTNERS, ASSOCIATES, AND_LICENSORS_HARMLESS_FROM_ANY_LIABILITY, LOSS, OR_COST_EMERGING_FROM_THEIR_PARTICIPATION_IN_ANY_CONTEST, INCLUDING_THOSE_ARISING_AS_A_RESULT_OF_INJURIES, DAMAGES (WHETHER_DIRECT_OR_INDIRECT).")}</li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>
                        {t("TERMS_AND_CONDITIONS.5_META_11_PARTICIPATION_PROCESS")}
                     </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.PARTICIPANTS_MUST_ACKNOWLEDGE_THEIR_UNDERSTANDING_AND_AGREEMENT_TO_ABIDE_BY_THESE_TCS_BEFORE_PLAYING_META_11_FANTASY")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.ENTRIES_MUST_BE_BASED_ON_THE_USER’S_OWN_SKILL_AND_EFFORT_BEHAVIOL_RESERVES_THE_RIGHT_TO_REQUIRE_THE_USER_TO_VERIFY_THAT_THE_ENTRY_IS_THE_USER’S_OWN_SKILL_AND_EFFORT_IF_BEHAVIOL_IS_UNABLE_TO_VERIFY_THE_ENTRY_TO_ITS_SATISFACTION_THE_ENTRY_WILL_BE_DEEMED_INVALID")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.USERS_WARRANT_THAT_THEIR_ENTRY_IS_NOT_IN_BREACH_OF_ANY_THIRD_PARTY_INTELLECTUAL_PROPERTY_RIGHTS_USERS_AGREE_TO_INDEMNIFY_THE_PROMOTER_AND_ITS_ASSOCIATED_AGENCIES_AGAINST_ALL_LOSSES_DAMAGES_CLAIMS_AND_COSTS_BY_THIRD_PARTIES_ARISING_OUT_OF_CONNECTED_TO_OR_RESULTING_FROM_A_BREACH_OF_THE_WARRANTY_IN_THIS_PARAGRAPH")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.CONTESTS_WITHIN_THE_META_11_PLATFORM_SHALL_BE_GOVERNED_NOT_ONLY_BY_THESE_TCS_BUT_ALSO_BY_THE_CONTEST_RULES_ITS_IMPERATIVE_FOR_ALL_PARTICIPANTS_TO_REMAIN_COMPLIANT_WITH_THESE_CONTEST_RULES_AS_HIGHLIGHTED_IN_META_11’S_WHITEPAPER")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.BEHAVIOL_COMMITS_TO_PROACTIVELY_INFORMING_PARTICIPANTS_ABOUT_ANY_VITAL_CHANGES_PREDOMINANTLY_BY_EMAIL_AND/OR_SOCIAL_MEDIA_CHANNELS")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>
                        {t("TERMS_AND_CONDITIONS.6_REGISTRATION_AND_USER_ACCOUNT")}
                     </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.TO_CREATE_A_META_11_ACCOUNT_USERS_MUST_SIGN_UP_USING_A_VALID_EMAIL_ADDRESS_OR_AUTHENTICATE_THROUGH_CHANNELS_INCLUDING_BUT_NOT_LIMITED_TO_GOOGLE_OR_FACEBOOK_THE_REGISTRATION_PROCESS_REQUIRES_PROVIDING_BASIC_INFORMATION_AND_SETTING_UP_LOGIN_CREDENTIALS_BEHAVIOL_MAY_REQUIRE_USERS_TO_VERIFY_THEIR_EMAIL_ADDRESS_OR_SOCIAL_MEDIA_ACCOUNT_TO_COMPLETE_THE_REGISTRATION_PROCESS_VERIFICATION_ENSURES_THE_SECURITY_AND_AUTHENTICITY_OF_THE_ACCOUNT")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.USERS_CAN_PLAY_FREE_2_PLAY_CONTESTS_AND_EARN_XP_THESE_XP_WILL_AUTOMATICALLY_BE_CONVERTED_TO_SPRTS_AT_A_RATIO_THAT_WILL_BE_CHANGED_FROM_TIME_TO_TIME_BY_BEHAVIOL")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>
                        {t("TERMS_AND_CONDITIONS.7_EARNING_AND_USING_SPRTS_FOR_PPG")}
                     </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.AFTER_CREATING_A_META_11_ACCOUNT_IF_USERS_WANT_TO_ENTER_POWER_PLAY_GAMES_USERS_MUST_SIGN_UP_FOR_A_SEQUENCE_UNIVERSAL_WALLET_SUW_WHICH_IS_LINKED_TO_THEIR_META_11_ACCOUNT")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.PLEASE_NOTE_THAT_SUW_IS_AN_INDEPENDENT_THIRD_PARTY_PAYMENT_FACILITATOR_DEFINED_BELOW_BEHAVIOL_IS_NOT_RESPONSIBLE_FOR_THE_OPERATIONS_TERMS_OR_PRACTICES_OF_SUW_USERS_MUST_READ_AND_UNDERSTAND_SUWS_TERMS_AND_CONDITIONS_BEFORE_LINKING_THEIR_META_11_ACCOUNT_TO_A_SUW")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.USERS_ARE_RESPONSIBLE_FOR_THE_SECURITY_OF_THEIR_SUW_INCLUDING_PROTECTING_LOGIN_CREDENTIALS_AND_ENSURING_THAT_UNAUTHORIZED_PARTIES_DO_NOT_ACCESS_THEIR_WALLET")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.SUW_IS_USED_TO_PURCHASE_SPRTS_DETAILED_UNDER_SECTION_8_OF_THESE_TCS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.EARNING_SPRTS_PARTICIPANTS_EARN_XP_WHEN_PLAYING_PPG_A_GREATER_NUMBER_OF_XP_IS_OFFERED_IN_PPG_CONTESTS_RELATIVE_TO_FREE_2_PLAY_CONTESTS_XP_EARNED_IN_THESE_GAMES_CAN_BE_CONVERTED_INTO_SPRTS_AT_BEHAVIOLS_DISCRETION_PLEASE_BE_AWARE_THAT_BEHAVIOL_RETAINS_THE_RIGHT_TO_DETERMINE_THE_RATE_OF_CONVERSION_FROM_XP_TO_SPRTS_AND_MAY_ALTER_THESE_RATES_AT_ANY_TIME_WITHOUT_PRIOR_NOTICE_CONVERSION_RATES_MAY_ALSO_DIFFER_DEPENDING_ON_THE_EVENT_OR_TOURNAMENT_TYPE_SPRTS_MAY_ALSO_BE_RANDOMLY_AIRDROPPED_TO_USERS_PLAYING_PPG")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.PARTICIPATING_IN_TOURNAMENTS_PARTICIPANTS_CAN_ENTER_PPG_CONTESTS_BY_PURCHASING_INGAME_PLAYER_CARDS_OFFCHAIN_USING_SPRTS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.PRIZES_AND_REWARDS_SPRTS_EARNED_INGAME_CAN_EVENTUALLY_BE_CONVERTED_TO_FIAT_CURRENCY_OR_OTHER_CRYPTO_CURRENCIES_THROUGH_THIRDPARTY_PROVIDERS")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>
                        {t("TERMS_AND_CONDITIONS.8_Purchasing_SPRTS")}
                     </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.WHEN_AVAILABLE_USERS_CAN_PURCHASE_SPRTS_USING_DEBITCREDIT_CARDS_EXISTING_CRYPTO_WALLETS_OR_QR_PAYMENTS_SPECIFIC_TO_INDIA_THROUGH_SUW_ANDOR_OTHER_THIRD_PARTY_PAYMENT_FACILITATOR_THE_EXACT_PAYMENT_METHODS_AVAILABLE_MAY_VARY_BASED_ON_LOCATION_AND_APPLICABLE_REGULATIONS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.USERS_MUST_ENSURE_THAT_ALL_PAYMENT_INFORMATION_PROVIDED_IS_ACCURATE_VALID_AND_BELONGS_TO_THEM_USE_OF_STOLEN_UNAUTHORIZED_OR_INVALID_PAYMENT_METHODS_IS_STRICTLY_PROHIBITED_AND_MAY_RESULT_IN_ACCOUNT_SUSPENSION_OR_LEGAL_ACTION")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THIRD_PARTY_PAYMENT_FACILITATORS_TRANSACTIONS_FOR_PURCHASING_SPRTS_ARE_PROCESSED_THROUGH_THIRDPARTY_PAYMENT_PROCESSORS_INCLUDING_CRYPTOCURRENCY_EXCHANGE_OR_CRYPTOCURRENCY_WALLETS_DIGITAL_ASSET_MANAGEMENT_PLATFORMS_THIRD_PARTY_PAYMENT_FACILITATORS_BEHAVIOL_HAS_NO_CONTROL_OVER_THESE_PROCESSORS_AND_IS_NOT_RESPONSIBLE_FOR_ISSUES_ARISING_FROM_THEIR_OPERATIONS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.SUBJECT_TO_THESE_TERMS_AND_APPLIABLE_LAWS_ALL_TRANSACTIONS_ARE_SUBJECT_TO_THE_TERMS_AND_CONDITIONS_OF_THE_THIRD_PARTY_PAYMENT_FACILITATORS_AND_APPLICABLE_LAWS_IN_THE_RELEVANT_JURISDICTION_USERS_SHOULD_FAMILIARIZE_THEMSELVES_WITH_THESE_TERMS_BEFORE_INITIATING_TRANSACTIONS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.DISCREPANCIES_AND_ISSUES_ANY_DISCREPANCIES_ERRORS_OR_ISSUES_DURING_THE_TRANSACTION_PROCESS_MUST_BE_RESOLVED_WITH_SUCH_THIRD_PARTY_PAYMENT_FACILITATOR_BEHAVIOL_WILL_PROVIDE_REASONABLE_SUPPORT_BUT_IS_NOT_LIABLE_FOR_DELAYS_OR_COMPLICATIONS_CAUSED_BY_THIRD_PARTY_PAYMENT_FACILITATORS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.BEHAVIOL_IS_COMMITTED_TO_ENSURING_SECURE_TRANSACTIONS_IMPLEMENTING_INDUSTRYSTANDARD_SECURITY_PROTOCOLS_HOWEVER_USERS_ARE_ENCOURAGED_TO_TAKE_PRECAUTIONS_SUCH_AS_NOT_SHARING_SENSITIVE_INFORMATION_AND_USING_SECURE_NETWORKS_DURING_TRANSACTIONS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.BEHAVIOL_IS_NOT_AFFILIATED_WITH_OR_RESPONSIBLE_FOR_THE_OPERATIONS_OF_THIRD_PARTY_PAYMENT_FACILITATORS_ANY_ISSUES_RELATED_TO_ACCOUNT_SECURITY_TRANSACTION_FAILURES_OR_UNAUTHORIZED_CHARGES_ARE_THE_RESPONSIBILITY_OF_SUCH_THIRD_PARTY_PAYMENT_FACILITATORS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.NO_RESPONSIBILITY_FOR_THIRD_PARTY_ACTIONS_BEHAVIOL_IS_NOT_LIABLE_FOR_ANY_LOSSES_OR_DAMAGES_RESULTING_FROM_THE_ACTIONS_OR_INACTIONS_OF_THIRD_PARTY_PAYMENT_FACILITATORS_THIS_INCLUDES_BUT_IS_NOT_LIMITED_TO_TRANSACTION_ERRORS_DATA_BREACHES_OR_UNAUTHORIZED_ACCESS_TO_PAYMENT_INFORMATION")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>
                        {t("TERMS_AND_CONDITIONS.9_Special_Tournaments")}
                     </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol className={Classnames(Styles.orderedList)} style={{listStyle: 'none'}}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.BEHAVIOL_MAY_HOST_SPECIAL_TOURNAMENTS_WITH_UNIQUE_RULES_OR_LARGER_PRIZE_POOLS_DETAILS_ABOUT_SPECIAL_TOURNAMENTS_ARE_ANNOUNCED_ON_META_11S_SOCIAL_MEDIA_CHANNELS_ANDOR_SITES_SPECIFIC_ELIGIBILITY_CRITERIA_MAY_APPLY_TO_SPECIAL_TOURNAMENTS_USERS_SHOULD_REFER_TO_THE_TOURNAMENT_RULES_FOR_MORE_INFORMATION")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>
                        {t("TERMS_AND_CONDITIONS.WINNERS")}
                        {t("TERMS_AND_CONDITIONS.DETERMINATION, CONTACT, AND_PRIZE_DISTRIBUTION")}
                     </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>{t("TERMS_AND_CONDITIONS.SELECTION_AND_VERIFICATION_OF_WINNERS")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>{t("TERMS_AND_CONDITIONS.DETERMINING_WINNERS")}
                              <ol type={"i"} className={Classnames(Styles.orderedList)}>
                                 <li>
                                    {t("TERMS_AND_CONDITIONS.I_ALL_USERS_CAN_RECEIVE_XP_FOR_PLAYING_META_11_THE_MORE_XP_A_USER_EARNS_THE_MORE_SPRTS_THEY_WILL_RECEIVE")}

                                 </li>
                                 <li>
                                    {t("TERMS_AND_CONDITIONS.II_WINNERS_OR_JOINT_WINNERS_OF_GAMES_MAY_RECEIVE_BONUS_SPRTS_PURELY_AT_THE_DISCRETION_OF_BEHAVIOL")}

                                 </li>
                                 <li>
                                    {t("TERMS_AND_CONDITIONS.III_THE_PROMOTER’S_DECISION_IS_FINAL_AND_BINDING_AND_NO_CORRESPONDENCE_WILL_BE_ENTERED_INTO_THE_PROMOTER_ACCEPTS_NO_RESPONSIBILITY_FOR_LATE_LOST_OR_MISDIRECTED_ENTRIES_OR_OTHER_COMMUNICATIONS_ENTRIES_WILL_BE_DEEMED_VOID_IF_ILLEGITIMATE_FORGED_MANIPULATED_OR_TAMPERED_WITH_IN_ANY_WAY")}

                                 </li>
                                 <li>
                                    {t("TERMS_AND_CONDITIONS.IV_IF_AN_USER’S_CONTACT_DETAILS_CHANGE_DURING_THE_CONTEST_PERIOD_IT_IS_THE_USER’S_RESPONSIBILITY_TO_NOTIFY_THE_PROMOTER")}

                                 </li>
                                 <li>
                                    {t("TERMS_AND_CONDITIONS.V_THE_PROMOTER_RESERVES_THE_RIGHT_TO_REQUEST_VERIFICATION_OF_THE_SOCIAL_MEDIA_PROFILE_OF_USERS_AND_OF_THE_AGE_IDENTITY_AND_RESIDENTIAL_ADDRESS_OF_WINNERS_AND_ANY_OTHER_INFORMATION_RELEVANT_TO_ENTRY_INTO_OR_PARTICIPATION_IN_THIS_CONTEST_VERIFICATION_IS_AT_THE_DISCRETION_OF_THE_PROMOTER_WHOSE_DECISION_IS_FINAL_FAILURE_BY_THE_PROMOTER_TO_ENFORCE_ANY_OF_ITS_RIGHTS_DOES_NOT_CONSTITUTE_A_WAIVER_OF_THOSE_RIGHTS")}
                                 </li>
                              </ol>
                           </li>
                           <div className={Classnames(Styles.sectionContent, "mt-3")}></div>
                           <li>{t("TERMS_AND_CONDITIONS.HIGHLIGHTING_WINNERS")}
                              <div className={Classnames(Styles.sectionContent, "mt-1")}>
                                 {t("TERMS_AND_CONDITIONS.WINNERS_WILL_BE_SHOWCASED_WITHIN_1_WEEK_OF_THE_TOURNAMENT_CONCLUDING_FURTHERMORE_WINNERS_WILL_RECEIVE_A_NOTIFICATION_VIA_EMAIL_IF_A_PARTICIPANT_IS_LISTED_AS_A_WINNER_O_THE_MENTIONED_WEBPAGE_BUT_HASN’T_RECEIVED_ANY_CORRESPONDENCE_FROM_THE_PROMOTER_THEY_ARE_ADVISED_TO_REACH_OUT_TO_THE_PROMOTER_WITHIN_THE_TIMEFRAME_MENTIONED_ON_THE_SITE")}
                              </div>
                              <div className={Classnames(Styles.sectionContent, "mt-2")}>
                                 {t("TERMS_AND_CONDITIONS.WINNERS_ARE_ENCOURAGED_TO_SHARE_THEIR_WIN_ON_THEIR_RELEVANT_SOCIAL_MEDIA_ACCOUNTS_PREFERABLY_FACEBOOK_INSTAGRAM_ANDOR_X")}
                              </div>
                           </li>
                           <div className={Classnames(Styles.sectionContent, "mt-3")}></div>
                           <li>{t("TERMS_AND_CONDITIONS.TAXES_PAYABLE")}
                              <div className={Classnames(Styles.sectionContent, "mt-1")}>
                                 {t("TERMS_AND_CONDITIONS.THE_PROMOTER_WILL_NOT_BE_RESPONSIBLE_FOR_ANY_TAXES_INCURRED_OR_PAYABLE_RELATING_TO_THE_SPRTS_ALL_TAXES_WHICH_MAY_BE_PAYABLE_AS_A_CONSEQUENCE_OF_RECEIVING_SPRTS_ARE_THE_SOLE_RESPONSIBILITY_OF_USERS")}
                              </div>
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>
                        {t("TERMS_AND_CONDITIONS.11_WITHDRAWAL_AND_REFUND_POLICY")}
                     </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol className={Classnames(Styles.orderedList)} style={{listStyle: 'none'}}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.AFTER_A_CERTAIN_THRESHOLD_OF_SPRTS_IS_REACHED_YOU_MAY_WITHDRAW_SPRTS_AND_CONVERT_THEM_INTO_OTHER_FORMS_OF_CRYPTOCURRENCY_AT_ANY_TIME_THE_THRESHOLD_REACHED_WILL_SOLELY_BE_DETERMINED_BY_BEHAVIOL_PTY_LTD_AND_IS_SUBJECT_TO_CHANGE_AT_BEHAVIOLS_SOLE_DISCRETION")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.GAME_OF_SKILL")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THE_PROMOTERS_FIRST_GAME_META_11_FANTASY_IS_A_GAME_OF_SKILL_SUCCESS_IN_CONTESTS_IS_SIGNIFICANTLY_RELIANT_ON_THE_PARTICIPANTS_UNDERSTANDING_OF_CRICKET_STATISTICS_AND_INSIGHTS_INTO_AI_PLAYERS_PREVIOUS_FORM_AND_PERFORMANCE_METRICS_THIS_INFORMATION_AS_WELL_AS_PERFORMANCE_IN_CURRENT_MATCHES_WOULD_BE_USED_BY_PARTICIPANTS_TO_PREDICT_LIKELY_OUTCOMES_OF_META_11_CONTESTS_AND_SELECT_THEIR_FANTASY_TEAM_ANY_QUESTIONS_ON_FUTURE_REAL_WORLD_MATCHES_WOULD_ALSO_BE_ANSWERED_FROM_A_SKILL_BASED_APPROACH_WHERE_PARTICIPANTS_REVIEW_REAL_WORLD_DATA_PRIOR_TO_MAKING_INFORMED_DECISIONS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.NO_BETTING_OR_WAGERING_META_11_FANTASY_DOES_NOT_INVOLVE_BETTING_OR_WAGERING_ON_THE_OUTCOME_OF_CRICKET_MATCHES_OR_OTHER_UNCERTAIN_EVENTS_USERS_DO_NOT_PLACE_BETS_OR_STAKES_AND_THERE_IS_NO_ELEMENT_OF_RANDOM_CHANCE_IN_DETERMINING_THE_OUTCOMES_OF_TOURNAMENTS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.COMPLIANCE_WITH_LAWS_BEHAVIOL_PTY_LTD_ENSURES_THAT_META_11_COMPLIES_WITH_RELEVANT_LAWS_AND_REGULATIONS_REGARDING_ONLINE_GAMING_AND_GAMBLING_THE_GAME_IS_DESIGNED_TO_AVOID_ANY_CHARACTERISTICS_TYPICALLY_ASSOCIATED_WITH_GAMBLING_FOCUSING_INSTEAD_ON_SKILL_STRATEGY_AND_COMPETITION")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.USER_CONDUCT_AND_FAIR_PLAY")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.USERS_COMMIT_TO_COMPLYING_WITH_THESE_TERMS_AND_CONDITIONS_AS_WELL_AS_ANY_OTHER_APPLICABLE_LAWS, RULES, GUIDELINES, AND_TERMS_OF_USE.")}
                              {t("TERMS_AND_CONDITIONS.SHOULD_A_USER_FAIL_TO_UPHOLD_THESE_STANDARDS, THE_PROMOTER_RESERVES_THE_RIGHT_TO_TAKE_CORRECTIVE_ACTIONS_WHICH_MAY_ENCOMPASS")}
                              {t("TERMS_AND_CONDITIONS.LIMITING, PAUSING, OR_ENDING_A_USER'S_ACCESS_TO_THE_PROMOTER’S_SERVICES_IN_FULL_OR_IN_PART; DISABLING_OR_ERASING_A_USER'S_PROFILE, INCLUSIVE_OF_ANY_RELATED_DATA_AND_RECORDS_WITHIN_THE_ACCOUNT.")}
                              {t("TERMS_AND_CONDITIONS.IT_IS_PARAMOUNT_TO_THE_PROMOTER_THAT_ALL_INTERACTIONS_REMAIN_FAIR_AND_GENUINE.")}
                              {t("TERMS_AND_CONDITIONS.THUS, ANY_DECEITFUL_ACTIONS, MANIPULATIONS, OR_OTHER_TYPES_OF_MALFEASANCE_ARE_EXPRESSLY_FORBIDDEN.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.WINNERS_MAY_HAVE_RIGHTS_UNDER_THE_AUSTRALIAN_CONSUMER_LAW_AND_OTHER_SIMILAR_LEGISLATION_WHICH_CANNOT_BE_EXCLUDED, RESTRICTED_OR_MODIFIED_BY_THE_PROMOTER.")}
                              {t("TERMS_AND_CONDITIONS.THESE_TERMS_AND_CONDITIONS_DO_NOT_EXCLUDE, RESTRICT_OR_LIMIT_THOSE_STATUTORY_RIGHTS_IN_ANY_WAY.")}
                              {t("TERMS_AND_CONDITIONS.HOWEVER, TO_THE_EXTENT_THAT_IT_IS_PERMITTED_TO_DO_SO_BY_LAW, THE_PROMOTER (INCLUDING_ITS_OFFICERS, EMPLOYEES_AND_AGENTS) EXCLUDES_ALL_LIABILITY_WHETHER_ARISING_IN_TORT (INCLUDING_WITHOUT_LIMITATION_NEGLIGENCE), CONTRACT_OR_OTHERWISE_FOR_ANY_PERSONAL_INJURY_OR_ANY_OTHER_LOSS_OR_DAMAGE (INCLUDING_WITHOUT_LIMITATION_LOSS_OF_OPPORTUNITY_OR_LOSS_OF_PROFITS) WHETHER_DIRECT, INDIRECT, SPECIAL_OR_CONSEQUENTIAL, ARISING_IN_ANY_WAY_OUT_OF_THE_CONTEST, INCLUDING, WITHOUT_LIMITATION")}
                              <ol type={"i"}>
                                 <li>{t("TERMS_AND_CONDITIONS.ANY_TECHNICAL_DIFFICULTIES_OR_EQUIPMENT_MALFUNCTION (WHETHER_OR_NOT_UNDER_THE_PROMOTER’S_CONTROL);")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.ANY_THEFT, UNAUTHORIZED_ACCESS_OR_THIRD_PARTY_INTERFERENCE;")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.ANY_ENTRY_THAT_IS_LATE, LOST, ALTERED, DAMAGED_OR_MISDIRECTED (WHETHER_OR_NOT_AFTER_THEIR_RECEIPT_BY_THE_PROMOTER) DUE_TO_ANY_REASON_BEYOND_THE_REASONABLE_CONTROL_OF_THE_PROMOTER;")}
                                 </li>
                                 <li>{t("TERMS_AND_CONDITIONS.ANY_VARIATION_IN_MARKET_VALUE_TO_THAT_STATED_IN_THESE_TERMS_AND_CONDITIONS;")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.ANY_TAX_IMPLICATIONS; OR")}</li>
                              </ol>
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.IF_FOR_ANY_REASON_BEYOND_THE_REASONABLE_CONTROL_OF_THE_PROMOTER_THIS_CONTEST_IS_NOT_CAPABLE_OF_RUNNING_AS_PLANNED, THE_PROMOTER_RESERVES_THE_RIGHT_IN_ITS_SOLE_DISCRETION_TO_TAKE_ANY_ACTION_THAT_MAY_BE_AVAILABLE_TO_IT, AND_TO_CANCEL, TERMINATE, MODIFY_OR_SUSPEND_THE_CONTEST, OR_AMEND_THESE_TERMS_AND_CONDITIONS, UNLESS_TO_DO_SO_WOULD_BE_PROHIBITED_BY_LAW.")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.INTELLECTUAL_PROPERTY_AND_USER_CONTENT")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.ALL_CONTENT_ON_THE_META_11_PLATFORM_META_11_CONTENT_IS_INTENDED_SOLELY_FOR_THE_PURPOSE_OF_ACCESSING_AND_UTILIZING_THE_SERVICES_IN_ACCORDANCE_WITH_THESE_TCS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.INTELLECTUAL_PROPERTY_RIGHTS_THE_USER_ACKNOWLEDGES_THAT_ALL_OWNERSHIP_RIGHTS_COPYRIGHTS_AND_OTHER_INTELLECTUAL_PROPERTY_RIGHTS_IN_THE_META_11_CONTENT_ARE_OWNED_BY_BEHAVIOL_PTY_LTD_OR_ITS_LICENSORS_THE_USER_HAS_NO_RIGHT_TITLE_OR_INTEREST_IN_ANY_OF_THESE_ITEMS_EXCEPT_AS_EXPRESSLY_GRANTED_IN_THESE_TERMS")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.LICENSE_GRANT_THE_USER_IS_GRANTED_A_PERSONAL_NONEXCLUSIVE_NONASSIGNABLE_AND_NONTRANSFERABLE_LICENSE_TO_USE_META_11_CONTENT_SOLELY_FOR_ACCESSING_AND_USING_THE_SERVICES_PROVIDED_ON_THE_META_11_PLATFORM_AND_FOR_NO_OTHER_PURPOSE")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.NO_SUB_LICENSING_THE_USER_SHALL_NOT_SUBLICENSE_ASSIGN_OR_TRANSFER_THE_LICENSE_GRANTED_NOR_RENT_LEASE_OR_PART_WITH_THE_WHOLE_OR_ANY_PART_OF_THE_LICENSE_OR_THE_META_11_CONTENT")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.PROHIBITED_ACTIONS_THE_USER_IS_PROHIBITED_FROM_TRANSFERRING_COPYING_REPRODUCING_DISTRIBUTING_EXPLOITING_REVERSEENGINEERING_DISASSEMBLING_TRANSLATING_DECODING_ALTERING_MAKING_DERIVATIVES_FROM_OR_USING_META_11_CONTENT_IN_ANY_WAY_OTHER_THAN_AS_PERMITTED_TO_ACCESS_AND_USE_THE_SERVICES_ON_THE_META_11_PLATFORM")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.NO_HYPERLINKING_THE_USER_SHALL_NOT_HYPERLINK_THE_META_11_PLATFORM_TO_ANY_OTHER_WEBSITE_WITHOUT_PRIOR_WRITTEN_PERMISSION_FROM_BEHAVIOL")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.PERSONAL_USE_ONLY_THE_USER_MAY_DOWNLOAD_AND_PRINT_EXTRACTS_FROM_THE_META_11_PLATFORM_FOR_PERSONAL_USE_ONLY_NO_RIGHT_TITLE_OR_INTEREST_IN_ANY_DOWNLOADED_MATERIALS_OR_SOFTWARE_IS_TRANSFERRED_BY_DOWNLOADING_THE_USER_IS_EXPRESSLY_PROHIBITED_FROM_USING_SUCH_MATERIALS_FOR_ANY_COMMERCIAL_PURPOSE_UNLESS_EXPLICITLY_AGREED_UPON_WITH_BEHAVIOL_IN_WRITING")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.USERS_ARE_ACCOUNTABLE_FOR_ALL_CONTENT_WHETHER_SHARED_PUBLICLY_OR_PRIVATELY_THAT_THEY_UPLOAD_POST_EMAIL_OR_OTHERWISE_DISSEMINATE_ON_BEHAVIOL'S_META_11_PLATFORM_USER_CONTENT_USERS_CONFIRM_THAT_THEY_OWN_OR_HOLD_A_LICENCE_TO_USE_ALL_INTELLECTUAL_PROPERTY_RIGHTS_ASSOCIATED_WITH_THEIR_USER_CONTENT_AND_THAT_SUCH_USE_DOESN'T_INFRINGE_THE_RIGHTS_OF_ANY_THIRDPARTY_USERS_COMMIT_NOT_TO_DISPLAY_UPLOAD_MODIFY_PUBLISH_TRANSMIT_STORE_UPDATE_OR_SHARE_ANY_INFORMATION_THAT")}
                              <ol type={"i"} className={Classnames(Styles.orderedList)}>
                                 <li>{t("TERMS_AND_CONDITIONS.I_BELONGS_TO_ANOTHER_PERSON_AND_TO_WHICH_THE_USER_DOES_NOT_HAVE_ANY_RIGHT")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.II_IS_OBSCENE_PORNOGRAPHIC_PAEDOPHILIC_INVASIVE_OF_ANOTHERS_PRIVACY_INCLUDING_BODILY_PRIVACY_INSULTING_OR_HARASSING_ON_THE_BASIS_OF_GENDER_RACIALLY_OR_ETHNICALLY_OBJECTIONABLE_RELATING_OR_ENCOURAGING_MONEY_LAUNDERING_OR_GAMBLING_OR_PROMOTING_ENMITY_BETWEEN_DIFFERENT_GROUPS_ON_THE_GROUNDS_OF_RELIGION_OR_CASTE_WITH_THE_INTENT_TO_INCITE_VIOLENCE")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.III_IS_HARMFUL_TO_CHILD")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.IV_INFRINGES_ANY_PATENT_TRADEMARK_COPYRIGHT_OR_OTHER_PROPRIETARY_RIGHTS")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.V_DECEIVES_OR_MISLEADS_THE_ADDRESSEE_ABOUT_THE_ORIGIN_OF_THE_MESSAGE_OR_KNOWINGLY_AND_INTENTIONALLY_COMMUNICATES_ANY_MISINFORMATION_OR_INFORMATION_WHICH_IS_PATENTLY_FALSE_AND_UNTRUE_OR_MISLEADING_IN_NATURE")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.VI_IMPERSONATES_ANOTHER_PERSON")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.VII_THREATENS_THE_UNITY_INTEGRITY_DEFENCE_SECURITY_OR_SOVEREIGNTY_OF_INDIA_FRIENDLY_RELATIONS_WITH_FOREIGN_STATES_OR_PUBLIC_ORDER_OR_CAUSES_INCITEMENT_TO_THE_COMMISSION_OF_ANY_COGNISABLE_OFFENCE_OR_PREVENTS_INVESTIGATION_OF_ANY_OFFENCE_OR_IS_INSULTING_OTHER_NATION")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.VIII_CONTAINS_SOFTWARE_VIRUS_OR_ANY_OTHER_COMPUTER_CODE_FILE_OR_PROGRAM_DESIGNED_TO_INTERRUPT_DESTROY_OR_LIMIT_THE_FUNCTIONALITY_OF_ANY_COMPUTER_RESOURCE;")}</li>
                              </ol>
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.USERS_ARE_OBLIGED_TO_INDEMNIFY_AND_HOLD_HARMLESS_THE_PROMOTER_ITS_AFFILIATES_DIRECTORS_EMPLOYEES_AND_ASSIGNS_AGAINST_ANY_THIRDPARTY_CLAIMS_INCLUDING_COSTS_RELATED_TO_LITIGATION_AND_ATTORNEY_FEES_THAT_ARISE_FROM_THE_USE_OF_SUCH_USER_CONTENT_ON_META_11_PLATFORM")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.15_Third_Party_Financing")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)} style={{listStyle: 'none'}}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.BEHAVIOL_DOES_NOT_OFFER_FINANCING_OR_CREDIT_TO_USERS_FOR_THE_PURPOSE_OF_PLAYING_ONLINE_GAMES_USERS_ARE_RESPONSIBLE_FOR_THEIR_OWN_FUNDS_AND_SHOULD_NOT_RELY_ON_THIRDPARTY_CREDIT_FOR_PARTICIPATION")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>
                        {t("TERMS_AND_CONDITIONS.SOCIAL_MEDIA_PLATFORMS_AND_LICENSE_TO_BEHAVIOL")}
                        <span>{t("TERMS_AND_CONDITIONS.PTY_LTD")}</span>
                     </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.BY_ENTERING_THIS_CONTEST, ELIGIBLE_ENTRANTS_GRANT_THE_PROMOTER_ITS_AFFILIATES, PARTNERS, AND_LICENSORS_A_WORLDWIDE, IRREVOCABLE, NON_EXCLUSIVE, TRANSFERABLE, SUB_LICENSABLE_LICENCE_TO_USE, DISTRIBUTE, EXHIBIT_AND/OR_STORE_IN_AN_ACCESSIBLE_REMOTE_DATABASE, THE_CONTENT_OF_THEIR_ENTRY_AND_ANY_OF_THEIR_USER_CONTENT, OR_ANY_PART_OF_THE_CONTENT_OF_THE_ENTRY_OR_THEIR_USER_CONTENT, IN_ANY_WAY_THE_PROMOTER_WISHES (INCLUDING_MODIFYING, ADAPTING, COPYING, CROPPING, RETOUCHING, EDITING, PUBLISHING, BROADCASTING_OR_COMMUNICATING_THE_ENTRY_WHETHER_IN_ORIGINAL_OR_MODIFIED_FORM_IN_WHOLE_OR_IN_PART) IN_ALL_MEDIA_FOR_THE_PURPOSES_OF_THE_PROMOTER’S_BUSINESS_ON_ITS_WEBSITE, SOCIAL_MEDIA_OR_IN_ANY_MARKETING_CAMPAIGN, WITHOUT_PAYMENT_TO_THE_ELIGIBLE_ENTRANT_OF_ROYALTIES_OR_COMPENSATION. ")}
                              {t("TERMS_AND_CONDITIONS.IF_REQUESTED_BY_THE_PROMOTER, THE_ELIGIBLE_ENTRANT_AGREES_TO_SIGN_ANY_FURTHER_DOCUMENTATION_REQUIRED_BY_THE_PROMOTER_L_TO_GIVE_EFFECT_TO_THIS_PARAGRAPH.")}
                              {t("TERMS_AND_CONDITIONS.THIS_LICENSE_COVERS_ANY_FORM, MEDIUM, OR_TECHNOLOGY_RELATED_TO_THE_STORAGE_OR_DISTRIBUTION_OF_USER_CONTENT.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.USER_CONSENT_TO_THE_PROMOTER_USING_THEIR_NAME_AND_OTHER_RELEVANT_PERSONAL_INFORMATION_AS_DEFINED_IN_THE_PRIVACY_POLICY_IN_THE_EVENT_THEY_ARE_A_WINNER_IN_ANY_MEDIA_FOR_AN_UNLIMITED_PERIOD_OF_TIME_WITHOUT_REMUNERATION_OR_COMPENSATION_FOR_THE_PURPOSE_OF_PROMOTING_THIS_CONTEST_INCLUDING_ANY_OUTCOME_ANDOR_PROMOTING_THE_BUSINESS_CONDUCTED_BY_THE_PROMOTER_AND_ANY_PRODUCTS_INCLUDING_GAMES_MANUFACTURED_DISTRIBUTED_ANDOR_SUPPLIED_BY_THE_PROMOTER")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.BY_USING_AND_ENTERING_THIS_CONTEST_ON_WWWMETA_11_COM.")}
                              <ol type={"i"} className={Classnames(Styles.orderedList)}>
                                 <li>{t("TERMS_AND_CONDITIONS.AGREE_TO_COMPLY_WITH_THE_PROMOTER’S_TERMS_OF_USE;")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.RELEASE_THE_PROMOTER_FROM_ALL_CLAIMS_BASED_ON, RELATED_TO_OR_ARISING_FROM_THE_CONTEST")}</li>
                              </ol>
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THE_PROMOTER_IS_NOT_RESPONSIBLE_FOR_ANY_LOSS, DAMAGE_OR_INJURY_TO_ELIGIBLE_ENTRANTS_RESULTING_FROM_ENTERING_OR_PARTICIPATING_IN_THIS_CONTEST_INCLUDING_ARISING_FROM_ANY_COMMENTS_MADE, OR_MATERIAL_PUBLISHED, BY_THIRD_PARTIES_ABOUT_ELIGIBLE_ENTRANTS_ON_ANY_SOCIAL_MEDIA_PLATFORM_IN_CONNECTION_WITH_THIS_CONTEST.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.ANY_INTELLECTUAL_AND_PROPRIETARY_RIGHTS_DISPLAYED_ON_META_11, BELONGING_TO_ANY_INDIVIDUAL_OR_THIRD_PARTY_ENTITY, ARE_ACKNOWLEDGED_AS_THEIR_RESPECTIVE_PROPERTIES. ANY_DISPUTES_OVER_THOSE_RIGHTS_MUST_BE_ADDRESSED_DIRECTLY_TO_THEIR_RESPECTIVE_OWNERS, AND_THE_PROMOTER_MUST_BE_PROMPTLY_INFORMED.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.IF_A_USER_SELECTS_A_USERNAME_DEEMED_BY_THE_PROMOTER_TO_BE_INAPPROPRIATE, DEROGATORY, OR_MIRRORING_OFFICIAL_NAMES_OF_SPORTS_ENTITIES_OR_PERSONALITIES, THE_PROMOTER_RESERVES_THE_UNILATERAL_RIGHT_TO_ALTER, DELETE, OR_RESTRICT_THE_USE_OF_SUCH_USERNAMES.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.ACCESSING_META_11_THROUGH_UNAUTHORIZED_MEANS_CONSTITUTES_A_VIOLATION_OF_THESE_TERMS_AND_CONDITIONS.")}
                              {t("TERMS_AND_CONDITIONS.USERS_AGREE_TO_ACCESS_META_11_SOLELY_VIA_THE_APPROVED_INTERFACES_AND_TO_REFRAIN_FROM_UTILIZING_ANY_UNAUTHORIZED_AUTOMATED_TOOLS_FOR_ACCESSING_THE_PLATFORM.")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.LIABILITY_RESTRICTIONS")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.USERS_CHOOSE_TO_ENGAGE_WITH_THE_PROMOTER’S_META_11_FANTASY_GAME_OF_THEIR_OWN_VOLITION.")}
                              {t("TERMS_AND_CONDITIONS.IN_ANY_SCENARIO, THE_PROMOTER_WON'T_BE_LIABLE_FOR_ANY_LOSS_OR_DAMAGE_OR_INJURY_SUFFERED (EVEN_IF_CAUSED_BY_NEGLIGENCE) BY_USERS_OR_OTHERS_AS_A_RESULT_OF_ANY_USER_PARTICIPATING_IN_A_CONTEST_OR_A_RESULT_OF_ANY_WINNER_ACCEPTING_AND/OR_USING_A_PRIZE_EXCEPT_FOR_ANY_LIABILITY_THAT_CANNOT_BE_EXCLUDED_BY_LAW")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.INDEMNITY_AND_RELEASE_USERS, BY_PARTICIPATING_IN_CONTESTS_AND_ACCESSING_THE_PROMOTER’S_META_11_FANTASY_GAME, THEREBY_RELEASE_AND_COMMIT_TO_HOLDING_THE_PROMOTER, ITS_DIRECTORS, STAFF, PARTNERS, ASSOCIATES, AND_LICENSORS_HARMLESS_FROM_ANY_LIABILITY, LOSS, OR_COST_EMERGING_FROM_THEIR_PARTICIPATION_IN_ANY_CONTEST, INCLUDING_THOSE_ARISING_AS_A_RESULT_OF_INJURIES, DAMAGES (WHETHER_DIRECT_OR_INDIRECT).")}
                              {t("TERMS_AND_CONDITIONS.THE_PROMOTER_WON'T_ACCEPT_ANY_LIABILITY_FOR_MISTAKES_OR_OVERSIGHTS, WHETHER_ON_ITS_BEHALF_OR_OF_THIRD_PARTIES, REGARDING_PRIZES.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.ANY_REPERCUSSIONS_STEMMING_FROM_USERS' ILLICIT_ACCESS_TO_THE_PROMOTER’S_META_11_FANTASY_GAME_OR_NON_ADHERENCE_TO_THESE_TERMS_AND_CONDITIONS_OR_FAILURE_TO_COMPLY_WITH_OTHER_APPLICABLE_LAWS_OR_RULES_WILL_SOLELY_BE_BORNE_BY_THE_USER.")}
                              {t("TERMS_AND_CONDITIONS.USERS_COMMIT_TO_COMPENSATE_THE_PROMOTER (INCLUDING_ATTORNEY_COSTS_AND_LEGAL_FEES) FOR_ANY_HARM_OR_LOSS_INCURRED_AS_A_RESULT_OF_SUCH_ACTIONS.")}
                              {t("TERMS_AND_CONDITIONS.USERS_ALSO_AGREE_TO_HOLD_THE_PROMOTER_HARMLESS_FROM_ANY_THIRD_PARTY_CLAIMS_THAT_MIGHT_ARISE_FROM_THEIR_ENGAGEMENT_WITH_THE_PROMOTER_OR_FROM_THEIR_CONTEST_PARTICIPATION.")}
                              {t("TERMS_AND_CONDITIONS.USERS_AGREE_THAT_THE_PROMOTER_WON'T_BE_RESPONSIBLE_FOR_ANY_ACTS_OR_OUTCOMES_ARISING_FROM_THEIR_ENGAGEMENT_OR_PARTICIPATION_IN_ANY_CONTEST(S).")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.IN_CONSIDERATION_OF_THE_PROMOTER'S_PERMISSION_FOR_USERS_TO_ACCESS_ITS_SERVICES, USERS, TO_THE_MAXIMUM_EXTENT_PERMITTED_BY_LAW, RELINQUISH_AND_FORGO_ANY_RIGHTS_OR_CLAIMS, PRESENT_OR_FUTURE, THEY_MAY_HOLD_AGAINST_THE_PROMOTER, ITS_AGENTS, DIRECTORS, BUSINESS_ASSOCIATES, SPONSORS, STAFF, OR_REPRESENTATIVES_FOR_ANY_POTENTIAL_MISHAPS, ACCIDENTS, OR_DAMAGES_LINKED_TO_THE_PROMOTER’S_SERVICES, CONTESTS, OR_PRIZES.")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.USE_OF_ELIGIBLE_ENTRANT’S_PERSONAL_INFORMATION")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.PERSONAL_INFORMATION_INCLUDING_THE_ELIGIBLE_ENTRANT’S_NAME, [ADDRESS], [TELEPHONE_NUMBER], EMAIL_ADDRESS_AND_NOMINATED_BANK_ACCOUNT_DETAILS_MAY_BE_COLLECTED_AND_USED_FOR_THE_PURPOSE_OF_CONDUCTING_THIS_CONTEST.")}
                              {t("TERMS_AND_CONDITIONS.THIS_MAY_REQUIRE_DISCLOSURE_TO_THIRD_PARTIES, INCLUDING_LOCAL_REGULATORY_AUTHORITIES_AND_THE_PROMOTER’S_AGENTS_OR_THIRD_PARTY_SERVICE_PROVIDERS, FOR_THE_PURPOSE_OF_CONDUCTING_THE_CONTEST [OR_FOR_PROMOTIONAL_AND_MARKETING_PURPOSES]")}
                              <b>({t("TERMS_AND_CONDITIONS.PURPOSE")})</b>.
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.BY_ENTERING_THIS_CONTEST, ELIGIBLE_ENTRANT’S_CONSENT_TO_THE_USE_OF_THEIR_PERSONAL_INFORMATION_FOR_THE_PURPOSE, [AND_THAT_THE_PROMOTER_MAY_CONTACT_THEM_FOR_FUTURE_MARKETING_AND_MATERIAL_PURPOSES_WITHOUT_PAYMENT].")}
                              {t("TERMS_AND_CONDITIONS.ELIGIBLE_ENTRANTS_AGREE_THAT_THE_PROMOTER_MAY_USE_THIS_INFORMATION_FOR_THAT_PURPOSE.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.ELIGIBLE_ENTRANTS_MAY_ACCESS, CHANGE_OR_UPDATE_THEIR_PERSONAL_INFORMATION_BY_EMAILING_THE_PROMOTER_AT")}
                              &nbsp;admin@behaviol.com.&nbsp;
                              {t("TERMS_AND_CONDITIONS.A_COPY_OF_THE_PROMOTER’S_PRIVACY_POLICY_IS_AVAILABLE_AT")}
                              <Link to={"/privacy-policy"}>{t("TERMS_AND_CONDITIONS.PRIVACY_POLICY")} </Link>.
                              {t("TERMS_AND_CONDITIONS.THE_PRIVACY_POLICY_CONTAINS_INFORMATION_ABOUT_HOW_INDIVIDUALS_MAY_ACCESS_OR_CORRECT_PERSONAL_INFORMATION_OR_MAKE_A_PRIVACY_RELATED_COMPLAINT.")}
                           </li>
                        </ol>

                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.EXTERNAL_LINKS_AND_THIRD_PARTY_INTERACTIONS")} </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THE_PROMOTER_MAY_FEATURE_LINKS_DIRECTING_USERS_TO_THIRD_PARTY_WEBSITES.")}
                              {t("TERMS_AND_CONDITIONS.THE_TERMS_AND_CONDITIONS_OF_THIRD_PARTY_WEBSITES_GOVERN_THE_USE_OF_SUCH_SITES, AND_THE_PROMOTER_HAS_NO_CONTROL_OVER_EXTERNAL_SITES. ")}
                              {t("TERMS_AND_CONDITIONS.THEREFORE, THE_PROMOTER_IS_NOT_ACCOUNTABLE_FOR_THE_CONTENT_PRESENT_ON_THIRD_PARTY_WEBSITES. ")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THE_PRESENCE_OF_THIRD_PARTY_CONTENT_OR_LINKS_WITHIN_A_CONTEST_DOESN'T_IMPLY_ENDORSEMENT_OR_APPROVAL_OF_THE_THIRD_PARTY_SITES_OR_THEIR_CONTENT_BY_THE_PROMOTER. ")}
                              {t("TERMS_AND_CONDITIONS.ALL_INTERACTIONS, DEALS, OR_ACTIVITIES_BETWEEN_THE_USER_AND_THIRD_PARTIES (INCLUDING_PAYMENT_AND_VERIFICATION_SERVICE_PROVIDERS) ARE_STRICTLY_BETWEEN_THE_USER_AND_THE_RESPECTIVE_THIRD_PARTY. ")}
                              {t("TERMS_AND_CONDITIONS.WHEN_ENGAGING_WITH_THIRD_PARTIES, USERS_ARE_BOUND_BY_THEIR_TERMS_AND_CONDITIONS_AND_POLICIES.")}
                              {t("TERMS_AND_CONDITIONS.BEFORE_ENTERING_INTO_ANY_TRANSACTION_OR_AVAILING_SERVICES/OFFERS_FROM_THESE_THIRD_PARTIES, USERS_SHOULD_DILIGENTLY_REVIEW_THEIR_TERMS.")}
                              {t("TERMS_AND_CONDITIONS.TO_THE_FULLEST_EXTENT_PERMITTED_BY_LAW, THE_PROMOTER_DISCLAIMS_RESPONSIBILITY_FOR_ANY_LOSSES_OR_DAMAGES_RESULTING_FROM_SUCH_THIRD_PARTY_INTERACTIONS.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.FOR_ISSUES, COMPLAINTS, OR_CLAIMS_RELATED_TO_PRODUCTS_OR_SERVICES_OFFERED_BY_THIRD_PARTIES, USERS_SHOULD_APPROACH_THE_RELEVANT_VENDOR_OR_SERVICE_PROVIDER_DIRECTLY.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.WHILE_THE_PROMOTER_OFFERS_ITS_OWN_CONTENT, IT_INCLUDES_CONTENT_PROVIDED_BY_THIRD_PARTIES.")}
                              {t("TERMS_AND_CONDITIONS.THE_PROMOTER_DOES_NOT_ASSURE_THE_ACCURACY, INTEGRITY, OR_QUALITY_OF_SUCH_THIRD_PARTY_CONTENT. ")}
                              {t("TERMS_AND_CONDITIONS.THEREFORE, USERS_SHOULD_EXERCISE_CAUTION_AND_NOT_SOLELY_RELY_ON_THIRD_PARTY_CONTENT_WHEN_USING_THE_PROMOTER’S_SERVICES_OR_PARTICIPATING_IN_CONTESTS_HOSTED_BY_THE_PROMOTER.")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.RESOLUTION_OF_DISPUTES")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.SHOULD_A_LEGAL_DISAGREEMENT_OR_QUERY_ARISE, THE_PARTY_RAISING_THE_CONCERN_SHALL_ISSUE_A_FORMAL_WRITTEN_NOTICE (\"DISPUTE_NOTIFICATION\") TO_THE_OTHER_PARTY_INVOLVED.")}
                              {t("TERMS_AND_CONDITIONS.UPON_RECEIVING_THIS_DISPUTE_NOTIFICATION, BOTH_PARTIES_COMMIT_TO_ATTEMPTING_RESOLUTION_THROUGH_DIRECT_DIALOGUE.")}
                              {t("TERMS_AND_CONDITIONS.IF_THE_PARTIES_FAIL_TO_COME_TO_AN_AGREEMENT_WITHIN_FIFTEEN (15) DAYS_FOLLOWING_THE_RECEIPT_OF_THE_DISPUTE_NOTIFICATION, THE_DISPUTE_WILL_BE_REFERRED_FOR_RESOLUTION_BY_ARBITRATION_IN_ACCORDANCE_WITH_THE_ARBITRATION_RULES_DEVELOPED_BY_THE_RESOLUTION_INSTITUTE.")}
                              {t("TERMS_AND_CONDITIONS.THE_ARBITRATION_SHALL_TAKE_PLACE_IN_SYDNEY, AUSTRALIA. ALL_PROCEEDINGS_RELATED_TO_THE_ARBITRATION_WILL_BE_CARRIED_OUT_IN_ENGLISH, AND_WILL_BE_GOVERNED_BY_THE_RULES_AND_REGULATIONS_OF_THE_THEN_CURRENT_COMMERCIAL_ARBITRATION_ACT_2010 (NSW).")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THE_DECISION_MADE_DURING_ARBITRATION_WILL_BE_DEEMED_FINAL_AND_WILL_BIND_BOTH_PARTIES_INVOLVED.")}
                              {t("TERMS_AND_CONDITIONS.EACH_PARTY_WILL_COVER_ITS_RESPECTIVE_COSTS_ASSOCIATED_WITH_THE_ARBITRATION, WITH_THE_ARBITRATOR'S_FEES_BEING_SHARED_EQUALLY.")}
                              {t("TERMS_AND_CONDITIONS.HOWEVER, THE_ARBITRATOR_HAS_THE_DISCRETION_TO_DETERMINE_A_DIFFERENT_ALLOCATION_OF_FEES.")}
                              {t("TERMS_AND_CONDITIONS.THE_ARBITRATOR_HOLDS_THE_RIGHT_TO_MAKE_INTERIM_DECISIONS_AND_AWARDS, INCLUDING_ORDERS_FOR_SPECIFIC_PERFORMANCE.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.NOTHING_WITHIN_THESE_TERMS_AND_CONDITIONS_RESTRICTS_THE_PROMOTER_FROM_SEEKING_AND_SECURING_URGENT_INTERIM_OR_PERMANENT_EQUITABLE_OR_INJUNCTIVE_RELIEF, OR_ANY_OTHER_FORM_OF_RELIEF_DEEMED_NECESSARY_TO_PROTECT_THE_PROMOTER'S_INTERESTS.")}
                              {t("TERMS_AND_CONDITIONS.THIS_CAN_BE_DONE_EITHER_BEFORE_THE_ARBITRATION, DURING_ITS_PROCEEDINGS, OR_AFTER_THE_ANNOUNCEMENT_OF_THE_ARBITRATION_DECISION, FROM_ANY_COURT_THAT_HAS_JURISDICTION.")}
                              {t("TERMS_AND_CONDITIONS.THE_PROMOTER'S_MOVE_TO_SEEK_SUCH_RELIEF_WILL_NOT_BE_CONSIDERED_A_WAIVER_OF_ITS_RIGHT_TO_SEEK_MONETARY_DAMAGES_THROUGH_THE_PRESCRIBED_ARBITRATION_PROCESS.")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.DISCLAIMERS")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THE_PROMOTER_AND_ITS_RELATED_ENTITIES, INCLUDING_ITS_PARENT/HOLDING_COMPANY, AFFILIATES, DIRECTORS, ADVISORS, AND_EMPLOYEES, ARE_NOT_LIABLE, TO_THE_FULLEST_EXTENT_PERMITTED_BY_LAW, FOR_THE_LOSS, MISPLACEMENT, OR_DELAY_IN_DELIVERING_ANY_INFORMATION_OR_MATERIAL.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THE_PROMOTER_ISN'T_RESPONSIBLE_FOR_DAMAGES_RESULTING_FROM_ACCESSING_ITS_SERVICES, INCLUDING_BUT_NOT_LIMITED_TO, DISRUPTIONS_CAUSED_BY_MAINTENANCE, SERVER_FAILURES, PROVIDER_FAILURES, VIRUSES, NATURAL_DISASTERS, CIVIL_UNRESTS, WAR, OR_ANY_OTHER_FACTORS_BEYOND_ITS_CONTROL.")}
                              {t("TERMS_AND_CONDITIONS.ANY_CONTENT_ON_THE_PROMOTER’S_SERVICES_ARE_OFFERED_ON_AN \"AS_IS, AS_AVAILABLE\" BASIS_WITHOUT_WARRANTIES.")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.ANY_MATERIAL_OR_DATA_ACCESSED_OR_DOWNLOADED_THROUGH_THE_PROMOTER’S_SERVICES_IS_AT_THE_USER'S_DISCRETION_AND_RISK.")}
                              {t("TERMS_AND_CONDITIONS.THE_USER_WILL_BEAR_ALL_POTENTIAL_DAMAGES, INCLUDING_SYSTEM_DAMAGES_OR_DATA_LOSS. WHILE_THE_PROMOTER_AIMS_TO_PROVIDE_SECURE_AND_ERROR_FREE_SERVICE, IT_DOESN'T_GUARANTEE_THAT")}

                              <ol type={"i"} className={Classnames(Styles.orderedList)}>
                                 <li>{t("TERMS_AND_CONDITIONS.THE_PROMOTER_WILL_MEET_SPECIFIC_USER_REQUIREMENTS;")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.SERVICES_WILL_BE_UNINTERRUPTED, SECURE, OR_FREE_FROM_ERRORS;")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.THE_OBTAINED_RESULTS_FROM_USING_THE_PROMOTER’S_SERVICES_WILL_BE_ACCURATE_OR_DEPENDABLE; OR")}</li>
                                 <li>{t("TERMS_AND_CONDITIONS.THE_QUALITY_OF_PRODUCTS_OR_SERVICES_WILL_MEET_USER_EXPECTATIONS.")}</li>
                              </ol>
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.SHOULD_THE_PROMOTER_IDENTIFY_ANY_MISTAKES, SUCH_AS_ERRORS_IN_WINNER_DETERMINATION_OR_PAYMENT_TRANSFERS, IT_HAS_THE_RIGHT_TO_CORRECT_THEM_AS_IT_SEES_FIT, INCLUDING   OFFSETTING_INCORRECT_PAYMENTS.")}
                              {t("TERMS_AND_CONDITIONS.IF_SUCH_REMEDIES_ARE_EMPLOYED, THE_PROMOTER_COMMITS_TO_INFORMING_THE_USER_ABOUT_THE_ERROR_AND_THE_RECTIFICATION_MEASURES_TAKEN.")}
                              {t("TERMS_AND_CONDITIONS.TO_THE_MAXIMUM_EXTENT_PERMITTED_BY_LAW, NEITHER_THE_PROMOTER_NOR_ITS_ASSOCIATES_WILL_BE_LIABLE_FOR_ANY_KIND_OF_DAMAGES_RESULTING_FROM_THE_USE_OR_INABILITY_TO_USE_THE_PROMOTER'S_PLATFORM, EVEN_IF_THEY_WERE_INFORMED_ABOUT_POTENTIAL_DAMAGES.")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.22_KNOW_YOUR_CUSTOMER_KYC_REQUIREMENTS")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.WE_RESERVE_THE_RIGHT_TO_CONDUCT_KYC_AS_MAY_BE_REQUIRED_FROM_TIME_TO_TIME_IN_THE_MANNER_PRESCRIBED_UNDER_APPLICABLE_LAWS")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.PRIVACY_POLICY")} </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.ALL_INFORMATION_COLLECTED_FROM_USERS, SUCH_AS_REGISTRATION_AND_CREDIT_CARD_INFORMATION, IS_SUBJECT_TO_THE_PROMOTER'S_PRIVACY_POLICY_WHICH_IS_AVAILABLE_AT")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.GOVERNING_LAW")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THESE_TERMS_AND_CONDITIONS_WILL_BE_GOVERNED_BY, AND_INTERPRETED_IN_ACCORDANCE_WITH, THE_LAWS_OF_NEW_SOUTH_WALES, AUSTRALIA.")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.25_GRIEVANCE_REDRESSAL")} </div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        <ol type={"a"} className={Classnames(Styles.orderedList)}>
                           <li>
                              {t("TERMS_AND_CONDITIONS.IN_CASE_YOU_HAVE_ANY_COMPLAINTS_OR_GRIEVANCE_PERTAINING_TO")}
                              <ol type={"i"} className={Classnames(Styles.orderedList)}>
                                 <li>
                                    {t("TERMS_AND_CONDITIONS.ANY_USER_CONTENT_THAT_YOU_BELIEVE_VIOLATES_THESE_TERMS_INCLUDING_ANY_INFRINGEMENT_OF_INTELLECTUAL_PROPERTY_RIGHTS")}
                                 </li>
                                 <li>
                                    {t("TERMS_AND_CONDITIONS.YOUR_ACCESS_TO_THE_SERVICES_PLEASE_SHARE_THE_SAME_WITH_US_BY_WRITING_TO_RODRIGO_SALONGA_ON_RECEIVING_SUCH_A_COMPLAINT_GRIEVANCE_OR_NOTICE_THE_GRIEVANCE_OFFICER_MAY_REACH_OUT_TO_YOU_TO_SEEK_FURTHER_CLARIFICATION_OR_TO_VERIFY_YOUR_COMPLAINT")}
                                 </li>
                              </ol>
                              {t("TERMS_AND_CONDITIONS.RODRIGO_SALONGA")}
                              <br/>
                              {t("TERMS_AND_CONDITIONS.IN_CASE_YOU_HAVE_ANY_COMPLAINTS_OR_GRIEVANCE_PERTAINING_TO")}
                              <br/>
                              <a href="mailto:rod@behaviol.com">admin@behaviol.com</a>
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.THE_GRIEVANCE_REDRESSAL_OFFICER_IDENTIFIED_ABOVE_IS_PURSUANT_TO_THE_PROVISIONS_OF_APPLICABLE_LAWS_INCLUDING_BUT_NOT_LIMITED_TO_THE_INFORMATION_TECHNOLOGY_ACT_2000_AND_THE_CONSUMER_PROTECTION_ACT_2019_AND_THE_RULES_ENACTED_UNDER_THOSE_LAWS_BEHAVIOL_RESERVES_THE_RIGHT_TO_REPLACE_THE_GRIEVANCE_REDRESSAL_OFFICER_AT_ITS_DISCRETION_THROUGH_THE_PUBLICATION_OF_THE_NAME_AND_TITLE_OF_SUCH_REPLACEMENT_ON_THE_SITES_WHICH_REPLACEMENT_SHALL_COME_INTO_EFFECT_IMMEDIATELY_UPON_PUBLICATION")}
                           </li>
                           <li>
                              {t("TERMS_AND_CONDITIONS.YOU_AGREE_THAT_REGARDLESS_OF_ANY_STATUTE_OR_LAW_TO_THE_CONTRARY_ANY_COMPLAINTS_OR_GRIEVANCES_ARISING_OUT_OF_OR_RELATED_TO_THE_USE_OF_SERVICES_OR_THESE_TERMS_SHOULD_BE_FILED_WITHIN_THIRTY_30_DAYS_OF_SUCH_CLAIM_TO_ENABLE_US_TO_RESOLVE_YOUR_COMPLAINT_AT_THE_EARLIEST")}
                           </li>
                        </ol>
                     </div>

                     <div className={Classnames(Styles.sectionTitle, "mt-5")}>{t("TERMS_AND_CONDITIONS.CONTACT_INFORMATION")}</div>
                     <div className={Classnames(Styles.sectionContent, "mt-3")}>
                        {t("TERMS_AND_CONDITIONS.FOR_ANY_CONCERNS_RELATED_TO_MATCHES_OR_CONTESTS, USERS_CAN_CONTACT_THE_PROMOTERS_COMMUNITY_SUPPORT, ON_DISCORD")}
                        <a href={"https://discord.gg/DW6rBfHMFm"}>{t("TERMS_AND_CONDITIONS.HERE")}</a> {t("TERMS_AND_CONDITIONS.WITHIN_FORTY_EIGHT (48) HOURS_POST_THE_DECLARATION_OF_CONTEST_RESULTS.")}
                     </div>
                     <div className={Classnames(Styles.sectionContent, "mt-5")}>{t("TERMS_AND_CONDITIONS.FOR_ANY_GRIEVANCES, PLEASE_CONTACT_OUR_GRIEVANCES_OFFICER, RODRIGO, AT")} <a href="mailto:rod@behaviol.com">admin@behaviol.com</a></div>
                  </div>
                  <Footer/>
               </div>
            </div>
         </div>
      </React.Fragment>
   );
};

export default TermsAndConditions;
