import {Card} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import styles from "./BallByBallCard.module.scss";
import classNames from "classnames";


const BallByBallHistory = ({cardData, lang, predictionResult, overSummary, summaryMessages}) => {

   const [predictions, setPredictions] = useState([]);
   const [ballData, setBallData] = useState([]);
   const [predictionData, setPredictionData] = useState([]);
   console.log(cardData)
   const ballArrangement = [
      {"ballId": 1}, {"ballId": 2}, {"ballId": 3}, {"ballId": 4}, {"ballId": 5}, {"ballId": 6}
   ]

   const getResult = (ballId) => {
      console.log("ball getResult called with ballId:", ballId);
      let ballResult = predictions.find(prediction => prediction.ballId == ballId);

      if (overSummary?.length > 0 && overSummary?.some(summary => summary?.inning == cardData?.inning && summary?.overId == cardData?.overNo)) {
         if (ballData?.some(result => result?.inning == cardData?.inning && result?.overId == cardData?.overNo)) {
            if (ballData?.find(result => result?.ballId == ballId && result?.overId == cardData?.overNo && result?.inning == cardData?.inning)?.actual == undefined ||
               ballData?.find(result => result?.ballId == ballId && result?.overId == cardData?.overNo && result?.inning == cardData?.inning)?.actual == "N/P" ||
               ballData?.find(result => result?.ballId == ballId && result?.overId == cardData?.overNo && result?.inning == cardData?.inning)?.prediction == null) {
               return null;
            } else {
               let actualValue = ballData?.find(result => result?.ballId == ballId && result?.overId == cardData?.overNo && result?.inning == cardData?.inning)?.actual;
               let predictionValue = ballData?.find(result => result?.ballId == ballId && result?.overId == cardData?.overNo && result?.inning == cardData?.inning)?.prediction;
               let overId = cardData?.overNo;
               console.log("Ball ID: ", ballId);
               console.log("ball Over ID: ", overId);
               console.log("ball Actual Value: ", actualValue);
               console.log("ball Prediction Value: ", predictionValue);
               return actualValue == predictionValue;
            }
         } else {
            return null;
         }
      }
   }

   const getSummaryData = (inning, overId) => {
      let summary = overSummary?.find(item => item?.inning == inning && item.overId == overId)
      console.log("SUMMARY ----", summary)
      return {
         "consecutive3Points": summary?.overSummary?.consecutive3Points != null ? summary?.overSummary?.consecutive3Points : summary?.overSummary?.consecutiveBonusPoints,
         "predictionPoints": summary?.overSummary?.predictionPoints,
         "streakBonusPoints": summary?.overSummary?.streakBonusPoints,
         "sixPredictionBonus": summary?.overSummary?.sixPredictionBonusPoints,
         "totalPoints": summary?.overSummary?.totalPoints
      };
   }

   const mapData = (feedbacks) => {
      const mappedObject = {};

      feedbacks.forEach((item) => {
         const {fId, language_code, feedback_txt} = item;

         // Define key names based on fId
         let keyName = '';

         switch (fId) {
            case 10:
               keyName = 'OverSummary';
               break;
            case 11:
               keyName = 'CorrectAnswers';
               break;
            case 12:
               keyName = 'StreakBonus';
               break;
            case 13:
               keyName = 'RowBonus';
               break;
            case 14:
               keyName = 'TPoints';
               break;
            case 15:
               keyName = 'CheckPoints';
               break;
            case 16:
               keyName = 'AllCorrectBonus';
               break;
            case 17:
               keyName = 'MatchSummary';
               break;
            default:
               keyName = `DefaultKey_${fId}`;
         }

         // Use keyName as the key
         if (!mappedObject[keyName]) {
            mappedObject[keyName] = {en: [], ta: [], hi: []};
         }

         mappedObject[keyName][language_code].push({language_code, feedback_txt});
      });

      return (mappedObject);
   };
   console.log("----------- = ball by ball History = ---------", cardData)

   useEffect(() => {
      const combineObjects = (data) => {
         const result = [];

         data.forEach((item) => {
            const existingItem = result.find(
               (el) =>
                  el.overId === item.overId &&
                  el.ballId === item.ballId &&
                  el.inning === item.inning
            );

            if (existingItem) {
               console.log("EXIST")
               if (item.prediction == null && item.actual != "N/P") {

                  existingItem.actual = item.actual;
               } else if (item.prediction != null && item.actual == "N/P") {
                  existingItem.prediction = item.prediction;
               }
            } else {
               result.push({...item});
            }
         });

         return result;
      };

      if (predictionResult && predictionResult.length > 0) {
         console.log(predictionResult)
         const processedData = combineObjects(predictionResult);
         setBallData(processedData);

      }
   }, [predictionResult]);

   useEffect(() => {
      setPredictionData(ballData?.filter(data => data?.actual != "N/P" && data?.inning == cardData?.inning && data?.overId == cardData?.overNo))
   }, [ballData])

   return (
      <div>
         <Card className={classNames(styles.botCardWrapper)}>
            {/*<Card.Subtitle>Inning : {cardData?.inning} Over : {cardData?.overNo}</Card.Subtitle>*/}
            <Card.Body className={classNames(styles.botCardBody)}>
               {overSummary?.length > 0 && overSummary?.some(summary => summary?.inning == cardData?.inning &&
                  summary?.overId == cardData?.overNo) || predictionData?.length > 0 ? <h6
                     className="body-text4 color-white d-flex"> {mapData(summaryMessages)?.CheckPoints[lang][0]?.feedback_txt}</h6> :
                  <h6
                     className="body-text4 color-white d-flex"> {cardData?.questionList?.find(question => question.languageCode == lang).questionText}</h6>}
               <div className={classNames(styles.ballBody)}>
                  {ballArrangement?.map(ball => (
                     <div className={classNames(getResult(ball?.ballId) == null ? styles.ballWrapper : styles.BallWrapper40, (getResult(ball?.ballId) != null && getResult(ball?.ballId) == true) ? styles.bigBallGreen : styles.bigBallRed)}>
                <span className={classNames("heading4 color-black")}>
                  {
                     ballData?.some(prediction => prediction?.ballId == ball?.ballId && prediction?.overId == cardData?.overNo && prediction?.inning == cardData?.inning) ? ballData?.find(result => result?.ballId == ball?.ballId && result?.overId == cardData?.overNo && result?.inning == cardData?.inning)?.prediction : ""
                  }
                </span>
                        {(getResult(ball?.ballId) != null && getResult(ball?.ballId) == true) ? <span
                           className={classNames(styles.muted, "bold italic")}>+4</span> : <>{getResult(ball?.ballId) == null ? "" :
                           <span className={classNames(styles.muted, "bold italic")}>0</span>} </>}
                     </div>
                  ))
                  }

               </div>

               {/*{(predictionData[predictionData?.length - 1] && !ballData?.some(prediction => prediction?.overId > cardData?.overNo && prediction?.inning == cardData?.inning) && ballData?.length > 0 && predictionData?.filter(prediction => prediction.overId == cardData?.overNo)?.length <= 6 && !overSummary?.some(summary => summary?.inning == cardData?.inning && summary?.overId == cardData?.overNo) && ballData?.some(result => result?.overId == cardData?.overNo)) ?*/}

               {/*   <div className={classNames(styles.ballSecondBody, "d-flex")} style={{justifyContent: "center"}}>*/}
               {/*      /!*<div*!/*/}
               {/*      /!*   className={classNames(styles.bigBallWrapper, (getResult(predictionData[predictionData?.length - 1]?.ballId) != null && getResult(predictionData[predictionData?.length - 1]?.ballId) == true) ? styles.bigBallGreen : styles.bigBallRed)}>*!/*/}
               {/*      /!*   <span className="heading3">{predictionData[predictionData?.length - 1]?.actual}</span>*!/*/}
               {/*      /!*</div>*!/*/}
               {/*      <label*/}
               {/*         className={classNames((getResult(predictionData[predictionData?.length - 1]?.ballId) != null && getResult(predictionData[predictionData?.length - 1]?.ballId) == true) ? styles.ptstxtlabel : styles.mnstxtlabel, "heading3 color-white bold italic d-flex lower-case")}*/}
               {/*         style={{alignItems: "center"}}>+ {getResult(predictionData[predictionData?.length - 1]?.ballId) == true ? 4 : 0} <span*/}
               {/*         className={classNames(styles.pts)}>pts</span></label>*/}
               {/*   </div> : ""*/}
               {/*}*/}


               {overSummary?.length > 0 && overSummary?.some(summary => summary?.inning == cardData?.inning && summary?.overId == cardData?.overNo) ?
                  <div className={classNames(styles.ballSecondBody, "d-flex mt-4")}
                       style={{
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center"
                       }}>
                     <h7
                        className={classNames(styles.summarytxtlabel, "heading4 color-white bold d-flex capitalize green-glow")}
                        style={{alignItems: "center"}}>{mapData(summaryMessages)?.OverSummary[lang][0]?.feedback_txt}</h7>
                     <h7
                        className="color-textturquiose heading5 bold green-glow">{getSummaryData(cardData?.inning, cardData?.overNo)?.totalPoints} pts
                     </h7>
                     <div className={classNames(styles.borderBottomNion, "d-flex mb-2")}></div>
                     <div className={classNames(styles.boardWrapper)}>
                     <span className="d-flex mt-1 heading6 semi-bold"><label
                        className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.CorrectAnswers[lang][0]?.feedback_txt}:</label><label
                        className="body-text4 color-textturquiose">{getSummaryData(cardData?.inning, cardData?.overNo)?.predictionPoints} pts</label></span>
                        <span className="d-flex mt-1 heading6 semi-bold"><label
                           className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.StreakBonus[lang][0]?.feedback_txt}</label><label
                           className="body-text4 color-textturquiose">{getSummaryData(cardData?.inning, cardData?.overNo)?.streakBonusPoints} pts</label></span>
                        <span className="d-flex mt-1 heading6 semi-bold"><label
                           className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.RowBonus[lang][0]?.feedback_txt}</label><label
                           className="body-text4 color-textturquiose">{getSummaryData(cardData?.inning, cardData?.overNo)?.consecutive3Points} pts</label></span>
                        <span className="d-flex mt-1 heading6 semi-bold"><label
                           className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.AllCorrectBonus[lang][0]?.feedback_txt}:</label><label
                           className="body-text4 color-textturquiose">{getSummaryData(cardData?.inning, cardData?.overNo)?.sixPredictionBonus} pts</label></span>
                        <div className={classNames(styles.borderBottomNion, "d-flex mb-2 mt-2")}></div>
                        <span className="d-flex mt-1 heading6 semi-bold"><label
                           className="color-white upper-case mb-0 pe-2">{mapData(summaryMessages)?.TPoints[lang][0]?.feedback_txt}:</label><label
                           className="body-text4 color-textturquiose">{getSummaryData(cardData?.inning, cardData?.overNo)?.totalPoints} pts</label></span>
                     </div>
                  </div> : ""}

            </Card.Body>
         </Card>
      </div>

   )
}

export default BallByBallHistory;
