import React, {useEffect, useState} from "react";
import {BsXLg} from "react-icons/bs";
import {useTranslation} from "react-i18next";

const HowToUseQBot = ({handleMoreInfoClose}) => {
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   return (
      <div className="qBotFaqModal">
         <a className="closeModal" onClick={() => handleMoreInfoClose()} style={{cursor: 'pointer'}}>
            <BsXLg/>
         </a>
         <h1 className="modal-main-header mb-3">{t("PUB_NUB_QUIZ_BOT.HOW_TO_USE_QBOT")}<img src="https://storage.googleapis.com/meta11/serviceplatform/QBOTNew.webp" alt="Qbot" style={{width: '127px', marginLeft: '15px', marginTop: '-6px'}}></img></h1>
         <div className="modal-topic text-center mb-3 color-white">{t("PUB_NUB_QUIZ_BOT.MEET_QBOT, YOUR_QUIZ_MASTER_IN_META_11_FANTASY")}</div>

         <div className="modal-topic">
            <span role="img" aria-label="sheep" className="modal-emoji">🤖</span>&nbsp;
            <span className='color-white'>{t("PUB_NUB_QUIZ_BOT.WHAT_IS_QBOT?")}</span>
         </div>
         <div className="modal-Para text-start">
            {t("PUB_NUB_QUIZ_BOT.QBOT_IS_YOUR_SMART, WITTY, AND_INSIGHTFUL_QUIZ_MASTER_IN_META_11_FANTASY.")}
            {t("PUB_NUB_QUIZ_BOT.POWERED_BY_THE_ADVANCED_OMNIMIND_NETWORK, QBOT_MAKES_YOUR_GAMING_EXPERIENCE_MORE_ENGAGING_AND_INFORMATIVE")}
         </div>

         <div className="modal-topic mt-3">
            <span role="img" aria-label="sheep" className="modal-emoji">🎯</span>&nbsp;
            <span className='color-white'>{t("PUB_NUB_QUIZ_BOT.HOW_DOES_QBOT_WORK")}</span>
         </div>
         <div className="modal-Para">
            <ol>
               <li>{t("PUB_NUB_QUIZ_BOT.QUESTION_TIME")} {t("PUB_NUB_QUIZ_BOT.DURING_THE_MATCH, QBOT_WILL_PRESENT_YOU_WITH_QUESTIONS_RELATED_TO_THE_GAME")}</li>
               <li>{t("PUB_NUB_QUIZ_BOT.YOUR_INPUT")} {t("PUB_NUB_QUIZ_BOT.ANSWER_THE_QUESTIONS_AS_BEST_AS_YOU_CAN! NO_PRESSURE; IT'S_ALL_PART_OF_THE_FUN")}</li>
               <li>{t("PUB_NUB_QUIZ_BOT.RESULT_CRUNCHING")} {t("PUB_NUB_QUIZ_BOT.AFTER_YOU'VE_GIVEN_YOUR_ANSWERS, QBOT_WORKS_ITS_MAGIC_TO_DETERMINE_IF_YOU'RE_RIGHT_OR_WRONG")}</li>
               <li>{t("PUB_NUB_QUIZ_BOT.POINT_ACCUMULATION")} {t("PUB_NUB_QUIZ_BOT.THE_POINTS_YOU_EARN_FROM_YOUR_ANSWERS_ARE_SAVED_TO_YOUR_ACCOUNT")}</li>
               <li>{t("PUB_NUB_QUIZ_BOT.SUMMARY_REPORT")} {t("PUB_NUB_QUIZ_BOT.AT_THE_END_OF_THE_MATCH, YOU'LL_RECEIVE_A_CHAT_SUMMARY_OF_YOUR_ANSWERS_AND_POINTS_EARNED")}</li>
               <li>{t("PUB_NUB_QUIZ_BOT.EMAIL_RECAP")} {t("PUB_NUB_QUIZ_BOT.WANT_A_MORE_PERMANENT_RECORD? QBOT_WILL_ALSO_SEND_YOU_AN_EMAIL_SUMMARY_OF_YOUR_GAME_PERFORMANCE")}</li>
            </ol>
         </div>

         <div className="modal-topic mt-3">
            <span role="img" aria-label="sheep" className="modal-emoji">🌟</span>
            <span className='color-white'> {t("PUB_NUB_QUIZ_BOT.WHY_ENGAGE_WITH_QBOT")}</span>
         </div>
         <div className="modal-Para">
            <ul>
               <li>{t("PUB_NUB_QUIZ_BOT.MAKE_META_11_FANTASY_EXPERIENCE_MORE_INTERACTIVE_AND_FUN")}</li>
               <li>{t("PUB_NUB_QUIZ_BOT.GAIN_INVALUABLE_INSIGHTS_TO_BOOST_YOUR_GAME_UNDERSTANDING")}</li>
               <li>{t("PUB_NUB_QUIZ_BOT.CLIMB_THE_LEADERBOARD_WITH_YOUR_ACCRUED_POINTS")}</li>
               <li>{t("PUB_NUB_QUIZ_BOT.USE_THE_POINTS_SAVED_TO_YOUR_ACCOUNT_FOR_IN_GAME_REWARDS_LATER")}</li>
               <li>{t("PUB_NUB_QUIZ_BOT.WIN_REWARDS")}</li>
            </ul>
         </div>

         <div className="modal-topic mt-3">
            <span role="img" aria-label="sheep" className="modal-emoji">🚀</span>&nbsp;
            <span>
                            {t("PUB_NUB_QUIZ_BOT.ENGAGE_WITH_QBOT_AND_TAKE_YOUR_META_11_FANTASY_EXPERIENCE_TO_THE_NEXT_LEVEL. WIN_TODAY_WHILE_EARNING_POINTS_FOR_FUTURE_REWARDS")}
                        </span>
         </div>
      </div>
   )
}

export default HowToUseQBot;
