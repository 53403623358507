import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import i18Backend from 'i18next-http-backend';
import languageDetector from 'i18next-browser-languagedetector';

const resources = {
   en: {
      translation: {}
   },
   es: {
      translation: {}
   },
   hi: {
      translation: {}
   }
}
const language = localStorage.getItem("language") ? localStorage.getItem("language") : window.navigator.language.substr(0, 2);
i18n
   .use(i18Backend)
   .use(languageDetector)
   .use(initReactI18next)
   .init({
      resources,
      fallbackLng: `${language}`,
      debug: true,
      detection: {
         order: ['querystring', 'cookie'],
         cache: ['cookie']
      },
      interpolation: {
         escapeValue: false
      }
   })

const loadTranslations = async () => {
   try {
      if (language == "en") {
         const enResponse = await fetch('/localization/en/translations.json');
         // const esResponse = await fetch('/localization/es/translations.json');

         const enTranslations = await enResponse.json();
         // const esTranslations = await esResponse.json();

         resources.en.translation = enTranslations;

      } else if (language == "hi") {
         const hiResponse = await fetch('/localization/hi/translations.json');
         // const esResponse = await fetch('/localization/es/translations.json');

         const hiTranslations = await hiResponse.json();
         // const esTranslations = await esResponse.json();

         resources.hi.translation = hiTranslations;

      }
      i18n.init({resources});
   } catch (error) {
      console.error('Error loading translations:', error);
   }

   if (localStorage.getItem("i18nextLng")) {
      let language = localStorage.getItem("i18nextLng");
      localStorage.removeItem("i18nextLng");
      localStorage.setItem("language", language);
   } else {

   }
};

loadTranslations();


export default i18n;
