import React, {useEffect, useState} from "react";
import Cropper from "react-easy-crop";
import Form from 'react-bootstrap/Form';
import getCroppedImg, {generateDownload} from "./utils/cropImage";
import styles from "./ImageCrop.module.scss";
import Classnames from "classnames";
import * as UserService from "../../services/UserService";
import {Puff} from "react-loading-icons";
import {ASSET_UPLOAD_TYPES, PIC_UPLOAD_SIZES} from "../../utilities/Constants";
import {toast} from "react-toastify";
import {COMMUNITY_TOASTS} from "../../utilities/ToastMessages";
import {useTranslation} from "react-i18next";

export default function ImageCrop(props) {
   const inputRef = React.useRef();
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const triggerFileSelectPopup = () => inputRef.current.click();

   const [image, setImage] = React.useState(null);
   const [croppedArea, setCroppedArea] = React.useState(null);
   const [crop, setCrop] = React.useState({x: 0, y: 0});
   const [zoom, setZoom] = React.useState(1);
   const [invalidFileUpload, setInvalidFileUpload] = React.useState(false);
   const [showProgress, setShowProgress] = React.useState(false);
   const [uploadButtonDisable, setUploadButtonDisable] = React.useState(false);

   useEffect(() => {
      onSelectFile()
   }, []);

   const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
      setCroppedArea(croppedAreaPixels);
   };

   const onSelectFile = (event) => {
      if (props.type == ASSET_UPLOAD_TYPES.COVER) {
         if (props.image && bytesToMegaBytes(props.image.size) <= PIC_UPLOAD_SIZES.COVER) {
            const reader = new FileReader();
            reader.readAsDataURL(props.image);
            reader.addEventListener("load", () => {
               setImage(reader.result);
            });
         } else if (bytesToMegaBytes(props.image.size) > PIC_UPLOAD_SIZES.COVER) {
            props.onCropModalHide(false, false, true)
         }
      } else if (props.type == ASSET_UPLOAD_TYPES.PROFILE) {
         if (props.image && bytesToMegaBytes(props.image.size) <= PIC_UPLOAD_SIZES.PROFILE) {
            const reader = new FileReader();
            reader.readAsDataURL(props.image);
            reader.addEventListener("load", () => {
               setImage(reader.result);
            });
         } else if (bytesToMegaBytes(props.image.size) > PIC_UPLOAD_SIZES.PROFILE) {
            props.onCropModalHide(false, false, true)
         }
      }

   };

   const onDownload = () => {
      generateDownload(image, croppedArea);
   };

   const bytesToMegaBytes = bytes => bytes / (1024 * 1024);

   const onFileUpload = async () => {
      setUploadButtonDisable(true)
      setShowProgress(true);

      try {
         if (!image || !croppedArea) {
            return;
         }
         const canvas = await getCroppedImg(image, croppedArea);

         canvas.toBlob(
            (blob) => {

               const file = new File([blob], "image.jpg", {type: blob.type});

               UserService.assetUpload(file, onUploadProgress, props.type, props.email).then((response) => {
                  if (response.apiStatus) {
                     let user = {};
                     props.onCropModalHide(false, true, false, response.blob.fileUrl, props.type);
                     setShowProgress(false);
                  } else {
                     toast.error(COMMUNITY_TOASTS.ASSET_UPLOAD_FAILED_PLEASE_TRY_AGAIN);
                  }
               })
            },
            props.image.type,
            0.66
         );
      } catch (error) {
         toast.error(error);
      }
   }

   const onUploadProgress = (progressEvent) => {

   };

   return (
      <div className={styles.container}>
         <div className={styles.containerCropper}>
            {image ? (
               <>
                  <div className={styles.cropper}>
                     <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={props.type == ASSET_UPLOAD_TYPES.COVER ? 3.48 : 1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                     />
                  </div>
                  <div className="mt-4">
                     <Form.Range value={zoom} step={0.1} min={1} max={3}
                                 onChange={(e) => setZoom(parseFloat(e.target.value))}/>
                  </div>
               </>
            ) : null}
         </div>

         <div className={styles.modalContent}>
            {showProgress === true ?
               <div className={"pb-2 pt-2 mt-5 float-start w-100"}>

                  <button type="button" onClick={onFileUpload} disabled={true} className={styles.updatingBtn} style={{width: "60%"}}>{t("CREATE_ACCOUNT.UPDATING")}..<Puff height={'2em'} strokeWidth={5} speed={2}/>
                  </button>
               </div> : <>

                  <div className={Classnames("mt-5", "row", "float-start", "w-100")}>
                     <div className="col-6" style={{paddingRight: 10}}>
                        <button type="button" style={{width: "100%"}}
                                onClick={() => props.onCropModalHide(false, false, false, "", props.type)}
                                className={Classnames(styles.BlackSkBtn)}>
                           <text>{t("CREATE_ACCOUNT.CANCEL")}</text>
                        </button>
                     </div>
                     <div className="col-6" style={{paddingRight: 10}}>
                        <button type="button" style={{width: "100%"}}
                                onClick={onFileUpload}
                                disabled={invalidFileUpload || uploadButtonDisable}
                                className={Classnames(styles.LoginButton)}>{t("CREATE_ACCOUNT.UPLOAD")}
                        </button>
                     </div>

                  </div>
               </>
            }
         </div>
      </div>
   );
}
