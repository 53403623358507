import React from "react";
import styles from "./FantasyPointsTable.module.scss";
import {t} from "i18next";
import classNames from "classnames";

const FantasyPointsTable = () => {
   const battersRuns = {
      0: {name: '0', points: -5},
      1: {name: '1', points: 1},
      2: {name: '2', points: 2},
      3: {name: '3', points: 3},
      4: {name: '4', points: 4},
      6: {name: '6', points: 5},
   }

   const batterStrikeRate = {
      over_125: {name: t("FANTASY_POINTS_TABLE.OVER_125"), points: 5},
      over_150: {name: t("FANTASY_POINTS_TABLE.OVER_150"), points: 10},
   }

   const bowlerStats = {
      wicket: {name: t("FANTASY_POINTS_TABLE.WICKETS"), points: 7.5},
      maiden_over: {name: t("FANTASY_POINTS_TABLE.MAIDEN_OVER"), points: 2.5},
      hat_trick: {name: t("FANTASY_POINTS_TABLE.HAT_TRICK"), points: 5},
      economy_rate_4: {name: t("FANTASY_POINTS_TABLE.ECONOMY_RATE_4_RUNS_PER_OVER"), points: 5},
      economy_rate_5: {name: t("FANTASY_POINTS_TABLE.ECONOMY_RATE_5_RUNS_PER_OVER"), points: 2.5},
      wide: {name: t("FANTASY_POINTS_TABLE.WIDE"), points: -1},
   }

   const getTable = (head, data) => <table>
      <thead>
      <tr>
         <th>{head}</th>
         <th>{t("FANTASY_POINTS_TABLE.POINTS_IF_PLAYER")}</th>
         <th>{t("FANTASY_POINTS_TABLE.POINTS_IF_CAPTAIN")}</th>
         <th>{t("FANTASY_POINTS_TABLE.POINTS_IF_VICE_CAPTAIN")}</th>
      </tr>
      </thead>
      <tbody>
      {Object.keys(data).map((key) => (<tr key={key}>
         <td>{data[key].name}</td>
         <td>{data[key].points}</td>
         <td>{data[key].points * 2}</td>
         <td>{data[key].points * 1.5}</td>
      </tr>))}
      </tbody>
   </table>


   return (<div className={classNames(styles.fantasyPointsTable, "fantasyPointsTable")}>
      <div className={classNames(styles.PointsTableWrapper)}>{getTable(t("FANTASY_POINTS_TABLE.BATTERS_RUNS"), battersRuns)}</div>
      <div className={classNames(styles.PointsTableWrapper)}>{getTable(t("FANTASY_POINTS_TABLE.BATTERS_STRIKE_RATE"), batterStrikeRate)}</div>
      <div className={classNames(styles.PointsTableWrapper)}>{getTable(t("FANTASY_POINTS_TABLE.BOWLERS_STATS"), bowlerStats)}</div>
   </div>);
}

export default FantasyPointsTable;
