import {calculateTimeLeft} from "../../helpers/CommonFunctions";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./TournamentTimer.module.scss";
import classNames from "classnames";
import {CountdownCircleTimer} from "react-countdown-circle-timer";

const TournamentTimer = ({metaTimer, tournamnetEndTimer}) => {
   let timeLeftConst = {minutes: 0, hours: 0, days: 0, seconds: 0};
   timeLeftConst = calculateTimeLeft(metaTimer);
   const checkIfPassed = () => {
      const currentTime = new Date();
      const targetDate = new Date(tournamnetEndTimer);
      if (currentTime > targetDate) {
         return true;
      } else {
         return false;
      }
   };
   const timePassed = checkIfPassed();
   const [timeLeft, setTimeLeft] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});
   const [remainingTimerDays, setRemainingTimerDays] = useState(0);
   const [remainingTimerHours, setRemainingTimerDaysHours] = useState(0);
   const [remainingTimerMinutes, setRemainingTimerMinutes] = useState(0);
   const [remainingTimerSeconds, setRemainingTimerSeconds] = useState(0);

   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   const renderTimeDays = ({remainingTime}) => {
      setRemainingTimerDays(remainingTime);
   }
   const renderTimeHours = ({remainingTime}) => {
      setRemainingTimerDaysHours(remainingTime);
   }
   const renderTimeMinutes = ({remainingTime}) => {
      setRemainingTimerMinutes(remainingTime);
   }
   const renderTimeSeconds = ({remainingTime}) => {
      setRemainingTimerSeconds(remainingTime);
   }

   useEffect(() => {
      setTimeLeft(timeLeftConst);
   }, []);

   useEffect(() => {
      const timer = setInterval(() => {
         if (timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0) {
            clearInterval(timer);
         } else {
            setTimeLeft(timeLeftConst);
         }
      }, 1000);

      return () => {
         clearInterval(timer);
      };
   }, [timeLeft]);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);

   return (
      <div className="row">
         {/*<div className={classNames(styles.nextTournamentLeftCol,"col-md-6 order-md-2")}>*/}
         {/*</div>*/}
         <div className={classNames(styles.nextTournamentCol, "col-12 order-md-2 p-0")}>
            <div className={classNames(styles.nextTournamentWrapper)}>
               {/*{*/}

               {/*   timePassed ? <h2>{t("HOME_PAGE.TOURNAMENT_IS_OVER")}</h2> :*/}
               {/*      timeLeft.days <= 0 && timeLeft.hours <= 0 && timeLeft.minutes <= 0 && timeLeft.seconds <= 0 ?*/}
               {/*         <h2>{t("HOME_PAGE.TOURNAMENT_IS_ONGOING")}</h2> :*/}
               {/*         <h2>{t("HOME_PAGE.NEXT_TOURNAMENT")}</h2>}*/}
               <div className={classNames(styles.countdown)}>
                  {timeLeft.days > 0 ?
                     <div>
                        <div className={classNames(styles.cercalBack)}>
                           <div className={classNames(styles.cercal)}>
                              <span>{String(timeLeft.days).padStart(2, '0')}</span>
                           </div>
                        </div>
                        <span>{t("HOME_PAGE.DAYS")}</span>
                        <div className={classNames(styles.timerWrapper)}>
                           {timeLeft.days > 0 ? <CountdownCircleTimer
                              isPlaying={timeLeft.days != remainingTimerDays}
                              duration={30}
                              updateInterval={60}
                              strokeWidth={timeLeft.days < 0 ? 0 : 8}
                              initialRemainingTime={timeLeftConst.days}
                              strokeLinecap="round"
                              rotation="counterclockwise"
                              isSmoothColorTransition={true}
                              colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                              colorsTime={[10, 6, 3, 0]}
                              onComplete={() => ({shouldRepeat: true, delay: 1})}>
                              {renderTimeDays}
                           </CountdownCircleTimer> : <></>}
                        </div>
                     </div> : <></>}

                  <div>
                     <div className={classNames(styles.cercalBack)}>
                        <div className={classNames(styles.cercal)}>
                           <span>{timeLeft.minutes >= 0 ? <> {String(timeLeft.hours).padStart(2, '0')}</> : "00"}</span>
                        </div>
                     </div>
                     <span>{t("HOME_PAGE.HOURS")}</span>
                     <div className={classNames(styles.timerWrapper)}>
                        {timeLeftConst.hours > 0 ? <CountdownCircleTimer
                           isPlaying={timeLeft.hours != remainingTimerHours}
                           initialRemainingTime={timeLeftConst.hours}
                           duration={24}
                           updateInterval={60}
                           strokeWidth={timeLeft.hours < 0 ? 0 : 8}
                           strokeLinecap="round"
                           rotation="counterclockwise"
                           isSmoothColorTransition={true}
                           colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                           colorsTime={[10, 6, 3, 0]}
                           onComplete={() => ({shouldRepeat: true, delay: 1})}>
                           {renderTimeHours}
                        </CountdownCircleTimer> : <></>}
                     </div>
                  </div>
                  <div>
                     <div className={classNames(styles.cercalBack)}>
                        <div className={classNames(styles.cercal)}>
                           <span>{timeLeft.minutes >= 0 ? <> {String(timeLeft.minutes).padStart(2, '0')}</> : "00"}</span>
                        </div>
                     </div>
                     <span>{t("HOME_PAGE.MINUTES")}</span>
                     <div className={classNames(styles.timerWrapper)}>
                        {timeLeftConst.minutes > 0 ? <CountdownCircleTimer
                           isPlaying={timeLeft.minutes != remainingTimerMinutes}
                           initialRemainingTime={timeLeftConst.minutes}
                           duration={60}
                           updateInterval={60}
                           strokeWidth={timeLeft.minutes < 0 ? 0 : 8}
                           strokeLinecap="round"
                           rotation="counterclockwise"
                           isSmoothColorTransition={true}
                           colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                           colorsTime={[10, 6, 3, 0]}
                           onComplete={() => ({shouldRepeat: true, delay: 1})}>
                           {renderTimeMinutes}
                        </CountdownCircleTimer> : <></>}
                     </div>
                  </div>
                  {timeLeft.days <= 0 ?
                     <div>
                        <div className={classNames(styles.cercalBack)}>
                           <div className={classNames(styles.cercal)}>
                              <span>{timeLeft.minutes >= 0 ? <> {String(timeLeft.seconds).padStart(2, '0')}</> : "00"}</span>
                           </div>
                        </div>
                        <span>{t("HOME_PAGE.SECONDS")}</span>
                        <div className={classNames(styles.timerWrapper)}>
                           {timeLeft.seconds > 0 ? <CountdownCircleTimer
                              isPlaying={timeLeft.seconds != remainingTimerSeconds}
                              duration={60}
                              updateInterval={1}
                              strokeWidth={timeLeft.seconds < 0 ? 0 : 8}
                              initialRemainingTime={timeLeftConst.seconds}
                              strokeLinecap="round"
                              rotation="counterclockwise"
                              isSmoothColorTransition={true}
                              colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                              colorsTime={[10, 6, 3, 0]}
                              onComplete={() => ({shouldRepeat: true, delay: 0})}>
                              {renderTimeSeconds}
                           </CountdownCircleTimer> : <></>}
                        </div>
                     </div> : <></>}
               </div>
            </div>
         </div>
      </div>
   )
}

export default TournamentTimer;
