import React, {useEffect, useState} from "react";
import Styles from "./CreateAccount.module.scss";
import Classnames from "classnames";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ASSET_UPLOAD_TYPES, PIC_UPLOAD_SIZES} from "../../utilities/Constants";
import * as FileService from "../../services/FileService"
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import ImageCrop from "../../components/ImageCrop/ImageCrop";
import VerifyCodeV2 from "../Register/VerifyCode";
import {toast} from "react-toastify";
import {USER_PROFILE_TOASTS} from "../../utilities/ToastMessages";
import {birthDayValidator} from "../../helpers/CommonFunctions";
import {useTranslation} from "react-i18next";

const inputRefs = [];

const defaultUser = {
   bio: "",
   coverPicURL: "",
   dob: "",
   facebook: "",
   firstName: "",
   instagram: "",
   lastName: "",
   mobile: "",
   isPrivate: false,
   profilePicURL: null,
   twitter: "",
   tiktok: ""
}

const CreateAccount = (props) => {
   const location = useLocation();
   const data = location.state;
   let navigate = useNavigate();
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
         }}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);

   const [coverPhotoUploaded, setCoverPhotoUploaded] = useState(false)
   const [profilePhotoUploaded, setProfilePhotoUploaded] = useState(false)
   const [user, setUser] = useState(defaultUser)
   const [Image, setImage] = useState(null)
   const [radioCheck1, setRadioCheck1] = useState(true)
   const [radioCheck2, setRadioCheck2] = useState(false)
   const [maxFileSizeExceed, setMaxFileSizeExceed] = useState(false)
   const [modalStatus, setModalStatus] = useState(false)
   const [type, setType] = useState(null)
   const [confirmAssetDelete, setConfirmAssetDelete] = useState(false)
   const [confirmSubmission, setConfirmSubmission] = useState(true)
   const [showModal, setShowModal] = useState(false)
   const [isSkip, setIsSkip] = useState(false)
   const [dateFormatError, setDateFormatError] = useState('')
   const [saveChangesError, setSaveChangesError] = useState(true);
   const [focusProfilePic, setFocusProfilePic] = useState(false)
   const [focusFullName, setFocusFullName] = useState(false)
   const [birthDate, setBirthDate] = useState({
      day: '',
      month: '',
      year: ''
   })

   //email and password checker

   useEffect(() => {
      if (data == null || props.Token != null || props.Token != undefined) {
         navigate("/");
      }
   }, [])

   useEffect(() => {
      if (isNaN(birthDate.day) || birthDate.day > 31) {
         setBirthDate({...birthDate, day: ''})
      } else if (isNaN(birthDate.month) || birthDate.month > 12) {
         setBirthDate({...birthDate, month: ''})
      } else if (isNaN(birthDate.year)) {
         setBirthDate({...birthDate, year: ''})
      }
      if (birthDate.day.length == 2 && birthDate.month.length == 2 && birthDate.year.length == 4) {
         let valid = birthDayValidator(birthDate.day, birthDate.month, birthDate.year);
         if (valid) {
            setDateFormatError("");
            setUser({
               ...user, dob: `${birthDate.year}-${birthDate.month}-${birthDate.day}`
            })

         } else {
            setDateFormatError(t("CREATE_ACCOUNT.BIRTHDATE_IS_NOT_VALID"));
            setUser({
               ...user, dob: ""
            })
            setBirthDate({
               day: '',
               month: '',
               year: ''
            })
         }
      } else if (birthDate.day.length == 0 && birthDate.month.length == 0 && birthDate.year.length == 0) {
         setDateFormatError('');
         setUser({
            ...user, dob: ""
         })
      } else {
         setDateFormatError(t("CREATE_ACCOUNT.BIRTHDATE_IS_NOT_VALID"));
         setUser({
            ...user, dob: ""
         })
      }

   }, [birthDate]);

   useEffect(() => {
      if (user?.firstName == "" || profilePhotoUploaded == false) {
         setSaveChangesError(true);
      } else {
         setSaveChangesError(false);
      }
   }, [user?.firstName, profilePhotoUploaded])

   const handleModalClose = (childResponse) => {
      setIsSkip(false)
      setShowModal(childResponse)
   }
   const onFullNameChange = (e) => {
      setUser({
         ...user, firstName: e.target.value
      })
   }
   const onBioChange = (e) => {
      setUser({
         ...user, bio: e.target.value
      })
   }
   const onPrivacyChange = (type) => {
      setUser({
         ...user, isPrivate: type === "private"
      })
   }
   const onSocialProfileChange = (e, type) => {
      setUser({
         ...user, [type]: e.target.value
      })
   }

   const onDobChange = (date) => {
      setUser({
         ...user, dob: date
      })
   }

   const onCropModalHide = (modalStatus, uploadStatus, maxFileSizeExceed, fileUrl, type) => {
      if (uploadStatus) {

         setConfirmSubmission(true)
         if (type == ASSET_UPLOAD_TYPES.PROFILE) {
            setProfilePhotoUploaded(true)
            setUser({
               ...user, profilePicURL: fileUrl
            })
         } else if (type == ASSET_UPLOAD_TYPES.COVER) {
            setCoverPhotoUploaded(true)
            setUser({
               ...user, coverPicURL: fileUrl
            })
         }

         setModalStatus(false)
      }
      if (modalStatus == false) {
         setConfirmSubmission(true)
         setImage(null)
      }
      if (maxFileSizeExceed) {
         setMaxFileSizeExceed(true)
      }

   }
   const getCoverImage = (e) => {
      setImage(e.target.files[0])
      setType(ASSET_UPLOAD_TYPES.COVER)
      setConfirmSubmission(false)
      // uploadCoverPic(e.target.files[0])
   }
   const getProfileImage = (e) => {
      setImage(e.target.files[0])
      setType(ASSET_UPLOAD_TYPES.PROFILE)
      setConfirmSubmission(false)
   }
   const deleteCoverPhoto = (type) => {
      setType(type)
      setConfirmAssetDelete(true)
   }
   const deleteAsset = async () => {
      let image
      if (type == ASSET_UPLOAD_TYPES.PROFILE) {
         image = user.profilePicURL
      } else if (type == ASSET_UPLOAD_TYPES.COVER) {
         image = user.coverPicURL
      }
      const imageName = image.split('/').pop();
      if (imageName.endsWith('.png') || imageName.endsWith('.jpg') || imageName.endsWith('.jpeg')) {
         const deleteAssetResponse = FileService.deleteAssetWithoutToken(imageName, type, data.email);
         deleteAssetResponse.then((response) => {
            if (response.apiStatus) {
               if (type == ASSET_UPLOAD_TYPES.COVER) {
                  setCoverPhotoUploaded(false)
                  setUser({
                     ...user, coverPicURL: null
                  })
               } else if (type == ASSET_UPLOAD_TYPES.PROFILE) {
                  setProfilePhotoUploaded(false)

                  setUser({
                     ...user, profilePicURL: null
                  })
               }
               setConfirmAssetDelete(false)
            } else {
               console.log("Error Deleting " + Image)
            }
         }).catch(e => {
            if (type == ASSET_UPLOAD_TYPES.PROFILE) {
               toast.error(USER_PROFILE_TOASTS.PROFILE_IMAGE_DELETE_ERROR)
            } else if (type == ASSET_UPLOAD_TYPES.COVER) {
               toast.error(USER_PROFILE_TOASTS.COVER_IMAGE_DELETE_ERROR)
            }
         })
      } else {
         toast.error(USER_PROFILE_TOASTS.GOOGLE_ASSIGNED_IMAGE_DELETE)
      }
   }

   const closeModal = () => {
      setImage(null)
      setMaxFileSizeExceed(false);
      setConfirmSubmission(true)
   }
   const closeModalDelete = () => {
      setConfirmAssetDelete(false)
   }
//drag and drop section for cover Image


   const handleDragEnter = (e) => {
      e.preventDefault();
   }
   const handleDragLeave = (e) => {
      e.preventDefault();
   }

   const handleDragOver = (e) => {
      e.preventDefault();
   }

   const handleDrop = (e) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length > 0) {
         const file = files[0];
         setImage(file);
         setType(ASSET_UPLOAD_TYPES.COVER)
         setConfirmSubmission(false)
      }
   }
   const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
   };

   const saveChangesHandler = () => {
      setIsSkip(false)
      setShowModal(true)
   }

   const handleInputError = (errMessage) => {
      // Here you can handle the input error.
      // For example, you can display an error message to the user or reset the input field.
      console.error(`Date input error: ${errMessage}`);
   };

   // const handleInputChange = (e, index) => {
   //   const inputValue = e.target.value;
   //   const inputLength = inputValue.length;
   //
   //   // Move focus to next input field when a digit is entered and input length is 1
   //   if (inputLength === 2 && index < inputRefs.length - 1) {
   //     inputRefs[index + 1].focus();
   //   }
   //   const updatedNumber = inputRefs.map((input) => input.value).join('');
   //   const date = updatedNumber.slice(0,2);
   //   const month = updatedNumber.slice(2,4);
   //   const year = updatedNumber.slice(4,8);
   //
   //   if(birthDayValidator(date, month, year)){
   //     setDateFormatError("Birthdate is not valid");
   //   }else {
   //     setDateFormatError('');
   //   }
   // };
   if (!translationsLoaded) {
      return loadingIndicator;
   }

   return (
      <React.Fragment>

         <div className={Classnames(Styles.Radial1)}></div>
         <div className={Classnames(Styles.Radial2)}></div>
         <div className={Classnames(Styles.Radial3)}></div>
         <div className={Classnames(Styles.Radial4)}></div>
         <div className={Classnames(Styles.ResponceCont, "col-12", "row", "ps-2", "pe-2", "ps-xxl-0", "pe-xxl-0", "mb-3")}>

            <div className="row p-0">
               <div className={Classnames("pt-5", "pb-5", "col-6", "p-0")}>
                  <Link to={"/"}>
                     <img src="https://storage.googleapis.com/meta11/serviceplatform/M11FantasyBetaLogo.webp" alt=""
                          className={Classnames(Styles.loginLogo)} style={{height: '54px', width: '197px'}}/>
                  </Link>
               </div>
               <div className="pt-5 pb-5 col-6  d-none d-sm-none d-md-flex p-0 text-end">
                  <text className={Classnames("body-text3 color-gray2", "w-100")}>{t("CREATE_ACCOUNT.ALREADY_HAVE_AN_ACCOUNT")} <a href="/login" className="color-textturquiose ps-2">
                     {t("CREATE_ACCOUNT.CLICK_TO_SIGN_IN")}</a></text>
               </div>
            </div>
            <div className="row ps-md-3 pe-md-3 ps-sm-0 pe-sm-0 ps-2 pe-2 mt-0 mt-sm-0 mt-md-0 mt-lg-3">
               <div className="col-6 p-0 ">
                  <button type="submit" className={Classnames(Styles.BlackSkBtn)}
                          onClick={() => navigate("/register")}>
                     <text>{t("CREATE_ACCOUNT.BACK")}</text>
                  </button>
               </div>
               <div className="col-6 p-0 text-end"><input onClick={() => {
                  setIsSkip(true)
                  setShowModal(true)
               }} type="submit" value={t("CREATE_ACCOUNT.SKIP_FOR_NOW")} className={Classnames(Styles.NextButton)}/></div>
            </div>


            <div className={Classnames(Styles.innerWith, "p-0", "ms-auto", "me-auto", "mt-5", "row")}>
               {/*cover Upload*/}
               {coverPhotoUploaded ? <div className={Classnames(Styles.UploadDiv, "p-5")} style={{
                  background: `url(${user.coverPicURL})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "31vh"
               }}>
                  <div className={Styles.editSection}>
                     <label htmlFor="coverImageUpload" id="chooseFileLabel">
                        <i className={Classnames("fa-solid", "fa-pencil", "p-2", "m-2", Styles.faPlus)}></i>
                        <input type="file" accept="image/*" onChange={getCoverImage}
                               id="coverImageUpload"
                               onClick={(event) => {
                                  event.target.value = null
                               }}
                               className={Classnames("float-left", "mt-4", "float-right", Styles.uploadBigBtn)}
                               style={{display: "none"}}/>
                     </label>
                     <i className={Classnames("fa-solid", "fa-trash-can", "p-2", "m-2", Styles.faPlus)}
                        onClick={() => deleteCoverPhoto(ASSET_UPLOAD_TYPES.COVER)}></i>
                  </div>
               </div> : <div className={Classnames(Styles.UploadDiv, "p-lg-5 p-md-3 p-sm-3 p-3")} onDragEnter={handleDragEnter}
                             onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
                  <div className="mb-4 text-center"><img
                     src="https://storage.googleapis.com/meta11/serviceplatform/image-big-icon.png"/></div>
                  <div className={Classnames("justify-content-center d-none d-sm-none d-md-flex body-text1 color-white")}>
                     {t("CREATE_ACCOUNT.DRAG_AND_DROP_A_COVER_IMAGE_OR")} <label htmlFor="coverImageUpload" id="chooseFileLabel" className={(Styles.cursor, "color-textturquiose ps-2")}
                  >&nbsp;{t("CREATE_ACCOUNT.BROWSE")}</label>&nbsp;{t("CREATE_ACCOUNT.(OPTIONAL)")}
                  </div>
                  <div className={Classnames(Styles.Text22, "justify-content-center d-flex d-sm-flex d-md-none mb-2")}><label htmlFor="coverImageUpload" id="chooseFileLabel" className={Styles.cursor}
                                                                                                                              style={{color: '#17EA85', marginRight: 5}}>{t("CREATE_ACCOUNT.UPLOAD")}</label>{t("CREATE_ACCOUNT.COVER_IMAGE(OPTIONAL)")}
                  </div>
                  <input type="file" accept="image/*" onChange={getCoverImage}
                         id="coverImageUpload"
                         onClick={(event) => {
                            event.target.value = null
                         }}
                         className={Classnames("float-left", "mt-4", "float-right", Styles.uploadBigBtn)}
                         style={{display: "none"}}/>
                  <div className={Classnames(Styles.Text16, "text-center")}><span className={Classnames(Styles.mobhalf, "body-text3 color-gray2")}><img
                     src="https://storage.googleapis.com/meta11/serviceplatform/gray-curcle-icon.png"/>&nbsp;
                     {t("CREATE_ACCOUNT.MAX_20_MB")}</span>
                     <span className={Classnames(Styles.mobhalf, "body-text3 color-gray2")}><img
                        src="https://storage.googleapis.com/meta11/serviceplatform/gray-curcle-icon.png"/>&nbsp;
                        {t("CREATE_ACCOUNT.ASPECT_RATIO_16_9")}</span>
                     <span className={Classnames(Styles.mobfull, "body-text3 color-gray2")}><img
                        src="https://storage.googleapis.com/meta11/serviceplatform/gray-curcle-icon.png"/>&nbsp;
                        {t("CREATE_ACCOUNT.RECOMMENDED_SIZE_1024_X_576")}</span>
                  </div>
               </div>
               }
               <div className="row col-12 p-0 m-0 d-flex justify-content-md-center justify-content-sm-center justify-content-center">
                  {/*profile Upload*/}
                  {profilePhotoUploaded ? <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 row col-7 mt-5 p-0 d-flex justify-content-md-center justify-content-sm-center justify-content-center">
                     <div className={Classnames(Styles.UploadDiv, "p-4", "m-0")}
                          style={{background: `url(${user.profilePicURL})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", maxHeight: "182px", maxWidth: "184px", minHeight: "182px", minWidth: "184px"}}>
                        <div className={Styles.editSectionProfile}>
                           <label htmlFor="profileImageUpload" id="chooseFileLabel" className="ps-0">
                              <i className={Classnames("fa-solid", "fa-pencil", "p-2", "m-1", Styles.faPlus)}></i>
                              <input type="file" accept="image/*" onChange={getProfileImage}
                                     id="profileImageUpload"
                                     onClick={(event) => {
                                        event.target.value = null
                                     }}
                                     className={Classnames("float-left", "mt-4", "float-right", Styles.uploadBigBtn)}
                                     style={{display: "none"}}/>
                           </label>
                           <i className={Classnames("fa-solid", "fa-trash-can", "p-2", "m-1", Styles.faPlus)}
                              onClick={() => deleteCoverPhoto(ASSET_UPLOAD_TYPES.PROFILE)}></i>
                        </div>
                     </div>
                  </div> : <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-7 mt-5 p-0 d-flex  justify-content-md-center justify-content-sm-center justify-content-center">
                     <label htmlFor="profileImageUpload"
                            id="chooseFileLabel" className="ps-0">
                        <div className={Classnames(Styles.UploadDiv, "p-4", "m-0")}>
                           <div className="mb-4 text-center">
                              <img
                                 src="https://storage.googleapis.com/meta11/serviceplatform/image-big-icon.png"
                                 style={{width: '38px'}}/>
                           </div>
                           <div className={Classnames("body-text3 color-white")} style={{textAlign: "center"}}>
                              {t("CREATE_ACCOUNT.UPLOAD_PROFILE_IMAGE(REQUIRED)")}
                           </div>
                           <input onFocus={() => setFocusProfilePic(true)} onBlur={() => setFocusProfilePic(false)} type="file" accept="image/*" onChange={getProfileImage}
                                  id="profileImageUpload"
                                  onClick={(event) => {
                                     event.target.value = null
                                  }}
                                  className={Classnames("float-left", "mt-4", "float-right", Styles.uploadBigBtn)}
                                  style={{display: "none"}}/>
                           <div className={Classnames("pt-2 body-text5 color-gray2")} style={{textAlign: "center"}}>
                              {t("CREATE_ACCOUNT.PNG_OR_JPEG_FILE_SIZE_UP_TO_5_MB")}
                           </div>
                        </div>
                        <text className={Classnames(Styles.TextError)}>{(!coverPhotoUploaded && focusProfilePic) ? t("CREATE_ACCOUNT.PLEASE_UPLOAD_A_PROFILE_IMAGE") : ""}</text>
                     </label>

                  </div>}
                  {/*PERSONAL DETAILS*/}
                  <div className="row col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 p-0 mt-5 m-0 ps-0">
                     <div className={Classnames("text-start ps-lg-3 pe-lg-0 p-md-0 p-sm-0 p-0 heading3 color-white extra-bold italic")}>{t("CREATE_ACCOUNT.PERSONAL_DETAILS")}</div>
                     <div className={Classnames("text-start ps-lg-3 pe-lg-0 p-md-0 p-sm-0 p-0", "mt-sm-0 mt-md-0 mt-lg-3 mt-0 body-text2 color-gray2")}>{t("CREATE_ACCOUNT.ENTER_YOUR_DETAILS_SUCH_AS_YOUR_FULL_NAME_DATE_OF_BIRTH_AND_BIO")}</div>
                     <div className={Classnames("text-start", "mt-4 ps-lg-0 pe-lg-0 p-md-0 p-sm-0 p-0", "row")}>
                        <div className={Classnames(Styles.group, "col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6  ps-lg-3 pe-lg-0 p-md-0 p-sm-0 p-0 p-md-0 p-sm-0 p-0 mb-3")}>
                           <input type="text" name="name" required maxLength="20" onChange={onFullNameChange} onFocus={() => setFocusFullName(true)} onBlur={() => setFocusFullName(false)} style={{maxWidth: "100%"}}/>
                           <label>{t("CREATE_ACCOUNT.FULL_NAME")}</label>
                           <text className={Classnames(Styles.TextError)}>{(focusFullName && user?.firstName == "") ? t("CREATE_ACCOUNT.PLEASE_ENTER_YOUR_FULL_NAME") : ""}</text>
                        </div>
                        <div className={Classnames("float-start col-12 col-lg-5 p-0")}>


                           {/*<DatePicker >*/}

                           <div className="mt-0 p-0">
                              <div className={Classnames(Styles.group, "float-start ms-0 ms-md-1")} style={{width: "30%"}}>
                                 <input
                                    type="text"
                                    name="day"
                                    placeholder=""
                                    size="2"
                                    autoComplete="off"
                                    value={birthDate.day}
                                    maxLength={2}
                                    className={Classnames(Styles.NumIn)}
                                    onChange={(e) => {
                                       setBirthDate({...birthDate, day: e.target.value})
                                    }}
                                 />

                                 <label style={{fontSize: "12px", top: "10px", padding: "0", left: "20%"}}>DD</label>
                              </div>
                              <div className={Classnames(Styles.group, "float-start ms-1")} style={{width: "30%"}}>
                                 <input
                                    type="text"
                                    name="month"
                                    placeholder=""
                                    size="2"
                                    autoComplete="off"
                                    value={birthDate.month}
                                    maxLength={2}
                                    className={Classnames(Styles.NumIn)}
                                    onChange={(e) => {
                                       setBirthDate({...birthDate, month: e.target.value})
                                    }}
                                 />
                                 <label style={{fontSize: "12px", top: "10px", padding: "0", left: "20%"}}>MM</label>
                              </div>
                              <div className={Classnames(Styles.group, "float-start ms-1")} style={{width: "34%"}}>
                                 <input
                                    type="text"
                                    name="year"
                                    placeholder=""
                                    size="2"
                                    autoComplete="off"
                                    value={birthDate.year}
                                    maxLength={4}
                                    className={Classnames(Styles.NumIn)}
                                    onChange={(e) => {
                                       setBirthDate({...birthDate, year: e.target.value})
                                    }}
                                 />
                                 <label style={{fontSize: "12px", top: "10px", padding: "0", left: "20%"}}>YYYY</label>
                              </div>
                           </div>
                           <text className={Classnames(Styles.TextError)}>{dateFormatError}</text>
                        </div>
                     </div>
                     <div className={Classnames("text-start", "mt-lg-4 mt-md-0 mt-sm-0 mt-0", "row", "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ps-lg-0 pe-lg-0 p-md-0 p-sm-0 p-0 p-md-0 p-sm-0 p-0")}>
                        <div className={Classnames(Styles.group, "col-12 ps-lg-3 pe-lg-0 p-md-0 p-sm-0 p-0 p-md-0 p-sm-0 p-0")}>
                           <textarea maxLength="200" name="name" required onChange={onBioChange}/>
                           <label>{t("CREATE_ACCOUNT.TELL_SOMETHING_ABOUT_YOU_HERE")}</label>
                        </div>
                     </div>
                     {/*Privacy DETAILS*/}

                     <div className={Classnames("text-start", "mt-3 ps-lg-3 pe-lg-3 p-md-0 p-sm-0 p-0 mt-sm-0 mt-md-0 mt-lg-3 mt-0 color-white heading3 extra-bold italic")}>{t("CREATE_ACCOUNT.PRIVACY")}</div>
                     <div className={Classnames("text-start", "ps-lg-3 pe-lg-3 p-md-0 p-sm-0 p-0 mt-sm-0 mt-md-0 mt-lg-3 mt-0 body-text2 color-gray2")}>{t("CREATE_ACCOUNT.SELECT_YOUR_PROFILE_TYPE")}</div>

                     <div className={Classnames("row", "mt-4", "row ps-lg-3 pe-lg-0 p-md-0 p-sm-0 p-0",)}>
                        <div className="row col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ps-lg-0 pe-lg-3 p-md-0 p-sm-0 p-0 p-md-0 p-sm-0 p-0 mb-3">
                           <div className={Classnames(Styles.RadioBtnBox)}>
                              <div className={Classnames(Styles.LockBox)}>
                                 <img src="https://storage.googleapis.com/meta11/serviceplatform/unlock.png"/></div>
                              <div className={Classnames(Styles.LockText)}
                                   style={{paddingTop: "23px"}}>{t("CREATE_ACCOUNT.PUBLIC_PROFILE")}
                              </div>
                              <div className={Classnames(Styles.RadioBox)}>
                                 <input type="radio"
                                        name="privacy"
                                        checked={radioCheck1}
                                        id="exampleRadios1"
                                        class={Classnames("form-check-input", Styles.radioFill)}
                                        onClick={() => {
                                           onPrivacyChange("public")
                                           setRadioCheck1(true)
                                           setRadioCheck2(false)
                                        }}/>
                              </div>
                           </div>
                        </div>
                        <div className="row col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ps-lg-0 pe-lg-0 p-md-0 p-sm-0 p-0 p-md-0 p-sm-0 p-0 mb-3">
                           <div className={Classnames(Styles.RadioBtnBox, "row", "pl-1")}>
                              <div className={Classnames(Styles.LockBox)}><img src="https://storage.googleapis.com/meta11/serviceplatform/lock.png"/></div>
                              <div className={Classnames(Styles.LockText)}
                                   style={{paddingTop: "23px"}}>{t("CREATE_ACCOUNT.PRIVATE_PROFILE")}
                              </div>
                              <div className={Classnames(Styles.RadioBox)}>
                                 <input type="radio"
                                        name="privacy"
                                        checked={radioCheck2}
                                        id="exampleRadios2"
                                        class={Classnames("form-check-input", Styles.radioFill)}
                                        onClick={() => {
                                           onPrivacyChange("private")
                                           setRadioCheck1(false)
                                           setRadioCheck2(true)
                                        }}/>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className={Classnames("text-start", "mt-3 ps-lg-3 pe-lg-0 p-md-0 p-sm-0 p-0 mt-sm-0 mt-md-0 mt-lg-3 mt-0 color-white heading3 extra-bold italic")}>{t("CREATE_ACCOUNT.SOCIAL_PROFILES")}</div>
                     <div className={Classnames("text-start", "ps-lg-3 pe-lg-0 p-md-0 p-sm-0 p-0 mt-sm-0 mt-md-0 mt-lg-3 mt-0 body-text2 color-gray2")}>
                        {t("CREATE_ACCOUNT.ADD_YOUR_SOCIAL_NETWORKS_TO_YOUR_META_11_ACCOUNT")}
                     </div>

                     <div className={Classnames("row", "mt-4", "row ps-lg-3 pe-lg-0 p-md-0 p-sm-0 p-0",)}>
                        <div className="row col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ps-lg-0 pe-lg-3 p-md-0 p-sm-0 p-0 p-md-0 p-sm-0 p-0 mb-3">
                           <div className={Classnames(Styles.RadioBtnBox)}>
                              <div className={Classnames(Styles.LockBox)}><img
                                 src="https://storage.googleapis.com/meta11/serviceplatform/bxl-twitter.png"/>
                              </div>
                              <div className={Classnames(Styles.LockText2)}><input
                                 onChange={(e) => onSocialProfileChange(e, "twitter")} type="text"
                                 className={Classnames(Styles.SocialInput)}/>
                              </div>
                           </div>
                        </div>
                        <div className="row col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ps-lg-0 pe-lg-0 p-md-0 p-sm-0 p-0 p-md-0 p-sm-0 p-0 mb-3">
                           <div className={Classnames(Styles.RadioBtnBox, "row", "pl-1")}>
                              <div className={Classnames(Styles.LockBox)}><img
                                 src="https://storage.googleapis.com/meta11/serviceplatform/bxl-facebook-square.png"/>
                              </div>
                              <div className={Classnames(Styles.LockText2)}><input
                                 onChange={(e) => onSocialProfileChange(e, "facebook")} type="text"
                                 className={Classnames(Styles.SocialInput)}/>
                              </div>
                           </div>
                        </div>
                        <div className="row col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ps-lg-0 pe-lg-3 p-md-0 p-sm-0 p-0 p-md-0 p-sm-0 p-0 mb-3">
                           <div className={Classnames(Styles.RadioBtnBox)}>
                              <div className={Classnames(Styles.LockBox)}><img
                                 src="https://storage.googleapis.com/meta11/serviceplatform/bxl-instagram-alt.png"/>
                              </div>
                              <div className={Classnames(Styles.LockText2)}><input
                                 onChange={(e) => onSocialProfileChange(e, "instagram")} type="text"
                                 className={Classnames(Styles.SocialInput)}/></div>
                           </div>
                        </div>
                        <div className="row col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ps-lg-0 pe-lg-0 p-md-0 p-sm-0 p-0 p-md-0 p-sm-0 p-0 mb-3">
                           <div className={Classnames(Styles.RadioBtnBox, "row", "pl-1")}>
                              <div className={Classnames(Styles.LockBox)}><img
                                 src="https://storage.googleapis.com/meta11/serviceplatform/bxl-tiktok.png"/>
                              </div>
                              <div className={Classnames(Styles.LockText2)}><input
                                 onChange={(e) => onSocialProfileChange(e, "tiktok")} type="text"
                                 className={Classnames(Styles.SocialInput)}/></div>
                              {/*<div className={Classnames(Styles.RadioBox)}><input type="radio" name="Social" id="exampleRadios6" class="form-check-input"/></div>*/}
                           </div>
                        </div>
                     </div>
                     <div className={Classnames(Styles.saveChangesBtnRow, "")}>
                        {/*<div className="col-6 p-0 ">*/}
                        {/*    <button type="submit" className={Classnames(Styles.BlackSkBtn)}*/}
                        {/*            onClick={() => navigate("/register")}>*/}
                        {/*        <text>BACK</text>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        <div className="col"><input disabled={saveChangesError} onClick={saveChangesHandler} type="submit" value={t("CREATE_ACCOUNT.SAVE_CHANGES")} className={Classnames(Styles.NextButton)}/></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {maxFileSizeExceed ?
            <div className={Classnames("modal fade show", Styles.modalBlur)} id="exampleModalCenter"
                 tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
               <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className={Classnames("modal-content", "p-4", Styles.modalContentCustom)}>
                     <div style={{textAlign: "right"}}>
                        <button className={Classnames(Styles.closeButton, "close")} type="button" onClick={closeModal} data-dismiss="modal" aria-label="Close">
                           <span className={Styles.closeButton} aria-hidden="true"><img src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/></span>
                        </button>
                     </div>
                     <div className="modal-body pb-4 text-center">
                        <div className="text-center mb-4">
                           <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" fill="currentColor"
                                viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                    fill="red"/>
                              <path
                                 d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
                                 fill="red"/>
                           </svg>
                        </div>
                        <font className={Classnames(Styles.modalTitle, "w-100", "mb-3 pt-2 float-start")}
                              id="exampleModalCenterTitle">
                           {t("CREATE_ACCOUNT.OH_SNAP_FILE_SIZE_SHOULD_BE_LESS_THAN")} {type == ASSET_UPLOAD_TYPES.COVER ? PIC_UPLOAD_SIZES.COVER : PIC_UPLOAD_SIZES.PROFILE}MB
                        </font>
                        <div className={Classnames("mt-3 body-text3 color-gray2")}>
                           {t("CREATE_ACCOUNT.SELECTED_FILE_IS_TOO_LARGE_TRY_UPLOADING_A_FILE_WITH_A_SMALL_FILE_SIZE")}
                        </div>
                     </div>
                  </div>
               </div>
            </div> : <></>}
         {confirmAssetDelete ?
            <div className={Classnames("modal fade show", Styles.modalBlur)} id="exampleModalCenter"
                 tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
               <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className={Classnames("modal-content", Styles.modalContentCustom, "p-3")}>
                     <div className={Classnames("modal-header", Styles.modalFooterLine)}>
                        <div style={{display: "block", float: "left"}} className={"w-100"}>
                        </div>
                     </div>
                     <div style={{display: "block", textAlign: "center"}}>
                        <div className="text-center mb-2">
                           <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" fill="currentColor" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="red"/>
                              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="red"/>
                           </svg>
                        </div>
                        <font className={Classnames(Styles.modalTitle, "w-100", "mb-3")}
                              id="exampleModalCenterTitle">
                           {t("CREATE_ACCOUNT.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_PHOTO")}
                        </font>
                     </div>
                     <div className="p-3">
                        <button type="button" style={{float: "left", width: "49%"}} className={Styles.BlackSkBtn} onClick={closeModalDelete}>
                           <text>{t("CREATE_ACCOUNT.CANCEL")}</text>
                        </button>
                        <button type="button" style={{float: "right", width: "49%"}} className={Styles.LoginButton} onClick={deleteAsset}>{t("CREATE_ACCOUNT.DELETE")} </button>
                     </div>
                  </div>
               </div>
            </div> : <></>}
         {confirmSubmission ? <div></div> :
            <div className={Classnames("modal fade show", Styles.modalBlur)} id="exampleModalCenter"
                 tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
               <div className="modal-dialog modal-dialog-centered" role="document">

                  <div className={Classnames("modal-content", Styles.modalContentCustom)}
                       style={{backgroundColor: "#f5f5f5", Width: '100%'}}>
                     <div className={Classnames("modal-header", Styles.modalFooterLine)}>

                        <font className={Classnames(Styles.modalTitle, "w-100", "mb-2", "mt-0", "text-center")}
                              id="exampleModalCenterTitle" style={{fontSize: "15px"}}>
                           {t("CREATE_ACCOUNT.PLEASE_CROP_YOUR_IMAGE")}
                        </font>
                     </div>

                     <div className="modal-body p-4 pt-1 text-center">
                        <ImageCrop image={Image}
                                   onCropModalHide={onCropModalHide}
                                   type={type} userName={props.userName}
                                   email={data.email} Token={props.Token}/>
                     </div>
                  </div>
               </div>
            </div>}
         {
            showModal ? <VerifyCodeV2 email={data.email} password={data.password} user={isSkip ? defaultUser : user}
                                      onCloseModal={handleModalClose}/> : <></>
         }

      </React.Fragment>
   );
};


const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};
const mapDispatchToProps = dispatch => {
   return {
      onAuth: (accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email) => dispatch(actions.auth(accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email))
   }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
