import React, {useEffect, useState} from 'react';

class WorkerInterval {
   worker = null;

   constructor(callback, interval) {
      const blob = new Blob([`setInterval(() => postMessage(0), ${interval});`]);
      const workerScript = URL.createObjectURL(blob);
      this.worker = new Worker(workerScript);
      this.worker.onmessage = callback;
   }

   stop() {
      this.worker.terminate();
   }
}

const ProgressBar = ({duration, interval, disable}) => {
   const [progress, setProgress] = useState(100);

   useEffect(() => {
      const workerInterval = new WorkerInterval(() => {
         setProgress((prevProgress) => {
            if (prevProgress <= 0) {
               workerInterval.stop();
               return 0;
            }
            return prevProgress - 0.5;
         });
      }, interval / 100);

      return () => {
         workerInterval.stop();
      };
   }, [interval]);

   useEffect(() => {
      if (progress === 0) {
         disable(true);
      } else {
         disable(false);
      }
   }, [progress, disable]);

   const getColor = () => {
      if (progress > 50) {
         return 'linear-gradient(to bottom, #FAFF01 0%, #39FF96 100%)'; // Initial gradient color
      } else if (progress > 25) {
         return 'linear-gradient(to bottom, #FAFF01 0%, #FFC300 100%)'; // Yellow gradient
      } else {
         return 'linear-gradient(to bottom, #C70039 0%, #C70039 100%)'; // Red color
      }
   };

   return (
      <div>
         <div
            style={{
               width: `${progress}%`,
               height: '10px',
               backgroundColor: 'blue',
               backgroundImage: getColor()
            }}
         ></div>
      </div>
   );
};

export default ProgressBar;
