import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import Styles from "./MatchTimer.module.scss";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import {useLocation} from "react-router-dom";

const MatchTimer = ({targetDate, matchId}) => {

   const {t} = useTranslation();
   const location = useLocation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [remainingTimerDays, setRemainingTimerDays] = useState(0);
   const [remainingTimerHours, setRemainingTimerDaysHours] = useState(0);
   const [remainingTimerMinutes, setRemainingTimerMinutes] = useState(0);
   const [remainingTimerSeconds, setRemainingTimerSeconds] = useState(0);

   const renderTimeDays = ({remainingTime}) => {
      setRemainingTimerDays(remainingTime);
   }
   const renderTimeHours = ({remainingTime}) => {
      setRemainingTimerDaysHours(remainingTime);
   }
   const renderTimeMinutes = ({remainingTime}) => {
      setRemainingTimerMinutes(remainingTime);
   }
   const renderTimeSeconds = ({remainingTime}) => {
      setRemainingTimerSeconds(remainingTime);
   }

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);
   const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const targetTime = new Date(targetDate).getTime();

      const difference = targetTime - now;

      if (difference > 0) {
         return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
         };
      }
      return {days: 0, hours: 0, minutes: 0, seconds: 0};
   };

   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

   useEffect(() => {
      const timer = setInterval(() => {
         setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearInterval(timer);
   }, [targetDate]);

   return (
      <div className={classNames(Styles.UpcomingBlock)}>
         <div className={classNames(Styles.NowPlayingContainer)}>
            <div className={classNames(Styles.Row1)}>
               <label className={classNames(Styles.upcomingTag)}><span></span>{t("MATCH_TIMER.UPCOMING")}</label>
            </div>
            <div className={classNames(Styles.Row2)}>
               <h2>{t("MATCH_TIMER.TOURNAMENT_STARTS")}</h2>
               <div className={classNames(Styles.countdown)}>
                  {timeLeft.days > 0 &&
                     <div>
                        <div className={classNames(Styles.cercalBack)}>
                           <div className={classNames(Styles.cercal)}>
                              <span>{String(timeLeft.days).padStart(2, '0')}</span>
                           </div>
                        </div>
                        <span>{t("MATCH_TIMER.DAYS")}</span>
                        <div className={classNames(Styles.timerWrapper)}>
                           {timeLeft.days > 0 ? <CountdownCircleTimer
                              isPlaying={timeLeft.days != remainingTimerDays}
                              duration={30}
                              updateInterval={60}
                              strokeWidth={timeLeft.days < 0 ? 0 : 8}
                              initialRemainingTime={timeLeft.days}
                              strokeLinecap="round"
                              rotation="counterclockwise"
                              isSmoothColorTransition={true}
                              colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                              colorsTime={[10, 6, 3, 0]}
                              onComplete={() => ({shouldRepeat: true, delay: 1})}>
                              {renderTimeDays}
                           </CountdownCircleTimer> : <></>}
                        </div>
                     </div>
                  }
                  <div>
                     <div className={classNames(Styles.cercalBack)}>
                        <div className={classNames(Styles.cercal)}>
                           <span>{timeLeft.minutes >= 0 ? <> {String(timeLeft.hours).padStart(2, '0')}</> : "00"}</span>
                        </div>
                     </div>
                     <span>{t("MATCH_TIMER.HOURS")}</span>
                     <div className={classNames(Styles.timerWrapper)}>
                        {timeLeft.hours > 0 ? <CountdownCircleTimer
                           isPlaying={timeLeft.hours != remainingTimerHours}
                           initialRemainingTime={timeLeft.hours}
                           duration={24}
                           updateInterval={60}
                           strokeWidth={timeLeft.hours < 0 ? 0 : 8}
                           strokeLinecap="round"
                           rotation="counterclockwise"
                           isSmoothColorTransition={true}
                           colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                           colorsTime={[10, 6, 3, 0]}
                           onComplete={() => ({shouldRepeat: true, delay: 1})}>
                           {renderTimeHours}
                        </CountdownCircleTimer> : <></>}
                     </div>
                  </div>
                  <div>
                     <div className={classNames(Styles.cercalBack)}>
                        <div className={classNames(Styles.cercal)}>
                           <span>{timeLeft.minutes >= 0 ? <> {String(timeLeft.minutes).padStart(2, '0')}</> : "00"}</span>
                        </div>
                     </div>
                     <span>{t("MATCH_TIMER.MINUTES")}</span>
                     <div className={classNames(Styles.timerWrapper)}>
                        {timeLeft.minutes > 0 ? <CountdownCircleTimer
                           isPlaying={timeLeft.minutes != remainingTimerMinutes}
                           initialRemainingTime={timeLeft.minutes}
                           duration={60}
                           updateInterval={60}
                           strokeWidth={timeLeft.minutes < 0 ? 0 : 8}
                           strokeLinecap="round"
                           rotation="counterclockwise"
                           isSmoothColorTransition={true}
                           colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                           colorsTime={[10, 6, 3, 0]}
                           onComplete={() => ({shouldRepeat: true, delay: 1})}>
                           {renderTimeMinutes}
                        </CountdownCircleTimer> : <></>}
                     </div>
                  </div>
                  {timeLeft.days <= 0 &&
                     <div>
                        <div className={classNames(Styles.cercalBack)}>
                           <div className={classNames(Styles.cercal)}>
                              <span>{timeLeft.minutes >= 0 ? <> {String(timeLeft.seconds).padStart(2, '0')}</> : "00"}</span>
                           </div>
                        </div>
                        <span>{t("MATCH_TIMER.SECONDS")}</span>
                        <div className={classNames(Styles.timerWrapper)}>
                           {timeLeft.seconds > 0 ? <CountdownCircleTimer
                              isPlaying={timeLeft.seconds != remainingTimerSeconds}
                              duration={60}
                              updateInterval={1}
                              strokeWidth={timeLeft.seconds < 0 ? 0 : 8}
                              initialRemainingTime={timeLeft.seconds}
                              strokeLinecap="round"
                              rotation="counterclockwise"
                              isSmoothColorTransition={true}
                              colors={["#FAFF00", "#FAFF00", "#00FFC2", "#00FFC2"]}
                              colorsTime={[10, 6, 3, 0]}
                              onComplete={() => ({shouldRepeat: true, delay: 0})}>
                              {renderTimeSeconds}
                           </CountdownCircleTimer> : <></>}
                        </div>
                     </div>}
               </div>
            </div>
         </div>
      </div>
   );
};

export default MatchTimer;
