import {useEffect, useState} from "react";

const useScreenSize = () => {
   const [screenWidth, setScreenWidth] = useState(window.innerWidth);
   const [screenHeight, setScreenHeight] = useState(window.innerHeight);
   useEffect(() => {
      const handleResize = () => {
         setScreenWidth(window.innerWidth);
         setScreenHeight(window.innerHeight);
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   return {screenWidth, screenHeight};
}

export default useScreenSize
